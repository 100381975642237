import * as MS from '@electreon/electreon-metadata-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class MetadataService {
  public bookmarks: MS.BookmarksControllerApi;
  public depots: MS.DepotsControllerApi;
  public parkingSpots: MS.ParkingSpotsControllerApi;
  public projects: MS.ProjectsControllerApi;
  public roads: MS.RoadsControllerApi;
  public roadSections: MS.RoadSectionsControllerApi;
  public rules: MS.RulesControllerApi;
  public fleets: MS.FleetsControllerApi;

  constructor(basePath: string, axios: AxiosInstance, config?: MS.Configuration) {
    this.bookmarks = new MS.BookmarksControllerApi(config, basePath, axios);
    this.depots = new MS.DepotsControllerApi(config, basePath, axios);
    this.parkingSpots = new MS.ParkingSpotsControllerApi(config, basePath, axios);
    this.projects = new MS.ProjectsControllerApi(config, basePath, axios);
    this.roads = new MS.RoadsControllerApi(config, basePath, axios);
    this.roadSections = new MS.RoadSectionsControllerApi(config, basePath, axios);
    this.rules = new MS.RulesControllerApi(config, basePath, axios);
    this.fleets = new MS.FleetsControllerApi(config, basePath, axios);
  }
}
