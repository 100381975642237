import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { EditDeviceFormProps } from 'Components/Forms/EditDeviceForm/EditDeviceForm';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { api } from 'Services/api';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { vehicleTypes } from '@electreon_ui/shared/stores/deviceStore/deviceStoreTypes';
import { toast } from 'sonner';

const EditVuSchema = z.object({
  deviceName: z
    .string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: z
    .string()
    .min(1, 'Device ID is required')
    .refine(async (value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  projectName: z.string().optional(),
  prefix: z.string().min(1, 'Prefix is required'),
  vehicleType: z.enum(vehicleTypes).refine((value) => {
    return vehicleTypes.includes(value);
  }, 'Vehicle type is required'),
  batteryCapacity: z.string().optional(),
  ignoreAlerts: z.boolean().optional(),
});

type EditVuInput = z.TypeOf<typeof EditVuSchema>;

export const EditVuForm: React.FC<EditDeviceFormProps> = ({
  device,
  onSuccessfulSubmit,
  onCancel,
  projectList,
  selectedProject: _selectedProject,
}) => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(_selectedProject ?? null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { deviceStore } = useAppStore();
  const deviceChangeVersion = deviceStore.versions?.[device.id!]?.deviceChangesVersion;

  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    watch,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<EditVuInput>({
    resolver: zodResolver(EditVuSchema),
    defaultValues: {
      deviceName: device.name,
      deviceId: device.id!.replace('VU', ''),
      projectName: selectedProject?.name,
      prefix: 'VU',
      vehicleType: device.vehicleType ?? vehicleTypes[0],
      ignoreAlerts: false,
    },
  });

  const { deviceNameAvaliable } = useInputAvailability(
    watch,
    selectedProject?.id,
    setError,
    clearErrors,
    'VU',
    device
  );

  const onSubmitHandler: SubmitHandler<EditVuInput> = (values) => {
    setLoading(true);
    setSubmitError(null);
    const { deviceName, deviceId, prefix, ignoreAlerts } = values;
    const vehicleType = values.vehicleType;
    api.deviceMetadata.vu
      .updateVehicleUnit({
        // todo: change api to updateVUDevice
        ...device,
        deviceType: 'VU',
        id: `${prefix}${deviceId}`,
        name: deviceName,
        projectId: selectedProject?.id as number,
        vehicleType,
        version: deviceChangeVersion || device.version,
        deviceSubType: device.deviceSubType,
        timezone: selectedProject?.timezoneStr ?? 'UTC',
        batteryCapacity: Number(values.batteryCapacity),
        ignoreAlerts: ignoreAlerts,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('VU device updated');
          reset();
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('VU device update failed');
          setSubmitError('VU Device update failed');
          throw new Error('Update VU device failed');
        } else if (res.status === 409) {
          toast.error('VU creation conflict');
          setSubmitError('VU Creation conflict');
          throw new Error('Update VU device failed');
        } else {
          toast.error('VU device update failed');
          setSubmitError('VU Device update failed');
          throw new Error('Update VU device failed');
        }
      })
      .catch((err) => {
        toast.error('VU device update failed');
        console.error('Update VU device error', JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      const selectedProject = projectList?.find((project) => project.name === value?.projectName);
      setSelectedProject(selectedProject ?? null);
    });

    return () => subscription.unsubscribe();
  }, [watch, projectList]);

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit Vehicle Unit
      </Typography>

      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
          <TextField
            label='Prefix'
            sx={{ ...disabledTextFieldStyle }}
            error={!!errors['prefix']}
            helperText={errors['prefix'] ? errors['prefix'].message : ''}
            disabled
            {...register('prefix')}
          />
          <TextField
            disabled
            sx={{ ...disabledTextFieldStyle }}
            InputLabelProps={{ shrink: !!watch('deviceId') }}
            label='Device ID'
            fullWidth
            error={!!errors['deviceId']}
            helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
            type='number'
            value={watch('deviceId')}
            {...register('deviceId')}
          />
          <TextField
            value={device.deviceSubType ?? '--'}
            disabled
            sx={{ ...disabledTextFieldStyle }}
            label='Subtype'
            fullWidth
          />
        </Stack>
        <Autocomplete
          sx={{ mb: 2 }}
          options={projectList ?? []}
          getOptionLabel={(option) => (option as { name: string }).name}
          defaultValue={selectedProject}
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.id} defaultValue={option.name}>
              {option.name}
            </MenuItem>
          )}
          onChange={(e: any) => {
            const selectedProject = projectList?.find((project) => project.name === e.target.outerText);
            setSelectedProject(selectedProject ?? null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Project Name'
              fullWidth
              error={!!errors['projectName']}
              helperText={errors['projectName'] ? errors['projectName'].message : ''}
              {...register('projectName')}
              value={String(selectedProject?.id || '')}
            />
          )}
        />
        <TextField
          sx={{ mb: 2 }}
          label='Device Name'
          fullWidth
          type='deviceName'
          error={!!errors['deviceName']}
          helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <InputAdornmentIcon isValid={deviceNameAvaliable} />
              </InputAdornment>
            ),
          }}
          {...register('deviceName')}
        />
        <TextField
          select
          sx={{ mb: 2 }}
          label='Vehicle Type'
          fullWidth
          error={!!errors['vehicleType']}
          helperText={errors['vehicleType'] ? errors['vehicleType'].message : ''}
          value={watch('vehicleType') || ''}
          {...register('vehicleType')}
        >
          <MenuItem value='BUS'>Bus</MenuItem>
          <MenuItem value='TRUCK'>Truck</MenuItem>
          <MenuItem value='PRIVATE'>Private</MenuItem>
          <MenuItem value='VAN'>Van</MenuItem>
          <MenuItem value='DELIVERY_TRUCK'>Delivery Truck</MenuItem>
        </TextField>
        <TextField
          sx={{ mb: 2 }}
          label='Battery Capacity'
          fullWidth
          type='number'
          error={!!errors['batteryCapacity']}
          helperText={errors['batteryCapacity'] ? errors['batteryCapacity'].message : ''}
          {...register('batteryCapacity')}
        />
        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <FormControlLabel
            control={<Checkbox defaultChecked={!!device?.ignoreAlerts} />}
            {...register('ignoreAlerts')}
            label='Ignore Alerts for This Device'
          />
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons loading={loading} onCancel={onCancel} />
      </Box>
    </>
  );
};
