import { UserRole, ScopeTypes } from '../../constants/constants';
import { UserData } from './UserStore';

type Permissions = typeof defaultPermissions;

export const getUserScopeType = (user: UserData) => {
  const isGlobalScoped = user?.scope === null;
  const isSingleScoped = user?.scope?.length === 1 && !isGlobalScoped;
  const userScopeType = isGlobalScoped
    ? ScopeTypes.GLOBAL
    : isSingleScoped
      ? ScopeTypes.SINGLE
      : ScopeTypes.MULTI;

  return userScopeType;
};

export const getUserPermissions = (userRole?: UserRole): Permissions => {
  if (!isUserRole(userRole)) return defaultPermissions;
  const { PROJECT_VIEWER, PROJECT_USER, OPERATOR, INTEGRATOR, ADMIN, SUPER_ADMIN } = UserRole;

  const isRoleMatching = (allowedRoles: UserRole[]) => {
    if (!userRole) return false;
    if (userRole === SUPER_ADMIN) return true; // super admin can do everything
    return allowedRoles.includes(String(userRole).toUpperCase() as UserRole);
  };

  return {
    canLock: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeAppMode: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeSMUChargingMode: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeContactorState: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canControlAPFC: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canControlIdleOff: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canControlForceBtns: isRoleMatching([ADMIN, INTEGRATOR]),
    canControlFans: isRoleMatching([ADMIN, INTEGRATOR]),
    canDisableSegment: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeComm: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeConfig: isRoleMatching([ADMIN, INTEGRATOR]),
    canDebug: isRoleMatching([ADMIN, INTEGRATOR]),
    canControlReceivers: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canAddProject: isRoleMatching([ADMIN]),
    canDeleteProject: isRoleMatching([]),
    canEditProject: isRoleMatching([ADMIN]),
    canUnlock: isRoleMatching([]),
    canChangePhase: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canCreateDevice: isRoleMatching([ADMIN, INTEGRATOR]),
    canEditDevice: isRoleMatching([ADMIN, INTEGRATOR]),
    canGetDeviceShadow: isRoleMatching([ADMIN, INTEGRATOR]),
    canViewAdvancedParameters: isRoleMatching([ADMIN, INTEGRATOR, OPERATOR]),
    canChangeHybridSmuVoltage: isRoleMatching([ADMIN, INTEGRATOR]),
    canControlHybridIdleAll: isRoleMatching([ADMIN, INTEGRATOR]),
  } as const;
};

const isUserRole = (role?: string): role is UserRole => {
  return Object.values(UserRole).includes(role as UserRole);
};

const defaultPermissions = {
  canControlIdleOff: false,
  canControlForceBtns: false,
  canControlAPFC: false,
  canControlFans: false,
  canDisableSegment: false,
  canChangeConfig: false,
  canChangeComm: false,
  canDebug: false,
  canControlReceivers: false,
  canAddProject: false,
  canDeleteProject: false,
  canEditProject: false,
  canLock: false,
  canUnlock: false,
  canChangePhase: false,
  canCreateDevice: false,
  canEditDevice: false,
  canGetDeviceShadow: false,
  canViewAdvancedParameters: false,
  canChangeAppMode: false,
  canChangeSMUChargingMode: false,
  canChangeContactorState: false,
  canChangeHybridSmuVoltage: false,
  canControlHybridIdleAll: false,
};
