import { useRef, useEffect, CSSProperties, useCallback } from 'react';
import { defaultDrawingManagerOptions, defaultMapOptions } from 'Components/Map/mapUtils';
// Using the following import due to a shim that is needed for the google maps types
// This import is required to initialize Google Maps types globally.
// Without it, TypeScript will throw errors about missing Google Maps types.
import {} from '@react-google-maps/api';

interface MapLoaderProps {
  center: google.maps.LatLngLiteral;
  zoom: number;
  polygons: google.maps.Polygon[];
  setPolygons: React.Dispatch<React.SetStateAction<google.maps.Polygon[]>>;
  mapOptions?: google.maps.MapOptions;
  drawingManagerOptions?: google.maps.drawing.DrawingManagerOptions;
  polygonColor?: CSSProperties['stroke'];
  polygonHighlightColor?: CSSProperties['stroke'];
}

export type GeoJsonMultipolygon = {
  type?: 'MultiPolygon';
  coordinates?: MultiPolygonCoordinates;
};

export type MultiPolygonCoordinates = number[][][][];

export const MapLoader: React.FC<MapLoaderProps> = ({
  center,
  zoom,
  polygons,
  setPolygons,
  mapOptions,
  drawingManagerOptions,
  polygonColor = '#2b2b2b',
  polygonHighlightColor = '#51c8f3',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map>();
  const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();

  const attachPolygonListeners = useCallback(
    (polygon: google.maps.Polygon) => {
      polygon.addListener('rightclick', () => {
        polygon.setMap(null);
        setPolygons((prevPolygons) => prevPolygons.filter((p) => p !== polygon));
      });
    },
    [setPolygons]
  );

  useEffect(() => {
    if (!mapRef.current && ref.current) {
      mapRef.current = new window.google.maps.Map(ref.current, {
        center,
        zoom,
        ...(mapOptions ?? defaultMapOptions),
      });
    }

    polygons.forEach((polygon) => {
      polygon.setMap(mapRef.current!);
      attachPolygonListeners(polygon);
    });

    if (!drawingManagerRef.current) {
      drawingManagerRef.current = new google.maps.drawing.DrawingManager({
        map: mapRef.current,
        ...(drawingManagerOptions ?? defaultDrawingManagerOptions),
      });

      drawingManagerRef.current.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
        setPolygons((prevPolygons) => [...prevPolygons, polygon]);
        drawingManagerRef.current?.setDrawingMode(null);
        attachPolygonListeners(polygon);
      });
    }

    if (polygons.length > 0 && mapRef.current) {
      polygons.forEach((polygon) => {
        polygon.setMap(mapRef.current!);
      });
    }
  }, [
    center,
    drawingManagerOptions,
    mapOptions,
    polygons,
    setPolygons,
    zoom,
    polygonColor,
    polygonHighlightColor,
    attachPolygonListeners,
  ]);

  useEffect(() => {
    if (mapRef.current) {
      // Create the search box and link it to the UI element
      const searchBox = new google.maps.places.SearchBox(
        document.getElementById('pac-input') as HTMLInputElement
      );

      // Bias the SearchBox results towards the current map's viewport
      mapRef.current.addListener('bounds_changed', () => {
        searchBox.setBounds(mapRef.current?.getBounds() as google.maps.LatLngBounds);
      });

      let markers: google.maps.Marker[] = [];

      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces();

        if (places?.length === 0) return;

        // Clear out the old markers
        markers.forEach((marker) => marker.setMap(null));
        markers = [];

        // Set new markers for each place found
        const bounds = new google.maps.LatLngBounds();
        places?.forEach((place) => {
          if (!place.geometry || !place.geometry.location) return;

          markers.push(
            new google.maps.Marker({
              map: mapRef.current,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });

        mapRef.current?.fitBounds(bounds);
      });
    }
  }, []);

  return <div ref={ref} id='map' style={{ width: '100%', height: '100%' }}></div>;
};
