import { observer } from 'mobx-react-lite';
import { useAppStore } from 'MobxStores/context';
import { Button, Typography, Stack } from '@mui/material';
import { AlertsTimeRangeSelector } from 'Screens/AlertDashboard/AlertsControls/AlertsTimeRangeSelector';
import { Functionalities, WithPermission } from '@electreon_ui/shared/permissions';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { HeaderDivider } from 'Components/Header/Components/HeaderDivider';
import { isTimeRangeSelectorDisabled } from 'Utils/featureFlags';
import { openInNewTab } from '../alertUtils';
import { AlertReadStatus } from 'MobxStores/utils/alertStoreTypes';

export const AlertsControls: React.FC<{
  selectedTimeRange: number | string;
  setSelectedTimeRange: React.Dispatch<React.SetStateAction<number>>;
}> = observer(({ selectedTimeRange, setSelectedTimeRange }) => {
  const { alertStore, popupStore, userStore, projectStore } = useAppStore();
  const isNotificationPopup = !location.pathname.includes('/dashboard/alerts');

  return (
    <Stack
      width='100%'
      height='30px'
      margin='auto'
      display='flex'
      direction='row'
      justifyContent='space-between'
      gap='10px'
      marginTop={'20px'}
      marginBottom={'20px'}
      padding={4}
      alignItems={'center'}
    >
      <Stack display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap={5}>
        <Typography sx={{ fontSize: '34px !important' }}> Notifications</Typography>
        {!isTimeRangeSelectorDisabled && (
          <AlertsTimeRangeSelector
            selectedTimeRange={selectedTimeRange}
            setSelectedTimeRange={setSelectedTimeRange}
            disabled={isTimeRangeSelectorDisabled}
          />
        )}
      </Stack>
      <WithPermission for={Functionalities.ALERTS_READ_ALL} userData={userStore.userData}>
        <Stack direction='row' gap='10px' alignItems='center' divider={<HeaderDivider />}>
          <Button
            variant='text'
            onClick={() => {
              if (!isNotificationPopup) {
                alertStore.setAlertsStatus(AlertReadStatus.READ);
                return;
              }
              const selectedProjectAlerts = projectStore.selectedProject?.id
                ? alertStore.projectAlerts[projectStore.selectedProject.id]
                : null;
              alertStore.setAlertsStatus(
                AlertReadStatus.READ,
                selectedProjectAlerts || alertStore.projectAlerts.allSites
              );
            }}
          >
            Mark All Read
          </Button>
          {isNotificationPopup ? (
            <Button
              variant='text'
              onClick={() => {
                const selectedProjectId = projectStore.selectedProject?.id;
                return openInNewTab(
                  selectedProjectId ? `/dashboard/alerts/project/${selectedProjectId}` : '/dashboard/alerts',
                  () => {
                    popupStore.closeNotificationsPopup();
                    const selectedProjectAlerts = projectStore.selectedProject?.id
                      ? alertStore.projectAlerts[projectStore.selectedProject.id]
                      : null;
                    alertStore.setAlertsStatus(
                      AlertReadStatus.READ,
                      selectedProjectAlerts || alertStore.projectAlerts.allSites
                    );
                  }
                );
              }}
            >
              POP-OUT
              <OpenInFullIcon sx={{ fontSize: '14px', transform: 'translateY(1px)' }} />
            </Button>
          ) : (
            ''
          )}
        </Stack>
      </WithPermission>
    </Stack>
  );
});
AlertsControls.displayName = 'AlertsControls';
