import React, { useEffect } from 'react';
import { Tab, Tabs, IconButton, Avatar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'MobxStores/context';

// Utils
import 'Screens/DepotDashboard/DepotDashboard.scss';
import { FiberManualRecord as RedDot } from '@mui/icons-material';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { AlertTab } from './Hooks/useProjectAlertsTabs';
import { useProjectLogo } from 'Utils/ProjectUtils';

interface AlertsDashboardTabsProps {
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  alertsTabs: AlertTab[];
}

export const AlertsDashboardTabs: React.FC<AlertsDashboardTabsProps> = observer(
  ({ alertsTabs, selectedTab, setSelectedTab }) => {
    const isPopOutPage = location.pathname.includes('/dashboard/alerts');
    const { alertStore, popupStore } = useAppStore();
    const shouldShowRedDot = Object.keys(alertStore.projectAlerts).map((projectId) => {
      const showRedDot =
        projectId === 'allSites'
          ? alertStore.unreadCriticalAlertCount.allSites > 0
          : alertStore.unreadCriticalAlertCount[projectId] > 0;
      return {
        projectId,
        showRedDot: showRedDot,
      };
    });

    const handleClose = (event: React.MouseEvent<HTMLElement>, index: number, projectId: string) => {
      // Prevent closing the last tab
      event.stopPropagation();
      if (alertsTabs.length === 1) return;

      alertStore.clearProjectAlerts(projectId);
      if (selectedTab < index) return;
      if (selectedTab > index) {
        setSelectedTab(selectedTab - 1);
        return;
      }
      const newSelectedTab = index === 0 ? 0 : index - 1;
      setSelectedTab(newSelectedTab);
    };

    return (
      <Tabs
        value={selectedTab}
        onChange={(event, newSelectedTab) => newSelectedTab !== -1 && setSelectedTab(newSelectedTab)}
        sx={{ '.MuiTabs-flexContainer': { alignItems: 'center' } }}
        variant='scrollable'
      >
        {alertsTabs.map((alertsTab: AlertTab, index: number) => (
          <AlertsTabHeader
            key={alertsTab.projectId}
            alertsTab={alertsTab}
            index={index}
            isPopOutPage={isPopOutPage}
            shouldShowRedDot={shouldShowRedDot}
            handleClose={handleClose}
            setSelectedTab={setSelectedTab}
            showCloseButton={alertsTabs.length > 1}
          />
        ))}
        {isPopOutPage && (
          <IconButton
            aria-label='add'
            style={{ height: '40px', width: '40px', color: theme.palette.accent.gray[800] }}
            onClick={popupStore.openProjectSelectionPopup}
          >
            <AddIcon fontSize='small' />
          </IconButton>
        )}
      </Tabs>
    );
  }
);

type AlertsTabHeaderProps = {
  alertsTab: AlertTab;
  index: number;
  isPopOutPage: boolean;
  shouldShowRedDot: { projectId: string; showRedDot: boolean }[];
  handleClose: (event: React.MouseEvent<HTMLButtonElement>, index: number, projectId: string) => void;
  setSelectedTab: (index: number) => void;
  showCloseButton: boolean;
};

const AlertsTabHeader: React.FC<AlertsTabHeaderProps> = ({
  alertsTab,
  index,
  isPopOutPage,
  shouldShowRedDot,
  handleClose,
  setSelectedTab,
  showCloseButton,
}) => {
  const isAllSitesTab = !!alertsTab.label.toLowerCase().includes('all sites');
  const shouldShowLogo = !isAllSitesTab && isPopOutPage;
  const { data: projectLogo } = useProjectLogo(alertsTab?.projectId, 'small');

  return (
    <Tab
      key={`alerts-tab-header-${alertsTab.projectId}`}
      label={
        <span style={{ margin: 0, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {projectLogo && shouldShowLogo ? (
            <Avatar
              alt='project logo'
              src={projectLogo}
              sx={{
                width: '25px',
                height: '25px',
                padding: 0,
                '& img': {
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  backgroundColor: 'transparent',
                },
              }}
            />
          ) : null}
          <span style={{ marginLeft: 1 }}> {alertsTab.label}</span>
          {showCloseButton && (
            <IconButton
              aria-label='close'
              onClick={(event) => handleClose(event, index, alertsTab.projectId)}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          )}
        </span>
      }
      sx={
        index === 0
          ? { marginLeft: 8, opacity: 1, minHeight: 48 }
          : { marginLeft: 1, opacity: 1, minHeight: 48 }
      }
      icon={
        shouldShowRedDot.find((showRedDot) => showRedDot.projectId === alertsTab.projectId)?.showRedDot ? (
          <RedDot style={{ color: theme.palette.error.main, margin: 0, opacity: '100%', fontSize: '14px' }} />
        ) : undefined
      }
      iconPosition='end'
      onClick={() => setSelectedTab(index)}
    />
  );
};
