import { UserRole } from '@electreon_ui/shared/constants/constants';
import { accountTypes } from '@electreon_ui/shared/stores/authStore/authStoreUtils';
import UserStore from '@electreon_ui/shared/stores/userStore/UserStore';
import { DecodedIdToken, DecodedTokens } from 'aws';
import LogRocket from 'logrocket';

export const setUserInfo = (userData: DecodedTokens, store: UserStore) => {
  const getRoleFromIdToken = (decodedIdToken: DecodedIdToken): UserRole | undefined => {
    if (!Array.isArray(decodedIdToken['cognito:groups'])) return decodedIdToken['cognito:groups'];
    const groups = decodedIdToken['cognito:groups'];
    const role = groups.find((group) => group.match(/^[A-Z]+[_A-Z]+[A-Z]+$/));
    if (!role) return undefined;
    return role as UserRole;
  };

  let { IdToken /* , AccessToken, RefreshToken */ } = userData;
  const user = {
    name: IdToken['cognito:username'],
    username: IdToken.email,
    role: getRoleFromIdToken(IdToken) || UserRole.PROJECT_VIEWER,
    email: IdToken['email'],
    scope: JSON.parse(IdToken['custom:authorization']).projectScope as number[] | null,
    sub: IdToken.sub,
    givenName: IdToken.given_name || '',
    familyName: IdToken.family_name || '',
  };

  store.setUserData(user);

  LogRocket.identify(IdToken.sub, {
    name: `${user.givenName || 'UnknownFirst'} ${user.familyName || 'UnknownLast'}`,
    email: user.email,
    userRole: user.role || 'No role',
    userScope: user.scope?.join(',') ?? 'all-projects',
    accountType: IdToken['cognito:username'].startsWith('google_') ? accountTypes.google : accountTypes.local,
  });
};
