import { Typography, useTheme } from '@mui/material';
import { headerTextStyles } from 'Components/Header/Styles/HeaderStyles';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { CSSProperties, useEffect, useState } from 'react';

interface TimeDisplayProps {
  timeZone: string;
  fontSize?: CSSProperties['fontSize'];
}

export const TimeDisplay: React.FC<TimeDisplayProps> = ({ timeZone, fontSize }) => {
  const theme = useTheme();
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const zonedTime = utcToZonedTime(currentTime, timeZone);

  return (
    <Typography
      variant='dynamicValue'
      fontSize={fontSize || '20px'}
      color={theme.palette.primary.main}
      alignSelf='center'
      minWidth={20}
      sx={headerTextStyles}
    >
      {format(zonedTime, 'HH:mm')}
    </Typography>
  );
};
