import { Alert, Box, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { createOCPPDevice } from '../FormsAPIs/api';
import { getProjectDeployment } from 'Utils/APIUtils';
import { useAppStore } from 'MobxStores/context';
import { WindowEventService, WindowEvents } from '@electreon_ui/shared/services/WindowEventService';
import { DepotSelectionInput } from '../EditAPFC&POSForms/FormInputs/DepotSelectionInput';
import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { CreateOCPPInput, createOCPPSchema } from 'Components/Forms/EditAPFC&POSForms/Utils/FormsSchemas';
import { toast } from 'sonner';

const createOCPPDefaultValues = {
  deviceName: '',
  projectName: '',
  longitude: '',
  latitude: '',
  depotId: 0,
};

export const CreateOCPPForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [depotList, setDepotList] = useState<Depot[]>([]);
    const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
    const [loading, setLoading] = useState(false);
    const { projectStore, popupStore } = useAppStore();
    const methods = useForm<CreateOCPPInput>({
      resolver: zodResolver(createOCPPSchema),
      defaultValues: createOCPPDefaultValues,
    });
    const { errors } = methods.formState;
    const { deviceNameAvaliable } = useInputAvailability(
      methods.watch,
      selectedProject?.id,
      methods.setError,
      methods.clearErrors,
      'OCPP'
    );

    const onSubmitHandler: SubmitHandler<CreateOCPPInput> = (values) => {
      console.log('submitting');
      setSubmitError(null);
      const { deviceName, latitude, longitude, depotId } = values;
      setLoading(true);
      createOCPPDevice({
        name: deviceName,
        projectId: selectedProject?.id as number,
        latitude: Number(latitude),
        longitude: Number(longitude),
        timezone: selectedProject?.timezoneStr || 'Asia/Jerusalem',
      })
        .then((response) => {
          if (response.status !== 201) {
            toast.error('OCPP device creation failed');
            setSubmitError('OCPP device creation failed');
            throw new Error('Failed to create OCPP device');
          }
          toast.success('OCPP device created');
          if (response.status === 201) {
            methods.reset(createOCPPDefaultValues);
            onSuccessfulSubmit?.();
            getProjectDeployment(selectedProject?.id || '').then((deployment) => {
              projectStore.setProjectDeployment(selectedProject?.id, deployment.data);
            });
            WindowEventService.emit(WindowEvents.SHOULD_UPDATE_DEPLOYMENT, {});
            depotId && popupStore.openAssignOCPPPopup(response.data);
          }
        })
        .catch((error) => {
          toast.error('OCPP device creation failed');
          console.error('Failed to create OCPP device', JSON.stringify(error));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // update depots list
    useEffect(() => {
      const getDepots = async () => {
        if (!selectedProject?.id) return;
        try {
          const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
          if (depotRes) {
            setDepotList(depotRes.data);
          }
        } catch (e) {
          setDepotList([]);
          console.error(JSON.stringify(e));
        }
      };
      getDepots();
    }, [selectedProject]);

    return (
      <FormProvider {...methods}>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Create OCPP Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...methods.register('deviceName')}
          />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...methods.register('longitude')}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...methods.register('latitude')}
            />
          </Stack>
          <DepotSelectionInput
            depotList={depotList}
            selectedDepot={selectedDepot}
            setSelectedDepot={setSelectedDepot}
          />
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Create Device' />
        </Box>
      </FormProvider>
    );
  }
);
