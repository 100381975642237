import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'MobxStores/context';

// Components
import { AlertsControls } from 'Screens/AlertDashboard/AlertsControls/AlertsControls';

// Utils
import { TIME_RANGE_IN_MINUTES } from 'Screens/AlertDashboard/AlertsControls/AlertsTimeRangeSelector';
import 'Screens/DepotDashboard/DepotDashboard.scss';
import { Functionalities, getAccessLevel } from '@electreon_ui/shared/permissions';
import { AccessLevels } from '@electreon_ui/shared/permissions/types/permissionsTypes';
import { useGetLatestAlerts } from './Hooks/useLatestAlerts';
import { AlertsDashboardTabs } from './AlertsDashboardTabs';
import { AlertsDashboardTabsContents } from './AlertsDashboardTabsContent';
import { useProjectAlertsTabs } from './Hooks/useProjectAlertsTabs';

const AlertsDashboard = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [selectedTimeRange, setSelectedTimeRange] = useState<number>(TIME_RANGE_IN_MINUTES.HOURS_24);
  const { userStore } = useAppStore();
  const navigate = useNavigate();

  const permission = getAccessLevel(Functionalities.ALERTS_BELL_POPUP, userStore.userData);
  // TODO: When this component is converted to a popup, restrict opening rather than navigating away
  if (permission === AccessLevels.DENIED) navigate('/dashboard');

  useGetLatestAlerts(selectedTimeRange);
  const alertsTabs = useProjectAlertsTabs();

  return (
    <Box width='100%' height='100%' id='test-alert-dashboard-warpper' overflow='hidden'>
      <AlertsControls selectedTimeRange={selectedTimeRange} setSelectedTimeRange={setSelectedTimeRange} />
      <Box
        sx={{
          width: '100%',
          height: (theme) => `calc(86vh - ${theme.header?.height})`,
          overflowY: 'hidden',
        }}
      >
        <AlertsDashboardTabs
          alertsTabs={alertsTabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <AlertsDashboardTabsContents
          alertsTabs={alertsTabs}
          selectedTab={selectedTab}
          selectedTimeRange={selectedTimeRange}
        />
      </Box>
    </Box>
  );
};

export default observer(AlertsDashboard);
