import { VehicleUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { VehicleUnitModelRefined } from '@electreon_ui/shared/types/globals';
import { api } from 'Services/api';

export const getUnassignedVus = (vusList: VehicleUnitModelRefined[]) => {
  const getVuPos = (vuId: string) => {
    return api.deviceMetadata.vu
      .getPos(vuId)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          throw new Error(`Failed to fetch POS for ${vuId}: Status ${res.status}`);
        }
      })
      .catch((error) => {
        console.error('An error occurred while fetching data:', error);
        throw error;
      });
  };

  return Promise.all(vusList.map((vu) => getVuPos(vu.id!).then((data) => (data.length === 0 ? vu : null)))).then(
    (results) => results.filter((vu) => vu !== null) as VehicleUnitModel[] | []
  );
}

export const getAssignedVu = async (selectedPosVuId: string) => {
  if (!selectedPosVuId) return noVuAssigned;
  return (await api.deviceMetadata.vu.getVehicleUnit(selectedPosVuId)).data;
};

export const getChipContainerHeight = (chipsNumber: number) =>
  chipsNumber <= 2 ? 55 : chipsNumber >= 6 ? 135 : 35 * Math.round(chipsNumber / 2) + 30;

export const SelectMusMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: '200px',
    },
  },
};

export const noVuAssigned: VehicleUnitModel = { name: 'No VU Assigned', id: '' };
