import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { UiDevice } from '@electreon_ui/shared/types/globals';
import { DeviceSearchResultsManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { getSearchResults } from 'Utils/APIUtils';

export const useSearch = ({ page, search }: { page: string | null; search: string | null }) => {
  const [searchDevices, setSearchDevices] = useState<Array<UiDevice> | null>(null);
  const [debouncedSearchValue] = useDebounce(search, 600);

  useEffect(() => {
    if (debouncedSearchValue && !page) {
      try {
        getSearchResults({ searchText: debouncedSearchValue }).then((res) => {
          if (res && res.length) {
            const deviceList = res.reduce(
              (acc: Array<UiDevice>, searchRes: DeviceSearchResultsManagementUnitModel) => {
                if (searchRes?.devices?.length) {
                  acc.push(...searchRes.devices);
                }
                return acc;
              },
              []
            );
            setSearchDevices(deviceList);
          }
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      setSearchDevices(null);
    }
  }, [debouncedSearchValue, page]);

  return { searchDevices, setSearchDevices };
};
