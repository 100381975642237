import { checkRoadName } from 'Components/Forms/FormsAPIs/api';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useRoadNameAvailability = (
  currentRoadName: string,
  roadName: string,
  projectId: number | undefined
) => {
  const [roadNameAvaliable, setRoadNameAvaliable] = useState<boolean>(true);
  const debouncedCheckRoadName = debounce(
    (roadName: string | undefined, selectedProjectId: number | undefined) => {
      if (currentRoadName === roadName) {
        setRoadNameAvaliable(true);
        return;
      }
      if (!roadName) {
        setRoadNameAvaliable(false);
        return;
      }
      checkRoadName(selectedProjectId || 0, roadName)
        .then((res) => {
          setRoadNameAvaliable(!!res.data);
        })
        .catch((error) => console.error(error));
    },
    1500
  );
  useEffect(() => {
    debouncedCheckRoadName(roadName, projectId);
  }, [roadName, projectId, debouncedCheckRoadName]);

  return { roadNameAvaliable };
};
