import { useAppStore } from 'MobxStores/context';
import { getSessionId } from 'Utils/SOMApi';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { hasDashboard } from 'dashboardConfig';

export const useAutoNavigate = () => {
  const { userStore } = useAppStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const isLoginEmployeePage = location.pathname.includes('/oauth2/callback');
  const isForceChangePasswordPage = location.pathname === '/force_change_password';

  // const userScope = userStore.userData?.scope;
  // const firstProjectIdInScope = userScope?.[0];
  // const userRole = userStore.userData?.role;
  // const isSingleProjectScoped = userScope?.length === 1;
  // const isDashboardAvailableForSingleProject = hasDashboard(firstProjectIdInScope);

  const navigationRules: Map<boolean[], string> = useMemo(() =>
    new Map([
      [[userStore.userData?.email === 'afikimDM@electreon.com'], '/dashboard/live/depot/129/40'],
      [[userStore.userData?.email === 'caesarea_manager@electreon.com'], '/dashboard/live/depot/132/39'],
    ])
    , [userStore.userData?.email]);



  useEffect(() => {
    // run the rules of the keys in the map, if true, navigate to the value
    navigationRules.forEach((value, key) => {
      if (key.every((condition) => condition)) {
        navigate(value);
      }
    });
  }, [userStore.userData?.email, navigate, navigationRules]);

  useEffect(() => {
    const storageSessionId = getSessionId('Router/Layout');
    if (!storageSessionId && !userStore.sub && !isLoginPage && !isLoginEmployeePage && !isForceChangePasswordPage) {
      console.error('No session id found, redirecting to login');
      navigate('/');
    }
  }, [userStore.sub, navigate, isLoginPage, isLoginEmployeePage, isForceChangePasswordPage]);
};
