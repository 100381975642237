import { setUserInfo } from 'aws/authUtils';
import axios from 'axios';
import { useAppStore } from 'MobxStores/context';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirectAuth = (onError: () => void, redirectAuthCode: string | null = null) => {
  const { userStore, authStore } = useAppStore();
  const navigate = useNavigate();
  const [loginInProgress, setLoginInProgress] = useState(false);
  const cancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    if (redirectAuthCode && !loginInProgress) {
      setLoginInProgress(true);
      authStore
        .handleLoginRedirect(redirectAuthCode)
        .then((session: any | null) => {
          setUserInfo(session, userStore);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.IdToken;
          navigate('/dashboard');
        })
        .catch((err) => {
          console.error('Login attempt with redirection code failed', err);
          onError();
        });
    }

    return () => {
      cancelTokenSource.cancel();
    };
  }, [authStore, redirectAuthCode, authStore.IdToken, loginInProgress, onError, userStore, navigate, cancelTokenSource]);
};
