import { makeAutoObservable } from 'mobx';
import { UserRole } from '../../constants/constants';
import { LockInfo } from '../../types/socketMessageTypes';

class DeviceLockStore {
  public lockedDevices: Record<string, LockInfo | { [displayName: string]: string }> = {};
  public lockedByMe: Record<string, boolean> = {};

  constructor() {
    makeAutoObservable(this);
  }

  addLockedDevice(deviceId?: string, lockInfo?: LockInfo, sessionId?: string) {
    //todo: handle errors.
    if (!deviceId) return;
    if (!lockInfo) {
      // this is called from LockButton => lockedByMe
      // the lockInfo and the lockedDevices will be updated when the data comes from the socket.
      this.lockedByMe[deviceId] = true;
      this.lockedDevices[deviceId] = { displayName: 'Device Locked by You' };
      return;
    }
    this.lockedDevices[deviceId] = { ...lockInfo };
    this.lockedByMe[deviceId] = lockInfo.sessionId === sessionId;
  }

  removeLockedDevice(deviceId?: string) {
    if (!deviceId) return;
    delete this.lockedDevices[deviceId];
    if (this.lockedByMe[deviceId]) {
      this.lockedByMe[deviceId] = false;
    }
  }

  getLockState(deviceId: string) {
    //todo: handle errors.
    return {
      isLocked: !!this.lockedDevices[deviceId],
      isLockedByMe: !!this.lockedByMe[deviceId],
      setupDisabled: (!!this.lockedDevices[deviceId] && !this.lockedByMe[deviceId]) || !this.lockedDevices[deviceId],
      lockedBy: this.lockedDevices[deviceId] ? this.lockedDevices[deviceId].displayName : '',
      lockRole: (this.lockedDevices[deviceId] ? this.lockedDevices[deviceId].role : '') as UserRole,
    };
  }
}

export default DeviceLockStore;
