import { SwitchButtonMui } from 'Components/SwitchButtonMui/SwitchButtonMui';
import { observer } from 'mobx-react-lite';

export const TIME_RANGE_IN_MINUTES = {
  HOURS_24: 60 * 24,
  DAYS_7: 60 * 24 * 7,
  DAYS_30: 60 * 24 * 30,
  YEAR: 60 * 24 * 365,
} as const;

const alertsTimeRangeSelectorOptions: Array<{ value: string | number; label: string }> = [
  { value: TIME_RANGE_IN_MINUTES.HOURS_24, label: 'Last 24 hours' },
  { value: TIME_RANGE_IN_MINUTES.DAYS_7, label: 'Last 7 days' },
];

export const AlertsTimeRangeSelector: React.FC<{
  selectedTimeRange: string | number;
  setSelectedTimeRange: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
}> = observer(({ selectedTimeRange, setSelectedTimeRange, disabled }) => {
  return (
    <SwitchButtonMui
      switchProps={alertsTimeRangeSelectorOptions}
      selectedValue={selectedTimeRange}
      onChange={(_, value) => value && setSelectedTimeRange(Number(value))}
      sx={
        disabled
          ? { opacity: 0.5, cursor: 'not-allowed', '&& *': { fontSize: '12px' }, height: '30px' }
          : { '&& *': { fontSize: '13px' }, height: '30px' }
      }
      isDisabled={disabled}
    />
  );
});
