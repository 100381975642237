import { api } from 'Services/api';

export const TOTAL_SEGMENTS_IN_DMU = 60;

export const getDynamicMuList = async (projectId: number) => {
  try {
    const muList = await api.deviceMetadata.mu.getProjectManagementUnits(projectId);
    return muList.data.devices?.filter((mu) => mu.chargingType === 'DYNAMIC');
  } catch (error) {
    console.error(JSON.stringify(error));
  }
};

export const getDrawerByIndex = (segmentNum: number) => {
  const drawerNumber = Math.floor(segmentNum / 12);
  return drawerNumber;
};
