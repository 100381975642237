import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { EnvType } from '../types/globals';
import { UserData } from '../stores/userStore/UserStore';
import { UserRole } from '../constants/constants';

type ProjectId = number;
type AllowedEmail = string;
type PrivateProjects = Record<EnvType, Record<ProjectId, Array<AllowedEmail>>>;
const privateProjects: PrivateProjects = {
  [EnvType.Dev]: {},
  [EnvType.Stage]: {},
  [EnvType.Prod]: {
    11: [
      'daniel.s@electreon.com',
      'gavish@electreon.com',
      'itay.z@electreon.com',
      'oleg.y@electreon.com',
      'roi.ma@electreon.com',
    ],
  },
};

const isProjectPrivate = (projectId: number | undefined, env: EnvType) =>
  projectId && privateProjects[env].hasOwnProperty(projectId);

const isUserSpecificallyPermitted = (projectId: number | undefined, userData: UserData, env: EnvType) => {
  if (!userData?.email || !projectId) return false;
  if (userData.role === UserRole.ADMIN || userData.role === UserRole.SUPER_ADMIN) return true;
  const allowedEmails = privateProjects[env][projectId];
  if (!allowedEmails) return false;
  return allowedEmails.includes(userData.email);
};

export const isFilteredByUser = (projectId: number | undefined, userData: UserData, env: EnvType) => {
  if (!userData) return false;
  return isProjectPrivate(projectId, env) && !isUserSpecificallyPermitted(projectId, userData, env);
};
