import * as UMS from '@electreon/electreon-user-management-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class UserManagmentService {
    public userManagementApi: UMS.DefaultApi;

    constructor(basePath: string, axios: AxiosInstance, config?: UMS.Configuration) {
        this.userManagementApi = new UMS.DefaultApi(config, basePath, axios);
    }
}
