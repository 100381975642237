import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { Autocomplete, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { SegmentInputs } from 'Components/Forms/RoadForm/Segmentsinputs';
import { ManageRoadInput } from 'Components/Forms/RoadForm/utils/roadSchemas';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const ManagementUnitInputs: React.FC<{
  managementUnit: ManagementUnitModel | null;
  managementUnitIndex: number;
  roadSectionIndex: number;
  dynamicMus: ManagementUnitModel[];
  handleRemoveManagementUnit: (roadSectionIndex: number, managementUnitIndex: number) => void;
}> = ({ managementUnit, managementUnitIndex, roadSectionIndex, dynamicMus, handleRemoveManagementUnit }) => {
  const [selectedMu, setSelectedMu] = useState<ManagementUnitModel | null>(managementUnit);

  const handleMuSelect = (event: any, value: ManagementUnitModel | null) => {
    const managementUnit = value;
    if (!managementUnit?.id) return setSelectedMu(null);
    setSelectedMu(managementUnit);
    setValue(`roadSections.${roadSectionIndex}.managementUnits.${managementUnitIndex}`, managementUnit);
  };

  const {
    formState: { errors },
    setValue,
  } = useFormContext<ManageRoadInput>();

  return (
    <>
      <Stack direction='row' alignItems='center' position='relative' sx={{ mb: 2 }}>
        <Typography variant='subtitle1' component='h3'>
          Road Section {roadSectionIndex + 1} / Management Unit {managementUnitIndex + 1}
        </Typography>
        <IconButton
          aria-label='delete'
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => handleRemoveManagementUnit(roadSectionIndex, managementUnitIndex)}
        >
          <GridDeleteIcon />
        </IconButton>
      </Stack>
      {/* Select management unit from dynamicMus list*/}
      <Autocomplete
        fullWidth
        options={dynamicMus}
        defaultValue={managementUnit}
        getOptionLabel={(option) => option.name || ''}
        onChange={handleMuSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Management Unit'
            error={!!errors.roadSections?.[roadSectionIndex]?.managementUnits?.[managementUnitIndex]}
            helperText={
              errors.roadSections?.[roadSectionIndex]?.managementUnits?.[managementUnitIndex]?.message
            }
          />
        )}
      />
      {/* Segment Selection */}
      {selectedMu?.id && <SegmentInputs roadSectionIndex={roadSectionIndex} selectedMu={selectedMu} />}
    </>
  );
};
