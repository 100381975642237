import { UserData } from "../../stores/userStore/UserStore";
import { Email, isEmail } from "../../types/globals";
import { permissionsDefinition } from "../permissionsDefinition";
import { AccessLevels, Functionalities } from "../types/permissionsTypes";

export const userSpecificOverrides: Record<Email, {
  [key in Functionalities]?: AccessLevels;
}> = {
  'iveco@electreon.com': {
    [Functionalities.INTEGRATION]: AccessLevels.DENIED,
  },
};

export const getAccessLevel = (functionality: Functionalities, userData?: UserData): AccessLevels => {
  if (!userData) return AccessLevels.DENIED;

  const userOverride = isEmail(userData.email) ? userSpecificOverrides[userData.email] : undefined;
  const override = userOverride?.[functionality];
  if (override) return override;

  const role = userData.role;
  const normalPerm = permissionsDefinition[functionality]?.[role];
  const isEmployee = userData.name?.startsWith('google_');
  const employeePerm = isEmployee ? permissionsDefinition[functionality]?.['EMPLOYEE'] : undefined;

  const allowed = normalPerm === true || employeePerm === true;
  const limited = normalPerm === 'limited' || employeePerm === 'limited';

  return allowed ? AccessLevels.ALLOWED : limited ? AccessLevels.LIMITED : AccessLevels.DENIED;
};