import { useLocation } from 'react-router-dom';

export const useCurrentPageName = () => {
  const location = useLocation();
  const { pathname } = location;
  const pageName = pathname.includes('history')
    ? 'Analytics'
    : pathname.includes('depot')
      ? 'Depot'
      : pathname.includes('integration')
        ? 'Integration'
        : 'Dashboard';

  return pageName;
};
