import { Alert, Snackbar } from '@mui/material';
import { CreateDevicePopup } from 'Components/Forms/CreateDevicePopup/CreateDevicePopup';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'MobxStores/context';

import { ConfigureDeviceForm } from 'Components/Forms/ConfigureShadowForm/ConfigureDeviceForm';
import { CreateProjectForm } from 'Components/Forms/ProjectForms/CreateProjectForm';
import { DeleteProjectForm } from 'Components/Forms/ProjectForms/DeleteProjectForm';
import { EditDeviceForm } from 'Components/Forms/EditDeviceForm/EditDeviceForm';
import { DashboardConfigurationForm } from 'Components/Forms/DashboardConfigurationForm/DashboardConfigurationForm';
import { CreateDepotForm } from 'Components/Forms/DepotForm/CreateDepotForm';
import { DeleteDepotForm } from 'Components/Forms/DepotForm/DeleteDepotForm';
import { ManageParkingSpotsForm } from 'Components/Forms/ParkingSpotForm/ManageParkingSpotsForm';
import { EditProjectForm } from 'Components/Forms/ProjectForms/EditProjectForm';
import { DeviceResetOptionsForm } from 'Components/Forms/DeviceResetOptionsForm/DeviceResetOptionsForm';
import { DeviceFotaOptionsForm } from 'Components/Forms/DeviceFotaForm/DeviceFotaOptionsForm';
import { EditUserForm } from 'Components/Forms/UserAttributes/EditUserForm';
import { EditDepotForm } from 'Components/Forms/DepotForm/EditDepotForm';
import { PopupWrapper } from 'Components/Forms/PopupWrapper';
import { EditPOSForm } from 'Components/Forms/EditAPFC&POSForms/EditPOSForm';
import { EditAPFCForm } from 'Components/Forms/EditAPFC&POSForms/EditAPFCForm';
import { ChargingScheduleFormWrapper } from 'Components/Forms/ChargingScheduleForm/ChargingScheduleFormWrapper';
import { SDCardDownloadForm } from 'Components/Forms/SDCardDownloadForm/SDCardDownloadForm';
import { CreateRoadForm } from 'Components/Forms/RoadForm/CreateRoadForm';
import { ManageRoadForm } from 'Components/Forms/RoadForm/ManageRoadForm';
import { EditRoadForm } from 'Components/Forms/RoadForm/EditRoadForm';
import AlertsDashboard from 'Screens/AlertDashboard/AlertsDashboard';
import { FirmwareDownloadForm } from 'Components/Forms/FirmwareDownloadForm/FirmwareDownloadForm';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { ProjectSelectionForm } from 'Components/Forms/ProjectForms/ProjectSelectionForm';
import { EditOCPPForm } from 'Components/Forms/EditDeviceForm/EditOCPPForm';
import { DeviceSetEnergyMeterValueForm } from 'Components/Forms/DeviceSetEnergyMeterValueForm/DeviceSetEnergyMeterValueForm';
import { isMu, isOcpp, isVu } from '@electreon_ui/shared/utils/globalUtils';
import { AssignOcppToDepotForm } from 'Components/Forms/OcppAssignmentForms/AssignOcppToDepotForm';
import { UnassignDeviceForm } from 'Components/Forms/OcppAssignmentForms/UnassignDeviceForm';
import { AlertReadStatus } from 'MobxStores/utils/alertStoreTypes';
import { InviteUserForm } from 'Components/Forms/ManageUsersForms/InviteUserForm';
import { CreateFleetForm } from 'Components/Forms/FleetForm/CreateFleetForm';
import { UpdateUserForm } from 'Components/Forms/ManageUsersForms/UpdateUserForm';

export const PopupsWrapper: React.FC = observer(() => {
  const { projectStore, deviceStore, popupStore, projectAnalyticsStore, alertStore, queryClient } =
    useAppStore();
  const { selectedDevice } = popupStore;

  return (
    <>
      <PopupWrapper open={popupStore.createDepotPopupOpen} onClose={() => popupStore.closeCreateDepotPopup()}>
        <CreateDepotForm
          onSuccessfulSubmit={() => {
            popupStore.closeCreateDepotPopup();
          }}
          onCancel={() => {
            popupStore.closeCreateDepotPopup();
          }}
          selectedProject={projectStore.selectedProject}
        />
      </PopupWrapper>
      <PopupWrapper open={popupStore.editDepotPopupOpen} onClose={() => popupStore.closeEditDepotPopup()}>
        <EditDepotForm
          onSuccessfulSubmit={() => {
            popupStore.closeEditDepotPopup();
          }}
          onCancel={() => {
            popupStore.closeEditDepotPopup();
          }}
          selectedProject={projectStore.selectedProject}
          depotId={popupStore.selectedDepotId}
        />
      </PopupWrapper>
      <PopupWrapper open={popupStore.deleteDepotPopupOpen} onClose={() => popupStore.closeDeleteDepotPopup()}>
        <DeleteDepotForm
          onSuccessfulSubmit={() => {
            popupStore.closeDeleteDepotPopup();
          }}
          onCancel={() => {
            popupStore.closeDeleteDepotPopup();
          }}
          selectedProject={projectStore.selectedProject}
          depotId={popupStore.selectedDepotId}
        />
      </PopupWrapper>
      {projectStore.selectedProject && isMu(selectedDevice) && (
        <PopupWrapper
          wrapperStyles={{ width: 600, paddingBottom: 0, minHeight: 400 }}
          open={popupStore.manageParkingSpotsPopupOpen}
          onClose={() => popupStore.closeManageParkingSpotsPopup()}
        >
          <ManageParkingSpotsForm
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeManageParkingSpotsPopup();
            }}
            onCancel={() => {
              popupStore.closeManageParkingSpotsPopup();
            }}
            selectedDevice={selectedDevice}
            depotId={selectedDevice.depotId}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper open={popupStore.createRoadPopupOpen} onClose={() => popupStore.closeCreateRoadPopup()}>
          <CreateRoadForm
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeCreateRoadPopup();
            }}
            onCancel={() => {
              popupStore.closeCreateRoadPopup();
            }}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper open={popupStore.editRoadPopupOpen} onClose={() => popupStore.closeEditRoadPopup()}>
          <EditRoadForm
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeEditRoadPopup();
            }}
            onCancel={() => {
              popupStore.closeEditRoadPopup();
            }}
            roadId={popupStore.selectedRoadId}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper
          open={popupStore.manageRoadPopupOpen}
          onClose={() => popupStore.closeManageRoadPopup()}
          wrapperStyles={{ width: '600px', paddingBottom: 0 }}
        >
          <ManageRoadForm
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeManageRoadPopup();
            }}
            onCancel={() => {
              popupStore.closeManageRoadPopup();
            }}
            roadId={popupStore.selectedRoadId}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper
          open={popupStore.createDevicePopupOpen}
          onClose={() => popupStore.closeCreateDevicePopup()}
          wrapperStyles={{ width: '30vw', paddingBottom: 3, pt: 3 }}
        >
          <CreateDevicePopup
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeCreateDevicePopup();
            }}
            onCancel={() => {
              popupStore.closeCreateDevicePopup();
            }}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper open={popupStore.editPOSPopupOpen} onClose={() => popupStore.closeEditPOSPopup()}>
          <EditPOSForm
            selectedProject={projectStore?.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeEditPOSPopup();
            }}
            onCancel={() => {
              popupStore.closeEditPOSPopup();
            }}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper open={popupStore.editAPFCPopupOpen} onClose={() => popupStore.closeEditAPFCPopup()}>
          <EditAPFCForm
            selectedProject={projectStore?.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeEditAPFCPopup();
            }}
            onCancel={() => {
              popupStore.closeEditAPFCPopup();
            }}
          />
        </PopupWrapper>
      )}
      {projectStore.selectedProject && (
        <PopupWrapper open={popupStore.editOCPPPopupOpen} onClose={() => popupStore.closeEditOCPPPopup()}>
          <EditOCPPForm
            selectedProject={projectStore?.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeEditOCPPPopup();
            }}
            onCancel={() => {
              popupStore.closeEditOCPPPopup();
            }}
          />
        </PopupWrapper>
      )}
      {selectedDevice && !isOcpp(selectedDevice) && (
        <PopupWrapper
          open={popupStore.configureDevicePopupOpen}
          onClose={() => popupStore.closeConfigureDevicePopup()}
          wrapperStyles={{ width: '40vw', height: '80dvh', paddingBottom: 0, pt: 0 }}
        >
          <ConfigureDeviceForm selectedDevice={selectedDevice} popupStore={popupStore} />
        </PopupWrapper>
      )}
      <PopupWrapper
        open={popupStore.createProjectPopupOpen}
        onClose={() => popupStore.closeCreateProjectPopup()}
      >
        <CreateProjectForm
          onSuccessfulSubmit={() => {
            popupStore.closeCreateProjectPopup();
            queryClient.invalidateQueries({ queryKey: ['projects'] });
          }}
          onCancel={() => {
            popupStore.closeCreateProjectPopup();
          }}
        />
      </PopupWrapper>
      <PopupWrapper
        open={popupStore.deleteProjectPopupOpen}
        onClose={() => popupStore.closeDeleteProjectPopup()}
      >
        <DeleteProjectForm
          onSuccessfulSubmit={() => {
            popupStore.closeDeleteProjectPopup();
            queryClient.invalidateQueries({ queryKey: ['projects'] });
          }}
          onCancel={() => {
            popupStore.closeDeleteProjectPopup();
          }}
        />
      </PopupWrapper>
      {projectStore.selectedProject && (
        <PopupWrapper
          open={popupStore.editProjectPopupOpen}
          onClose={() => popupStore.closeEditProjectPopup()}
        >
          <EditProjectForm
            project={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeEditProjectPopup();
              queryClient.invalidateQueries({ queryKey: ['projects'] });
            }}
            onCancel={() => {
              popupStore.closeEditProjectPopup();
            }}
          />
        </PopupWrapper>
      )}
      {selectedDevice && (
        <PopupWrapper open={popupStore.editDevicePopupOpen} onClose={() => popupStore.closeEditDevicePopup()}>
          <EditDeviceForm
            device={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeEditDevicePopup();
            }}
            onCancel={() => {
              popupStore.closeEditDevicePopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      {selectedDevice && isOcpp(selectedDevice) && (
        <PopupWrapper
          wrapperStyles={{ width: 600, minHeight: 400 }}
          open={popupStore.assignOCPPPopupOpen}
          onClose={() => popupStore.closeAssignOCPPPopup()}
        >
          <AssignOcppToDepotForm
            selectedDevice={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeAssignOCPPPopup();
            }}
            onCancel={() => {
              popupStore.closeAssignOCPPPopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      {selectedDevice && (isOcpp(selectedDevice) || isMu(selectedDevice)) && (
        <PopupWrapper
          open={popupStore.unassignDevicePopupOpen}
          onClose={() => popupStore.closeUnassignDevicePopup()}
        >
          <UnassignDeviceForm
            selectedDevice={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeUnassignDevicePopup();
            }}
            onCancel={() => {
              popupStore.closeUnassignDevicePopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      {selectedDevice && !isOcpp(selectedDevice) && (
        <PopupWrapper
          open={popupStore.deviceResetOptionsPopupOpen}
          onClose={() => popupStore.closeDeviceResetOptionsPopup()}
        >
          <DeviceResetOptionsForm
            device={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeDeviceResetOptionsPopup();
            }}
            onCancel={() => {
              popupStore.closeDeviceResetOptionsPopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      {selectedDevice && !isOcpp(selectedDevice) && (
        <PopupWrapper open={popupStore.deviceFotaPopupOpen} onClose={() => popupStore.closeDeviceFotaPopup()}>
          <DeviceFotaOptionsForm
            device={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeDeviceFotaPopup();
            }}
            onCancel={() => {
              popupStore.closeDeviceFotaPopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      {selectedDevice && !isOcpp(selectedDevice) && (
        <PopupWrapper
          open={popupStore.deviceSetEnergyMeterValuePopupOpen}
          onClose={() => popupStore.closeDeviceSetEnergyMeterValuePopup()}
        >
          <DeviceSetEnergyMeterValueForm
            device={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeDeviceSetEnergyMeterValuePopup();
            }}
            onCancel={() => {
              popupStore.closeDeviceSetEnergyMeterValuePopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      <PopupWrapper
        open={popupStore.dashboardConfigurationPopupOpen}
        onClose={() => popupStore.closeDashboardConfigurationPopup()}
        wrapperStyles={{ width: '25vw' }}
      >
        <DashboardConfigurationForm />
      </PopupWrapper>
      <PopupWrapper
        open={popupStore.userAttributesPopupOpen}
        onClose={() => popupStore.closeUserAttributesPopup()}
        wrapperStyles={{ width: '25vw' }}
      >
        <EditUserForm
          onSuccessfulSubmit={() => {
            popupStore.closeUserAttributesPopup();
            popupStore.displayAlertMessage({
              message: 'User attributes updated successfully',
              duration: 10000,
              severity: 'success',
            });
          }}
          onCancel={() => {
            popupStore.closeUserAttributesPopup();
          }}
        />
      </PopupWrapper>
      {projectStore.selectedProject && (
        <PopupWrapper
          open={popupStore.chargingSchedulePopupOpen}
          onClose={() => popupStore.closeChargingSchedulePopup()}
          wrapperStyles={{ width: '500px' }}
        >
          <ChargingScheduleFormWrapper
            selectedProject={projectStore.selectedProject}
            onSuccessfulSubmit={() => {
              popupStore.closeChargingSchedulePopup();
              popupStore.displayAlertMessage({
                message: 'Charging schedule updated successfully',
                duration: 10000,
                severity: 'success',
              });
            }}
            onCancel={() => {
              popupStore.closeChargingSchedulePopup();
            }}
          />
        </PopupWrapper>
      )}
      {selectedDevice?.id && !isOcpp(selectedDevice) && (
        <PopupWrapper
          open={popupStore.sdCardDownloadPopupOpen}
          onClose={() => popupStore.closeSdCardDownloadPopup()}
        >
          <SDCardDownloadForm
            device={selectedDevice}
            onSuccessfulSubmit={() => {
              popupStore.closeSdCardDownloadPopup();
            }}
            onCancel={() => {
              popupStore.closeSdCardDownloadPopup();
            }}
            selectedProject={projectStore.selectedProject}
          />
        </PopupWrapper>
      )}
      <PopupWrapper
        open={popupStore.firmwareDownloadPopupOpen}
        onClose={() => popupStore.closeFirmwareDownloadPopup()}
      >
        <FirmwareDownloadForm
          onSuccessfulSubmit={() => {
            popupStore.closeFirmwareDownloadPopup();
          }}
          onCancel={() => {
            popupStore.closeFirmwareDownloadPopup();
          }}
          selectedProject={projectStore.selectedProject}
        />
      </PopupWrapper>
      <PopupWrapper
        open={popupStore.displayNotificationsPopupOpen}
        onClose={() => {
          popupStore.closeNotificationsPopup();
          const selectedProjectAlerts = projectStore.selectedProject?.id
            ? alertStore.projectAlerts[projectStore.selectedProject.id]
            : null;
          alertStore.setAlertsStatus(
            AlertReadStatus.READ,
            selectedProjectAlerts || alertStore.projectAlerts.allSites
          );
        }}
        wrapperStyles={{
          width: '80vw',
          maxWidth: '80%',
          padding: 0,
        }}
      >
        <AlertsDashboard />
      </PopupWrapper>
      <PopupWrapper
        open={popupStore.projectSelectionPopupOpen}
        onClose={() => popupStore.closeProjectSelectionPopup()}
      >
        <ProjectSelectionForm
          onSuccessfulSubmit={() => {
            popupStore.closeProjectSelectionPopup();
          }}
          onCancel={() => {
            popupStore.closeProjectSelectionPopup();
          }}
        />
      </PopupWrapper>
      <PopupWrapper open={popupStore.inviteUserPopupOpen} onClose={() => popupStore.closeInviteUserPopup()}>
        <InviteUserForm
          onSuccessfulSubmit={() => {
            popupStore.closeInviteUserPopup();
          }}
          onCancel={() => {
            popupStore.closeInviteUserPopup();
          }}
        />
      </PopupWrapper>
      <PopupWrapper open={popupStore.updateUserPopupOpen} onClose={() => popupStore.closeUpdateUserPopup()}>
        <UpdateUserForm
          onSuccessfulSubmit={() => {
            popupStore.closeUpdateUserPopup();
          }}
          onCancel={() => {
            popupStore.closeUpdateUserPopup();
          }}
        />
      </PopupWrapper>
      <PopupWrapper open={popupStore.createFleetPopupOpen} onClose={() => popupStore.closeCreateFleetPopup()}>
        <CreateFleetForm
          onSuccessfulSubmit={() => {
            popupStore.closeCreateFleetPopup();
          }}
          onCancel={() => {
            popupStore.closeCreateFleetPopup();
          }}
          selectedProject={projectStore.selectedProject}
        />
      </PopupWrapper>
      {selectedDevice && isVu(selectedDevice) && <></>}
      {/* TODO: Replace all snackbars with toast from 'sonner' */}
      <Snackbar
        open={deviceStore.isSDCardDLInitiating}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity='info' sx={{ width: '100%' }}>
          SD Card Download Starting
        </Alert>
      </Snackbar>
      <Snackbar
        open={projectAnalyticsStore?.isExportingRawData}
        onClose={() => projectAnalyticsStore?.setIsExportingRawData(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ '&.MuiSnackbar-root': { top: '65px' } }}
      >
        <Alert severity='info' sx={{ width: '100%' }}>
          Data export in progress.
        </Alert>
      </Snackbar>
      <Snackbar
        open={projectAnalyticsStore?.isRawDataExportError}
        onClose={() => projectAnalyticsStore?.setIsRawDataExportError(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        sx={{ '&.MuiSnackbar-root': { top: '65px' } }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          Error exporting data.
        </Alert>
      </Snackbar>
      <Snackbar
        open={projectAnalyticsStore?.isRawDataExportSuccess}
        onClose={() => projectAnalyticsStore?.setIsRawDataExportSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        sx={{ '&.MuiSnackbar-root': { top: '65px' } }}
      >
        <Alert severity='success' sx={{ width: '100%' }}>
          Data export complete.
        </Alert>
      </Snackbar>
      <Snackbar
        open={popupStore.informationPopup.isOpen}
        onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
          if (reason === 'clickaway' && !popupStore.informationPopup.handleClickAway) {
            return;
          }
          popupStore.informationPopup.close();
        }}
        anchorOrigin={{
          vertical: popupStore.informationPopup.anchorOrigin?.vertical || 'top',
          horizontal: popupStore.informationPopup.anchorOrigin?.horizontal || 'center',
        }}
        autoHideDuration={popupStore.informationPopup.autoHideDuration || 4000}
        sx={{ '&.MuiSnackbar-root': { top: theme.header?.height, marginTop: '5px' } }}
      >
        <Alert
          severity={popupStore.informationPopup.severity}
          sx={{ width: popupStore.informationPopup.width || '100%' }}
        >
          {popupStore.informationPopup.message}
        </Alert>
      </Snackbar>
    </>
  );
});
