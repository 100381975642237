import { api } from 'Services/api';
import {
  ManagementUnitV2FpgaFotaType,
  SomFOTAType,
} from '@electreon/electreon-device-control-service-gen-ts-client';
import { getSessionId } from 'Utils/SOMApi';

export async function lsmuResetCpu(muId: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muRemoteControl.controlResetCpu(muId, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in lsmuResetCpu request: ${error}`);
    throw error;
  }
}

export async function lsmuResetDcSamplers(muId: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muRemoteControl.controlResetDcSamplers(muId, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in lsmuResetDcSamplers request: ${error}`);
    throw error;
  }
}

// export async function lsmuResetModem(muId: string) {
//   try {
//     const sessionId = getSessionId();
//     if (!sessionId) throw new Error('No session id found');
//     const response = await api.deviceControl.muRemoteControl.controlResetModem(muId, sessionId);
//     return response;
//   } catch (error) {
//     console.error(`Error in lsmuResetModem request: ${error}`);
//     throw error;
//   }
// }

export async function lsmuResetMuPower(muId: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muRemoteControl.controlResetMuPower(muId, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in lsmuResetMuPower request: ${error}`);
    throw error;
  }
}

export async function lsmuResetDcSamplersCustomDelay(muId: string, customDelay: number) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const offResponse = await api.deviceControl.muRemoteControl.controlDcSamplersOff(muId, sessionId);
    if (offResponse.status === 200) {
      setTimeout(async function () {
        const onResponse = await api.deviceControl.muRemoteControl.controlDcSamplersOn(muId, sessionId);
        return onResponse;
      }, customDelay * 1000);
    }
    return offResponse;
  } catch (error) {
    console.error(`Error in lsmuResetDcSamplersCustomDelay request: ${error}`);
    throw error;
  }
}

export async function vuResetCpu(vuId: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.vuRemoteControl.controlResetCpu(vuId, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in vuResetCpu request: ${error}`);
    throw error;
  }
}

export async function vuResetDcSamplers(vuId: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.vuRemoteControl.controlResetDcSamplers(vuId, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in vuResetDcSamplers request: ${error}`);
    throw error;
  }
}

export async function vuResetDcSamplersCustomDelay(muId: string, customDelay: number) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const offResponse = await api.deviceControl.vuRemoteControl.controlDcSamplersOff(muId, sessionId);
    if (offResponse.status === 200) {
      setTimeout(async function () {
        const onResponse = await api.deviceControl.vuRemoteControl.controlDcSamplersOn(muId, sessionId);
        return onResponse;
      }, customDelay * 1000);
    }
    return offResponse;
  } catch (error) {
    console.error(`Error in vuResetDcSamplersCustomDelay request: ${error}`);
    throw error;
  }
}

// MU FOTA RELATED APIs

export async function muSomVersionExists(version: string, somFoatType: SomFOTAType) {
  try {
    const response = await api.deviceControl.muFotaControl.somFirmwareExists(version, somFoatType);
    return response;
  } catch (error) {
    console.error(`Error in muSomVersionExists request: ${error}`);
    throw error;
  }
}

export async function muSomFota(muId: string, version: string, somFoatType: SomFOTAType) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muFotaControl.updateSomFirmware(
      muId,
      version,
      somFoatType,
      sessionId
    );
    return response;
  } catch (error) {
    console.error(`Error in muSomFota request: ${error}`);
    throw error;
  }
}

export async function muDcSamplerVersionExists(version: string) {
  try {
    const response = await api.deviceControl.muFotaControl.dcSamplerFirmwareExists(version);
    return response;
  } catch (error) {
    console.error(`Error in muDcSamplerVersionExists request: ${error}`);
    throw error;
  }
}

export async function muDcSamplerFota(muId: string, version: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muFotaControl.updateDcSamplerFirmware(muId, version, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in muDcSamplerFota request: ${error}`);
    throw error;
  }
}

export async function muFansBoardVersionExists(version: string) {
  try {
    const response = await api.deviceControl.muFotaControl.fansBoardFirmwareExists(version);
    return response;
  } catch (error) {
    console.error(`Error in muFansBoardVersionExists request: ${error}`);
    throw error;
  }
}

export async function muFansBoardFota(muId: string, version: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muFotaControl.updateFansBoardFirmware(muId, version, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in muFansBoardFota request: ${error}`);
    throw error;
  }
}

export async function muCommBoardFota(muId: string, version: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muFotaControl.updateCommBoardFirmware(muId, version, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in muCommBoardFota request: ${error}`);
    throw error;
  }
}

export async function muFpgaVersionExists(version: string, fpgaFoatType: ManagementUnitV2FpgaFotaType) {
  try {
    const response = await api.deviceControl.muFotaControl.fpgaFirmwareExists(version, fpgaFoatType);
    return response;
  } catch (error) {
    console.error(`Error in muFpgaVersionExists request: ${error}`);
    throw error;
  }
}

export async function muFpgaFota(muId: string, version: string, fpgaFoatType: ManagementUnitV2FpgaFotaType) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muFotaControl.updateFpga(muId, fpgaFoatType, version, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in muFpgaFota request: ${error}`);
    throw error;
  }
}

export async function muCommBoardVersionExists(version: string) {
  try {
    const response = await api.deviceControl.muFotaControl.commBoardFirmwareExists(version);
    return response;
  } catch (error) {
    console.error(`Error in muCommBoardVersionExists request: ${error}`);
    throw error;
  }
}

export async function muFpgaSdCardImageVersionExists(version: string) {
  try {
    const response = await api.deviceControl.muFotaControl.fpgaSdCardImageExists(version);
    return response;
  } catch (error) {
    console.error(`Error in muFpgaSdCardImageVersionExists request: ${error}`);
    throw error;
  }
}

export async function vuSomVersionExists(version: string, somFoatType: SomFOTAType) {
  try {
    const response = await api.deviceControl.vuFotaControl.somFirmwareExists(version, somFoatType);
    return response;
  } catch (error) {
    console.error(`Error in vuSomVersionExists request: ${error}`);
    throw error;
  }
}

export async function vuSomFota(muId: string, version: string, somFoatType: SomFOTAType) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.vuFotaControl.updateSomFirmware(
      muId,
      version,
      somFoatType,
      sessionId
    );
    return response;
  } catch (error) {
    console.error(`Error in vuSomFota request: ${error}`);
    throw error;
  }
}

export async function vuDcSamplerVersionExists(version: string) {
  try {
    const response = await api.deviceControl.vuFotaControl.dcSamplerFirmwareExists(version);
    return response;
  } catch (error) {
    console.error(`Error in vuDcSamplerVersionExists request: ${error}`);
    throw error;
  }
}

export async function vuDcSamplerFota(muId: string, version: string) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.vuFotaControl.updateDcSamplerFirmware(muId, version, sessionId);
    return response;
  } catch (error) {
    console.error(`Error in vuDcSamplerFota request: ${error}`);
    throw error;
  }
}
