export const ROLE_TYPES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  INTEGRATOR: 'INTEGRATOR',
  OPERATOR: 'OPERATOR',
  PROJECT_USER: 'PROJECT_USER',
  PROJECT_VIEWER: 'PROJECT_VIEWER',
} as const;

export const ROLE_HIERARCHY = {
  [ROLE_TYPES.SUPER_ADMIN]: 6,
  [ROLE_TYPES.ADMIN]: 5,
  [ROLE_TYPES.INTEGRATOR]: 4,
  [ROLE_TYPES.OPERATOR]: 3,
  [ROLE_TYPES.PROJECT_USER]: 2,
  [ROLE_TYPES.PROJECT_VIEWER]: 1,
} as const;
