import { MenuItem } from '@mui/material';
import { IconMenuItem, NestedMenuItem } from 'mui-nested-menu';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DepotIcon from 'Utils/Images/HeaderIcons/menu-bus-icon.svg?react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useProjects } from 'Utils/APIUtils';

const depotsMenuStyles = {
  px: '16px',
  '& .MuiBox-root': {
    alignItems: 'center',
    '& svg': {
      width: '25px',
      height: '25px',
    },
    '& p': { ml: '10px', fontSize: '16px', alignSelf: 'flex-start' },
  },
};

type NavHeaderProps = {
  isOpen: boolean;
  selectedProjectId?: number | string;
  handleClose: () => void;
};

export const ProjectDepotNavigationMenu: React.FC<NavHeaderProps> = observer(
  ({ isOpen, selectedProjectId, handleClose }) => {
    const { data: projectsMap } = useProjects();
    const depotsData = selectedProjectId ? [...(projectsMap?.get(+selectedProjectId)?.depots || [])] : [];
    const moreThanOneDepotAvailable = !!depotsData?.length && depotsData.length > 1;

    return moreThanOneDepotAvailable ? (
      <NestedMenuItem
        leftIcon={<DepotIcon />}
        rightIcon={<KeyboardArrowRightIcon />}
        label='Depots'
        parentMenuOpen={isOpen}
        sx={depotsMenuStyles}
      >
        {depotsData.map((depot) => (
          <Link key={depot.id} to={`/dashboard/live/depot/${selectedProjectId}/${depot.id}`}>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              {depot.name}
            </MenuItem>
          </Link>
        ))}
      </NestedMenuItem>
    ) : (
      Boolean(depotsData?.length) && (
        <Link to={`/dashboard/live/depot/${selectedProjectId}/${depotsData?.[0].id}`}>
          <IconMenuItem
            leftIcon={<DepotIcon />}
            label='Depot'
            sx={depotsMenuStyles}
            onClick={() => {
              handleClose();
            }}
          />
        </Link>
      )
    );
  }
);
ProjectDepotNavigationMenu.displayName = 'ProjectDepotNavigationMenu';
