import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface RoadTotalLengthInputProps {
  currentLength?: number | undefined;
}

export const RoadTotalLengthInput: React.FC<RoadTotalLengthInputProps> = ({ currentLength }) => {
  const [totalLength, setTotalLength] = useState<number | undefined>(currentLength);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    setTotalLength(currentLength);
    setValue('totalLengthKm', currentLength);
  }, [currentLength, setValue]);
  return (
    <TextField
      sx={{ mb: 2 }}
      fullWidth
      label='Total Length'
      type='totalLengthKm'
      error={!!errors.totalLengthKm}
      helperText={errors.totalLengthKm?.message?.toString() || ''}
      value={totalLength || ''}
      onChange={(e: any) => {
        setTotalLength(Number(e.target.value));
        setValue('totalLengthKm', Number(e.target.value));
      }}
    />
  );
};
