import { ManagementUnitModelRefined, VehicleUnitModelRefined } from '../../types/globals';
import {
  ActiveCommBoards,
  ActiveFanBoards,
  DcSamplersShadowProperties,
  DeviceTwinChangesSocketMessage,
  FPGASocketMessage,
  Reported,
  isCommBoardKey,
  isDcSamplerKey,
  isFansBoardKey,
} from '../../types/socketMessageTypes';
import { DeviceStoreVersions } from './deviceStoreTypes';

export enum MessageType {
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
  DEFAULT = 'default',
}

export function getVersionsFromFpgaMessage(fpgaMessage?: FPGASocketMessage) {
  if (!fpgaMessage) return {};
  const versions = {
    fpgasVersions: {},
  } as DeviceStoreVersions['fpgasVersions'];

  Object.entries(fpgaMessage).forEach(([fpgaId, fpgaData]) => {
    if (fpgaId.startsWith('fpga_') && typeof fpgaData === 'object') {
      if (!versions.fpgasVersions?.[fpgaId]) {
        versions.fpgasVersions![fpgaId] = {
          linuxAppVersion: 'N/A',
          fpgaFWVersion: 'N/A',
        };
      }

      if (fpgaData?.header_data?.linuxAppVersion) {
        versions.fpgasVersions![fpgaId].linuxAppVersion = fpgaData?.header_data?.linuxAppVersion;
      }

      if (fpgaData?.header_data?.firmwareVersion) {
        versions.fpgasVersions![fpgaId].fpgaFWVersion = fpgaData?.header_data?.firmwareVersion;
      }
    }
  });

  return versions;
}

export const generateMessageType = (message: string) => {
  if (message.toLowerCase().includes('fail')) {
    return MessageType.ERROR;
  } else if (
    message.toLowerCase().includes('over_current') ||
    message.toLowerCase().includes('terminated') ||
    message.toLowerCase().includes('over_temperature')
  ) {
    return MessageType.WARNING;
  } else if (
    message.toLowerCase().includes('success') ||
    message.toLowerCase().includes('charging_stopped')
  ) {
    return MessageType.INFO;
  }
  return MessageType.INFO;
};

export const shouldDisplayMessage = (eventString?: string, eventType?: string) => {
  if (eventString?.toLowerCase().includes('fpga')) {
    if (
      eventString?.toLowerCase().includes('calibration') ||
      eventString?.toLowerCase().includes('command_success')
    ) {
      return false;
    }
    return true;
  }
  if (eventString?.toLowerCase().includes('segment')) {
    return true;
  }
  const infoToDisplay =
    eventString?.toLowerCase().includes('gpio') ||
    eventString?.toLowerCase().includes('fota') ||
    eventString === 'EVENT_PROFILE_MODULE_RESETING_THE_DEVICE' ||
    eventString === 'EVENT_APPLICATION_STARTED' ||
    eventString === 'EVENT_FPGA_CONNECTED' ||
    eventString === 'EVENT_FPGA_DISCONNECTED' ||
    eventString === 'EVENT_APP_MODE_CHANGE_APP_MODE_SUCCESS' ||
    eventString === 'EVENT_DOWNLOAD_PERCENTAGE_OF_PROGRESS';
  if (eventType?.toLowerCase().includes('info') && !infoToDisplay) {
    return false;
  }
  return true;
};

export const getVersionsFromMetadata = (
  deviceId: string,
  muList?: ManagementUnitModelRefined[],
  vuList?: VehicleUnitModelRefined[]
): Partial<DeviceStoreVersions['string']> => {
  const versions = {} as Partial<DeviceStoreVersions['string']>;
  const mu = muList?.find((mu) => mu?.id === deviceId);
  const vu = vuList?.find((vu) => vu?.id === deviceId);
  const device = mu || vu;

  if (!device) return versions;

  if (device?.softwareVersion) versions.firmwareVersion = device?.softwareVersion;
  if (device?.softwareVersionBoot) versions.bootloaderVersion = device?.softwareVersionBoot;
  if (device?.softwareVersionRootfs) versions.rootFsVersion = device?.softwareVersionRootfs;

  return versions;
};

export function getVersionsFromReportedProperties(
  deviceProperties?: Reported
): Partial<DeviceStoreVersions['string']> {
  if (!deviceProperties) return {};

  const versions = {} as Partial<DeviceStoreVersions['string']>;

  if (
    deviceProperties.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.CAN_TX_Msg_Time_Interval_In_MS
  ) {
    versions.canTxMsgTimeIntervalInMs =
      deviceProperties.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.CAN_TX_Msg_Time_Interval_In_MS;
  }

  if (
    deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_Low_Threshold_Value
  ) {
    versions.voltageMin =
      deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_Low_Threshold_Value;
  }

  if (
    deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_High_Threshold_Value
  ) {
    versions.voltageMax =
      deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_High_Threshold_Value;
  }

  if (deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Receiver_Max_Current_Level) {
    versions.receiverMaxCurrent =
      deviceProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Receiver_Max_Current_Level;
  }

  if (deviceProperties?.fwVersion) {
    versions.firmwareVersion = deviceProperties?.fwVersion;
  }

  if (deviceProperties?.activeFansBoard) {
    Object.entries(deviceProperties.activeFansBoard).forEach(([fansBoardId, fansBoardData]) => {
      if (!isFansBoardKey(fansBoardId)) return;
      if (fansBoardData?.version) {
        versions.activeFanBoards = versions.activeFanBoards || ({} as ActiveFanBoards);
        versions.activeFanBoards[fansBoardId] = versions.activeFanBoards[fansBoardId] || {};
        versions.activeFanBoards[fansBoardId].version = fansBoardData.version;
      }

      if (fansBoardData?.partition) {
        versions.activeFanBoards = versions.activeFanBoards || ({} as ActiveFanBoards);
        versions.activeFanBoards[fansBoardId] = versions.activeFanBoards[fansBoardId] || {};
        versions.activeFanBoards[fansBoardId].partition = fansBoardData.partition;
      }
    });
  }

  if (deviceProperties?.activeCommBoard) {
    Object.entries(deviceProperties.activeCommBoard).forEach(([commBoardId, commBoardData]) => {
      if (!isCommBoardKey(commBoardId)) return;
      if (commBoardData?.version) {
        versions.activeCommBoards = versions.activeCommBoards || ({} as ActiveCommBoards);
        versions.activeCommBoards[commBoardId] = versions.activeCommBoards[commBoardId] || {};
        versions.activeCommBoards[commBoardId].version = commBoardData.version;
      }

      if (commBoardData?.partition) {
        versions.activeCommBoards = versions.activeCommBoards || ({} as ActiveCommBoards);
        versions.activeCommBoards[commBoardId] = versions.activeCommBoards[commBoardId] || {};
        versions.activeCommBoards[commBoardId].partition = commBoardData.partition;
      }
    });
  }

  if (deviceProperties?.activeDcSamplers) {
    Object.entries(deviceProperties.activeDcSamplers).forEach(([dcSamplerId, dcSamplerData]) => {
      if (!isDcSamplerKey(dcSamplerId)) return;
      if (dcSamplerData?.version) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].version = dcSamplerData.version;
      }

      if (dcSamplerData?.partition) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].partition = dcSamplerData.partition;
      }

      if (dcSamplerData?.activation) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].activation = dcSamplerData.activation;
      }
    });
  }

  return versions;
}

export const getVersionsFromDeviceTwinChanges = (
  deviceTwinChangesMessage?: DeviceTwinChangesSocketMessage
): Partial<DeviceStoreVersions['string']> => {
  const versions = {} as Partial<DeviceStoreVersions['string']>;
  const reportedProperties = deviceTwinChangesMessage?.properties?.reported;

  if (
    reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER
      ?.CAN_TX_Msg_Time_Interval_In_MS
  ) {
    versions.canTxMsgTimeIntervalInMs =
      reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.CAN_TX_Msg_Time_Interval_In_MS;
  }

  if (
    reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_Low_Threshold_Value
  ) {
    versions.voltageMin =
      reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_Low_Threshold_Value;
  }

  if (
    reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_High_Threshold_Value
  ) {
    versions.voltageMax =
      reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Voltage_High_Threshold_Value;
  }

  if (
    reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Receiver_Max_Current_Level
  ) {
    versions.receiverMaxCurrent =
      reportedProperties?.configuration?.profile?.PROFILE_SECTION_BATTERY_CHARGER?.Receiver_Max_Current_Level;
  }

  if (reportedProperties?.fwVersion) {
    versions.firmwareVersion = reportedProperties?.fwVersion;
  }

  if (deviceTwinChangesMessage?.version) {
    versions.deviceChangesVersion = deviceTwinChangesMessage?.version;
  }

  if (deviceTwinChangesMessage?.properties?.reported?.fwVersionBoot) {
    versions.bootloaderVersion = deviceTwinChangesMessage?.properties?.reported?.fwVersionBoot;
  }

  if (deviceTwinChangesMessage?.properties?.reported?.fwVersionRootfs) {
    versions.rootFsVersion = deviceTwinChangesMessage?.properties?.reported?.fwVersionRootfs;
  }

  if (reportedProperties?.activeFansBoard) {
    Object.entries(reportedProperties.activeFansBoard).forEach(([fansBoardId, fansBoardData]) => {
      if (!isFansBoardKey(fansBoardId)) return;
      if (fansBoardData?.version) {
        versions.activeFanBoards = versions.activeFanBoards || ({} as ActiveFanBoards);
        versions.activeFanBoards[fansBoardId] = versions.activeFanBoards[fansBoardId] || {};
        versions.activeFanBoards[fansBoardId].version = fansBoardData.version;
      }

      if (fansBoardData?.partition) {
        versions.activeFanBoards = versions.activeFanBoards || ({} as ActiveFanBoards);
        versions.activeFanBoards[fansBoardId] = versions.activeFanBoards[fansBoardId] || {};
        versions.activeFanBoards[fansBoardId].partition = fansBoardData.partition;
      }
    });
  }

  if (reportedProperties?.activeCommBoard) {
    Object.entries(reportedProperties.activeCommBoard).forEach(([commBoardId, commBoardData]) => {
      if (!isCommBoardKey(commBoardId)) return;
      if (commBoardData?.version) {
        versions.activeCommBoards = versions.activeCommBoards || ({} as ActiveCommBoards);
        versions.activeCommBoards[commBoardId] = versions.activeCommBoards[commBoardId] || {};
        versions.activeCommBoards[commBoardId].version = commBoardData.version;
      }

      if (commBoardData?.partition) {
        versions.activeCommBoards = versions.activeCommBoards || ({} as ActiveCommBoards);
        versions.activeCommBoards[commBoardId] = versions.activeCommBoards[commBoardId] || {};
        versions.activeCommBoards[commBoardId].partition = commBoardData.partition;
      }
    });
  }

  if (reportedProperties?.activeDcSamplers) {
    Object.entries(reportedProperties.activeDcSamplers).forEach(([dcSamplerId, dcSamplerData]) => {
      if (!isDcSamplerKey(dcSamplerId)) return;
      if (dcSamplerData?.version) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].version = dcSamplerData.version;
      }

      if (dcSamplerData?.partition) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].partition = dcSamplerData.partition;
      }

      if (dcSamplerData?.activation) {
        versions.dcSamplersProperties = versions.dcSamplersProperties || ({} as DcSamplersShadowProperties);
        versions.dcSamplersProperties[dcSamplerId] = versions.dcSamplersProperties[dcSamplerId] || {};
        versions.dcSamplersProperties[dcSamplerId].activation = dcSamplerData.activation;
      }
    });
  }

  return versions;
};
