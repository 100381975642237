import { Menu, Divider, PopoverOrigin } from '@mui/material';
import { forwardRef, useRef } from 'react';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';

type MenuProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: (event?: MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  body: React.ReactNode;
  title: React.ReactNode;
  footer: React.ReactNode;
  extras?: React.ReactNode;
  origin?: PopoverOrigin;
  className?: string;
};

export const CustomMenu = forwardRef<HTMLDivElement, MenuProps>(
  ({ anchorEl, open, onClose, body, title, footer, extras, origin, className }, menuRef) => {
    return (
      <Menu
        closeAfterTransition
        ref={menuRef}
        disableScrollLock
        anchorEl={anchorEl}
        open={open}
        onClose={(e: MouseEvent, reason) => onClose(e, reason)}
        transformOrigin={{
          vertical: origin?.vertical || 'top',
          horizontal: origin?.horizontal || 'center',
        }}
        className={className}
        sx={{
          '& ul': {
            borderTop: `${theme.palette.accent.primary.main} 4px solid`,
            minWidth: '200px',
          },
          '& .MuiTypography-root': {
            textAlign: 'start',
          },
          '& .MuiPaper-root': {
            borderRadius: '8px',
          },
        }}
      >
        {title}
        {title !== null && <Divider sx={{ my: '8px' }} />}
        {body}
        {extras && (
          <div>
            <Divider sx={{ my: '8px' }} />
            {extras}
          </div>
        )}
        {footer && (
          <div>
            <Divider sx={{ my: '8px' }} />
            {footer}
          </div>
        )}
      </Menu>
    );
  }
);
CustomMenu.displayName = 'CustomMenu';
