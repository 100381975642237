import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { Road } from '@electreon/electreon-metadata-service-gen-ts-client';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button, Stack } from '@mui/material';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { RoadSectionInputs } from 'Components/Forms/RoadForm/RoadSectionInputs';
import { getDynamicMuList } from 'Components/Forms/RoadForm/utils/roadFormUtils';
import {
  roadDefaultValues,
  ManageRoadInput,
  ManageRoadSchema,
} from 'Components/Forms/RoadForm/utils/roadSchemas';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { manageRoad } from '../FormsAPIs/api';
import { toast } from 'sonner';

export const RoadForm: React.FC<FormProps & { roadToModify?: Road | null }> = ({
  onSuccessfulSubmit,
  selectedProject,
  roadToModify,
  onCancel,
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [dynamicMus, setDynamicMus] = useState<ManagementUnitModel[]>([]);

  const methods = useForm<ManageRoadInput>({
    resolver: zodResolver(ManageRoadSchema),
    defaultValues: {
      ...roadDefaultValues,
      projectId: selectedProject?.id,
      ...roadToModify,
      geoPath: roadToModify?.geoPath ?? '',
    },
    mode: 'onChange',
    resetOptions: { keepDirtyValues: true, keepDirty: true, keepDefaultValues: false },
  });
  const { dirtyFields } = methods.formState || {};

  const handleAddRoadSection = () => {
    const roadSections = methods.watch('roadSections') || [];
    const newRoadSection = {
      name: '',
      muIdToSegments: {},
      managementUnits: [],
      geoPath: '',
      roadId: roadToModify?.id || undefined,
    };
    methods.setValue('roadSections', [...roadSections, newRoadSection]);
  };

  const handleRemoveRoadSection = (roadSectionIndex: number) => {
    const roadSections = methods.watch('roadSections') || [];
    methods.setValue(
      'roadSections',
      roadSections.filter((_: any, index: number) => index !== roadSectionIndex)
    );
  };

  const handleAddManagementUnit = (roadSectionIndex: number) => {
    const roadSections = methods.watch('roadSections') || [];
    const managementUnits = roadSections[roadSectionIndex].managementUnits || [];
    const newManagementUnit = { name: '' };
    methods.setValue(`roadSections.${roadSectionIndex}.managementUnits`, [
      ...managementUnits,
      newManagementUnit,
    ]);
  };

  const handleRemoveManagementUnit = (roadSectionIndex: number, managementUnitIndex: number) => {
    const roadSections = methods.watch('roadSections') || [];
    const managementUnits = roadSections[roadSectionIndex].managementUnits || [];
    methods.setValue(
      `roadSections.${roadSectionIndex}.managementUnits`,
      managementUnits.filter((_: any, index: number) => index !== managementUnitIndex)
    );
  };

  const hasRoadSectionNameChanged = (index: number) => Boolean(dirtyFields.roadSections?.[index]?.name);

  const onSubmitHandler: SubmitHandler<ManageRoadInput> = (inputs) => {
    const submitUpdateRoad = () => {
      if (!roadToModify?.id || !inputs.roadSections)
        return Promise.reject('Missing road id or road sections');

      // convert segmentNum to be between 0 and 11
      const roadSectionsToSubmit = inputs.roadSections.map((roadSection, index) => {
        const muIdToSegments = Object.entries(roadSection.muIdToSegments!).reduce((acc, [muId, segments]) => {
          if (segments.length === 0) return acc;
          const newSegments = segments.map((segment) => {
            if (!segment || segment.segmentNum === undefined) return segment;
            const newSegment = { ...segment };
            newSegment.segmentNum = newSegment.segmentNum! % 12;
            newSegment.roadSectionId = roadSection.id;
            return newSegment;
          });
          const newMuIdToSegments = { ...acc, [muId]: newSegments };
          return newMuIdToSegments;
        }, {});
        roadSection.muIdToSegments = muIdToSegments;
        if (Object.keys(roadSection.muIdToSegments).length === 0) delete roadSection.muIdToSegments;

        // remove .managementUnits from roadSection
        const { managementUnits, ...roadSectionWithoutManagementUnits } = roadSection;
        return {
          ...roadSectionWithoutManagementUnits,
          id: hasRoadSectionNameChanged(index) ? undefined : roadSection.id,
          geoPath: roadSection.geoPath ?? '',
        };
      });
      return manageRoad(roadToModify.id, roadSectionsToSubmit);
    };

    setLoading(true);
    setSubmitError(null);
    submitUpdateRoad()
      .then((res) => {
        toast.success('Road updated');
        onSuccessfulSubmit();
      })
      .catch((error) => {
        toast.error('Road update failed');
        setSubmitError('Something went wrong');
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmitError: SubmitErrorHandler<ManageRoadInput> = (errors) => {
    setSubmitError('Something went wrong');
    console.error(errors);
  };

  // get dnyamic mu list
  useEffect(() => {
    if (!selectedProject) return;
    const getDynamicMus = async () => {
      if (!selectedProject?.id) return;
      const dynamicMus = await getDynamicMuList(selectedProject.id);
      if (dynamicMus) setDynamicMus(dynamicMus);
    };
    getDynamicMus();
  }, [selectedProject]);

  useEffect(() => {
    methods.setValue('roadSections', roadToModify?.roadSections);
  }, [roadToModify, methods]);

  return (
    <FormProvider {...methods}>
      <Box
        component='form'
        onSubmit={methods.handleSubmit(onSubmitHandler, onSubmitError)}
        noValidate
        autoComplete='off'
      >
        <Stack spacing={2}>
          {/* Road Sections */}
          {roadToModify &&
            methods.watch('roadSections')?.map((roadSection, roadSectionIndex: number) => (
              <Stack
                key={roadSectionIndex}
                sx={{
                  p: 2,
                  border: '1px solid',
                  borderColor: (theme) => theme.palette.grey[300],
                  borderRadius: '5px',
                }}
              >
                <RoadSectionInputs
                  roadSectionIndex={roadSectionIndex}
                  dynamicMus={dynamicMus}
                  handleAddManagementUnit={handleAddManagementUnit}
                  handleRemoveManagementUnit={handleRemoveManagementUnit}
                  handleRemoveRoadSection={handleRemoveRoadSection}
                />
              </Stack>
            ))}
          {roadToModify && (
            <Button
              variant='outlined'
              color='primary'
              startIcon={<div>+</div>}
              onClick={() => handleAddRoadSection()}
            >
              Add Road Section
            </Button>
          )}
        </Stack>
        {/* Bottom - Submit + Errors */}
        <Stack
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 1,
            width: '100%',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          {submitError && (
            <Stack alignItems={'center'} sx={{ mt: 2 }}>
              <Alert severity='error'>{submitError}</Alert>
            </Stack>
          )}
          <FormSubmitionButtons loading={loading} onCancel={onCancel} sx={{ paddingBlock: 2 }} />
        </Stack>
      </Box>
    </FormProvider>
  );
};
