import { action, computed, makeAutoObservable } from 'mobx';
import { AbstractRootStore } from '../storeModels';
import { ParsedMeterValues } from '../../utils/ocppUtils';
import * as OCPPMessageTypes from '../../types/ocppTypes';
import * as OCPPV16 from '@cshil/ocpp-tools/types/v16';
import * as OCPPV201 from '@cshil/ocpp-tools/types/v201';
import { isValidGetConfigurationResponseV16, isValidStatusNotificationRequestV16 } from '@cshil/ocpp-tools/validation/v16';
import { isValidStatusNotificationRequestV201 } from '@cshil/ocpp-tools/validation/v201';

class OCPPStore {
  rootStore: AbstractRootStore;
  public connectionState: Record<string, boolean> = {};
  public liveData: {
    [chargerId: string]: Record<string, unknown> & {
      // Device level live data
      charger?: {
        ocppBootloaderMessage?: OCPPMessageTypes.OCPPBootNotificationMessage;
        ocppConnectionStateMessage?: OCPPMessageTypes.OCPPConnectionStateMessage;
      };

      // Connector level live data
      connectors?: {
        [connectorId: number]: {
          meterValues?: ParsedMeterValues;
          statusNotification?: OCPPV16.StatusNotificationRequestV16 | OCPPV201.StatusNotificationRequestV201;
          startTransaction?: { data: OCPPV16.StartTransactionRequestV16; properties: { transactionId?: number } };
        }
      }

    };
  } = {};
  public liveChargingState: {
    [chargerId: string]: { [connectorId: number]: boolean | undefined };
  } = {};

  constructor(rootStore: AbstractRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action
  setOCPPConnectionState = (chargerId: string, isConnected: boolean) => {
    this.connectionState[chargerId] = isConnected;
  }

  @action
  setOCPPMeterValues = (chargerId: string, data: ParsedMeterValues) => {
    if (!data?.connectorId) {
      console.error(`Missing connectorId in meterValues for charger ${chargerId}`)
      return;
    };
    this.liveData[chargerId] = {
      ...this.liveData[chargerId],
      connectors: {
        ...this.liveData[chargerId]?.connectors,
        [data.connectorId]: {
          ...this.liveData[chargerId]?.connectors?.[data.connectorId],
          meterValues: data
        }
      }
    };
  }

  @action
  setConnectorStatus = (
    chargerId: string,
    connectorId: number,
    data: OCPPV16.StatusNotificationRequestV16 | OCPPV201.StatusNotificationRequestV201
  ) => {
    this.liveData[chargerId] = {
      ...this.liveData[chargerId],
      connectors: {
        ...this.liveData[chargerId]?.connectors,
        [connectorId]: {
          ...this.liveData[chargerId]?.connectors?.[connectorId],
          statusNotification: data
        }
      }
    };
  }

  @action
  setLiveChargingState = (chargerId: string, connectorId: number, isCharging: boolean) => {
    this.liveChargingState[chargerId] = {
      ...this.liveChargingState[chargerId],
      [connectorId]: isCharging
    };
  }

  @action
  setStartTransaction = (chargerId: string, connectorId: number, data: OCPPV16.StartTransactionRequestV16, transactionId?: number) => {
    this.liveData[chargerId] = {
      ...this.liveData[chargerId],
      connectors: {
        ...this.liveData[chargerId]?.connectors,
        [connectorId]: {
          ...this.liveData[chargerId]?.connectors?.[connectorId],
          startTransaction: { data, properties: { transactionId } }
        }
      }
    };
  }

  /** Returns true if the given connector is charging either by liveData or metadata (most recent) */
  isConnectorCharging = (chargerId: string, connectorId: number) => {
    const isChargingByLiveState = this.liveChargingState[chargerId]?.[connectorId];
    if (isChargingByLiveState !== undefined) return isChargingByLiveState;

    const chargerMetadata = this.rootStore.projectStore.ocppMap.get(chargerId);

    const statusNotificationMessage = chargerMetadata?.statusNotification?.[connectorId];

    if (!isValidStatusNotificationRequestV16(statusNotificationMessage)) {
      console.error(`Invalid statusNotificationMessage for charger ${chargerId} and connector ${connectorId}`);
      return false;
    }

    const isChargingByMetadata = statusNotificationMessage?.status === 'Charging';
    return isChargingByMetadata;
  }

  /** Returns true if at least one of the connectors of the charger is charging */
  isChargerCharging = (chargerId: string) => {
    // if one of the connectors is charging, the charger is charging
    for (const connectorId in this.liveChargingState[chargerId]) {
      if (this.isConnectorCharging(chargerId, Number(connectorId))) return true;
    }
    return false;
  }

  /** Returns the statusNotification message for the given connector either from liveData or metadata (most recent) */
  getConnectorStatus = (chargerId: string, connectorId: number) => {
    const liveStatusNotificationMessage
      = this.liveData[chargerId]?.connectors?.[connectorId]?.statusNotification;
    const metadataStatusNotificationMessage = this.rootStore.projectStore.ocppMap.get(chargerId)?.statusNotification?.[connectorId];

    const statusNotificationMessage = liveStatusNotificationMessage ?? metadataStatusNotificationMessage;

    if (statusNotificationMessage && !isValidStatusNotificationRequestV16(statusNotificationMessage)) {
      console.error(`Invalid statusNotificationMessage for charger ${chargerId} and connector ${connectorId}`);
    } else return statusNotificationMessage;
  }

  /** Returns the number of connectors for each OCPP */
  @computed
  get ocppIdToConnectorCountMap() {
    const ocppIdToConnectorCountMap = new Map<string, number>();
    this.rootStore.projectStore.ocppList.forEach((ocpp) => {
      if (!ocpp.id) return;
      const configuration = (isValidGetConfigurationResponseV16(ocpp.configuration)) ? ocpp.configuration : null;
      const numberOfConnectors = configuration?.configurationKey?.find(({ key }) => key === 'NumberOfConnectors')?.value ?? null;
      if (numberOfConnectors === null) console.warn(`OCPP ${ocpp.id} has no NumberOfConnectors configuration key`);
      ocppIdToConnectorCountMap.set(ocpp.id, parseInt(numberOfConnectors ?? '0'));
    });
    return ocppIdToConnectorCountMap;
  }

  /** Returns true if a connector is in transaction (charging, preparing, finishing, suspended) */
  isConnectorInTransaction = (chargerId: string, connectorId: number) => {
    const statusNotificationMessage = this.getConnectorStatus(chargerId, connectorId);
    return statusNotificationMessage?.status === 'Charging'
      || statusNotificationMessage?.status === 'Preparing'
      || statusNotificationMessage?.status === 'Finishing'
      || statusNotificationMessage?.status === 'SuspendedEV'
      || statusNotificationMessage?.status === 'SuspendedEVSE'
  }

  /** Returns transaction id of a conenctor if it is in transaction */
  getTransactionId = (chargerId: string, connectorId: number) => {
    const liveTransactionId = this.liveData[chargerId]?.connectors?.[connectorId]?.startTransaction?.properties.transactionId;
    if (liveTransactionId) return liveTransactionId;

    const chargerMetadata = this.rootStore.projectStore.ocppMap.get(chargerId);
    const meterValues = chargerMetadata?.meterValues?.[connectorId];
    if (!meterValues) return undefined;

    const transactionId = meterValues.meterValue?.find((value) => value.sampledValue?.find((sampledValue) => sampledValue.context === 'Transaction.Begin'))?.sampledValue?.find((sampledValue) => sampledValue.context === 'Transaction.Begin')?.value;
    return transactionId;
  }

}

export default OCPPStore;
