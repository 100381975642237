import { SxProps } from '@mui/material';

export const AlertsMainTableStyles: SxProps = {
  "& .MuiDataGrid-cell[data-field='severity']": {
    padding: 0,
  },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
  '& .MuiDataGrid-cell:focus': { outline: 'none' },
  '& .MuiDataGrid-cell:focus-visible': { outline: 'none' },
  '& .MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '& .MuiDataGrid-columnHeader:focus': { outline: 'none' },
  '& .MuiDataGrid-columnHeader:focus-visible': { outline: 'none' },
  '& .MuiDataGrid-row': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnSeparator': {
    borderBottom: 'none',
  },
};
