import { Button, useTheme, Box, Typography, Stack } from '@mui/material';
import { electreonHeaderLogoImageStyles } from 'Components/Header/Styles/HeaderStyles';
import { env } from 'config';
import { HeaderMode } from 'Components/Header/Hooks/useHeaderMode';
import electreonSmallLogo from 'Utils/Images/GlobalIcons/electreon-logo-small.svg';
import { EnvType } from '@electreon_ui/shared/types/globals';
interface ElectreonHeaderLogoProps {
  handleClick: () => void;
}

export const ElectreonHeaderLogo: React.FC<ElectreonHeaderLogoProps> = ({ handleClick }) => {
  const theme = useTheme();
  const envTextIndication =
    env === EnvType.Dev ? 'Dev' : env === EnvType.Stage ? 'Test' : env === EnvType.Prod ? '' : '';
  const showEnvName = env === EnvType.Dev || env === EnvType.Stage;
  return (
    <Button
      disableRipple
      onClick={handleClick}
      sx={{
        border: 'none',
        padding: 0,
        margin: 0,
        gap: 0,
      }}
    >
      <Stack style={{ width: 'clamp(43px, 3vw, 55px)' }}>
        <Box component='img' sx={{ ...electreonHeaderLogoImageStyles }} alt='Logo' src={electreonSmallLogo} />
        {showEnvName && (
          <Typography
            color={HeaderMode.DARK ? theme.palette.primary.main : theme.palette.secondary.light}
            fontSize={8}
            paddingTop={1}
          >
            {envTextIndication}
          </Typography>
        )}
      </Stack>
    </Button>
  );
};
