type CollectImageFiles = ({
  logoSmall,
  logoMain,
  cardImage,
}: {
  logoSmall: File | null;
  logoMain: File | null;
  cardImage: File | null;
}) => File[];

export const collectImageFiles: CollectImageFiles = ({ logoSmall, logoMain, cardImage }) => {
  const files: File[] = [];
  const descriptions = generateFiles(logoSmall, logoMain, cardImage);

  if (logoSmall) {
    files.push(new File([logoSmall], descriptions.logoSmall.name, { type: descriptions.logoSmall.type }));
  }

  if (logoMain) {
    files.push(new File([logoMain], descriptions.logoMain.name, { type: descriptions.logoMain.type }));
  }

  if (cardImage) {
    files.push(new File([cardImage], descriptions.cardImage.name, { type: descriptions.cardImage.type }));
  }

  return files;
};

const getFileExtension = (file: File | null) => {
  if (!file) return '';
  return file.type.split('/')[1].split('+')[0];
};

const getMimeType = (file: File | null) => {
  if (!file) return '';
  const type = getFileExtension(file);
  return type === 'svg' ? 'image/svg+xml' : 'image/png';
};

const generateFiles = (logoSmall: File | null, logoMain: File | null, cardImage: File | null) => {
  const logoSmallType = getFileExtension(logoSmall);
  const logoSmallFileName = `project_logo_small.${logoSmallType}`;
  const logoSmallMimeType = getMimeType(logoSmall);
  const logoMainType = getFileExtension(logoMain);
  const logoMainFileName = `project_logo_main.${logoMainType}`;
  const logoMainMimeType = getMimeType(logoMain);
  const cardImageType = getFileExtension(cardImage);
  const cardImageFileName = `project_bg.${cardImageType}`;
  const cardImageMimeType = getMimeType(cardImage);

  return {
    logoSmall: { name: logoSmallFileName, type: logoSmallMimeType },
    logoMain: { name: logoMainFileName, type: logoMainMimeType },
    cardImage: { name: cardImageFileName, type: cardImageMimeType },
  };
};
