import { InputAdornment, TextField } from '@mui/material';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAppStore } from 'MobxStores/context';
import { useDeviceNameAvailability } from '../Hooks/useDeviceNameAvailability';
import { UiDeviceType } from '@electreon_ui/shared/types/globals';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';

interface DeviceNameInputProps {
  currentDeviceName: string;
  deviceType: UiDeviceType;
  disabled?: boolean;
}

export const DeviceNameInput: React.FC<DeviceNameInputProps> = ({
  deviceType,
  currentDeviceName,
  disabled,
}) => {
  const [deviceName, setDeviceName] = useState<string>(currentDeviceName);
  const { projectStore } = useAppStore();
  const { deviceNameAvaliable } = useDeviceNameAvailability(
    currentDeviceName,
    deviceName,
    projectStore?.selectedProject?.id,
    deviceType
  );
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    setDeviceName(currentDeviceName);
    setValue('deviceName', currentDeviceName);
  }, [currentDeviceName, setValue]);

  return (
    <TextField
      disabled={disabled}
      sx={disabled ? { ...disabledTextFieldStyle, mb: 2 } : { mb: 2 }}
      label='Device Name'
      fullWidth
      type='deviceName'
      error={!!errors['deviceName']}
      helperText={errors['deviceName'] ? errors['deviceName'].message?.toString() : ''}
      value={deviceName}
      {...register('deviceName')}
      InputProps={{
        endAdornment: !disabled && (
          <InputAdornment position='end'>
            <InputAdornmentIcon isValid={deviceNameAvaliable} />
          </InputAdornment>
        ),
      }}
      onChange={(e: any) => {
        setDeviceName(e.target.value);
      }}
    />
  );
};
