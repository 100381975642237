import {
  AbstractIoTDeviceModel,
  IoTDeviceType,
  PosModel,
  VehicleUnitModel,
} from '@electreon/electreon-device-metadata-service-gen-ts-client';
import {
  ApfcModel,
  ManagementUnitModel,
  OcppChargerWithParkingSpots,
} from '@electreon/electreon-metadata-service-gen-ts-client';
import { UserRole } from '../constants/constants';
import {
  OcppV16ChargerModel,
  OcppV201ChargerModel,
} from '@electreon/electreon-ocpp-device-metadata-service-gen-ts-client';

export enum EnvType {
  Dev = 'dev',
  Stage = 'stage',
  Prod = 'prod',
}

export enum AppNames {
  SmartRoad = 'smartRoad',
  Integration = 'integration',
}

export type Email = `${string}@${string}.${string}`;
export const isEmail = (email?: string): email is Email => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email || '');
};

export type ValuesOf<T> = T[keyof T];

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

/** Includes all possible paths within the object, covering both intermediate and leaf nodes. */
export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? {
        [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
      }[keyof T]
    : '';

/** Includes only paths that lead directly to leaf nodes, ignoring intermediate nodes. */
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
    : '';

type IsLeaf<T> = T extends object ? (keyof T extends never ? true : false) : true;

/** Includes only keys that lead directly to leaf nodes, ignoring intermediate nodes. */
export type LeafKeys<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
    ? { [K in keyof T]-?: IsLeaf<T[K]> extends true ? K : LeafKeys<T[K], Prev[D]> }[keyof T]
    : never;

export type ThemeMode = 'light' | 'dark';

export type BaseUrl =
  | 'https://backend.dev.electreon.com'
  | 'https://backend.stage.electreon.com'
  | 'https://backend.app.electreon.com';

export interface ChargingTypes {
  STATIC?: number;
  DYNAMIC?: number;
}

export interface DeploymentTypes {
  SURFACE?: number | null;
  UNDERGROUND?: number | null;
  LAB?: number | null;
}

export interface VehicleTypes {
  BUS?: number | null;
  TRUCK?: number | null;
  PRIVATE?: number | null;
  VAN?: number | null;
  DELIVERY_TRUCK?: number | null;
}

export enum ParsedAppMode {
  Static = 'Static',
  Dynamic = 'Dynamic',
  Unknown = 'Unknown',
}
export type Logo = { main: string; small: string };

export type UiDevice = AbstractIoTDeviceModelRefined | OcppChargerModelRefined;

export type UiDeviceType = UiDevice['deviceType'];

export type AbstractIoTDeviceModelRefined = Omit<AbstractIoTDeviceModel, 'deviceType'> & {
  // fixes deviceType being defined as enum in one service and string in another
  deviceType?: IoTDeviceType | string;
  stateVersion?: number;
};

export type AbstractIoTDeviceType = AbstractIoTDeviceModelRefined['deviceType'];

export type ManagementUnitModelRefined = Omit<ManagementUnitModel, 'deviceType'> & {
  deviceType?: IoTDeviceType | string;
  stateVersion?: number;
};

export type VehicleUnitModelRefined = Omit<VehicleUnitModel, 'deviceType'> & {
  deviceType?: IoTDeviceType | string;
  stateVersion?: number;
};

export type PosModelRefined = Omit<PosModel, 'deviceType'> & {
  deviceType?: IoTDeviceType | string;
  stateVersion?: number;
};

export type ApfcModelRefined = Omit<ApfcModel, 'deviceType'> & {
  deviceType?: IoTDeviceType | string;
  stateVersion?: number;
};

export type OcppChargerModelRefined = Omit<OcppV16ChargerModel | OcppV201ChargerModel, 'deviceType'> & {
  deviceType?: IoTDeviceType | string;
};

export type OcppChargerWithParkingSpotsRefined = Omit<OcppChargerWithParkingSpots, 'ocppCharger'> & {
  ocppCharger: OcppChargerModelRefined;
};
