import React, { useState } from 'react';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';

// Components
import { AlertsTable } from 'Screens/AlertDashboard/AlertsMainTable/AlertsTable';

// Utils
import { alertsTableColumns } from 'Screens/AlertDashboard/AlertsMainTable/AlertsTableData';
import 'Screens/DepotDashboard/DepotDashboard.scss';
import { AlertsTableCustomFilter } from './AlertsTableCustomFilter';
import { AlertTab } from './Hooks/useProjectAlertsTabs';
interface AlertsDashboardTabsContentsProps {
  selectedTab: number,
  selectedTimeRange: number,
  alertsTabs: AlertTab[]
}
export const AlertsDashboardTabsContents: React.FC<AlertsDashboardTabsContentsProps> = observer(({
  alertsTabs, selectedTab, selectedTimeRange
}) => {
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([]);

  return (
    <React.Fragment>
      {alertsTabs.map((alertsTab, index) => (
        <AlertsTableCustomFilter
          key={`alerts-tab-table-${alertsTab.projectId}`}
          sx={{ height: '100%', overflow: 'auto', flex: 1, mt: 2 }}
          value={selectedTab}
          index={index}
        >
          <AlertsTable
            rows={alertsTab.alertsToDisplay || []}
            columns={alertsTableColumns}
            selectedRowIds={selectedRowIds}
            setSelectedRowIds={setSelectedRowIds}
            selectedTimeRange={selectedTimeRange}
            projectId={Number(alertsTab.projectId) || undefined}
          />
        </AlertsTableCustomFilter>
      )
      )}
    </React.Fragment>
  );
});
