import { useState, useEffect } from 'react';

//Context
import { useAppStore } from 'MobxStores/context';

//Utils
import { lockDevice, unlockDevice } from 'Utils/APIUtils';
import lockedByMeIcon from 'Utils/Images/ProjectMaintanance/locked-by-me-icon.svg';
import lockedIcon from 'Utils/Images/ProjectMaintanance/locked-icon.svg';
import unlockedIcon from 'Utils/Images/ProjectMaintanance/unlocked-icon.svg';
import { Functionalities, WithPermission } from '@electreon_ui/shared/permissions';
import { isRoleHigher } from 'Utils/utils';
import { ROLE_TYPES } from 'Utils/constants';
import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';
import { cn } from '@repo/ui';

type LockButtonProps = { device: AbstractIoTDeviceModelRefined | null; className?: string };

export const LockButton: React.FC<LockButtonProps> = ({ device, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { userStore, deviceLockStore } = useAppStore();
  const lockState = deviceLockStore.getLockState(device?.id!);
  const sessionId = userStore.userData?.sub;
  const isHigherRole = isRoleHigher(
    userStore.userData?.role,
    deviceLockStore.getLockState(device?.id!)?.lockRole
  );
  const isSuperAdmin = userStore.userData?.role === ROLE_TYPES.SUPER_ADMIN;

  useEffect(() => {
    console.info('User role at lockButton - ', JSON.stringify(userStore.userData?.role));
  }, [userStore]);

  if (!sessionId) return null;

  const permissionType = device?.deviceType === 'MU' ? Functionalities.MU_LOCK : Functionalities.VU_LOCK;

  return (
    <WithPermission for={permissionType} userData={userStore.userData}>
      <div
        title={
          !lockState.isLocked
            ? 'Click to lock device'
            : lockState.isLockedByMe
              ? 'Click to unlock device'
              : userStore.isProjectViewer
                ? 'Device is locked'
                : `Device is locked by ${lockState.lockedBy}`
        }
        onClick={() => {
          if (isLoading) return;
          setIsLoading(true);
          const { isLockedByMe, isLocked } = lockState;
          if (!isLocked && sessionId) {
            return lockDevice({
              deviceId: device?.id,
              sessionId: sessionId,
            })
              .then((res) => {
                if (res && res.status === 200 && device && device.id) {
                  deviceLockStore.addLockedDevice(device.id);
                  setIsLoading(false);
                }
              })
              .catch((err) => setIsLoading(false));
          } else if ((isSuperAdmin || isLockedByMe || isHigherRole) && device && sessionId) {
            return unlockDevice({
              deviceId: device.id,
              sessionId: sessionId,
            })
              .then((res) => {
                if (res && res.status === 200 && device && device.id) {
                  deviceLockStore.removeLockedDevice(device.id);
                  setIsLoading(false);
                }
              })
              .catch((err) => setIsLoading(false));
          }
        }}
        className={cn('locked-unlock-icon', className)}
        style={{ cursor: 'pointer' }}
      >
        <img
          src={lockState.isLockedByMe ? lockedByMeIcon : lockState.isLocked ? lockedIcon : unlockedIcon}
          height={16}
          width={16}
          alt={`Lock Button on device type ${device ? device.name : ''}`}
        />
      </div>
    </WithPermission>
  );
};
LockButton.displayName = 'LockButton';
