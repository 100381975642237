import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';
import { getPalette } from './colorPalette';
import { baseStyles } from './baseComponentStyles';
import { ThemeMode } from '../types/globals';

const getThemeStyle = (): ThemeOptions => {
  return baseStyles;
};

export const generateTheme = (mode: ThemeMode) => {
  const theme = createTheme(getPalette(mode), getThemeStyle());
  return theme;
};

export const theme = generateTheme('light');

const GlobalThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default GlobalThemeProvider;
