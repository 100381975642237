import { useAppStore } from 'MobxStores/context';
import { getProjectIdFromURL } from 'MobxStores/utils/alertStoreUtils';
import { useEffect } from 'react';

export const useGetLatestAlerts = (timeRangeMinutes: number = 60 * 24) => {
  const { alertStore, projectStore } = useAppStore();
  const { selectedProject } = projectStore;
  const projectId = selectedProject?.id || getProjectIdFromURL();
  const isPopOutProjectPage = location.pathname.includes('/dashboard/alerts/project');

  useEffect(() => {
    if (!projectId && isPopOutProjectPage) return;
    alertStore?.clearProjectsAlerts(projectId);
    alertStore?.getLatestAlerts(projectId, Number(timeRangeMinutes));
  }, [timeRangeMinutes, projectId, alertStore, isPopOutProjectPage]);
};
