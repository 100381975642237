import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';

import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import {
  DeviceEnergyMeterValueFormProps,
  DeviceEnergyMeterValueInput,
  deviceEnergyMeterValueSchema,
} from './DeviceSetEnergyMeterValueUtils';
import { useState } from 'react';
import { setDeviceEnergyMeterValueApi } from '../FormsAPIs/api';
import { useLockState } from 'CustomHooks/useLockState';
import { toast } from 'sonner';

export const DeviceSetEnergyMeterValueForm: React.FC<DeviceEnergyMeterValueFormProps> = ({
  device,
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
}) => {
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    watch,
    reset,
    handleSubmit,
  } = useForm<DeviceEnergyMeterValueInput>({
    resolver: zodResolver(deviceEnergyMeterValueSchema),
    defaultValues: {
      energyMeterValue: '0',
      dcSamplerIndex: '0',
    },
  });
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { isLockedByMe } = useLockState(device);

  const deviceTypeName =
    device.deviceType === 'MU' ? 'Management Unit' : device.deviceType === 'VU' ? 'Vehicle Unit' : 'Device';
  const onSubmitHandler: SubmitHandler<DeviceEnergyMeterValueInput> = (values) => {
    setSubmitError(null);
    const EnergyMeterValue = parseFloat(values.energyMeterValue);
    const dcSamplerIndex = parseInt(values.dcSamplerIndex);
    setLoading(true);
    setDeviceEnergyMeterValueApi(device?.id || '', device.deviceType, dcSamplerIndex, EnergyMeterValue)
      .then((res: any) => {
        if (res && res.status === 200) {
          toast.success('Device Setting Energy Meter Value success');
          reset();
          onSuccessfulSubmit?.();
        } else if (res && res.status === 400) {
          toast.error('Device Setting Energy Meter Value failed');
          setSubmitError('Device Setting Energy Meter Value failed');
          throw new Error('Device Setting Energy Meter Value failed');
        } else if (res && res.status === 422) {
          toast.error('Could Not perform Setting Energy Meter Value on Device, Lock Device and Try Again');
          setSubmitError('Could Not perform Setting Energy Meter Value on Device, Lock Device and Try Again');
          throw new Error('Device Setting Energy Meter Value failed');
        } else {
          //todo: what are possible errors??
          toast.error('Device Setting Energy Meter Value failed');
          setSubmitError('Device Setting Energy Meter Value failed');
          throw new Error('Device Setting Energy Meter Value failed');
        }
      })
      .catch((err: any) => {
        toast.error('Device Setting Energy Meter Value failed');
        console.error('Device Setting EnergyMeter Value error', JSON.stringify(err));
        if (err.message.includes('422')) {
          setSubmitError('Could Not perform Setting EnergyMeter Value on Device, Lock Device and Try Again');
          throw new Error('Device Setting EnergyMeter Value failed');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant='h4' component='h1'>
        Set Energy Meter Value For {deviceTypeName}
      </Typography>
      {!isLockedByMe && (
        <Alert severity='warning' sx={{ mt: 2 }}>
          This device is not locked by you.
        </Alert>
      )}
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (err) => {
          console.log(err);
          console.log(watch());
        })}
      >
        <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label='Project'
            disabled
            sx={{ ...disabledTextFieldStyle }}
            value={selectedProject?.name}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            sx={{ ...disabledTextFieldStyle }}
            label='Device Id'
            fullWidth
            value={device?.id}
          />
        </Stack>

        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <TextField
            sx={{ mb: 2 }}
            label='DC-Sampler Index'
            fullWidth
            type='dcSamplerIndex'
            error={!!errors['dcSamplerIndex']}
            helperText={errors['dcSamplerIndex'] ? errors['dcSamplerIndex'].message : ''}
            {...register('dcSamplerIndex')}
          />
          <TextField
            sx={{ mb: 2 }}
            label='Requiered Energy Meter Value'
            fullWidth
            type='energyMeterValue'
            error={!!errors['energyMeterValue']}
            helperText={errors['energyMeterValue'] ? errors['energyMeterValue'].message : ''}
            {...register('energyMeterValue')}
          />
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons
          loading={loading}
          onCancel={onCancel}
          submitLabel='Set Energy'
          disableSubmit={!isLockedByMe}
        />
      </Box>
    </>
  );
};
DeviceSetEnergyMeterValueForm.displayName = 'DeviceSetEnergyMeterValueForm';
