import 'App.scss';
import LogRocket, { init } from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { AppContext, rootStore } from 'MobxStores/context';
import { isLocalDevEnv } from 'config';
import { useNetworkStatus } from 'CustomHooks/useNetworkStatus';
import GlobalThemeProvider from '@electreon_ui/shared/Themes/globalTheme';
import AppRouter from 'AppRouter/AppRouter';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

if (!isLocalDevEnv) {
  init('qaac7g/smart-road', { release: import.meta.env.VITE_BUILD_VERSION });
  setupLogRocketReact(LogRocket);
}

export function App() {
  useNetworkStatus();

  return (
    <div className='App'>
      <GlobalThemeProvider>
        <AppContext.Provider value={rootStore}>
          <QueryClientProvider client={rootStore.queryClient}>
            <AppRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AppContext.Provider>
      </GlobalThemeProvider>
    </div>
  );
}
