import { object, string, number, array, TypeOf, any, record } from 'zod';

const segmentSchema = object({
  projectId: number().optional(),
  muId: string().optional(),
  drawerNum: string().or(number()).transform(Number).optional(),
  segmentNum: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  longitude: string().or(number()).transform(Number).optional(),
  latitude: string().or(number()).transform(Number).optional(),
  parkingSpotId: number().optional(),
  roadSectionId: number().optional(),
});

const roadSectionSchema = object({
  id: number().optional(),
  name: string().min(1, 'Section name is required').optional(),
  version: number().optional(),
  roadId: number().optional(),
  totalLengthKm: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  totalSegments: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  geoPath: string().optional().nullable(),
  muIdToSegments: record(segmentSchema.array()).optional(),
  managementUnits: array(
    object({
      id: string().optional(),
      name: string().optional(),
      chargingType: any().optional(),
      deploymentType: any().optional(),
      longitude: any().optional(),
      latitude: any().optional(),
      powerInputType: any().optional(),
      highPowerStatus: any().optional(),
      apfcId: any().optional(),
      depotId: any().optional(),
      parkingSpotsMapping: any().optional(),
      roadId: any().optional(),
      roadSectionsMapping: any().optional(),
      simIccid: any().optional(),
      deviceType: any().optional(),
      projectId: any().optional(),
      version: any().optional(),
      softwareVersion: any().optional(),
      creationTimestamp: any().optional(),
      connectionStatus: any().optional(),
      lastReconnect: any().optional(),
      sdCardFree: any().optional(),
      sdCardTotal: any().optional(),
      lockInfo: any().optional(),
      lastDisconnect: any().optional(),
      lastDisconnectReason: any().optional(),
      ipAddress: any().optional(),
      deviceSubType: any().optional(),
    })
  ).optional(),
});

export const CreateRoadSchema = object({
  id: number().optional(),
  roadName: string().min(1, 'Road name is required'),
  version: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  projectId: string().or(number()).optional().transform(Number),
  totalLengthKm: string()
    .or(number())
    .optional()
    .refine((value) => {
      if (value === '' || value === undefined) return false;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number),
  geoPath: any().optional().nullable(),
  totalSegments: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  roadSections: array(roadSectionSchema).optional(),
});

export const roadDefaultValues = {
  id: undefined,
  roadName: undefined,
  version: undefined,
  projectId: undefined,
  totalLengthKm: undefined,
  geoPath: undefined,
  totalSegments: undefined,
  roadSections: [],
};

export type CreateRoadInput = TypeOf<typeof CreateRoadSchema>;

export const ManageRoadSchema = object({
  id: number().optional(),
  version: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  projectId: string().or(number()).optional().transform(Number),
  totalLengthKm: string()
    .or(number())
    .optional()
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number),
  geoPath: string().optional().nullable(),
  totalSegments: string()
    .or(number())
    .refine((value) => {
      if (value === '' || value === undefined) return true;
      return !isNaN(+value);
    }, 'Number req.')
    .transform(Number)
    .optional(),
  roadSections: array(roadSectionSchema).optional(),
});

export type ManageRoadInput = TypeOf<typeof ManageRoadSchema>;
