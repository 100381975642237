import { FleetChargingSchedule } from '@electreon/electreon-metadata-service-gen-ts-client';
import { ElectreonLoader } from '@electreon_ui/shared';
import { ChargingScheduleForm } from 'Components/Forms/ChargingScheduleForm/ChargingScheduleForm';
import { api } from 'Services/api';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

export const ChargingScheduleFormWrapper: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [previousSchedule, setPreviousSchedule] = useState<FleetChargingSchedule[] | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      if (!selectedProject?.id) return;

      api.metadata.projects
        .getProjectChargingSchedules(selectedProject.id)
        .then((response) => {
          setPreviousSchedule(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [selectedProject]);

    return (
      <>
        {loading ? (
          <ElectreonLoader />
        ) : (
          <ChargingScheduleForm
            previousSchedule={previousSchedule || []}
            onSuccessfulSubmit={onSuccessfulSubmit}
            onCancel={onCancel}
            selectedProject={selectedProject}
          />
        )}
      </>
    );
  }
);
