import { createTheme, SxProps, Theme } from '@mui/material';

export const loginTheme = createTheme({
  typography: {
    fontFamily: 'Urbanist, sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            fontSize: '0.7rem',
          },
          '& .MuiInputLabel-root': {
            fontSize: '0.7rem',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            fontSize: '0.7rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink': {
            fontSize: '0.7rem',
          },
          '& .MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused': {
            fontSize: '0.7rem',
          },
          '& .MuiInputBase-input': {
            fontSize: '0.7rem',
          },
        },
      },
    },
  },
});

export const leftGridContainer: SxProps = {
  width: '50%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};

export const randomImageFooterWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'space-between',
  alignSelf: 'flex-end',
  alignItems: 'flex-end',
  marginBottom: '0',
};

export const projectNameWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'left',
  justifyContent: 'left',
  alignContent: 'end',
  marginBottom: '5%',
};

export const refreshButtonWrapper: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  padding: 1,
  alignContent: 'end',
  marginBottom: '4%',
  marginRight: 5,
};

export const loginButton: SxProps = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  width: '100%',
  border: '1px solid rgba(62, 228, 82, 0.5)',
  borderRadius: '999px',
  marginBottom: '1.25rem',
  marginTop: '1.25rem',
  color: 'black',
  fontSize: '1rem',
  height: '10%',
};

export const googleLoginButton: SxProps = {
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '2% 5%',
  width: '100%',
  border: '1px solid rgba(0, 209, 253, 0.5)',
  borderRadius: '999px',
  marginTop: '1.25rem',
  marginBottom: '1.25rem',
  color: 'black',
  fontSize: '1rem',
  maxHeight: '10%',
};

export const LocationIcon: SxProps = {
  component: 'h1',
  variant: 'h5',
  paddingTop: 1,
  marginLeft: 5,
};

export const ProjectNameStyle: SxProps = {
  component: 'h1',
  variant: 'h5',
  color: 'white',
  marginLeft: 3,
  padding: 1,
  marginBottom: 'auto',
  marginTop: 'auto',
  textAlign: 'center',
  fontFamily: 'Urbanist',
};

export const loginRandomImageFooter: SxProps = {
  display: 'flex',
  direction: 'row',
  width: '100%',
  justifyContent: 'space-between',
};

export const loginErrorStyles: SxProps = {
  color: loginTheme.palette.error.main,
  fontSize: '0.7rem',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
};

export const loginFormStyles = (theme: Theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  [theme.breakpoints.down('xs')]: {
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
  [theme.breakpoints.up('sm')]: {
    width: '70%',
  },
  [theme.breakpoints.up('md')]: {
    width: '40%',
  },
});

export const deviderStyle: SxProps = {
  marginTop: '2vh',
  marginBottom: '2vh',
  width: '100%',
};
