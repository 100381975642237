import { Alert, Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import {
  BasicDeviceResetOptions,
  DeviceResetOptionsFormProps,
  ResetDeviceInput,
  ResetDeviceSchema,
} from './DeviceResetUtils';
import { useDeviceResetFormLogic } from 'CustomHooks/useDeviceResetFormLogic';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { useLockState } from 'CustomHooks/useLockState';
import { LockButton } from 'Components/Buttons/LockButton/LockButton';
import { observer } from 'mobx-react-lite';

export const DeviceResetOptionsForm: React.FC<DeviceResetOptionsFormProps> = observer(
  ({ device, onSuccessfulSubmit, onCancel, selectedProject }) => {
    const {
      register,
      formState: { errors /* isSubmitSuccessful */ },
      watch,
      reset,
      handleSubmit,
    } = useForm<ResetDeviceInput>({
      resolver: zodResolver(ResetDeviceSchema),
      defaultValues: {
        deviceId: device.id,
        resetType: '',
        delay: '0',
      },
    });
    const { onSubmitHandler, submitError, loading, DeviceResetTypes, deviceTypeName } =
      useDeviceResetFormLogic(device, reset, onSuccessfulSubmit);
    const resetTypeSelected = watch('resetType');
    const { isLockedByMe } = useLockState(device);

    return (
      <>
        <div className='grid grid-cols-2 gap-0 items-center'>
          <div className='flex flex-col gap-1'>
            <h2 className='m-0'>Reset {deviceTypeName}</h2>
            <p className='text-gray-200'>{device.name}</p>
          </div>
          <div className='justify-self-end'>
            <LockButton device={device} />
          </div>
        </div>
        {!isLockedByMe && (
          <Alert severity='warning' sx={{ mt: 2 }}>
            This device is not locked by you.
          </Alert>
        )}
        <Box
          component='form'
          noValidate
          autoComplete='off'
          sx={{ mt: 4 }}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label='Project'
              disabled
              sx={{ ...disabledTextFieldStyle }}
              value={selectedProject?.name}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              disabled
              sx={{ ...disabledTextFieldStyle }}
              label='Device Id'
              fullWidth
              value={device?.id}
            />
          </Stack>
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField
              select
              label='Select Reset Type'
              sx={{ flex: 1 }}
              error={!!errors['resetType']}
              helperText={errors['resetType'] ? errors['resetType'].message : ''}
              value={watch('resetType')}
              {...register('resetType')}
            >
              {DeviceResetTypes.map((resetType) => (
                <MenuItem key={resetType.key} value={resetType.key}>
                  {resetType.text}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          {resetTypeSelected === BasicDeviceResetOptions.RESET_DCSAMPLERS_CUSTOM_DELAY && (
            <TextField
              sx={{ flex: 1 }}
              label='Delay'
              fullWidth
              error={!!errors['delay']}
              helperText={errors['delay'] ? errors['delay'].message : ''}
              {...register('delay')}
              InputProps={{
                endAdornment: <InputAdornment position='end'>seconds</InputAdornment>,
              }}
            />
          )}
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons
            loading={loading}
            onCancel={onCancel}
            submitLabel='Reset'
            disableSubmit={!isLockedByMe}
          />
        </Box>
      </>
    );
  }
);
DeviceResetOptionsForm.displayName = 'DeviceResetOptionsForm';
