import { Alert, Stack, Typography, alpha } from '@mui/material';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { ConfigureDeviceFormFields } from 'Components/Forms/ConfigureShadowForm/ConfigureDeviceFormFields';
import { IndexableManagementUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureMuShadowForm';
import { IndexableVehicleUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureVuShadowForm';
import {
  FieldErrors,
  SubmitHandler,
  UseFormClearErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';

type ConfigureFormTemplateProps = {
  formTitle: React.ReactNode;
  submitButtonText: string;
  handleSubmit:
    | UseFormHandleSubmit<IndexableManagementUnitConfiguration>
    | UseFormHandleSubmit<IndexableVehicleUnitConfiguration>;
  onSubmitHandler:
    | SubmitHandler<IndexableManagementUnitConfiguration>
    | SubmitHandler<IndexableVehicleUnitConfiguration>;
  onCancel: () => void;
  initialShadow: IndexableManagementUnitConfiguration | IndexableVehicleUnitConfiguration | null;
  errors: FieldErrors<any>;
  register: UseFormRegister<any>;
  submitError: string | null;
  loading: boolean;
  swaggerSchema: Record<string, any>;
  setError: UseFormSetError<any>;
  clearErrors:
    | UseFormClearErrors<IndexableManagementUnitConfiguration>
    | UseFormClearErrors<IndexableVehicleUnitConfiguration>;
  isLockedByMe?: boolean;
};

export const ConfigureFormTemplate: React.FC<ConfigureFormTemplateProps> = ({
  formTitle,
  submitButtonText,
  handleSubmit,
  onSubmitHandler,
  onCancel,
  initialShadow,
  errors,
  register,
  submitError,
  loading,
  swaggerSchema,
  setError,
  clearErrors,
  isLockedByMe,
}) => {
  return (
    <Stack
      sx={{ width: '100%', height: '100%' }}
      direction='column'
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmitHandler, (errors) => {
        console.log(errors);
      })}
    >
      <Typography
        variant='h4'
        component='h1'
        sx={{
          mb: '15px',
          fontSize: '24px !important',
          position: 'sticky',
          top: 0,
          zIndex: 2,
          backgroundColor: (theme) => theme.palette.background.default,
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {formTitle}
      </Typography>
      {!isLockedByMe && (
        <Alert severity='warning' sx={{ mb: 2 }}>
          This device is not locked by you. You can view the configuration, but you cannot make any changes.
        </Alert>
      )}
      <Stack sx={{ flexGrow: 1 /* overflowY: 'auto' */ }}>
        <ConfigureDeviceFormFields
          errors={errors}
          swaggerSchema={swaggerSchema}
          register={register}
          setError={setError}
          shadowEntry={initialShadow}
          clearErrors={clearErrors}
          isExpanded={true}
        />
      </Stack>
      <Stack
        sx={{
          position: 'sticky',
          bottom: 0,
          zIndex: 1,
          width: '100%',
          backgroundColor: (theme) => theme.palette.background.default,
        }}
      >
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons
          loading={loading}
          onCancel={onCancel}
          submitLabel={submitButtonText}
          disableSubmit={isLockedByMe ? false : true}
          sx={{ paddingBlock: 2 }}
        />
      </Stack>
    </Stack>
  );
};
