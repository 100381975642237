import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PopupButton from 'Components/Buttons/PopupButton/PopupButton';

export const Popup = ({
  isOpen,
  handleClose,
  handleSuccess,
  title,
  content,
  onlyOKButton = false,
  keepMounted,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSuccess: () => void;
  title?: string;
  content: JSX.Element;
  onlyOKButton?: boolean;
  keepMounted?: boolean;
}) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false} keepMounted={keepMounted}>
      {title && <DialogTitle sx={{ backgroundColor: '#0000000A' }}>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <PopupButton text='OK' onClick={handleSuccess} primary />
        {!onlyOKButton && <PopupButton text='Cancel' onClick={handleClose} />}
      </DialogActions>
    </Dialog>
  );
};
Popup.displayName = 'Popup';
