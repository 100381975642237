import { EnvType } from '../types/globals';

export class GlobalConfig {
  private AUTH_CONFIG = {
    REGION: 'eu-west-1',
    DEV_CLIENT_ID: 'lbu3mqk89oqjg4ob0m9vef8vn',
    DEV_CLIENT_SECRET: '1cgqlgtd3v22rtpu43j9s5skb7152hpafcf6238ampev684fqgjv',
    DEV_USER_POOL_ID: 'eu-west-1_d8ggWXCyf',
    DEV_REDIRECT_URL: 'https://dev.electreon.com/oauth2/callback',
    LOCAL_REDIRECT_URL: 'http://localhost:3000/oauth2/callback',
    STAGE_REDIRECT_URL: 'https://stage.electreon.com/oauth2/callback',
    PROD_REDIRECT_URL: 'https://app.electreon.com/oauth2/callback',
  } as const;

  env: EnvType;
  isLocalDevEnv: boolean;
  cognitoClientSecret: string;
  cognitoClientId: string;
  cognitoUserPool: string;
  region: string;

  constructor(config: {
    env?: EnvType;
    isLocalDevEnv?: boolean;
    cognitoClientSecret?: string;
    cognitoClientId?: string;
    cognitoUserPool?: string;
    region?: string;
  }) {
    this.env = config.env || EnvType.Dev;
    this.isLocalDevEnv = config.isLocalDevEnv || false;
    this.cognitoClientSecret = config.cognitoClientSecret || this.AUTH_CONFIG.DEV_CLIENT_SECRET;
    this.cognitoClientId = config.cognitoClientId || this.AUTH_CONFIG.DEV_CLIENT_ID;
    this.cognitoUserPool = config.cognitoUserPool || this.AUTH_CONFIG.DEV_USER_POOL_ID;
    this.region = config.region || this.AUTH_CONFIG.REGION;
  }

  get redirectUrl() {
    if (this.isLocalDevEnv) return this.AUTH_CONFIG.LOCAL_REDIRECT_URL;
    switch (this.env) {
      case 'dev':
        return this.AUTH_CONFIG.DEV_REDIRECT_URL;
      case 'stage':
        return this.AUTH_CONFIG.STAGE_REDIRECT_URL;
      case 'prod':
        return this.AUTH_CONFIG.PROD_REDIRECT_URL;
      default:
        console.log('No NODE_ENV provided, using DEV_REDIRECT_URL', this.AUTH_CONFIG.DEV_REDIRECT_URL);
        return this.AUTH_CONFIG.DEV_REDIRECT_URL;
    }
  }

  get federatedSignInUrl() {
    return `https://${this.env}-electreon.auth.${this.region}.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${this.redirectUrl}&response_type=CODE&client_id=${this.cognitoClientId}`;
  }

  get tokenEndpoint() {
    return `https://${this.env}-electreon.auth.${this.region}.amazoncognito.com/oauth2/token`;
  }
}
