import { GetDataForSelectedTimespan } from 'MobxStores/Types';
import LogRocket from 'logrocket';
import z from 'zod';

const GetDataForSelectedTimespanSchema = z.tuple([
  z.union([z.number(), z.date(), z.null()]),
  z.union([z.number(), z.date(), z.null()]),
  z.boolean().optional(),
]);

const handleError = (error: z.ZodError) => {
  error.errors.forEach((err) => {
    const fieldPath = err.path.length > 0 ? `Argument at index ${err.path[0]}` : '[Root level]';
    console.error(`Error in ${fieldPath}: ${err.message}`);
    LogRocket.captureException(new Error(`Error parsing history traversals in analytics`), {
      extra: {
        fieldPath,
        message: err.message,
      },
    });
  });
};

export const isDataRequest = (args: any[]): args is Parameters<GetDataForSelectedTimespan> => {
  const result = GetDataForSelectedTimespanSchema.safeParse(args);
  if (!result.success) {
    handleError(result.error);
    return false;
  }
  return result.success;
};

export const sharedUrlParamsSchema = z.object({
  paramsArr: z.array(
    z.object({
      id: z.string(),
      params: z.custom<Set<string>>((val) => {
        return val instanceof Object;
      }, 'incorrect type for params'),
    })
  ),
  selectedDate: z.union([z.date(), z.null(), z.string()]),
  selectedTimespanValues: z
    .object({
      startTime: z.union([z.date(), z.number(), z.string()]),
      endTime: z.union([z.date(), z.number(), z.string()]),
    })
    .optional(),
  selectedActivityDevice: z.string().optional(),
  selectedEventSeveritiesAndTypes: z.record(
    z.string(),
    z.record(z.string(), z.record(z.string(), z.boolean())).optional()
  ),
  stickyRefLinesObj: z.record(
    z.string(),
    z.array(
      z.object({
        currentY: z.number().optional(),
        currentValue: z.union([z.number(), z.string()]).optional(),
        currentTimestamp: z.number().optional(),
        activeDotYCoordRef: z
          .custom<React.MutableRefObject<number | undefined> | undefined>((val) => {
            return typeof val.current === 'number';
          })
          .optional(),
      })
    )
  ),
  userTz: z.string(),
  dutyCycleObj: z.record(
    z.string(),
    z.object({
      cycleInPercentage: z.string().optional(),
      labelPosition: z
        .custom<'insideLeft' | 'insideRight'>((val) => {
          return val === 'insideLeft' || val === 'insideRight';
        }, 'incorrect label position')
        .optional(),
      points: z.array(z.number()),
    })
  ),
});

export const isValidSharedParams = (paramsObj: any) => {
  const result = sharedUrlParamsSchema.safeParse(paramsObj);
  return result.success;
};
