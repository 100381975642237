import { Typography } from '@mui/material';
import Tooltip, { TooltipProps, TooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import { observer } from 'mobx-react-lite';

type TooltipContent = React.ReactNode;

export interface CustomTooltipProps {
  tooltipContent?: TooltipContent;
  placement?: TooltipProps['placement'];
  children: React.ReactElement;
  hideArrow?: boolean;
  classes?: Partial<TooltipClasses>;
  disableInteractive?: boolean;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = observer(
  ({
    tooltipContent,
    placement,
    children,
    hideArrow = true,
    classes,
    disableInteractive = true,
    ...rest
  }) => {
    if (isTooltipContentEmpty(tooltipContent)) return children;
    return (
      <Tooltip
        arrow={!hideArrow}
        disableInteractive={disableInteractive}
        placement={placement}
        title={<Title tooltipContent={tooltipContent} />}
        classes={classes}
        {...rest}
      >
        {children}
      </Tooltip>
    );
  }
);

const Title: React.FC<{ tooltipContent: TooltipContent }> = ({ tooltipContent }) => {
  const content = Array.isArray(tooltipContent) ? tooltipContent : [tooltipContent];
  return content.map((element, index) => {
    return (
      <Typography component='div' key={index} sx={{ fontSize: '14px' }}>
        {element}
      </Typography>
    );
  });
};

const isTooltipContentEmpty = (tooltipContent?: TooltipContent) => {
  if (typeof tooltipContent === 'string' && !tooltipContent) return true;
  if (!tooltipContent) return true;
  if (Array.isArray(tooltipContent)) tooltipContent = tooltipContent.filter(Boolean);
  if (Array.isArray(tooltipContent) && !tooltipContent.length) return true;
  return false;
};
