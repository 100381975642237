import { observer } from 'mobx-react-lite';
import { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, Box, InputLabel, MenuItem, Select } from '@mui/material';
import { LayoutType, DashoardSelectedVehicleIds } from 'MobxStores/Types';
import { DashboardConfigurationStore } from 'MobxStores/DashboardConfigurationStore';
import { useAppStore } from 'MobxStores/context';

type VehicleSelectionProps = {
  layoutType: LayoutType;
  selectedVehicleId: DashoardSelectedVehicleIds;
  setSelectedVehicleId: React.Dispatch<React.SetStateAction<DashoardSelectedVehicleIds>>;
};

export const ActiveVehicleSelection: React.FC<VehicleSelectionProps> = observer(
  ({ layoutType, selectedVehicleId, setSelectedVehicleId }) => {
    const { dashboardConfigurationStore, projectStore } = useAppStore();

    const handleFirstVehicleId = (event: SelectChangeEvent) => {
      setSelectedVehicleId((selectedVehicleId: DashoardSelectedVehicleIds) => ({
        ...selectedVehicleId,
        first: event.target.value,
      }));
    };

    const handleSecondVehicleId = (event: SelectChangeEvent) => {
      setSelectedVehicleId((selectedVehicleId: DashoardSelectedVehicleIds) => ({
        ...selectedVehicleId,
        second: event.target.value,
      }));
    };

    return (
      <Box justifyContent='space-evenly' display='flex'>
        <FormControl size='small'>
          <InputLabel id='first-label'>Active Vehicle</InputLabel>
          <Select
            labelId='first-label'
            value={selectedVehicleId?.first ?? ''}
            label='ActiveVehicle'
            onChange={handleFirstVehicleId}
            sx={{ minWidth: '150px' }}
          >
            {dashboardConfigurationStore.activeVehicles?.map((vehicleId: string, i: number) => (
              <MenuItem key={i} value={vehicleId}>
                {/* {vehicleId} */}
                {projectStore.vuMap.get(vehicleId)?.name ?? vehicleId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {layoutType === 'multipleVehicles' && (
          <FormControl size='small'>
            <InputLabel id='second-label'>Active Vehicle</InputLabel>
            <Select
              labelId='second-label'
              value={selectedVehicleId?.second ?? ''}
              label='ActiveVehicle'
              onChange={handleSecondVehicleId}
              sx={{ minWidth: '150px' }}
            >
              {dashboardConfigurationStore.activeVehicles?.map(
                (vehicleId: string, i: number) =>
                  vehicleId !== selectedVehicleId.first && (
                    <MenuItem key={i} value={vehicleId}>
                      {/* {vehicleId} */}
                      {projectStore.vuMap.get(vehicleId)?.name ?? vehicleId}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormControl>
        )}
      </Box>
    );
  }
);
ActiveVehicleSelection.displayName = 'ActiveVehicleSelection';
