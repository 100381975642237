import { useRef, useEffect, useState, CSSProperties } from 'react';
import { Button, Input } from '@mui/material';
import { defaultDrawingManagerOptions, defaultMapOptions } from 'Components/Map/mapUtils';
import { GeoJsonMultipolygon, MapLoader, MultiPolygonCoordinates } from 'Components/Map/MapLoader';

type MapProps = {
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  polygons?: google.maps.Polygon[];
  setPolygons?: React.Dispatch<React.SetStateAction<google.maps.Polygon[]>>;
  onSubmitted?: (multiPolygon: GeoJsonMultipolygon) => void;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
};

export const Map: React.FC<MapProps> = ({
  center = { lat: 32.371526, lng: 34.862859 },
  zoom = 12,
  polygons = [],
  setPolygons = () => {},
  onSubmitted,
  width = '100%',
  height = '100%',
}) => {
  useEffect(() => {
    const search = document.getElementById('pac-input') as HTMLInputElement;
    search.focus();
  }, []);

  const handleSubmit = () => {
    const data: MultiPolygonCoordinates = polygons.map((polygon) => {
      const path = polygon.getPath();
      const points = path.getArray().map((point) => [[point.lat(), point.lng()]]);
      return points;
    });

    const geoJsonMultipolygon: GeoJsonMultipolygon = {
      type: 'MultiPolygon',
      coordinates: data,
    };

    onSubmitted?.(geoJsonMultipolygon);
  };

  return (
    <div
      style={{
        height,
        width,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 2,
      }}
    >
      <Input
        size='small'
        sx={{ '&&': { height: '24px', paddingX: 2 } }}
        id='pac-input'
        type='text'
        placeholder='Search locations'
        tabIndex={0}
      />
      <div className='map-wrapper' style={{ flex: 1 }}>
        <MapLoader polygons={polygons} setPolygons={setPolygons} center={center} zoom={zoom} />
      </div>

      <Button onClick={handleSubmit} sx={{ height: 30 }}>
        Submit Geofence
      </Button>
    </div>
  );
};
