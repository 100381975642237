import { EnvType } from '@electreon_ui/shared/types/globals';
import { env } from 'config';

const devDashboards = [126, 132, 118, 130];
const stageDashboards = [126, 132, 118, 130];
const prodDashboards = [126, 132, 130, 133, 131, 118, 111, 139, 87, 119, 151];

const availableDashboardIds =
  env === EnvType.Dev ? devDashboards : env === EnvType.Stage ? stageDashboards : prodDashboards;

export const hasDashboard = (id?: number | string) => {
  if (!id) return false;
  return availableDashboardIds.includes(+id);
};
