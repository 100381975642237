import { AlertProps } from '@mui/material';
import { CSSProperties } from 'react';

export const defaultModalState = {
  isOpened: false,
  message: '',
  onCancelModal: () => {},
  onSubmitModal: () => {},
};

export interface IModalState {
  isOpened: boolean;
  message: string;
  onCancelModal: () => void;
  onSubmitModal: () => void;
}
export type InformationPopupState = {
  isOpen: boolean;
  width: CSSProperties['width'];
  severity: AlertProps['severity'];
  close: () => void;
  message: string | JSX.Element | null;
  autoHideDuration?: number;
  anchorOrigin?: {
    vertical?: 'top' | 'bottom';
    horizontal?: 'left' | 'center' | 'right';
  };
  handleClickAway?: boolean;
};
