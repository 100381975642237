import { getNextDeviceIdApi } from 'Components/Forms/FormsAPIs/api';

export const getMUPrefix = (chargingType?: string, deploymentType?: string) => {
  if (chargingType === 'STATIC') return 'SM';
  if (deploymentType === 'UNDERGROUND') return 'UG';
  if (deploymentType === 'SURFACE') return 'AG';
  console.error('Invalid charging type or deployment type');
  return '';
};

const padWithZeros = (value: number | string, toLength: number) => {
  return String(value).padStart(toLength, '0');
};

const handleNextId = (id?: number) => {
  if (id) return padWithZeros(id, 7);
  return `0000001`;
};

export const getNextMUIdByPrefix = async (prefix: 'SM' | 'UG' | 'AG') => {
  const nextIds = await getNextDeviceIdApi('MU');
  return nextIds ? handleNextId(nextIds[prefix]) : handleNextId();
};

export const getNextVUId = async () => {
  const nextIds = await getNextDeviceIdApi('VU');
  return nextIds ? handleNextId(nextIds['VU']) : handleNextId();
};

export const getNextAPFCId = async () => {
  const nextIds = await getNextDeviceIdApi('APFC');
  return nextIds ? handleNextId(nextIds['AP']) : handleNextId();
};

export const getNextPOSId = async () => {
  const nextIds = await getNextDeviceIdApi('POS');
  return nextIds ? handleNextId(nextIds['PS']) : handleNextId();
};
