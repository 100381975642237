import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  lsmuResetCpu,
  lsmuResetDcSamplers,
  lsmuResetDcSamplersCustomDelay,
  lsmuResetMuPower,
  vuResetCpu,
  vuResetDcSamplers,
  vuResetDcSamplersCustomDelay,
} from 'Utils/APIUtils/DeviceApiUtils';
import { object, string, TypeOf } from 'zod';
import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';

export const ResetDeviceSchema = object({
  deviceId: string().min(1, 'Device ID is required'),
  resetType: string().min(1, 'Reset type is required'),
  delay: string()
    .nullable()
    .refine((value) => {
      if (!value) return true;
      const delay = parseInt(value);
      return delay >= 0 && delay <= 60;
    }, 'Delay must be between 0 and 60 seconds'),
});

export type ResetDeviceInput = TypeOf<typeof ResetDeviceSchema>;

export interface DeviceResetOptionsFormProps extends FormProps {
  device: AbstractIoTDeviceModelRefined;
  selectedProject?: Project | null;
}

export enum BasicDeviceResetOptions {
  RESET_CPU = 'RESET_CPU',
  RESET_DCSAMPLERS = 'RESET_DCSAMPLERS',
  RESET_POWER = 'RESET_POWER',
  RESET_MODEM = 'RESET_MODEM',
  RESET_DCSAMPLERS_CUSTOM_DELAY = 'RESET_DCSAMPLERS_CUSTOM_DELAY',
}

const DeviceResetOptions = [
  { key: BasicDeviceResetOptions.RESET_CPU, text: 'Reset CPU' },
  { key: BasicDeviceResetOptions.RESET_DCSAMPLERS, text: 'Reset DC Samplers' },
  { key: BasicDeviceResetOptions.RESET_POWER, text: 'Reset Power' },
  { key: BasicDeviceResetOptions.RESET_MODEM, text: 'Reset Modem' },
  {
    key: BasicDeviceResetOptions.RESET_DCSAMPLERS_CUSTOM_DELAY,
    text: 'Reset DC Samplers with Custom Delay',
  },
];

export const MuResetTypes = DeviceResetOptions.filter(
  ({ key, text }) => key !== BasicDeviceResetOptions.RESET_MODEM
)

export const VuResetTypes = DeviceResetOptions.filter(
  ({ key, text }) =>
    key !== BasicDeviceResetOptions.RESET_POWER && key !== BasicDeviceResetOptions.RESET_MODEM
);

export const ResetDeviceApi = (
  deviceId: string,
  resetType: string,
  deviceType: string | undefined,
  delay?: number | null
) => {
  if (deviceType === 'MU') {
    return ResetMUDeviceApi(deviceId, resetType, delay);
  }
  if (deviceType === 'VU') {
    return ResetVUDeviceApi(deviceId, resetType, delay);
  }
  throw new Error('Device Type is not MU nor VU');
};

const ResetMUDeviceApi = (deviceId: string, resetType: string, delay?: number | null) => {
  switch (resetType) {
    case BasicDeviceResetOptions.RESET_CPU:
      return lsmuResetCpu(deviceId);
    case BasicDeviceResetOptions.RESET_DCSAMPLERS:
      return lsmuResetDcSamplers(deviceId);
    case BasicDeviceResetOptions.RESET_POWER:
      return lsmuResetMuPower(deviceId);
    case BasicDeviceResetOptions.RESET_MODEM:
      throw new Error('Modem reset is not supported');
    // return lsmuResetModem(deviceId);
    case BasicDeviceResetOptions.RESET_DCSAMPLERS_CUSTOM_DELAY:
      return lsmuResetDcSamplersCustomDelay(deviceId, delay || 0);
  }
  return lsmuResetCpu(deviceId);
};

const ResetVUDeviceApi = (deviceId: string, resetType: string, delay?: number | null) => {
  switch (resetType) {
    case BasicDeviceResetOptions.RESET_CPU:
      return vuResetCpu(deviceId);
    case BasicDeviceResetOptions.RESET_DCSAMPLERS:
      return vuResetDcSamplers(deviceId);
    case BasicDeviceResetOptions.RESET_DCSAMPLERS_CUSTOM_DELAY:
      return vuResetDcSamplersCustomDelay(deviceId, delay || 0);
  }
  return vuResetCpu(deviceId);
};
