import * as z from 'zod';

export const AssignOCPPSchema = z.object({
  ocppId: z
    .string()
    .min(1, 'OCPP ID is Required'),
  depotId: z
    .number({
      required_error: "Depot ID is required",
    }),
  parkingSpots: z.array(
    z.object({
      id: z.number().optional(),
      name: z.string().refine((value) => {
        return value && value.length > 0;
      }, 'Parking spot name is required'),
      depotId: z.number().optional(),
      muId: z.string().optional(),
      longitude: z.string()
        .or(z.number())
        // .optional()
        .refine((value) => {
          if (value === '' || value === undefined) return true;
          return !isNaN(+value);
        }, 'Number req.')
        .transform(Number),
      latitude: z.string()
        .or(z.number())
        // .optional()
        .refine((value) => {
          if (value === '' || value === undefined) return true;
          return !isNaN(+value);
        }, 'Number req.')
        .transform(Number),
    })
  ).min(1, 'At least one parking spot is required'),
});

export type AssignOCPPInput = z.TypeOf<typeof AssignOCPPSchema>;

export const UnassignDeviceSchema = z.object({
  deviceId: z
    .string()
    .min(1, 'Device ID is Required'),
});

export type UnassignDeviceInput = z.TypeOf<typeof UnassignDeviceSchema>;
