import { Avatar, Box, IconButton } from '@mui/material';
import { userIconStyles, headerButtonWrapperStyles } from 'Components/Header/Styles/HeaderStyles';
import { forwardRef, HTMLAttributes } from 'react';

interface HeaderUserIconProps extends HTMLAttributes<HTMLDivElement> {
  textDisplay: string;
}

export const HeaderUserIcon = forwardRef<HTMLDivElement, HeaderUserIconProps>(
  ({ textDisplay, onClick }, ref) => {
    return (
      <Box sx={headerButtonWrapperStyles} onClick={onClick} ref={ref}>
        <IconButton sx={{ height: 'min-content', padding: 0 }}>
          <Avatar alt={textDisplay} sx={userIconStyles}>
            {textDisplay}
          </Avatar>
        </IconButton>
      </Box>
    );
  }
);
HeaderUserIcon.displayName = 'HeaderUserIcon';
