import { useCallback, useRef } from 'react';
import { useTheme, ThemeProvider, alpha, IconButton, SxProps, Theme } from '@mui/material';
import { Toolbar, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

//Components
import { UserMenu, ControlPanelMenu } from 'Components/Menu';
import {
  ElectreonHeaderLogo,
  HeaderNotificationIcon,
  HeaderUserIcon,
  TimeDisplay,
  ControlPanelIcon,
  SelectActiveVehicleIcon,
} from 'Components/Header/Components';
import { HeaderDivider } from 'Components/Header/Components/HeaderDivider';
import { StyledAppBar } from 'Components/Header/Components/StyledAppBar';
import { Flag } from 'Components/Header/Components/Flag';
import { HeaderNavigationSection } from 'Components/Header/Components/HeaderNavigationSection';

//Hooks
import { useHeaderData } from 'Components/Header/Hooks/useHeaderData';
import { useAppStore } from 'MobxStores/context';
import { useHeaderMode, HeaderMode } from 'Components/Header/Hooks/useHeaderMode';
import { useTimezoneStr } from 'CustomHooks/useTimezoneStr';
import { WithPermission, Functionalities } from '@electreon_ui/shared/permissions';

//Utils
import { handleSignOut } from 'Utils/APIUtils';
import { getUsernameAbbreviation, getCountryCode } from 'Components/Header/Utils/headerUtils';
import {
  headerLeftSideStyles,
  headerRightSideStyles,
  timeAndFlagWrapperStyles,
} from 'Components/Header/Styles/HeaderStyles';
import { useMedia } from 'react-use';
import { generateTheme } from '@electreon_ui/shared/Themes/globalTheme';
import { useGetLatestAlerts } from 'Screens/AlertDashboard/Hooks/useLatestAlerts';
import { AlertReadStatus } from 'MobxStores/utils/alertStoreTypes';
import { CommandMenu } from 'Components/CommandMenu/CommandMenu';
import SearchIcon from '@mui/icons-material/Search';
import { searchIconStyles } from 'Components/Header/Styles/HeaderStyles';
import { headerStore } from 'Components/Header/headerStore';

export const Header: React.FC = observer(() => {
  const theme = useTheme();
  useHeaderData();
  const { userStore, projectStore, dashboardConfigurationStore, popupStore, alertStore } = useAppStore();
  const navigate = useNavigate();
  const timezoneStr = useTimezoneStr();
  const { headerMode, themeColor, isDashboard } = useHeaderMode();
  const { isSingleProjectViewer, isProjectViewer } = userStore;
  const { selectedProject } = projectStore;
  const { isControlPanelMenuOpen, isUserMenuOpen, setControlPanelMenuOpen, setUserMenuOpen } = headerStore;

  const countryCode = getCountryCode(selectedProject?.countryCode) || 'IL';
  const isMobile = useMedia(`(max-width: ${theme.breakpoints.values.sm}px)`);
  const isAlertsPopOutPage = location.pathname.includes('/dashboard/alerts');
  const hideHeaderNavigation = location.pathname.includes('/dashboard/alerts');
  const showNav = !hideHeaderNavigation;

  const notificationCount = selectedProject?.id
    ? alertStore?.unreadCriticalAlertCount[selectedProject.id]
    : alertStore?.unreadCriticalAlertCount.allSites;

  const handleMainLogoClick = useCallback(() => {
    if (isSingleProjectViewer) return;
    headerStore.closeAllMenus();
    navigate('/dashboard');
    projectStore.setSelectedProject(null);
    dashboardConfigurationStore?.clearStore();
  }, [projectStore, navigate, dashboardConfigurationStore, isSingleProjectViewer]);

  const handleBellClick = useCallback(() => {
    if (hideHeaderNavigation) return;
    headerStore.closeAllMenus();
    if (!popupStore.displayNotificationsPopupOpen) {
      popupStore.openNotificationsPopup();
      return;
    }
    popupStore.closeNotificationsPopup();
    !isAlertsPopOutPage &&
      alertStore.setAlertsStatus(
        AlertReadStatus.READ,
        selectedProject?.id
          ? alertStore.projectAlerts[selectedProject.id] || []
          : alertStore.projectAlerts.allSites
      );
  }, [popupStore, alertStore, selectedProject, hideHeaderNavigation, isAlertsPopOutPage]);

  // gets the project data & logo on mount
  useGetLatestAlerts();
  if (headerMode === HeaderMode.HIDDEN) return null;

  const appBarStyles = {
    position: 'fixed',
    height: theme.header?.height,
    bgcolor:
      headerMode === HeaderMode.TRANSPARENT
        ? 'transparent'
        : headerMode === HeaderMode.LIGHT
          ? alpha(theme.palette.primary.main, 0.04)
          : theme.palette.primary.main,
    '&.MuiAppBar-root': {
      boxShadow: 'none',
    },
  } satisfies SxProps<Theme>;

  const toolbarStyles = {
    px: isMobile ? '8px' : '20px',
    justifyContent: 'space-between',
    '&.MuiToolbar-root.MuiToolbar-regular': {
      minHeight: theme.header?.height,
    },
  } satisfies SxProps<Theme>;

  return (
    <ThemeProvider theme={generateTheme(themeColor)}>
      <StyledAppBar id='main-header' sx={appBarStyles}>
        <Toolbar disableGutters sx={toolbarStyles}>
          <Stack direction='row' spacing={1} sx={headerLeftSideStyles} className='header-left-section'>
            <WithPermission for={Functionalities.HEADER_LOGO} userData={userStore.userData}>
              <ElectreonHeaderLogo handleClick={handleMainLogoClick} />
            </WithPermission>
            {showNav && <HeaderNavigationSection />}
          </Stack>
          <Stack direction='row' sx={headerRightSideStyles}>
            {!isProjectViewer && (
              <>
                {!isMobile && showNav && <HeaderSearch />}
                <WithPermission for={Functionalities.HEADER_BELL} userData={userStore.userData}>
                  <HeaderNotificationIcon
                    disableBellCount
                    notificationCount={notificationCount}
                    hide={hideHeaderNavigation} //specific
                    onClick={handleBellClick}
                  />
                </WithPermission>
              </>
            )}
            <ControlPanelIconWithMenu isOpen={isControlPanelMenuOpen} setIsOpen={setControlPanelMenuOpen} />
            <UserIconWithMenu isOpen={isUserMenuOpen} setIsOpen={setUserMenuOpen} />
            {isDashboard && <SelectActiveVehicleIcon />}
            <HeaderDivider />
            <Stack direction='row' sx={timeAndFlagWrapperStyles}>
              <WithPermission for={Functionalities.HEADER_TIME} userData={userStore.userData}>
                <TimeDisplay timeZone={timezoneStr || ''} />
              </WithPermission>
              <WithPermission for={Functionalities.HEADER_FLAG} userData={userStore.userData}>
                <Flag countryCode={countryCode} />
              </WithPermission>
            </Stack>
          </Stack>
        </Toolbar>
      </StyledAppBar>
    </ThemeProvider>
  );
});
Header.displayName = 'Header';

const UserIconWithMenu: React.FC<{
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}> = observer(({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const userIconRef = useRef<HTMLDivElement | null>(null);
  const { userStore, authStore, projectStore } = useAppStore();
  const { givenName, familyName } = userStore.userData || {};
  const usernameAbbreviation = getUsernameAbbreviation(givenName, familyName);

  const toggleUserMenuOpen = () => {
    headerStore.closeAllMenus();
    setIsOpen(!isOpen);
  };

  const handleLogout = useCallback(() => {
    setIsOpen(false);
    return handleSignOut(navigate, authStore, userStore, projectStore);
  }, [navigate, authStore, projectStore, userStore]);

  return (
    <>
      <HeaderUserIcon ref={userIconRef} onClick={toggleUserMenuOpen} textDisplay={usernameAbbreviation} />
      <WithPermission for={Functionalities.HEADER_ACCOUNT} userData={userStore.userData}>
        <UserMenu
          anchorEl={userIconRef.current}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleLogout={handleLogout}
        />
      </WithPermission>
    </>
  );
});
UserIconWithMenu.displayName = 'UserIconWithMenu';

const ControlPanelIconWithMenu: React.FC<{
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}> = observer(({ isOpen, setIsOpen }) => {
  const { userStore } = useAppStore();
  const controlPanelMenuIconRef = useRef<HTMLDivElement | null>(null);

  const toggleControlPanelMenuOpen = () => {
    headerStore.closeAllMenus();
    setIsOpen(!isOpen);
  };

  return (
    <WithPermission for={Functionalities.HEADER_CONTROL_PANEL} userData={userStore.userData}>
      <ControlPanelIcon onClick={toggleControlPanelMenuOpen} ref={controlPanelMenuIconRef} />
      <ControlPanelMenu anchorEl={controlPanelMenuIconRef.current} isOpen={isOpen} setIsOpen={setIsOpen} />
    </WithPermission>
  );
});
ControlPanelIconWithMenu.displayName = 'ControlPanelIconWithMenu';

const HeaderSearch = observer(() => {
  const { searchMenuOpen, setSearchMenuOpen, closeAllMenus } = headerStore;
  const { userStore, popupStore } = useAppStore();
  return (
    <WithPermission for={Functionalities.HEADER_SEARCH} userData={userStore.userData}>
      <IconButton
        sx={searchIconStyles}
        onClick={() => {
          closeAllMenus();
          popupStore.closePopups();
          setSearchMenuOpen(true);
        }}
      >
        <SearchIcon />
      </IconButton>
      <CommandMenu
        onOpen={() => {
          closeAllMenus();
          popupStore.closePopups();
        }}
        searchMenuOpen={searchMenuOpen}
        setSearchMenuOpen={setSearchMenuOpen}
      />
    </WithPermission>
  );
});
HeaderSearch.displayName = 'HeaderSearch';
