import { useAppStore } from 'MobxStores/context';
import { AlertMessage } from 'MobxStores/utils/alertStoreTypes';
import { getProjectIdFromURL } from 'MobxStores/utils/alertStoreUtils';
import { useRef, useCallback } from 'react';
import { useProjects } from 'Utils/APIUtils';

export interface AlertTab {
  projectId: string;
  label: string;
  alertsToDisplay: AlertMessage[];
}

export const useProjectAlertsTabs = () => {
  const { data: projectsMap } = useProjects();
  const isPopOutPage = location.pathname.includes('/dashboard/alerts');
  const { alertStore, projectStore } = useAppStore();
  const { selectedProject } = projectStore;
  const defaultProjectId = selectedProject?.id || getProjectIdFromURL();
  const defaultProjectName = isPopOutPage ? selectedProject?.name || '' : 'THIS SITE';
  const openTabs = useRef<string[]>([]);
  const projectAlerts = alertStore.projectAlerts;

  const alertsTabs = useCallback(() => {
    const newOpenTabs: string[] = [];
    const newAlertsTabs: AlertTab[] = [];
    //add already open tabs to the newAlertsTabs
    openTabs.current.forEach((openProjectIdTab) => {
      if (alertStore.projectAlerts[openProjectIdTab]) {
        const projectName =
          Number(openProjectIdTab) === defaultProjectId
            ? defaultProjectName
            : projectStore.projectIdToProjectMap.get(Number(openProjectIdTab))?.name || '';
        const label =
          openProjectIdTab !== 'allSites'
            ? `${projectName} (${alertStore.unreadAlertCount[openProjectIdTab] || 0})`
            : `ALL SITES (${alertStore.unreadAlertCount.allSites || 0})`;
        const alertsToDisplay =
          openProjectIdTab !== 'allSites'
            ? alertStore.projectAlerts[openProjectIdTab]
            : alertStore.projectAlerts.allSites;

        newOpenTabs.push(openProjectIdTab);
        newAlertsTabs.push({ projectId: openProjectIdTab, label: label, alertsToDisplay: alertsToDisplay });
      }
    });
    //add new tabs
    Object.keys(projectAlerts).forEach((projectId) => {
      if (!openTabs.current.find((openProjectIdTab: string) => openProjectIdTab === projectId)) {
        const projectName =
          Number(projectId) === defaultProjectId
            ? defaultProjectName
            : projectStore.projectIdToProjectMap.get(Number(projectId))?.name || '';
        const label =
          projectId !== 'allSites'
            ? `${projectName} (${alertStore.unreadAlertCount[projectId] || 0})`
            : `ALL SITES (${alertStore.unreadAlertCount.allSites || 0})`;
        const alertsToDisplay = projectId ? projectAlerts[projectId] : projectAlerts.allSites;

        newOpenTabs.push(projectId);
        newAlertsTabs.push({ projectId, label: label, alertsToDisplay: alertsToDisplay });
      }
    });

    openTabs.current = newOpenTabs;
    return newAlertsTabs;
  }, [alertStore, defaultProjectId, projectAlerts, defaultProjectName, projectsMap]);

  return alertsTabs();
};
