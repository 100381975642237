import { useAppStore } from 'MobxStores/context';
import { useEffect, useState } from 'react';

export const useNetworkStatus = () => {
  const { popupStore } = useAppStore();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const goOnline = () => {
      setIsOnline(true);
      popupStore.hideAlertMessage();
    };

    const goOffline = () => {
      setIsOnline(false);
      popupStore.displayAlertMessage({
        message: 'Connection lost, please check your internet connection',
        duration: 1000 * 60 * 60,
        severity: 'error',
      });
    };

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, [popupStore]);

  return { isOnline };
};
