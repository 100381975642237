import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { ListItemText, Box, useTheme } from '@mui/material';

export const TextListItem = ({
  title,
  paragraph,
  extra,
}: {
  title: string;
  paragraph: string;
  extra: string;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <ListItemText>{title}</ListItemText>
      <ListItemText sx={{ '& span': { fontSize: '14px', opacity: 0.6 } }}>{paragraph}</ListItemText>
      <ListItemText
        sx={{
          '& span': {
            fontSize: '12px',
            opacity: 1,
            lineHeight: '22px',
            color: theme.palette.accent.primary.main,
          },
        }}
      >
        {extra}
      </ListItemText>
    </Box>
  );
};
TextListItem.displayName = 'TextListItem';
