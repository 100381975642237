import { AlertModel } from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { SxProps } from '@mui/material';
import { Theme, alpha } from '@mui/material/styles';
import { getPalette } from '@electreon_ui/shared/Themes/colorPalette';
import format from 'date-fns/format';
import { RuleSeverityEnum as AlertSeverityStatus } from '@electreon/electreon-metadata-service-gen-ts-client';
import { utcToZonedTime } from 'date-fns-tz';
import { AlertMessage } from 'MobxStores/utils/alertStoreTypes';
import ProjectStore from '@electreon_ui/shared/stores/projectStore/ProjectStore';

const parseAlertDescription = (ruleName: string | undefined) => {
  switch (ruleName) {
    case 'project_rule_100':
      return 'Device disconnected';
    default:
      return ruleName || 'Unknown alert';
  }
};

export const alertModelToAlertMessage = (alert: AlertModel, projectStore: ProjectStore) => {
  const {
    id,
    timestamp,
    deviceId,
    projectId,
    description,
    isActive,
    deviceName,
    rule: { severity, description: ruleDescription },
  } = (alert as any) || {};

  const alertMessage: AlertMessage = {
    id: id || '',
    severity: severity ?? AlertSeverityStatus.High,
    alertTime: Number(timestamp) || 0,
    projectName: projectStore.projectIdToProjectMap.get(projectId)?.name || '',
    projectId: projectId || 0,
    origin: deviceId || 'Unknown',
    alertDescription: parseAlertDescription(description || ruleDescription),
    isActive: isActive,
    deviceName: deviceName,
  };
  return alertMessage;
};

export const formatDateString = (time: number, tz: string) => {
  const date = new Date(time);

  if (isNaN(date.getTime())) {
    return 'Provided date is invalid.';
  } else {
    return format(utcToZonedTime(date, tz), 'dd/MM/yyyy HH:mm:ss');
  }
};

export const alertDataLargeStyle: SxProps<Theme> = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 700,
  fontFamily: 'Urbanist, Arial, sans-serif',
  letterSpacing: '0.15px',
  textAlign: 'left',
  color: (theme: Theme) => alpha(theme.palette.text.secondary, 0.7),
};

export const openInNewTab = (url: string, beforeOpen?: any) => {
  typeof beforeOpen === 'function' && beforeOpen();
  window.open(url, '_blank', 'noreferrer');
};
