import { api } from 'Services/api';

export const getMaxContactors = async (muId: string) => {
  let maxContactors: number | undefined;

  try {
    const muConfig = await api.deviceControl.muConfig.getState(muId);
    const numOfContactors =
      muConfig.data?.delta?.profile?.PROFILE_SECTION_GPIO?.Num_Of_Contactors ||
      muConfig.data?.reportedConfiguration?.profile?.PROFILE_SECTION_GPIO?.Num_Of_Contactors;

    // 1 contactor -> 12 max parking spots
    // X contactors -> x max parking spots
    maxContactors = numOfContactors === 1 ? 12 : numOfContactors;
  } catch (err) {
    console.error(`Error getting mu configuration: ${JSON.stringify(err)}}`);
    maxContactors = undefined;
  }

  return maxContactors;
};
