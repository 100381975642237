import { Autocomplete, Typography, MenuItem, TextField } from '@mui/material';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { VehicleUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { useEffect, useState } from 'react';
import { getUnassignedVus, getAssignedVu, noVuAssigned } from '../Utils/EditFormsUtils';

interface AssignedVuSelectionInputProps {
  selectedPosVuId: string;
}

export const AssignedVuSelectionInput: React.FC<AssignedVuSelectionInputProps> = ({ selectedPosVuId }) => {
  const { projectStore } = useAppStore();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectableVus, setSelectableVus] = useState<VehicleUnitModel[] | []>([noVuAssigned]);
  const [assignedVu, setAssignedVu] = useState<VehicleUnitModel>(noVuAssigned);
  useEffect(() => {
    getAssignedVu(selectedPosVuId)
      .then((assignedVuRes) => {
        setAssignedVu(assignedVuRes);
        setValue('vuId', assignedVuRes.id);
        projectStore?.vuList
          ? getUnassignedVus(projectStore.vuList).then((res) =>
              setSelectableVus([...res, assignedVuRes, noVuAssigned])
            )
          : setSelectableVus([assignedVuRes, noVuAssigned]);
        console.log(assignedVuRes);
      })
      .catch((error) => {
        console.error(`error getting the assigned VU: ${error}`);
      });
  }, [selectedPosVuId, projectStore?.vuList, setValue]);

  return (
    <Autocomplete
      freeSolo
      sx={{ mb: 2 }}
      options={selectableVus}
      getOptionLabel={(option) => (option as { id: string }).id}
      isOptionEqualToValue={(option: VehicleUnitModel | null, value: VehicleUnitModel | null) =>
        option?.id === value?.id
      }
      value={assignedVu}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          <Typography>{option.id}</Typography>
          <Typography sx={{ color: theme.palette.accent.primary.main, ml: 1 }} fontSize={12}>
            ({option.name})
          </Typography>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Change Assigned Vehicle'
          fullWidth
          error={!!errors['vuId']}
          helperText={errors['vuId'] ? errors['vuId'].message?.toString() : ''}
          value={assignedVu.id}
          {...register('vuId')}
        />
      )}
    />
  );
};
