import { createTheme, Theme } from '@mui/material';
import { CSSProperties } from 'react';
import { ThemeMode } from '../types/globals';

type ColorTone = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;
type ColorToneMap = Record<ColorTone, string> | { disabled?: string };

declare module '@mui/material/styles' {
  interface Palette {
    accent: {
      primary: Palette['primary'];
      secondary: Palette['primary'];
      gray: Palette['primary'];
      green: Palette['primary'];
      red: Palette['primary'];
      orange: Palette['primary'];
      yellow: Palette['primary'];
      violet: Palette['primary'];
    };
    maintenanceBlue: Palette['primary'];
    maintenanceMain: CSSProperties['color'];
  }
  interface PaletteOptions {
    accent: {
      primary: PaletteOptions['primary'] | ColorToneMap;
      secondary: PaletteOptions['primary'] | ColorToneMap;
      gray: PaletteOptions['primary'] | ColorToneMap;
      green: PaletteOptions['primary'] | ColorToneMap;
      red: PaletteOptions['primary'] | ColorToneMap;
      orange: PaletteOptions['primary'] | ColorToneMap;
      yellow: PaletteOptions['primary'] | ColorToneMap;
      violet: PaletteOptions['primary'] | ColorToneMap;
    };
    maintenanceBlue: PaletteOptions['primary'] | ColorToneMap;
    maintenanceMain: CSSProperties['color'];
  }
  interface TypeBackground {
    dashboard: CSSProperties['color'];
  }

  interface PaletteColor extends Record<ColorTone, string> {
    disabled?: string;
  }
}

const lightPalette = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
      light: '#EEEEEE',
    },
    background: {
      default: 'rgba(249, 250, 251, 1)',
    },
    maintenanceBlue: {
      main: '#ccddff',
    },
    maintenanceMain: '#001133',
    accent: {
      primary: {
        main: '#00D1FD',
        50: '#C2F5FF',
        100: '#AEF1FF',
        200: '#93EDFF',
        300: '#6EE7FF',
        400: '#3EDFFF',
        500: '#00D1FD',
        600: '#00B3D7',
        700: '#0098B7',
        800: '#00819B',
        900: '#006E84',
      },
      secondary: {
        main: '#3EE452',
        50: '#D1F9D6',
        100: '#C2F6C8',
        200: '#AEF4B6',
        300: '#93F09D',
        400: '#6EEB7D',
        500: '#3EE452',
        600: '#1ED834',
        700: '#1AB82C',
        800: '#169C26',
        900: '#138520',
      },
      gray: {
        main: '#62696F',
        disabled: '#BBBCC1',
        light: '#B4BABF',
        dark: '#53585C',
        50: '#EFF0F1',
        100: '#CCD0D2',
        200: '#BFBFBF',
        300: '#A5ABB0',
        400: '#878F95',
        500: '#62696F',
        600: '#53595E',
        700: '#474C50',
        800: '#3C4044',
        900: '#33373A',
      },
      green: {
        main: '#128521',
      },
      red: {
        main: '#F71828',
        dark: '#c22b2b',
      },
      orange: {
        main: '#FF8C00',
        light: '#F5B142',
      },
      yellow: {
        main: '#ffd600',
        50: '#F9CD5F',
      },
      violet: {
        main: '#5F75F9',
      },
      // TODO: add additional, and system colors from figma
    },
  },
});

const darkPalette = createTheme({
  palette: {
    ...lightPalette.palette,
    mode: 'dark',
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
  },
});

export const getPalette = (type?: ThemeMode): Theme => {
  switch (type) {
    case 'light':
      return lightPalette;
    case 'dark':
      return darkPalette;
    default:
      return lightPalette;
  }
};
