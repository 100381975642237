import { alpha } from '@mui/material';

export const prefixStyles = {
  flex: 2,
  '& .MuiOutlinedInput-root *': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    borderBottom: '1px dashed #ccc',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
};

export const multiSelectChipStyles = { bgcolor: alpha('#00D1FD', 0.3) };

export const multiSelectChipContainerStyles = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5,
  overflowY: 'auto',
  maxHeight: '105px',
};

export const inputLabelStyles = { '&.Mui-focused': { color: '#00D1FD' } };
