import { Segment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { IconButton, Stack, Typography, Chip, Grid, useTheme, Popover, Button } from '@mui/material';
import { ManageRoadInput } from 'Components/Forms/RoadForm/utils/roadSchemas';
import EditIcon from '@mui/icons-material/Edit';
import React, { useCallback, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TOTAL_SEGMENTS_IN_DMU, getDrawerByIndex } from 'Components/Forms/RoadForm/utils/roadFormUtils';
import { EditSegmentFields } from 'Components/Forms/RoadForm/EditSegmentFields';
import { useAppStore } from 'MobxStores/context';
import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';

export const SegmentInputs: React.FC<{
  roadSectionIndex: number;
  selectedMu: ManagementUnitModel;
}> = ({ roadSectionIndex, selectedMu }) => {
  const { control, setValue } = useFormContext<ManageRoadInput>();

  const form = useWatch({ control });

  const { popupStore } = useAppStore();

  const isSegmentSelected = useCallback(
    (segmentNum: number, mu: ManagementUnitModel) => {
      if (!mu.id) return false;
      const selectedSegment = form.roadSections?.[roadSectionIndex].muIdToSegments?.[mu.id]?.find(
        (segment) => segment.segmentNum === segmentNum
      );
      return !!selectedSegment;
    },
    [form, roadSectionIndex]
  );

  const isSegmentSelectedInOtherSection = (segmentNum: number, mu: ManagementUnitModel) => {
    if (!mu.id) return false;
    const roadSections = form.roadSections || [];
    const isSelected = roadSections.some((roadSection, index) => {
      if (index === roadSectionIndex) return false;
      const selectedSegment = roadSection.muIdToSegments?.[mu.id!]?.find(
        (segment) => segment.segmentNum === segmentNum
      );
      return !!selectedSegment;
    });
    return isSelected;
  };

  const setAllSegmentsSelected = (selectedMu: ManagementUnitModel) => {
    // Selects all segments for the selected management unit unless they are already selected in another section
    if (!selectedMu.id) return;
    const currentSegments = form.roadSections?.[roadSectionIndex].muIdToSegments?.[selectedMu.id] || [];
    const segmentsToSelect: Segment[] = [];
    let addedSegmentsCount = 0;
    for (let segmentNum = 0; segmentNum < TOTAL_SEGMENTS_IN_DMU; segmentNum++) {
      const isSelectedInOtherSection = isSegmentSelectedInOtherSection(segmentNum, selectedMu);
      const isSelected = isSegmentSelected(segmentNum, selectedMu);
      if (!isSelected && !isSelectedInOtherSection) {
        addedSegmentsCount++;
        segmentsToSelect.push({
          projectId: form.projectId,
          muId: selectedMu.id,
          drawerNum: getDrawerByIndex(segmentNum),
          segmentNum,
          longitude: 0,
          latitude: 0,
        });
      }
    }
    setValue(`roadSections.${roadSectionIndex}.muIdToSegments.${selectedMu.id}`, [
      ...currentSegments,
      ...segmentsToSelect,
    ]);

    if (addedSegmentsCount === 0) {
      popupStore.displayAlertMessage({
        message: 'All segments are already assigned for this management unit',
        duration: 5000,
        severity: 'warning',
      });
    }
  };

  return (
    <Stack sx={{ mt: 2 }}>
      <Stack direction='row' justifyContent={'space-between'}>
        <Typography variant='subtitle1' component='h3'>
          Select Segments
        </Typography>
        <Button
          variant='outlined'
          size='small'
          onClick={() => setAllSegmentsSelected(selectedMu)}
          sx={{ height: '25px' }}
        >
          Select All Unassigned Segments
        </Button>
      </Stack>
      <Grid container spacing={1} sx={{ mt: 1, ml: 1 }}>
        {Array.from(Array(TOTAL_SEGMENTS_IN_DMU).keys()).map((segmentNum) => (
          <SegmentChip
            key={segmentNum}
            segmentNum={segmentNum}
            mu={selectedMu}
            isSegmentSelected={isSegmentSelected}
            roadSectionIndex={roadSectionIndex}
          />
        ))}
      </Grid>
    </Stack>
  );
};

const SegmentChip: React.FC<{
  segmentNum: number;
  mu: ManagementUnitModel;
  isSegmentSelected: (segmentNum: number, mu: ManagementUnitModel) => boolean;
  roadSectionIndex: number;
}> = ({ segmentNum, isSegmentSelected, mu, roadSectionIndex }) => {
  const { control, setValue } = useFormContext<ManageRoadInput>();
  const form = useWatch({ control });
  const [selectedSegmentForEdit, setSelectedSegmentForEdit] = useState<Segment | null>(null);
  const chipRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Grid item xs={2}>
        <Stack direction='row' ref={chipRef}>
          <Chip
            variant='outlined'
            clickable
            size='small'
            label={segmentNum + 1}
            sx={{
              minWidth: '40px',
              '&.MuiChip-root': {
                backgroundColor: isSegmentSelected(segmentNum, mu)
                  ? theme.palette.accent.primary.main
                  : theme.palette.background.default,
              },
              '&.MuiChip-clickable:hover': {
                backgroundColor: theme.palette.accent.primary[200],
              },
              '& .MuiChip-label': {
                color: isSegmentSelected(segmentNum, mu)
                  ? theme.palette.background.default
                  : theme.palette.text.primary,
              },
            }}
            onClick={() => {
              // toggle segment selection in form
              const segment = {
                projectId: form.projectId,
                muId: mu.id,
                drawerNum: getDrawerByIndex(segmentNum),
                segmentNum,
                longitude: 0,
                latitude: 0,
              };
              const selectedSegments = form.roadSections?.[roadSectionIndex]?.muIdToSegments?.[mu.id!] || [];
              const segmentIndex = selectedSegments.findIndex((s) => s.segmentNum === segmentNum);
              if (segmentIndex === -1) {
                // segment not selected, add it
                setValue(`roadSections.${roadSectionIndex}.muIdToSegments.${mu.id}`, [
                  ...selectedSegments,
                  segment,
                ]);

                // if segment of this mu is selected in another road section, remove it
                const otherRoadSectionIndex = form.roadSections?.findIndex((rs, i) => {
                  if (i === roadSectionIndex || !mu?.id) return false;
                  return !!rs.muIdToSegments?.[mu.id]?.find((s) => s.segmentNum === segmentNum);
                });
                if (otherRoadSectionIndex !== -1 && otherRoadSectionIndex !== undefined) {
                  const otherSelectedSegments =
                    form.roadSections?.[otherRoadSectionIndex]?.muIdToSegments?.[mu.id!] || [];
                  const otherSegmentIndex = otherSelectedSegments.findIndex(
                    (s) => s.segmentNum === segmentNum
                  );
                  if (otherSegmentIndex !== -1) {
                    otherSelectedSegments.splice(otherSegmentIndex, 1);
                    setValue(`roadSections.${otherRoadSectionIndex}.muIdToSegments.${mu.id}`, [
                      ...otherSelectedSegments,
                    ]);
                  }
                }
              } else {
                // segment already selected, remove it
                selectedSegments.splice(segmentIndex, 1);
                setValue(`roadSections.${roadSectionIndex}.muIdToSegments.${mu.id}`, [...selectedSegments]);
              }
            }}
          />
          {/* Edit button to allow long/lat editing */}
          {isSegmentSelected(segmentNum, mu) && (
            <IconButton
              size='small'
              onClick={() => {
                const selectedSegments =
                  form.roadSections?.[roadSectionIndex]?.muIdToSegments?.[mu.id!] || [];
                const segmentIndex = selectedSegments.findIndex((s) => s.segmentNum === segmentNum);
                if (segmentIndex === -1) return;
                const segmentForEdit = selectedSegments[segmentIndex];
                setSelectedSegmentForEdit(segmentForEdit);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Grid>
      {/* Popover that displays the current segment long/lat and allows editing */}
      <Popover
        open={!!selectedSegmentForEdit}
        anchorEl={chipRef.current}
        onClose={() => setSelectedSegmentForEdit(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <EditSegmentFields
          segment={selectedSegmentForEdit}
          roadSectionIndex={roadSectionIndex}
          selectedMu={mu}
          control={control}
          setValue={setValue}
          onClose={() => setSelectedSegmentForEdit(null)}
        />
      </Popover>
    </>
  );
};
