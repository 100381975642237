import { RootStore } from 'MobxStores/RootStore';
import { alertModelToAlertMessage } from 'Screens/AlertDashboard/alertUtils';
import { api } from 'Services/api';
import { env } from 'config';
import { computed, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Socket } from 'socket.io-client';
import { RuleSeverityEnum as AlertSeverityStatus } from '@electreon/electreon-metadata-service-gen-ts-client';
import { getProjectIdFromURL, initAlertsSocket } from './utils/alertStoreUtils';
import { AlertsSocketMessage } from '@electreon_ui/shared/types/socketMessageTypes';
import { AlertMessage, AlertReadStatus } from './utils/alertStoreTypes';
import { EnvType } from '@electreon_ui/shared/types/globals';

export class AlertStore {
  rootStore: RootStore;
  liveAlertsSocket: Socket | null;
  projectAlerts: Record<string, AlertMessage[]> = {};
  readAlerts: Record<string, AlertReadStatus> | null = null;
  pageNumberPerProject: Record<string, number> = {};
  defaultPageSize = 100;
  isLoading: boolean = false;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
    makePersistable(this, {
      name: 'alertStore',
      properties: ['readAlerts'],
      storage: window.localStorage,
    });

    reaction(
      () => this.rootStore.authStore.IdToken,
      (idToken) => {
        if (!idToken) return;
        if (this.liveAlertsSocket) this.liveAlertsSocket.emit('closeSocket');
        initAlertsSocket(this, idToken);
      }
    );
  }

  setAlertsStatus(
    status: AlertReadStatus,
    alertIds?: string | number | AlertMessage | Array<string | number | AlertMessage>
  ) {
    if (!alertIds) {
      alertIds = this.getAllAlerts();
    }
    let alertsToSet: string[] = [];
    if (typeof alertIds === 'string' || typeof alertIds === 'number') alertsToSet = [String(alertIds)];
    else if (Array.isArray(alertIds))
      alertsToSet = alertIds.map((alert) =>
        typeof alert === 'string' || typeof alert === 'number' ? String(alert) : alert.id
      );
    else alertsToSet = [alertIds.id];

    if (!this.readAlerts) this.readAlerts = {};
    alertsToSet.forEach((alertId) => (this.readAlerts![alertId] = status));
  }

  isAlertRead(alertId: string) {
    return this.readAlerts?.[alertId] === AlertReadStatus.READ;
  }

  isCriticalAlert(alertSeverity: AlertSeverityStatus) {
    return alertSeverity === AlertSeverityStatus.High;
  }

  async getLatestAlerts(projectId?: number, timeRangeMinutes: number = 60 * 24) {
    // todo: need to add run action and change the name
    const projectToEdit = projectId ? +projectId : 'allSites';
    const pageNumber = 0;

    const alertsResponse = projectId
      ? await api.deviceTelemetry.AlertsApi.getLastProjectsAlerts(
          [projectId],
          timeRangeMinutes,
          this.defaultPageSize,
          pageNumber
        )
      : await api.deviceTelemetry.AlertsApi.getLastAlerts(timeRangeMinutes, this.defaultPageSize, pageNumber);

    if (alertsResponse.status !== 200) {
      return alertsResponse;
    }

    // prevent duplicate alerts from being saved and remove already saved alerts
    const seenAlerts = new Set<string>();
    const alerts = alertsResponse.data?.pageData?.reduce((acc: AlertMessage[], alert) => {
      if (seenAlerts.has(alert.id || '')) return acc;
      if (this.projectAlerts[projectToEdit]?.find((a) => a.id === alert.id)) return acc;
      seenAlerts.add(alert.id || '');
      acc.push(alertModelToAlertMessage(alert, this.rootStore.projectStore));
      return acc;
    }, []);

    // Exclude device AG0000151 (simulator) in production
    const filteredAlerts = alerts?.filter((alert) => {
      if (env !== EnvType.Prod) return true;
      return alert.origin !== 'AG0000151';
    });

    runInAction(() => {
      this.projectAlerts[projectToEdit] = [
        ...(filteredAlerts || []),
        ...(this.projectAlerts[projectToEdit] || []),
      ];
    });
  }

  handleAlertSocketMessage(message: AlertsSocketMessage) {
    const projectTabOpen = !!this.projectAlerts[message.projectId];
    const allSitesTabOpen = !!this.projectAlerts['allSites'];
    const isPopOutPage = location.pathname.includes('/dashboard/alerts');
    const selectedProjectId = this?.rootStore?.projectStore?.selectedProject?.id || getProjectIdFromURL();
    if (!isPopOutPage) {
      if (selectedProjectId && message.projectId !== selectedProjectId) return; //added for performance, if popup, get only if relevant.
      this.getLatestAlerts(selectedProjectId);
    } else {
      projectTabOpen && this.getLatestAlerts(message.projectId);
      allSitesTabOpen && this.getLatestAlerts();
    }
  }

  async getMoreAlerts(projectId?: number, timeRangeMinutes: number = 60 * 24) {
    // it is better to seperate calls for socket and load more.
    // todo: need to add run action and change the name
    this.isLoading = true;
    const projectToEdit = projectId ? +projectId : 'allSites';

    if (!this.pageNumberPerProject[projectToEdit]) this.pageNumberPerProject[projectToEdit] = 1;

    const alertsResponse = projectId
      ? await api.deviceTelemetry.AlertsApi.getLastProjectsAlerts(
          [projectId],
          timeRangeMinutes,
          this.defaultPageSize,
          this.pageNumberPerProject[projectToEdit]
        )
      : await api.deviceTelemetry.AlertsApi.getLastAlerts(
          timeRangeMinutes,
          this.defaultPageSize,
          this.pageNumberPerProject[projectToEdit]
        );

    if (alertsResponse.status !== 200) {
      return alertsResponse;
    }

    this.pageNumberPerProject[projectToEdit] = this.pageNumberPerProject[projectToEdit] + 1;

    // prevent duplicate alerts from being saved and remove already saved alerts
    const seenAlerts = new Set<string>();
    if (!this.projectAlerts[projectToEdit]) this.projectAlerts[projectToEdit] = [];
    const alerts = alertsResponse.data?.pageData?.reduce((acc: AlertMessage[], alert) => {
      if (seenAlerts.has(alert.id || '')) return acc;
      if (this.projectAlerts[projectToEdit].find((a) => a.id === alert.id)) return acc;
      seenAlerts.add(alert.id || '');
      acc.push(alertModelToAlertMessage(alert, this.rootStore.projectStore));
      return acc;
    }, []);

    // Exclude device AG0000151 (simulator) in production
    const filteredAlerts = alerts?.filter((alert) => {
      if (env !== EnvType.Prod) return true;
      return alert.origin !== 'AG0000151';
    });

    runInAction(() => {
      this.projectAlerts[projectToEdit] = [
        ...(this.projectAlerts[projectToEdit] || []),
        ...(filteredAlerts || []),
      ];
      this.isLoading = false;
    });
  }

  @computed
  get unreadAlertCount() {
    let unreadAlertCount: Record<string, number> = {};
    for (let projectId in this.projectAlerts) {
      unreadAlertCount[projectId] = this.projectAlerts[projectId].filter(
        (alert) => !this.isAlertRead(alert.id)
      ).length;
    }
    return unreadAlertCount;
  }

  @computed
  get unreadCriticalAlertCount() {
    let unreadCriticalAlertCount: Record<string, number> = {};
    for (let projectId in this.projectAlerts) {
      unreadCriticalAlertCount[projectId] = this.projectAlerts[projectId].filter(
        (alert) => !this.isAlertRead(alert.id) && this.isCriticalAlert(alert.severity)
      ).length;
    }
    return unreadCriticalAlertCount;
  }

  getAllAlerts() {
    const allAlers = Object.values(this.projectAlerts)?.reduce((currentProjectAlerts, prevProjectAlerts) => {
      // remove duplicate alerts.
      const newUniqueAlerts = currentProjectAlerts.filter(
        (alert) => !prevProjectAlerts.find((prevAlert) => prevAlert.id === alert.id)
      );
      return [...toJS(prevProjectAlerts), ...toJS(newUniqueAlerts)];
    }, []);
    return allAlers;
  }

  clearProjectsAlerts(excludedProjectIds?: number | string | string[]) {
    const excludedIds =
      typeof excludedProjectIds === 'string' || typeof excludedProjectIds === 'number'
        ? [+excludedProjectIds]
        : excludedProjectIds || [];
    Object.keys(this.projectAlerts).forEach((projectId) => {
      if (!excludedIds.find((id) => id === projectId)) {
        delete this.projectAlerts[projectId];
      }
    });
    Object.keys(this.pageNumberPerProject).forEach((projectId) => {
      if (!excludedIds.find((id) => id === projectId)) {
        delete this.projectAlerts[projectId];
      }
    });
  }

  clearProjectAlerts(projectId: number | string) {
    delete this.projectAlerts[projectId];
    delete this.pageNumberPerProject[projectId];
  }

  //for testing
  logUnreadAlertCount() {
    console.log(this.unreadAlertCount, `unreadAlertCount`);
  }
  // for testing.
  logProjectAlerts() {
    console.log(toJS(this.projectAlerts), `projects alerts `);
  }
  //for testing
  logUnreadCriticalAlertCount() {
    console.log(this.unreadCriticalAlertCount, `unreadCriticalAlertCount`);
  }
}
