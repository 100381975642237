import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid, Divider, Typography, ThemeProvider } from '@mui/material';

import { Logo } from 'Components/Logo/Logo';
import { ErrorMessage } from 'Screens/Login/LoginConstants';
import {
  deviderStyle,
  googleLoginButton,
  leftGridContainer,
  loginButton,
  loginErrorStyles,
  loginFormStyles,
  loginTheme,
} from 'Screens/Login/styles/LoginStyles';
import { RandomLoginImage } from 'Screens/LoginRandomImages/LoginRandomImages';

import { useAppStore } from 'MobxStores/context';

import { handleSignOut } from 'Utils/APIUtils';
import { setUserInfo } from 'aws/authUtils';
import axios, { AxiosError } from 'axios';

import ElectreonSmallLogo from 'Utils/Images/GlobalIcons/electreon-dark-logo.svg';
import ElectreonLogo from 'Utils/Images/GlobalIcons/electreon-logo-small.svg';
import BlueForwardArrow from 'Utils/Images/GlobalIcons/forward_blue_arrow.svg';
import GreenForwardArrow from 'Utils/Images/GlobalIcons/forward_green_arrow.svg';

import { useRedirectAuth } from 'Screens/Login/hooks/useRedirectAuth';
import { CognitoChallengeResponse } from '@electreon_ui/shared/types/authTypes';
import { toast } from 'sonner';

function Login() {
  const { userStore, projectStore, authStore } = useAppStore();
  const navigate = useNavigate();
  const [loginStatusMessage, setLoginStatusMessage] = useState<string>(ErrorMessage.noError);
  const location = useLocation();
  const authCode = new URLSearchParams(location.search).get('code');
  const routeError = new URLSearchParams(location.search).get('error');

  useEffect(() => {
    if (routeError) toast.error(routeError);
  }, [routeError]);

  const handleError = useCallback(() => {
    handleSignOut(navigate, authStore, userStore, projectStore);
    authStore.logout();
  }, [authStore, navigate, projectStore, userStore]);

  useRedirectAuth(handleError, authCode);

  const onPasswordChageRequied = (cognitoChallengeResponse: CognitoChallengeResponse) => {
    authStore.setCognitoChallenge(cognitoChallengeResponse);
    navigate('/force_change_password');
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username')?.toString();
    const password = data.get('password')?.toString();
    if (!username || !password) {
      setLoginStatusMessage(ErrorMessage.wrongCredentials);
      return;
    }
    setLoginStatusMessage(ErrorMessage.noError);
    return authStore
      .login(username, password, onPasswordChageRequied)
      .then((session: any | null) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.IdToken;
        setUserInfo(session, userStore);
        navigate(userStore.getPathToUserMainPage());
      })
      .catch((err: AxiosError) => {
        setLoginStatusMessage(ErrorMessage.noError);
        if (err?.response?.status === 400) {
          setLoginStatusMessage(ErrorMessage.wrongCredentials);
          console.warn('Wrong credentials', JSON.stringify(err));
        } else {
          setLoginStatusMessage(ErrorMessage.loginAttemptFailed);
          console.error('Login attempt failed', JSON.stringify(err));
        }
        // handleError();
      });
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <Grid item xs={12} height='100vh' container spacing={0}>
        <Grid item container xs={12} sm={6} md={6} sx={leftGridContainer}>
          <Box component='div'>
            <Logo
              logo={ElectreonSmallLogo}
              width='100%'
              height='100%'
              alt='Electreon logo'
              onClickLogo={() => ''}
            />
          </Box>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={loginFormStyles}>
            <Typography component='h1' variant='h3' marginBottom={2}>
              LOG IN
            </Typography>
            <TextField
              margin='normal'
              required
              fullWidth
              id='username'
              label='User Name'
              name='username'
              autoComplete='username'
              autoFocus
              sx={{ '& .MuiInputBase-input': { height: '45px' } }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              sx={{ '& .MuiInputBase-input': { height: '45px' } }}
            />
            <Typography sx={loginErrorStyles}>{loginStatusMessage}</Typography>
            <Button
              type='submit'
              fullWidth
              endIcon={<Logo logo={GreenForwardArrow} width='75%' height='75%' alt='Forward Arrow' />}
              sx={loginButton}
            >
              LOG IN
            </Button>

            <Divider sx={deviderStyle} />

            <Button
              type='button'
              fullWidth
              startIcon={<Logo logo={ElectreonLogo} width='75%' height='75%' alt='Electreon logo' />}
              endIcon={<Logo logo={BlueForwardArrow} width='75%' height='75%' alt='Forward Arrow' />}
              sx={googleLoginButton}
              onClick={() => {
                window.location.href = authStore.federatedSignInUrl;
              }}
            >
              ELECTREON EMPLOYEE
            </Button>
            {/* <Typography component="h1" variant="h5">
              //TODO : activate after setting backend 
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Typography> */}
          </Box>
        </Grid>
        <RandomLoginImage />
      </Grid>
    </ThemeProvider>
  );
}

const arePropsEqual = (prevProps: any, nextProps: any) => {
  return prevProps.authCode === nextProps.authCode;
};

const MemoizedLogin = React.memo(Login, arePropsEqual);
export default MemoizedLogin;
