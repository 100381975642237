import { useQuery } from '@tanstack/react-query';
import { getProjectLogoImgPath } from 'Utils/APIUtils';

const fetchProjectLogoByType = async (id: string, size: 'main' | 'small') => {
  return await getProjectLogoImgPath(id, size);
};

export const useProjectLogo = (id: string, size: 'main' | 'small') => {
  return useQuery({
    queryKey: ['projectLogo', id, size],
    queryFn: () => fetchProjectLogoByType(id, size),
    enabled: !!id && !!size,
    refetchOnMount: false,
  });
};
