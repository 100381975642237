import { ROLE_TYPES, ROLE_HIERARCHY } from 'Utils/constants';
import { ValuesOf } from '@electreon_ui/shared/types/globals';
import { format } from 'date-fns';

export const isFalseyButNotZero = (value: any): value is false | null | undefined => {
  return value === false || value === null || value === undefined;
};

export const snakeToTitleCase = (str: string, options?: { onlyFirstWordCapital?: boolean }): string => {
  if (!str) return '';

  return str
    .split('_')
    .map((word, index) => {
      if (index === 0 || !options?.onlyFirstWordCapital) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word.toLowerCase();
      }
    })
    .join(' ');
};

export const isRoleHigher = (
  role1?: ValuesOf<typeof ROLE_TYPES>,
  role2?: ValuesOf<typeof ROLE_TYPES>
): boolean => {
  if (!role1 || !role2) return false;
  return ROLE_HIERARCHY[role1] > ROLE_HIERARCHY[role2];
};

export const isRoleLower = (
  role1?: ValuesOf<typeof ROLE_TYPES>,
  role2?: ValuesOf<typeof ROLE_TYPES>
): boolean => {
  if (!role1 || !role2) return false;
  return ROLE_HIERARCHY[role1] < ROLE_HIERARCHY[role2];
};

export const getISOFormat = (time: number | Date) => {
  return format(time, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`);
};

export const getDateTimeFormatWithMilliseconds = (time: number | Date) => {
  return format(time, `yyyy-MM-dd'T'HH:mm:ss.SSS`);
};

export const getDateTTimeFormat = (time: number | Date) => {
  return format(time, `yyyy-MM-dd'T'HH:mm:ss`);
};
