import { Alert, Box, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { ProjectSelectionFormInput } from '../ProjectSelectionInputs/ProjectSelectionFormInput';
import { DeviceNameInput } from '../EditAPFC&POSForms/FormInputs/DeviceNameInput';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { getProjectMetadata } from '@electreon_ui/shared/stores/projectStore/projectStoreUtils';
import { FormLoading } from '../FormUtils/FormLoading';
import { unassignMuDevice, unassignOCPPDevice } from '../FormsAPIs/api';
import { OCPPSelectionInput } from '../EditAPFC&POSForms/FormInputs/OCPPSelectionInput';
import { ManagementUnitModelRefined, OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { UnassignDeviceInput, UnassignDeviceSchema } from './Utils/FormsSchemas';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { api } from 'Services/api';
import { getProjectDeployment } from 'Utils/APIUtils';
import { observer } from 'mobx-react-lite';
import { isMu, isOcpp } from '@electreon_ui/shared/utils/globalUtils';
import { MuSelectionInput } from '../EditAPFC&POSForms/FormInputs/MuSelectionInput';
import { useLockState } from 'CustomHooks/useLockState';
import { WindowEventService, WindowEvents } from '@electreon_ui/shared/services/WindowEventService';
import { toast } from 'sonner';

interface UnassignDeviceFormProps extends FormProps {
  selectedProject?: Project | null;
  selectedDevice?: OcppChargerModelRefined | ManagementUnitModelRefined;
}

export const UnassignDeviceForm: React.FC<UnassignDeviceFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject: _selectedProject, selectedDevice }) => {
    const { projectStore } = useAppStore();
    const [selectedProject, setSelectedProject] = useState<Project | null>(_selectedProject ?? null);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [depotName, setDepotName] = useState('');
    const methods = useForm<UnassignDeviceInput>({
      resolver: zodResolver(UnassignDeviceSchema),
      defaultValues: {
        deviceId: selectedDevice?.id || '',
      },
    });
    const selectedMuLockState = useLockState(selectedDevice);
    const onSubmitHandler: SubmitHandler<UnassignDeviceInput> = (values) => {
      setLoading(true);
      setSubmitError(null);
      const { deviceId } = values;
      const submitFunction = isOcpp(selectedDevice) ? unassignOCPPDevice : unassignMuDevice;
      submitFunction(deviceId)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Device unassigned');
            methods.reset();
            onSuccessfulSubmit?.();
            getProjectDeployment(selectedProject?.id || '').then((deployment) => {
              projectStore.setProjectDeployment(selectedProject?.id, deployment.data);
            });
            WindowEventService.emit(WindowEvents.SHOULD_UPDATE_DEPLOYMENT, {});
          } else if (res.status === 400) {
            toast.error('Device unassignment failed');
            setSubmitError('Device un-assignment failed');
            throw new Error('Unassigning Device failed');
          } else if (res.status === 409) {
            toast.error('Device unassignment conflict');
            setSubmitError('Device un-assignment conflict');
            throw new Error('Unassigning Device failed');
          } else {
            toast.error('Device unassignment failed');
            setSubmitError('Device un-assignment failed');
            throw new Error('Unassigning Device failed');
          }
        })
        .catch((err) => {
          toast.error('Device unassignment failed');
          console.error('Unassigning Device error', JSON.stringify(err));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // update devices lists
    useEffect(() => {
      if (!projectStore?.selectedProject?.id) return;
      setFormLoading(true);
      getProjectMetadata(projectStore.selectedProject.id, projectStore).then((res) => setFormLoading(false));
      selectedDevice?.depotId &&
        api.metadata.depots
          .getDepot(selectedDevice?.depotId)
          .then((res) => setDepotName(res.data.name || 'No Depot'));
    }, [projectStore, selectedDevice?.depotId]);

    return (
      <FormProvider {...methods}>
        {!selectedMuLockState.isLockedByMe && isMu(selectedDevice) && (
          <Alert severity='error' sx={{ mb: 2 }}>
            This MU is not locked by you. Your changes will not be saved.
          </Alert>
        )}
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Unassign Device from Depot
        </Typography>

        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
            console.error(e);
          })}
        >
          <ProjectSelectionFormInput
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            disabled
          />
          <DeviceNameInput
            currentDeviceName={selectedDevice?.name || ''}
            deviceType={isOcpp(selectedDevice) ? 'OCPP' : 'MU'}
            disabled={!!selectedDevice}
          />
          {isOcpp(selectedDevice) ? (
            <OCPPSelectionInput selectedOcpp={selectedDevice} disabled={!!selectedDevice} />
          ) : (
            <MuSelectionInput selectedMu={selectedDevice || null} disabled={!!selectedDevice} />
          )}
          <TextField
            disabled
            sx={{ ...disabledTextFieldStyle, mb: 2 }}
            label='Depot'
            fullWidth
            value={`${depotName} (ID: ${selectedDevice?.depotId || ''})`}
          />
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormLoading open={formLoading} />
          <FormSubmitionButtons
            loading={loading}
            onCancel={onCancel}
            submitLabel={
              !selectedMuLockState.isLockedByMe && isMu(selectedDevice) ? 'Please lock MU first' : 'Unassign'
            }
            confirmation
            confirmTitle='Are You Sure You Want To Un-assign Device?'
            confirmMessage='All Parking Spots will be permanently deleted!'
            onSubmitHandler={onSubmitHandler}
          />
        </Box>
      </FormProvider>
    );
  }
);
