import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { boolean, number, object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { createMUDevice } from 'Components/Forms/FormsAPIs/api';
import { getMUPrefix, getNextMUIdByPrefix } from 'Components/Forms/CreateDevicePopup/utils/CreateDeviceUtils';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { DeviceSubType } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { prefixStyles } from './utils/InputStyles';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { DepotSelectionInput } from '../EditAPFC&POSForms/FormInputs/DepotSelectionInput';
import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { getProjectDeployment } from 'Utils/APIUtils';
import { WindowEventService, WindowEvents } from '@electreon_ui/shared/services/WindowEventService';
import { useAppStore } from 'MobxStores/context';
import { toast } from 'sonner';

const createMUSchema = object({
  deviceName: string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: string()
    .min(1, 'Device ID is required')
    .refine((value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  projectName: string().optional(),
  prefix: string().min(1, 'Prefix is required'),
  chargingType: string().min(1, 'Charging type is required'),
  deploymentType: string().min(1, 'Deployment type is required'),
  deviceSubType: string().min(1, 'Device subtype is required'),
  longitude: string()
    .min(1, 'Longitude is required')
    .refine((value) => {
      const longitude = parseFloat(value);
      return longitude >= -180 && longitude <= 180;
    }, 'Longitude must be between -180 and 180')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Longitude must not include spaces'),
  latitude: string()
    .min(1, 'Latitude is required')
    .refine((value) => {
      const latitude = parseFloat(value);
      return latitude >= -90 && latitude <= 90;
    }, 'Latitude must be between -90 and 90')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Latitude must not include spaces'),
  ignoreAlerts: boolean().optional(),
  depotId: number().optional(),
});

const createMuDefaultValues = {
  deviceName: '',
  deviceId: '',
  projectName: '',
  prefix: '',
  chargingType: '',
  deploymentType: '',
  deviceSubType: '',
  longitude: '',
  latitude: '',
  ignoreAlerts: false,
  depotId: 0,
};

type PartialDeviceSubTypeOptions = Partial<Record<DeviceSubType, DeviceSubType>>;

export const MuDeviceSubTypeOptions: PartialDeviceSubTypeOptions = {
  LSMU: 'LSMU',
  DMU_AC: 'DMU_AC',
  DMU_DC: 'DMU_DC',
  DMU_APFC: 'DMU_APFC',
  SMU_AC: 'SMU_AC',
  SMU_DC: 'SMU_DC',
  DMU_APFC_HYBRID: 'DMU_APFC_HYBRID',
  SMU_DC_HYBRID: 'SMU_DC_HYBRID',
  DMU_D40: 'DMU_D40',
  SMU_S6: 'SMU_S6',
};

type CreateMuInput = TypeOf<typeof createMUSchema>;

export const CreateMuForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [muPrefix, setMUPrefix] = useState<'AG' | 'UG' | 'SM' | ''>('');
    const [depotList, setDepotList] = useState<Depot[]>([]);
    const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
    const { projectStore, popupStore } = useAppStore();
    const deviceSubTypeValues = Object.values(MuDeviceSubTypeOptions);

    const methods = useForm<CreateMuInput>({
      resolver: zodResolver(createMUSchema),
      defaultValues: createMuDefaultValues,
    });
    const { errors } = methods.formState;
    const register = methods.register;
    const { deviceNameAvaliable, deviceIDAvaliable } = useInputAvailability(
      methods.watch,
      selectedProject?.id,
      methods.setError,
      methods.clearErrors,
      'MU'
    );

    useEffect(() => {
      const subscription = methods.watch((value) => {
        const { chargingType, deploymentType } = value;
        if (chargingType && deploymentType) {
          const prefix = getMUPrefix(chargingType, deploymentType);
          setMUPrefix(prefix);
        }
      });

      return () => subscription.unsubscribe();
    }, [methods.watch, methods.setValue]);

    useEffect(() => {
      if (!selectedProject || !muPrefix) return;
      getNextMUIdByPrefix(muPrefix)
        .then((nextID) => {
          methods.reset({ ...methods.watch(), deviceId: String(nextID || '') });
        })
        .catch((error) => {
          console.error(`Failed to get next MU ID for prefix ${muPrefix}`, JSON.stringify(error));
        });
    }, [selectedProject, muPrefix, methods.reset, methods.watch]);

    useEffect(() => {
      if (muPrefix) {
        methods.reset({ ...methods.watch(), prefix: muPrefix });
      }
    }, [muPrefix, methods.reset, methods.watch]);

    // update depots list
    useEffect(() => {
      const getDepots = async () => {
        if (!selectedProject?.id) return;
        try {
          const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
          if (depotRes) {
            setDepotList(depotRes.data);
          }
        } catch (e) {
          setDepotList([]);
          console.error(JSON.stringify(e));
        }
      };
      getDepots();
    }, [selectedProject]);

    const onSubmitHandler: SubmitHandler<CreateMuInput> = (values) => {
      setSubmitError(null);
      const { deviceName, deviceId, prefix, latitude, longitude, ignoreAlerts, depotId } = values;
      const chargingType = values.chargingType as 'STATIC' | 'DYNAMIC';
      const deploymentType = values.deploymentType as 'UNDERGROUND' | 'SURFACE';
      const deviceSubType = values.deviceSubType as DeviceSubType;
      setLoading(true);
      createMUDevice({
        name: deviceName,
        projectId: selectedProject?.id as number,
        chargingType,
        deploymentType,
        deviceSubType,
        deviceType: 'MU',
        id: `${prefix}${deviceId}`,
        latitude: Number(latitude),
        longitude: Number(longitude),
        timezone: selectedProject?.timezoneStr as string,
        ignoreAlerts: ignoreAlerts,
      })
        .then((response) => {
          if (response.status !== 201) {
            toast.error('MU device creation failed');
            setSubmitError('MU device creation failed');
            throw new Error('Failed to create MU device');
          }
          toast.success('MU device created');
          if (response.status === 201) {
            methods.reset(createMuDefaultValues);
            onSuccessfulSubmit?.();
            getProjectDeployment(selectedProject?.id || '').then((deployment) => {
              projectStore.setProjectDeployment(selectedProject?.id, deployment.data);
            });
            WindowEventService.emit(WindowEvents.SHOULD_UPDATE_DEPLOYMENT, {});
            depotId && popupStore.openManageParkingSpotsPopup(response.data);
          }
        })
        .catch((error) => {
          toast.error('Failed to create MU device');
          console.error('Failed to create MU device', JSON.stringify(error));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <FormProvider {...methods}>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Create Management Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={methods.handleSubmit(onSubmitHandler)}>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField
              select
              label='Charging Type'
              sx={{ flex: 1 }}
              error={!!errors['chargingType']}
              helperText={errors['chargingType'] ? errors['chargingType'].message : ''}
              defaultValue=''
              {...register('chargingType')}
            >
              <MenuItem value='STATIC'>Static</MenuItem>
              <MenuItem value='DYNAMIC'>Dynamic</MenuItem>
            </TextField>
            <TextField
              select
              label='Deployment Type'
              sx={{ flex: 1 }}
              error={!!errors['deploymentType']}
              helperText={errors['deploymentType'] ? errors['deploymentType'].message : ''}
              defaultValue=''
              {...register('deploymentType')}
            >
              <MenuItem value='UNDERGROUND'>Under Ground</MenuItem>
              <MenuItem value='SURFACE'>Above Ground</MenuItem>
            </TextField>
          </Stack>
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField
              select
              label='Device SubType'
              sx={{ flex: 1 }}
              error={!!errors['deviceSubType']}
              helperText={errors['deviceSubType'] ? errors['deviceSubType'].message : ''}
              defaultValue=''
              {...register('deviceSubType')}
            >
              {deviceSubTypeValues.map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField
              label={'Prefix'}
              disabled
              select
              sx={prefixStyles}
              error={!!errors['prefix']}
              helperText={errors['prefix'] ? errors['prefix'].message : ''}
              value={muPrefix}
              {...register('prefix')}
            >
              <MenuItem key='MU' value={muPrefix}>
                {muPrefix}
              </MenuItem>
            </TextField>
            <TextField
              sx={{ flex: 8 }}
              InputLabelProps={{ shrink: !!methods.watch('deviceId') }}
              label='Device ID'
              fullWidth
              error={!!errors['deviceId']}
              helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InputAdornmentIcon isValid={deviceIDAvaliable} />
                  </InputAdornment>
                ),
              }}
              {...register('deviceId')}
            />
          </Stack>
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...register('deviceName')}
          />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...register('longitude')}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...register('latitude')}
            />
          </Stack>
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <FormControlLabel
              control={<Checkbox />}
              {...register('ignoreAlerts')}
              label='Ignore Alerts for This Device'
            />
          </Stack>
          {methods.getValues('chargingType').toUpperCase() === 'STATIC' && (
            <DepotSelectionInput
              selectedDepot={selectedDepot}
              setSelectedDepot={setSelectedDepot}
              depotList={depotList}
            />
          )}
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Create Device' />
        </Box>
      </FormProvider>
    );
  }
);
