import { useEffect, useRef } from 'react';
import { useAppStore } from 'MobxStores/context';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import LogRocket from 'logrocket';
import { redirect } from 'react-router-dom';

interface PromiseResolver {
  resolve: (value?: string) => void;
  reject: (reason?: any) => void;
}

export const useAxiosMiddleware = (): void => {
  const { userStore, authStore, popupStore } = useAppStore();

  const refreshAttempted = useRef<boolean>(false);
  const failedQueue = useRef<PromiseResolver[]>([]);
  const isRefreshing = useRef<boolean>(false);
  const errorCount = useRef<number>(0);

  const processQueue = (error: any, token: string | null): void => {
    failedQueue.current.forEach((prevRequestResolver) => {
      if (error) prevRequestResolver.reject(error);
      else prevRequestResolver.resolve(token || '');
    });

    failedQueue.current = [];
  };

  useEffect(() => {
    if (authStore.IdToken) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.IdToken;
    }
  }, [authStore.IdToken]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (errorCount.current > 0) console.info(`Received 200 response, resetting error count`);
        errorCount.current = 0;
        return response;
      },
      (error: AxiosError) => {
        if (error?.response?.status === 403) {
          console.error('Received 403 error', JSON.stringify(error.response?.data));
          LogRocket.captureException(new Error(`403 Forbidden: ${error.response?.data}`), { tags: { error: '403 Forbidden' } });
          // popupStore.displayAlertMessage({
          //   message: 'You do not have permission to access this resource.',
          //   duration: 10000,
          //   severity: 'warning',
          // })
        }

        if (error?.response?.status === 401) {
          errorCount.current++;
          console.info(`Received 401 error, error count: ${errorCount.current}`);

          if (!error.config) {
            return Promise.reject(error);
          }
          const originalRequest: AxiosRequestConfig = error.config;

          // If we've received 5 consecutive 401 responses, log out.
          if (errorCount.current >= 5) {
            console.info('Received 5 consecutive 401 errors, logging out');
            errorCount.current = 0;
            authStore.logout();
            userStore.resetStore();
            redirect('/');
            return Promise.reject(error);
          }

          if (!isRefreshing.current) {
            isRefreshing.current = true;
            console.info('Error 401, attempting to refresh session...');
            authStore
              .refreshSession()
              .then((tokens) => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.IdToken;
                console.info('Session refreshed successfully');
                isRefreshing.current = false;
                refreshAttempted.current = true;
                processQueue(null, authStore.IdToken);
              })
              .catch((err) => {
                // If the refresh fails, log out.
                console.info('Error refreshing session', err);
                authStore.logout();
                userStore.resetStore();
                redirect('/');
                isRefreshing.current = false;
                refreshAttempted.current = true;
                processQueue(err, null);
                errorCount.current = 0;
              });
          }

          // If a refresh is in progress, queue the request for retry after the refresh.
          if (isRefreshing.current) {
            return new Promise((resolve, reject) => {
              failedQueue.current.push({ resolve, reject });
            })
              .then((token) => {
                if (originalRequest.headers) originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                console.error('Error in request retry after session refresh', err);
                return Promise.reject(err);
              });
          }
        }

        return Promise.reject(error);
      }
    );
  }, [authStore, userStore]);
};
