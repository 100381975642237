import { Alert, Autocomplete, Box, FormHelperText, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { deleteProject, useProjects } from 'Utils/APIUtils';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';
import { observer } from 'mobx-react-lite';

const deleteProjectSchema = object({
  projectId: string().min(1, 'Project ID is required'),
  projectName: string().min(1, 'Project name is required'),
  confirmDelete: string().min(1, 'Confirm delete is required'),
})
  .partial()
  .refine(
    (data) => {
      return `delete project ${data.projectName}` === data.confirmDelete;
    },
    {
      message: 'Confirmation does not match.',
      path: ['confirmDelete'],
    }
  );

const deleteProjectDefaultValues = {
  projectId: '',
  projectName: '',
  confirmDelete: '',
};

type DeleteProjectInput = TypeOf<typeof deleteProjectSchema>;

export const DeleteProjectForm: React.FC<FormProps> = observer(({ onSuccessfulSubmit, onCancel }) => {
  const { data: projectsMap } = useProjects();
  const projects = [...(projectsMap || []).values()];
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    watch,
    handleSubmit,
  } = useForm<DeleteProjectInput>({
    resolver: zodResolver(deleteProjectSchema),
    defaultValues: deleteProjectDefaultValues,
  });

  const onSubmitHandler: SubmitHandler<DeleteProjectInput> = async (values) => {
    const projectId = Number(values.projectId);
    if (!projectId) return;
    setLoading(true);

    try {
      const deleteRes = await deleteProject({ projectId });
      if (!deleteRes) {
        toast.error('Error deleting project');
        console.error('Error deleting project', JSON.stringify(deleteRes));
        setSubmitError('Error deleting project');
        setLoading(false);
        return;
      }
      toast.success('Project deleted');
      onSuccessfulSubmit();
    } catch (e) {
      toast.error('Error deleting project');
      console.error(JSON.stringify(e));
      setSubmitError('Error deleting project');
    } finally {
      setLoading(false);
    }
  };

  if (!projectsMap) return null;

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Delete Project
      </Typography>

      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <Autocomplete
          fullWidth
          options={projects}
          getOptionLabel={(option) => option.name || 'Unnamed Project'}
          onChange={(_, value) => {
            if (value) {
              reset({
                ...watch(),
                projectName: value.name,
                projectId: String(value.id),
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Project'
              error={!!errors['projectName']}
              helperText={errors['projectName'] ? errors['projectName'].message : ''}
              {...register('projectName')}
            />
          )}
        />
        <TextField
          disabled
          sx={{ mb: 2 }}
          label='Project ID'
          InputLabelProps={{ shrink: !!watch('projectId') }}
          fullWidth
          error={!!errors['projectId']}
          helperText={errors['projectId'] ? errors['projectId'].message : ''}
          {...register('projectId')}
        />
        <FormHelperText sx={{ mb: 2, color: 'red' }} error>
          {`To confirm deletion, type 'delete project <project name>'`}
        </FormHelperText>
        <TextField
          sx={{ mb: 2 }}
          label='Confirm Delete'
          fullWidth
          error={!!errors['confirmDelete']}
          helperText={errors['confirmDelete'] ? errors['confirmDelete'].message : ''}
          {...register('confirmDelete')}
        />
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Delete Project' />
      </Box>
    </>
  );
});
DeleteProjectForm.displayName = 'DeleteProjectForm';
