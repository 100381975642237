import { getAlpha2Code } from 'Components/Header/Utils/headerUtils';
import { AWS_CONFIG } from 'aws/config';
import React, { useState } from 'react';
import { Skeleton } from '@mui/material';

type FlagIconProps = {
  countryCode: string;
  className?: string;
  width?: number;
  height?: number;
  borderRadius?: number;
};

export const Flag: React.FC<FlagIconProps> = ({
  countryCode,
  className,
  width = 45,
  height = 34,
  borderRadius = 3,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const onImageLoad = () => setIsImageLoaded(true);

  const S3_FLAGS_URL = `${AWS_CONFIG.STATIC_ASSETS}/static-assets/country-flags`;
  const flagUrl = `${S3_FLAGS_URL}/${getAlpha2Code(countryCode)}.svg`;

  return (
    <>
      <img
        src={flagUrl}
        alt={`Flag of ${countryCode}`}
        className={className}
        onLoad={onImageLoad}
        style={{
          borderRadius: `${borderRadius}px`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
      {!isImageLoaded && (
        <Skeleton
          variant='rectangular'
          width={width}
          sx={{ aspectRatio: '16 / 9', borderRadius: `${borderRadius}px` }}
        />
      )}
    </>
  );
};
