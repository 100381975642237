import { Autocomplete, TextField } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { Road } from '@electreon/electreon-metadata-service-gen-ts-client';

interface RoadSelectionInputProps {
  roads: Road[];
  setSelectedRoad: Dispatch<SetStateAction<Road | null>>;
  selectedRoad: Road | null;
  onChange?: (_: any, newValue: any | null) => Promise<void>;
  disabled?: boolean;
}

export const RoadSelectionInput: React.FC<RoadSelectionInputProps> = ({
  roads,
  setSelectedRoad,
  selectedRoad,
  onChange,
  disabled
}) => {
  return (
    <Autocomplete
      sx={{ mb: 4 }}
      options={roads}
      getOptionLabel={(option) => option.name || ''}
      renderInput={(params) => {
        return <TextField {...params} label='Select Road' variant='outlined' />;
      }}
      onChange={
        onChange
          ? onChange
          : (e: any) => {
            setSelectedRoad(roads.find((road) => road.name === e.target.outerText) || null);
          }
      }
      value={selectedRoad}
      disabled={disabled}
    />
  );
};
