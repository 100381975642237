import { SxProps, Theme, alpha } from '@mui/material';

const headerTitleStyles: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.header?.titleSize?.lg,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.header?.titleSize?.md,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.header?.titleSize?.sm,
  },
});

export const headerTextStyles: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.header?.textSize?.lg,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.header?.textSize?.md,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.header?.textSize?.sm,
  },
});

const headerIconFontStyles: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    fontSize: theme.header?.iconFontSize?.lg,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: theme.header?.iconFontSize?.md,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.header?.iconFontSize?.sm,
  },
});

export const electreonHeaderLogoImageStyles = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    height: theme.header?.iconSize?.lg,
  },
  [theme.breakpoints.down('lg')]: {
    height: theme.header?.iconSize?.md,
  },
  [theme.breakpoints.down('md')]: {
    height: theme.header?.iconSize?.sm,
  },
});

// Responsive header logo
// export const electreonHeaderLogoImageStyles: SxProps<Theme> = (
//   theme: Theme
// ) => ({
//   maxWidth: "170px",
//   [theme.breakpoints.up("xl")]: {
//     ...headerSvgSize(theme),
//     content: `url(${electreonLogo})`,
//   },
//   [theme.breakpoints.down("xl")]: {
//     ...headerSvgSize(theme),
//     content: `url(${electreonSmallLogo})`,
//   },
// });

export const headerIconHeight: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    height: theme.header?.iconSize?.lg,
  },
  [theme.breakpoints.down('lg')]: {
    height: theme.header?.iconSize?.md,
  },
  [theme.breakpoints.down('md')]: {
    height: theme.header?.iconSize?.sm,
  },
});

const headerIconStyles: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    ...headerTextStyles(theme),
    width: theme.header?.iconSize?.lg,
    height: theme.header?.iconSize?.lg,
    '& svg': {
      ...headerIconFontStyles(theme),
    },
  },
  [theme.breakpoints.down('lg')]: {
    ...headerTextStyles(theme),
    width: theme.header?.iconSize?.md,
    height: theme.header?.iconSize?.md,
    '& svg': {
      ...headerIconFontStyles(theme),
    },
  },
  [theme.breakpoints.down('md')]: {
    ...headerTextStyles(theme),
    width: theme.header?.iconSize?.sm,
    height: theme.header?.iconSize?.sm,
    '& svg': {
      ...headerIconFontStyles(theme),
    },
  },
});

const headerSvgSize: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
  [theme.breakpoints.down('lg')]: {
    '& svg': {
      height: '75%',
      width: '75%',
    },
  },
  [theme.breakpoints.down('md')]: {
    '& svg': {
      height: '50%',
      width: '50%',
    },
  },
});

const headerGap: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.up('lg')]: {
    gap: '2vh',
  },
  [theme.breakpoints.down('lg')]: {
    gap: '1vh',
  },
  [theme.breakpoints.down('md')]: {
    gap: '1vh',
  },
});

export const headerLeftSideStyles: SxProps = {
  height: '100%',
  alignItems: 'center',
  width: 'fit-content',
};

export const headerRightSideStyles: SxProps<Theme> = (theme: Theme) => ({
  height: '100%',
  alignItems: 'center',
  ...headerGap(theme),
});

export const notificationsButtonStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerIconStyles(theme),
  ...headerSvgSize(theme),
  '& .MuiBadge-badge': { fontSize: '12px' },
  '&:hover svg path': {
    stroke: theme.palette.accent.primary.main,
  },
});

export const headerButtonWrapperStyles: SxProps<Theme> = (theme: Theme) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const userIconStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerIconStyles(theme),
  border: '2px solid',
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.accent.primary.main,
  },
});

export const controlPanelIconStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerIconStyles(theme),
  border: '2px solid',
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  '&:hover': {
    color: theme.palette.accent.primary.main,
  },
});

export const searchIconStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerIconStyles(theme),
  ...headerSvgSize(theme),
  backgroundColor: 'transparent',
  color: theme.palette.primary.main,
  width: '35px',
  height: '35px',
  '&:hover': {
    color: theme.palette.accent.primary.main,
  },
});

export const ProjectNameStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerTitleStyles(theme),
  textTransform: 'capitalize',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('xl')]: {
    display: 'none',
  },
  [theme.breakpoints.up('xl')]: {
    display: 'block',
  },
});

export const currentPageDisplayStyles: SxProps<Theme> = (theme: Theme) => ({
  ...headerTitleStyles(theme),
});

export const timeAndFlagWrapperStyles: SxProps<Theme> = (theme: Theme) => ({
  alignItems: 'center',
  [theme.breakpoints.up('lg')]: {
    ...headerGap(theme),
    height: '26px',
  },
  [theme.breakpoints.down('lg')]: {
    ...headerGap(theme),
    height: '22px',
  },
  [theme.breakpoints.down('md')]: {
    ...headerGap(theme),
    height: '15px',
  },
});

export const getNavButtonDynamicStyles = (isMenuOpen: boolean) => {
  const navButtonDynamicStyles: SxProps<Theme> = isMenuOpen
    ? {
        bgcolor: (theme: Theme) => alpha(theme.palette.accent.primary.main, 0.3),
        '& .arrow-icon': (theme: Theme) => ({
          fill: alpha(theme.palette.primary.main, 0.55),
        }),
      }
    : {
        bgcolor: '',
        '& .arrow-icon': {
          fill: (theme: Theme) => theme.palette.primary.main,
        },
      };
  return navButtonDynamicStyles;
};
