import { ParkingSpot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { CreateParkingSpotsInput } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotSchema';
import { api } from 'Services/api';
import { getProjectParkingSpots } from 'Utils/APIUtils';
import { Dispatch, SetStateAction } from 'react';
import { FormState } from 'react-hook-form';
import { toast } from 'sonner';

export const getAndRefreshParkingSpots = async (depotId: number, muId: string) => {
  let parkingSpotsRes;
  let filteredParkingSpots: ParkingSpot[] = [];
  if (depotId) parkingSpotsRes = await getProjectParkingSpots(depotId);
  if (parkingSpotsRes) {
    filteredParkingSpots = parkingSpotsRes.data.filter((spot) => spot.muId === muId);
  }
  return filteredParkingSpots;
};

export const getAndRefreshOcppParkingSpots = async (depotId: number, ocppId: string) => {
  let parkingSpotsRes;
  let filteredParkingSpots: ParkingSpot[] = [];
  if (depotId) parkingSpotsRes = await getProjectParkingSpots(depotId);
  if (parkingSpotsRes) {
    filteredParkingSpots = parkingSpotsRes.data.filter((spot) => spot.muId === ocppId);
  }

  return filteredParkingSpots;
};

export const submitParkingSpots = (
  values: CreateParkingSpotsInput,
  dirtyFields: FormState<CreateParkingSpotsInput>['dirtyFields'],
  setLoading: Dispatch<SetStateAction<boolean>>,
  setSubmitError: Dispatch<SetStateAction<string | null>>,
  sessionId?: string,
  onSuccessfulSubmit?: () => void
) => {
  if (!sessionId) {
    console.error('No session id found');
    return;
  }
  setLoading(true);
  setSubmitError(null);
  const hasParkingSpotNameChanged = (index: number) => Boolean(dirtyFields.parkingSpots?.[index]?.name);

  const parkingSpotsWithMuAndDepotId: ParkingSpot[] = values.parkingSpots.map((spot, i) => ({
    ...spot,
    id: hasParkingSpotNameChanged(i) ? undefined : spot.id,
    depotId: values.depotId,
    muId: values.muId,
    segments: spot.segments?.map((segment) => ({
      ...segment,
      muId: values.muId,
      projectId: values.projectId,
      drawerNum: 0,
      parkingSpotId: hasParkingSpotNameChanged(i) ? undefined : spot.id,
    })),
  }));

  api.metadata.parkingSpots
    .createOrUpdateParkingSpots(values.depotId, values.muId, sessionId, parkingSpotsWithMuAndDepotId)
    .then((res) => {
      toast.success('Parking spots updated');
      onSuccessfulSubmit?.();
    })
    .catch((err) => {
      toast.error('Parking spots update failed');
      console.error(JSON.stringify(err));
      setSubmitError(err.message);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getFirstAvailableSegmentNum = (parkingSpots: ParkingSpot[]) => {
  const getCurrentSelectedSegmentNums = (parkingSpots: ParkingSpot[]) => {
    const segmentNums = parkingSpots
      .flatMap((spot) => spot.segments?.map((segment) => segment.segmentNum) ?? [])
      .sort((a, b) => (a ? (b ? a - b : -1) : 1));
    return segmentNums;
  };

  const segmentNums = getCurrentSelectedSegmentNums(parkingSpots);
  const firstAvailableSegmentNum = segmentNums
    .sort((a, b) => (a as number) - (b as number))
    .reduce<number>((acc, curr, i) => {
      if (acc === curr) return acc + 1;
      return acc;
    }, 0);
  return firstAvailableSegmentNum > 11
    ? undefined
    : firstAvailableSegmentNum < 0
      ? 0
      : firstAvailableSegmentNum;
};
