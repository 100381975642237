import { FC } from 'react';
import { AccessLevels, Functionalities } from './types/permissionsTypes';
import { getAccessLevel } from './utils/permissionsUtils';
import { UserData } from '../stores/userStore/UserStore';

interface WithPermissionProps {
  children: React.ReactNode;
  /** Functionality to check permission for */
  for: Functionalities;
  /** Override permission for debugging purposes */
  override?: AccessLevels;
  /** User to check permission for */
  userData?: UserData;
}

/**
 * @example
 * <WithPermission for={Functionalities.DROP_DATABASE}>
 *  <Button>Drop database</Button>
 * </WithPermission>
 */
export const WithPermission: FC<WithPermissionProps> = ({ for: to, children, override, userData }) => {
  if (override === AccessLevels.DENIED) return null;
  if (!userData) return null;

  const permission = override ?? getAccessLevel(to, userData);

  if (permission === AccessLevels.DENIED) return null;

  if (permission === AccessLevels.LIMITED) {
    const childrenArray = Array.isArray(children) ? children : [children];

    const restrictedChildren = childrenArray.map((child, i) => {
      return (
        <div key={i} style={{ pointerEvents: 'none', opacity: 0.5 }}>
          {child}
        </div>
      );
    });

    return <>{restrictedChildren}</>;
  }

  return <>{children}</>;
};
