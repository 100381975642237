import * as z from 'zod';

export const EditPOSSchema = z.object({
  deviceId: z
    .string()
    .min(1, 'Device ID is required')
    .refine(async (value) => {
      return (String(value).length === 9 && String(value).startsWith('PS')) || value.length === 0;
    }, 'Device ID must be 9 characters long'),
  deviceName: z
    .string()
    .refine((value) => {
      return (value.length >= 4 && value.length <= 20) || value.length === 0;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  projectName: z.string().optional(),
  vuId: z
    .string()
    .optional()
    .refine(async (value) => {
      return (String(value).length === 9 && String(value).startsWith('VU')) || String(value).length === 0;
    }, 'Device ID must be 9 characters long'),
});

export type EditPOSInput = z.TypeOf<typeof EditPOSSchema>;

export const EditAPFCSchema = z.object({
  deviceId: z
    .string()
    .min(1, 'Device ID is required')
    .refine(async (value) => {
      return (String(value).length === 9 && String(value).startsWith('AP')) || value.length === 0;
    }, 'Device ID must be 9 characters long'),
  deviceName: z
    .string()
    .refine((value) => {
      return (value.length >= 4 && value.length <= 20) || value.length === 0;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  projectName: z.string().optional(),
  muIds: z.array(z.string()).optional(),
});

export type EditAPFCInput = z.TypeOf<typeof EditAPFCSchema>;

export const EditOCPPSchema = z.object({
  deviceId: z
    .string()
    .min(1, 'Device ID is required'),
  deviceName: z
    .string()
    .refine((value) => {
      return (value.length >= 4 && value.length <= 20) || value.length === 0;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  projectName: z.string().optional(),
  longitude: z.string()
    .nullable()
    .refine((value) => {
      if (!value) return true;
      const onlyNumbersRegex = /^\d+$/;
      return onlyNumbersRegex.test(value);
    }, 'Longitude must contain only numbers')
    .refine((value) => {
      if (!value) return true;
      const longitude = parseFloat(value);
      return longitude >= -180 && longitude <= 180;
    }, 'Longitude must be between -180 and 180')
    .refine((value) => {
      if (!value) return true;
      return !value.includes(' ');
    }, 'Longitude must not include spaces'),
  latitude: z.string()
    .nullable()
    .refine((value) => {
      if (!value) return true;
      const onlyNumbersRegex = /^\d+$/;
      return onlyNumbersRegex.test(value);
    }, 'Latitude must contain only numbers')
    .refine((value) => {
      if (!value) return true;
      const latitude = parseFloat(value);
      return latitude >= -90 && latitude <= 90;
    }, 'Latitude must be between -90 and 90')
    .refine((value) => {
      if (!value) return true;
      return !value.includes(' ');
    }, 'Latitude must not include spaces'),
});

export type EditOCPPInput = z.TypeOf<typeof EditOCPPSchema>;

export const createOCPPSchema = z.object({
  deviceName: z.string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  projectName: z.string().optional(),
  longitude: z.string()
    .min(1, 'Longitude is required')
    .refine((value) => {
      const longitude = parseFloat(value);
      return longitude >= -180 && longitude <= 180;
    }, 'Longitude must be between -180 and 180')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Longitude must not include spaces'),
  latitude: z.string()
    .min(1, 'Latitude is required')
    .refine((value) => {
      const latitude = parseFloat(value);
      return latitude >= -90 && latitude <= 90;
    }, 'Latitude must be between -90 and 90')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Latitude must not include spaces'),
  depotId: z.number().optional(),
});

export type CreateOCPPInput = z.TypeOf<typeof createOCPPSchema>;