import {
  ManagementUnitModelRefined,
  OcppChargerModelRefined,
  VehicleUnitModelRefined,
} from '../../types/globals';
import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
  getVersionsFromFpgaMessage,
  shouldDisplayMessage,
  getVersionsFromDeviceTwinChanges,
  getVersionsFromReportedProperties,
  getVersionsFromMetadata,
  generateMessageType,
} from './deviceStoreUtils';
import { AbstractRootStore } from '../storeModels';
import { DeviceStoreVersions, EventsMessagesHistoryProps } from './deviceStoreTypes';
import { isMu } from '../../utils/globalUtils';
import * as SocketMessageTypes from '../../types/socketMessageTypes';

const LIVE_DATA_UPDATE_INTERVAL = 900;

class DeviceStore {
  lastAlive: Date | null = null;
  rootStore: AbstractRootStore;
  public liveData: {
    [deviceId: string]: Record<string, unknown> & {
      latestDeviceTwinChangesTagsVersion?: number;
      latestDeviceTwinChangesReportedPropertiesVersion?: number;
      canbusDataMessage?: SocketMessageTypes.CanBusDataSocketMessage;
      receiversMessage?: SocketMessageTypes.ReceiversSocketMessage;
      batteryDataMessage?: SocketMessageTypes.BatteryDataSocketMessage;
      gpsMessage?: SocketMessageTypes.GPSSocketMessage;
      dcSamplerMessage?: SocketMessageTypes.DcSamplerSocketMessage;
      fpgaMessage?: SocketMessageTypes.FPGASocketMessage;
      connectionStateChanges?: SocketMessageTypes.ConnectionStateChangesSocketMessage;
      chargingMessage?: SocketMessageTypes.ChargingSocketMessage;
      devicesStatesMessage?: SocketMessageTypes.DeviceStateSocketMessage;
      deviceTwinChanges?: SocketMessageTypes.DeviceTwinChangesSocketMessage;
      gpiosMessage?: SocketMessageTypes.GPIOSSocketMessage | SocketMessageTypes.GPIOSVUSocketMessage;
      operationalStateMessage?: SocketMessageTypes.OperationalStateSocketMessage;
      cuUnitTempMessage?: SocketMessageTypes.CUUnitTempSocketMessage;
      hpMessage?: SocketMessageTypes.HPSocketMessage;
      envSensorsMessage?: SocketMessageTypes.EnvSensorsSocketMessage;
      dcSupplyMessage?: SocketMessageTypes.APFCSocketMessage;
      eventsMessage?: SocketMessageTypes.FpgaEvents;
      fansMessage?: SocketMessageTypes.MuFansSocketMessage;
      commBoardsMessage?: SocketMessageTypes.CommBoardsSocketMessage;
      parkingSpotStatesMessage?: SocketMessageTypes.ParkingSpotStatesMessage;
      airConditionMessage?: SocketMessageTypes.AirConditionMessage;

      errors?: {
        versionMismatchFGPASom?: SocketMessageTypes.VersionMismatchFPGASOMEvent;
      };
    };
  } = {};

  public reportedProperties: {
    [deviceId: string]: SocketMessageTypes.Reported;
  } = {};
  public isSDCardDLInitiating: boolean = false;
  /** Display number of the selected drawer (1-5) - NOT INDEX */
  public selectedDrawerNumber: number = 1;
  public selectedParkingSpotIndex: number = 0;
  public selectedSegmentIndex: number = 0;
  public eventsMessagesHistory: {
    [deviceId: string]: EventsMessagesHistoryProps[];
  } = {};
  public selectedDevices: {
    mu: ManagementUnitModelRefined | null;
    vu: VehicleUnitModelRefined | null;
    ocpp: OcppChargerModelRefined | null;
  } = {
    mu: null,
    vu: null,
    ocpp: null,
  };

  private messageBuffer: {
    [deviceId: string]: {
      [eventName: string]: unknown;
    };
  } = {};
  private updateInterval: NodeJS.Timeout | null = null;

  constructor(rootStore: AbstractRootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      versions: computed,
      setLiveData: action,
    });
  }

  public startUpdateInterval() {
    this.updateInterval = setInterval(this.updateLiveData, LIVE_DATA_UPDATE_INTERVAL);
  }

  private updateLiveData = () => {
    runInAction(() => {
      for (const deviceId in this.messageBuffer) {
        if (!this.liveData[deviceId]) {
          this.liveData[deviceId] = {};
        }
        this.liveData[deviceId] = { ...this.liveData[deviceId], ...this.messageBuffer[deviceId] };
      }
      this.messageBuffer = {};
    });
  };

  stopUpdateInterval() {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  setLastAlive = () => {
    this.lastAlive = new Date();
  };

  setIsSDCardDLInitiating = (inProgress: boolean) => {
    this.isSDCardDLInitiating = inProgress;
  };

  public handleEventsMessage = (deviceId: string, data: SocketMessageTypes.EventsMessage) => {
    if (!this.eventsMessagesHistory[deviceId]) this.eventsMessagesHistory[deviceId] = [];

    for (const eventData of data) {
      if (SocketMessageTypes.isVersionMismatchFPGASOMEvent(eventData)) {
        this.liveData[deviceId].errors = {
          ...this.liveData[deviceId].errors,
          versionMismatchFGPASom: eventData,
        };
      }
      if (!shouldDisplayMessage(eventData?.nameString, eventData?.typeName)) continue;
      const device = this.rootStore?.projectStore.deviceIdToIOTDeviceModelMap.get(deviceId);
      const deviceType = isMu(device) ? 'MU' : 'VU';

      const newMessage = {
        key: `${eventData?.ts}-${eventData?.nameString}` || null,
        data: eventData,
        showMoreInfo: false,
        deviceId: deviceId,
        shelfNumber: null,
        segmentNumber: null,
        deviceType,
      } satisfies EventsMessagesHistoryProps;

      if (!this.eventsMessagesHistory[deviceId].find((message) => message.key === newMessage.key)) {
        this.eventsMessagesHistory[deviceId].unshift(newMessage);
      }

      if (this.eventsMessagesHistory[deviceId].length > 100) {
        this.eventsMessagesHistory[deviceId].pop();
      }
    }
  };

  private saveFpgaAndSegmentEvents = (
    deviceId: string,
    ts: number,
    data: SocketMessageTypes.FpgaEvents,
    shelfNumber: string,
    segmentNumber?: string
  ) => {
    if (!this.eventsMessagesHistory[deviceId]) this.eventsMessagesHistory[deviceId] = [];
    for (const key in data) {
      const eventMessage = data[key];
      if (!shouldDisplayMessage(eventMessage)) {
        continue;
      }
      const newData = {
        nameString: eventMessage,
        typeName: generateMessageType(eventMessage),
        ts: ts,
      };
      const device = this.rootStore?.projectStore.deviceIdToIOTDeviceModelMap.get(deviceId);
      const messageKey = segmentNumber ? `${ts}-${key}-${segmentNumber}` : `${ts}-${key}` || null;
      const deviceType = isMu(device) ? 'MU' : 'VU';

      const newMessage: EventsMessagesHistoryProps = {
        key: messageKey,
        data: newData,
        showMoreInfo: false,
        deviceId: deviceId,
        shelfNumber: shelfNumber,
        segmentNumber: segmentNumber || null,
        deviceType: deviceType,
      };
      this.eventsMessagesHistory[deviceId].unshift(newMessage);
      if (this.eventsMessagesHistory[deviceId].length > 100) {
        this.eventsMessagesHistory[deviceId].pop();
      }
    }
  };

  public handleFpgaMessage = (deviceId: string, data: SocketMessageTypes.FPGASocketMessage) => {
    for (const fpgaKey in data) {
      // for each shelf, search for events in the "header_data", fpga events
      if (SocketMessageTypes.isFpgaKey(fpgaKey)) {
        const fpgaData = data[fpgaKey];
        const shelfNumber = fpgaKey.split('_')[1];
        const events = fpgaData?.header_data?.events;
        if (typeof fpgaData === 'string' || typeof fpgaData === 'number') continue;
        if (events && Object.keys(events).length > 0) {
          this.saveFpgaAndSegmentEvents(deviceId, +(data?.ts || 0), events, shelfNumber);
        }
        for (const segmentKey in fpgaData) {
          // for each segment in the shelf, search for events
          if (SocketMessageTypes.isSegmentKey(segmentKey)) {
            const segmentData = fpgaData[segmentKey];
            if (!segmentData?.events) continue;
            if (Object.keys(segmentData?.events).length > 0) {
              const segmentNumber = segmentKey.split('_')[1];
              this.saveFpgaAndSegmentEvents(
                deviceId,
                segmentData?.timestamp || 0,
                segmentData?.events,
                shelfNumber,
                segmentNumber
              );
            }
          }
        }
      }
    }
  };

  toggleEventMessageMoreInfo = (deviceId: string, messageDataKey: string | null) => {
    const eventMessage = this?.eventsMessagesHistory[deviceId]?.find(
      (messageData) => messageData.key === messageDataKey
    );
    if (eventMessage?.data) {
      eventMessage.showMoreInfo = !eventMessage.showMoreInfo;
    }
  };

  clearEventsHistory = () => {
    for (const deviceId in this.eventsMessagesHistory) {
      this.clearDeviceEventsHistory(deviceId);
    }
    this.eventsMessagesHistory = {};
  };

  clearDeviceEventsHistory(deviceId: string) {
    this.eventsMessagesHistory[deviceId] = [];
  }

  setLiveData = (deviceId: string, eventName: string, data: unknown) => {
    this.setLastAlive();

    if (!this.messageBuffer[deviceId]) {
      this.messageBuffer[deviceId] = {};
    }

    this.messageBuffer[deviceId][eventName] = data;
  };

  setSelectedDrawer = (drawer: number) => {
    this.selectedDrawerNumber = drawer;
  };

  setSelectedParkingSpot = (parkingSpot: number) => {
    this.selectedParkingSpotIndex = parkingSpot;
  };

  setSelectedSegment = (segment: number) => {
    this.selectedSegmentIndex = segment;
  };

  deviceTwinChangesReceived = (deviceId: string): boolean => {
    return !!this.liveData[deviceId]?.deviceTwinChanges;
  };

  clearDevicesTwinChanges = () => {
    for (const deviceId in this.liveData) {
      this.clearDeviceTwinChanges(deviceId);
    }
  };

  clearDeviceTwinChanges(deviceId: string) {
    this.liveData[deviceId].deviceTwinChanges = {};
  }

  setReportedProperties = (deviceId: string, reportedProperties: SocketMessageTypes.Reported) => {
    this.reportedProperties[deviceId] = reportedProperties;
  };

  get versions() {
    const versions = Object.entries(this.liveData).reduce<DeviceStoreVersions>(
      (versions, [deviceId, { fpgaMessage, deviceTwinChanges }]) => ({
        ...versions,
        [deviceId]: {
          ...versions[deviceId],
          ...getVersionsFromMetadata(
            deviceId,
            this.rootStore?.projectStore?.muList,
            this.rootStore?.projectStore?.vuList
          ),
          ...getVersionsFromReportedProperties(this.reportedProperties[deviceId]),
          ...getVersionsFromFpgaMessage(fpgaMessage),
          ...getVersionsFromDeviceTwinChanges(deviceTwinChanges),
        },
      }),
      {}
    );
    return versions;
  }

  cleanup() {
    this.stopUpdateInterval();
  }
}

export default DeviceStore;
