import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { ManagementUnitInputs } from 'Components/Forms/RoadForm/ManagementUnitInputs';
import { ManageRoadInput } from 'Components/Forms/RoadForm/utils/roadSchemas';
import { useFormContext } from 'react-hook-form';

export const RoadSectionInputs: React.FC<{
  roadSectionIndex: number;
  dynamicMus: ManagementUnitModel[];
  handleAddManagementUnit: (roadSectionIndex: number) => void;
  handleRemoveManagementUnit: (roadSectionIndex: number, managementUnitIndex: number) => void;
  handleRemoveRoadSection: (roadSectionIndex: number) => void;
}> = ({
  roadSectionIndex,
  dynamicMus,
  handleAddManagementUnit,
  handleRemoveManagementUnit,
  handleRemoveRoadSection,
}) => {
  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<ManageRoadInput>();

  const roadSections = watch('roadSections');

  return (
    <Stack spacing={1}>
      <Stack direction='row' alignItems='center' position={'relative'}>
        <Typography variant='subtitle1' component='h3'>
          Road Section {roadSectionIndex + 1}
        </Typography>
        <IconButton
          aria-label='delete'
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => handleRemoveRoadSection(roadSectionIndex)}
        >
          <GridDeleteIcon />
        </IconButton>
      </Stack>
      <Stack spacing={2} sx={{ mb: 2 }}>
        {/* Input name */}
        <TextField
          fullWidth
          label='Road Section Name'
          error={!!errors.roadSections?.[roadSectionIndex]?.name}
          helperText={errors.roadSections?.[roadSectionIndex]?.name?.message}
          {...register(`roadSections.${roadSectionIndex}.name`)}
        />
        {/* Input length  */}
        <TextField
          fullWidth
          label='Length Km'
          error={!!errors.roadSections?.[roadSectionIndex]?.totalLengthKm}
          helperText={errors.roadSections?.[roadSectionIndex]?.totalLengthKm?.message}
          {...register(`roadSections.${roadSectionIndex}.totalLengthKm`)}
        />
        {/* Input road geoPath */}
        <TextField
          fullWidth
          label='Geo Path'
          error={!!errors.roadSections?.[roadSectionIndex]?.geoPath}
          helperText={errors.roadSections?.[roadSectionIndex]?.geoPath?.message}
          {...register(`roadSections.${roadSectionIndex}.geoPath`)}
        />
      </Stack>
      {/* Management Units */}
      {roadSections?.[roadSectionIndex].managementUnits?.map((managementUnit, managementUnitIndex) => (
        <Stack
          key={managementUnitIndex + roadSectionIndex + 'managementUnit'}
          sx={{
            p: 2,
            border: '1px solid',
            borderColor: (theme) => theme.palette.grey[300],
            borderRadius: '5px',
          }}
        >
          <ManagementUnitInputs
            managementUnit={managementUnit}
            managementUnitIndex={managementUnitIndex}
            roadSectionIndex={roadSectionIndex}
            dynamicMus={dynamicMus}
            handleRemoveManagementUnit={handleRemoveManagementUnit}
          />
        </Stack>
      ))}
      {/* Add Management Unit */}
      <Button
        variant='outlined'
        color='primary'
        startIcon={<div>+</div>}
        onClick={() => handleAddManagementUnit(roadSectionIndex)}
      >
        Add Management Unit
      </Button>
    </Stack>
  );
};
