import { SxProps, Theme, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

type SwitchButtonMuiProps<T extends string | number> = {
  switchProps: Array<{ value: T; label: string }>;
  selectedValue: string | number;
  textColor?: string;
  textHighlightColor?: string;
  backgroundHighlightColor?: string;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: T | null) => void;
  sx?: SxProps<Theme>;
  toggleButtonGroupColor?: ToggleButtonGroupProps['color'];
  isDisabled?: boolean;
  showCheckIcon?: boolean;
  showShadow?: boolean;
  onClick?: (event: React.ChangeEvent<HTMLButtonElement> | any) => void;
  title?: string;
  classNames?: string;
  optionWidth?: string;
};

export const SwitchButtonMui = <T extends string | number>({
  switchProps,
  selectedValue,
  textColor,
  textHighlightColor,
  backgroundHighlightColor,
  onChange,
  toggleButtonGroupColor,
  optionWidth,
  sx,
  isDisabled,
  showCheckIcon,
  showShadow = false,
  onClick,
  title,
  classNames,
}: SwitchButtonMuiProps<T>) => {
  if (switchProps.length < 2) {
    console.error('SwitchButtonMui: switchProps must have at least 2 items');
    return null;
  }

  const onValueChange = (event: React.MouseEvent<HTMLElement>, value: T) => {
    if (onChange && value !== null) {
      onChange(event, value);
    }
  };

  return (
    <ToggleButtonGroup
      title={title}
      className={classNames}
      disabled={isDisabled}
      color={toggleButtonGroupColor || 'info'}
      value={selectedValue}
      exclusive
      onChange={onValueChange}
      onClick={onClick}
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, minmax(0, 1fr))`,
        opacity: isDisabled ? 0.5 : 1,
        '& .MuiToggleButton-root.Mui-selected': {
          color: (theme) => textHighlightColor || theme.palette.secondary.light,
          backgroundColor: (theme) => backgroundHighlightColor || theme.palette.accent.gray[500],
        },
        '& .MuiToggleButton-root:hover': {
          opacity: 0.8,
        },
        '& .MuiToggleButton-root.Mui-selected:hover': {
          opacity: 0.8,
        },
        '& .MuiToggleButton-root': {
          color: (theme) => textColor || theme.palette.primary.main,
          fontSize: '1.3ch',
          width: optionWidth || 'auto',
        },
        boxShadow: showShadow
          ? '0px 1px 3px 1px rgba(0, 0, 0, 0.30), 0px 1px 2px 0px rgba(0, 0, 0, 0.60)'
          : 'none',

        ...sx,
      }}
    >
      {switchProps.map((switchProp) => (
        <ToggleButton
          value={switchProp.value}
          key={switchProp.value}
          sx={{ py: 0, px: showCheckIcon && selectedValue === switchProp.value ? 0.5 : 'auto', flex: '1 1' }}
        >
          {selectedValue === switchProp.value && showCheckIcon && (
            <CheckIcon fontSize='small' sx={{ width: '15px', mr: '5px' }} />
          )}
          <span className='text-nowrap' style={{ fontSize: 'inherit' }}>
            {switchProp.label}
          </span>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
