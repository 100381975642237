import { ManagementUnitModel } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { Segment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { ManageRoadInput } from 'Components/Forms/RoadForm/utils/roadSchemas';
import { useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

export const EditSegmentFields: React.FC<{
  segment: Segment | null;
  roadSectionIndex: number;
  selectedMu: ManagementUnitModel;
  control: Control<ManageRoadInput>;
  setValue: UseFormSetValue<ManageRoadInput>;
  onClose: () => void;
}> = ({ segment, roadSectionIndex, selectedMu, control, setValue, onClose }) => {
  const form = useWatch({ control });
  const [values, setValues] = useState<{ longitude: number; latitude: number }>({
    longitude: segment?.longitude || 0,
    latitude: segment?.latitude || 0,
  });

  if (!segment || segment.segmentNum === undefined) return null;

  const fieldsHeight = 40;

  const textFieldStyles = {
    '& .MuiInputLabel-root': {
      fontSize: '0.6rem',
    },
    '& .MuiInputBase-root': {
      maxHeight: `${fieldsHeight}px`,
      maxWidth: '100px',
    },
    '& .MuiInputBase-input': {
      fontSize: '0.6rem',
      textAlign: 'center',
    },
  };

  const buttonStyles = {
    height: `${fieldsHeight}px`,
  };

  const currentSegments = form.roadSections?.[roadSectionIndex]?.muIdToSegments?.[selectedMu.id!] || [];
  const segmentIndex = currentSegments.findIndex((s) => s.segmentNum === segment.segmentNum);
  if (segmentIndex === -1) return null;

  return (
    <form noValidate autoComplete='off'>
      <Stack direction='row' sx={{ p: 1.5 }} spacing={1} justifyContent={'center'} alignItems={'center'}>
        <Typography variant='subtitle1' component='h3' minWidth={'70px'}>
          Seg. {segment?.segmentNum! + 1}
        </Typography>
        <TextField
          fullWidth
          autoFocus
          label='Longitude'
          sx={textFieldStyles}
          value={values.longitude}
          onChange={(e) => {
            setValues((prev) => ({ ...prev, longitude: Number(e.target.value) }));
          }}
        />
        <TextField
          fullWidth
          label='Latitude'
          defaultValue={segment?.latitude}
          sx={textFieldStyles}
          value={values.latitude}
          onChange={(e) => {
            setValues((prev) => ({ ...prev, latitude: Number(e.target.value) }));
          }}
        />
        <Button
          type='submit'
          variant='contained'
          onClick={() => {
            const newSegment = {
              ...segment,
              longitude: values.longitude,
              latitude: values.latitude,
            };
            currentSegments[segmentIndex] = newSegment;
            setValue(`roadSections.${roadSectionIndex}.muIdToSegments.${selectedMu.id}`, [
              ...currentSegments,
            ]);
            onClose();
          }}
          sx={buttonStyles}
        >
          Save
        </Button>
      </Stack>
    </form>
  );
};
