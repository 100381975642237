import { EditMuForm } from 'Components/Forms/EditDeviceForm/EditMuForm';
import { EditVuForm } from 'Components/Forms/EditDeviceForm/EditVuForm';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  ManagementUnitModel,
  VehicleUnitModel,
} from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { isOcpp, isVu, isMu } from '@electreon_ui/shared/utils/globalUtils';
import { EditOCPPForm } from './EditOCPPForm';
import { useProjects } from 'Utils/APIUtils';

export interface EditDeviceFormProps extends FormProps {
  device: ManagementUnitModel | VehicleUnitModel | any;
  selectedProject?: Project | null;
}

export const EditDeviceForm: React.FC<EditDeviceFormProps> = ({
  onSuccessfulSubmit,
  onCancel,
  device,
  selectedProject,
}) => {
  const { data: projectsMap } = useProjects();
  const projects = [...(projectsMap || []).values()];
  if (!device) return null;

  return isMu(device) ? (
    <EditMuForm
      device={device}
      onSuccessfulSubmit={onSuccessfulSubmit}
      onCancel={onCancel}
      selectedProject={selectedProject}
    />
  ) : isVu(device) ? (
    <EditVuForm
      device={device}
      onSuccessfulSubmit={onSuccessfulSubmit}
      onCancel={onCancel}
      selectedProject={selectedProject}
      projectList={projects}
    />
  ) : isOcpp(device) ? (
    <EditOCPPForm
      onSuccessfulSubmit={onSuccessfulSubmit}
      onCancel={onCancel}
      selectedProject={selectedProject}
      selectedDevice={device}
    />
  ) : null;
};
EditDeviceForm.displayName = 'EditDeviceForm';
