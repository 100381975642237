import * as DCS from '@electreon/electreon-device-control-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class DeviceControlService {
  public muConfig: DCS.ManagementUnitConfigurationControllerApi;
  public vuConfig: DCS.VehicleUnitConfigurationControllerApi;
  public muRemoteControl: DCS.ManagementUnitRemoteControlControllerApi;
  public vuRemoteControl: DCS.VehicleUnitRemoteControlControllerApi;
  public apfcRemoteControl: DCS.ApfcRemoteControlControllerApi;
  public muFotaControl: DCS.ManagementUnitFotaControllerApi;
  public vuFotaControl: DCS.VehicleUnitFotaControllerApi;

  constructor(basePath: string, axios: AxiosInstance, config?: DCS.Configuration) {
    this.muConfig = new DCS.ManagementUnitConfigurationControllerApi(config, basePath, axios);
    this.vuConfig = new DCS.VehicleUnitConfigurationControllerApi(config, basePath, axios);
    this.muRemoteControl = new DCS.ManagementUnitRemoteControlControllerApi(config, basePath, axios);
    this.vuRemoteControl = new DCS.VehicleUnitRemoteControlControllerApi(config, basePath, axios);
    this.apfcRemoteControl = new DCS.ApfcRemoteControlControllerApi(config, basePath, axios);
    this.muFotaControl = new DCS.ManagementUnitFotaControllerApi(config, basePath, axios);
    this.vuFotaControl = new DCS.VehicleUnitFotaControllerApi(config, basePath, axios);
  }
}
