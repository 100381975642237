import { useAppStore } from 'MobxStores/context';
import { UiDevice } from '@electreon_ui/shared/types/globals';
import { isOcpp } from '@electreon_ui/shared/utils/globalUtils';

export const useLockState = (device?: UiDevice | null) => {
  const { deviceLockStore } = useAppStore();
  if (!device) return { isLockedByMe: false, isLocked: false, lockedBy: '', setupDisabled: true };
  if (isOcpp(device)) return { isLockedByMe: false, isLocked: false, lockedBy: '', setupDisabled: true };

  const mobxLockState = deviceLockStore.getLockState(device?.id!);

  return {
    isLockedByMe: mobxLockState.isLockedByMe,
    isLocked: mobxLockState.isLocked,
    lockedBy: mobxLockState.lockedBy,
    setupDisabled: !mobxLockState.isLockedByMe,
  };
};