import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { ManagementUnitModel, Road, Segment } from '@electreon/electreon-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { RoadForm } from './RoadForm';
import { RoadSelectionInput } from './RoadFormsInputs/RoadSelectionInput';
import { FormLoading } from '../FormUtils/FormLoading';

interface ManageRoadFormProps extends FormProps {
  roadId?: number;
}

export const ManageRoadForm: React.FC<ManageRoadFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject, roadId }) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [selectedRoad, setSelectedRoad] = useState<Road | null>(null);
    const [roads, setRoads] = useState<Road[]>([]);

    useEffect(() => {
      const fetchRoads = async () => {
        if (!selectedProject?.id) return;
        const roads = await api.metadata.roads.getProjectRoads(selectedProject.id);
        setRoads(roads.data);
        setFormLoading(false);
      };
      setFormLoading(true);
      fetchRoads();
    }, [selectedProject?.id]);

    useEffect(() => {
      if (roadId) {
        const road = roads.find((road) => road.id === roadId);
        if (road) {
          setSelectedRoad(road);
          // methods.setValue('id', road.id);
          // methods.setValue('roadName', String(road.name));
        }
      }
    }, [roads, roadId]);

    const handleRoadChange = async (_: any, newValue: Road | null) => {
      if (!newValue?.id) return;
      try {
        const road = (await api.metadata.roads.getRoad(newValue.id)).data;
        road.roadSections?.forEach((roadSection) => {
          if (!roadSection.managementUnits) {
            const muIds = Object.keys(roadSection.muIdToSegments || {});
            roadSection.managementUnits = muIds.map((id) => ({ id })) as ManagementUnitModel[];
          }

          // convert segments in muIdToSegments[every muId] to be 0-59 number based and 0-4 drawer based
          if (roadSection.muIdToSegments) {
            const newMuIdToSegments: Record<string, Segment[]> = {};
            for (const [muId, segmentArray] of Object.entries(roadSection.muIdToSegments)) {
              newMuIdToSegments[muId] = segmentArray.map((segment) => {
                if (segment.segmentNum === undefined || segment.drawerNum === undefined) {
                  console.error(`segmentNum or drawerNum is undefined for segment}`);
                  return segment;
                }
                const newSegment = {
                  ...segment,
                  segmentNum: segment.segmentNum + segment.drawerNum * 12,
                };
                return newSegment;
              });
            }
            roadSection.muIdToSegments = newMuIdToSegments;
          }
        });

        setSelectedRoad(road);
      } catch (e) {
        console.error(JSON.stringify(e));
      }
    };

    return (
      <Container disableGutters>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Manage Road
        </Typography>
        <Box>
          <SelectProject selectedProject={selectedProject} />
          <RoadSelectionInput
            roads={roads}
            onChange={handleRoadChange}
            setSelectedRoad={setSelectedRoad}
            selectedRoad={selectedRoad}
            disabled={!!roadId}
          />
          {selectedRoad && (
            <RoadForm
              onCancel={onCancel}
              onSuccessfulSubmit={onSuccessfulSubmit}
              selectedProject={selectedProject}
              roadToModify={selectedRoad}
            />
          )}
          <FormLoading open={formLoading} />
        </Box>
      </Container>
    );
  }
);
