import {
  ApfcModel,
  ManagementUnitModel,
  PosModel,
} from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { ParkingSpot, Road, RoadSection } from '@electreon/electreon-metadata-service-gen-ts-client';
import { OcppChargerMetadataModel } from '@electreon/electreon-ocpp-device-metadata-service-gen-ts-client';
import { AbstractIoTDeviceType } from '@electreon_ui/shared/types/globals';
import { api } from 'Services/api';
import { getSessionId } from 'Utils/SOMApi';

export const createMUDevice = (muData: ManagementUnitModel) => {
  return api.deviceMetadata.mu.createManagementUnit(muData);
};

export const createAPFCDevice = (apfcData: ApfcModel) => {
  return api.deviceMetadata.apfc.createApfc(apfcData);
};

export const createPOSDevice = (posData: PosModel) => {
  return api.deviceMetadata.pos.createPos(posData);
};

export const createOCPPDevice = (ocppData: OcppChargerMetadataModel) => {
  return api.ocppChargersMetadata.ocpp.createOcppCharger(ocppData);
};

export const getNextDeviceIdApi = async (deviceType: AbstractIoTDeviceType) => {
  if (deviceType === 'MU') {
    const response = await api.deviceMetadata.mu.getNextDeviceIds();
    return response.data;
  } else if (deviceType === 'VU') {
    const response = await api.deviceMetadata.vu.getNextDeviceIds();
    return response.data;
  } else if (deviceType === 'APFC') {
    const response = await api.deviceMetadata.apfc.getNextDeviceIds();
    return response.data;
  } else if (deviceType === 'POS') {
    const response = await api.deviceMetadata.pos.getNextDeviceIds();
    return response.data;
  } else {
    console.error('getNextDeviceIds Error: Not Supported for This Device Type yet');
    return null;
  }
};

export const checkDeviceName = async (
  name: string,
  projectId: number,
  deviceType: AbstractIoTDeviceType
) => {
  if (deviceType === 'MU') {
    const response = await api.deviceMetadata.mu.isDeviceNameFree(name, projectId);
    return response.data;
  } else if (deviceType === 'VU') {
    const response = await api.deviceMetadata.vu.isDeviceNameFree(name, projectId);
    return response.data;
  } else if (deviceType === 'APFC') {
    const response = await api.deviceMetadata.apfc.isDeviceNameFree(name, projectId);
    return response.data;
  } else if (deviceType === 'POS') {
    const response = await api.deviceMetadata.pos.isDeviceNameFree(name, projectId);
    return response.data;
  } else if (deviceType === 'OCPP') {
    const response = await api.ocppChargersMetadata.ocpp.isOcppChargerNameFree(projectId, name);
    return response.data;
  } else {
    console.error('isDeviceNameFree Error: Not Supported for This Device Type yet');
    return null;
  }
};

export const checkDeviceId = async (id: string, deviceType: AbstractIoTDeviceType) => {
  if (deviceType === 'MU') {
    const response = await api.deviceMetadata.mu.isDeviceIdFree(id);
    return response.data;
  } else if (deviceType === 'VU') {
    const response = await api.deviceMetadata.vu.isDeviceIdFree(id);
    return response.data;
  } else if (deviceType === 'APFC') {
    const response = await api.deviceMetadata.apfc.isDeviceIdFree(id);
    return response.data;
  } else if (deviceType === 'POS') {
    const response = await api.deviceMetadata.pos.isDeviceIdFree(id);
    return response.data;
  } else {
    console.error('checkDeviceId Error: Not Supported for This Device Type yet');
    return null;
  }
};

export const updateMUDevice = (muData: ManagementUnitModel) => {
  return api.deviceMetadata.mu.updateManagementUnit(muData);
};

export const updatePOSDevice = (posData: PosModel) => {
  return api.deviceMetadata.pos.updatePos(posData);
};

export const updateAPFCDevice = (apfcData: ApfcModel, muIds: string[]) => {
  return api.deviceMetadata.apfc.updateApfc(apfcData, muIds);
};

export const updateOCPPDevice = (ocppData: OcppChargerMetadataModel) => {
  return api.ocppChargersMetadata.ocpp.updateOcppCharger(ocppData);
};

export const assignOCPPDeviceToDepot = (depotId: number, ocppId: string, parkingSpots: ParkingSpot[]) => {
  return api.metadata.parkingSpots.createOrUpdateOcppParkingSpots(depotId, ocppId, parkingSpots);
};

export const unassignOCPPDevice = (ocppId: string) => {
  return api.metadata.parkingSpots.clearOcppParkingSpots(ocppId);
};

export const unassignMuDevice = (muId: string) => {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found, from unassignMuDevice');
    return api.metadata.parkingSpots.clearParkingSpots(muId, sessionId);
  } catch (error) {
    console.error(`Error in unassignMuDevice request: ${error}`);
    throw error;
  }
};

export const setDeviceEnergyMeterValueApi = async (deviceId: string, deviceType: string | undefined, dcSamplerIndex: number, energyValue: number) => {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found, from setDeviceEnergyMeterValueApi');
    if (deviceType === "MU") {
      return await api.deviceControl.muRemoteControl.controlEnergyUpdate(deviceId, sessionId, { id: dcSamplerIndex, energy: energyValue });
    }
    if (deviceType === "VU") {
      return await api.deviceControl.vuRemoteControl.controlEnergyUpdate(deviceId, sessionId, { id: dcSamplerIndex, energy: energyValue });
    }
  } catch (error) {
    console.error(`Error in setDeviceEnergyMeterValueApi request: ${error}`);
    throw error;
  }
}

export const getApfcMusList = async (apfcId: string) => {
  return (await api.deviceMetadata.apfc.getApfcManagementUnits(apfcId)).data;
};

export const createRoad = (roadData: Road) => {
  return api.metadata.roads.createRoad(roadData);
};

export const editRoad = (roadData: Road) => {
  return api.metadata.roads.updateRoad(roadData);
};

export const manageRoad = (roadId: number, roadSectionsToUpdate: RoadSection[]) => {
  return api.metadata.roadSections.createOrUpdateRoadSections(roadId, roadSectionsToUpdate);
};

export const checkRoadName = (projectId: number, roadName: string) => {
  return api.metadata.roads.isRoadNameFree(projectId, roadName);
};
