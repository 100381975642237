import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { CreateMuForm } from 'Components/Forms/CreateDevicePopup/CreateMuForm';
import { CreateVuForm } from 'Components/Forms/CreateDevicePopup/CreateVuForm';
import { useState } from 'react';
import { CreateAPFCForm } from './CreateAPFCForm';
import { CreatePosForm } from './CreatePOSForm';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { CreateOCPPForm } from './CreateOCPPForm';

export const CreateDevicePopup: React.FC<FormProps> = ({ selectedProject, onSuccessfulSubmit, onCancel }) => {
  const [selectedDeviceType, setSelectedDeviceType] = useState<'MU' | 'VU' | 'POS' | 'APFC' | 'OCPP'>('MU');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    deviceType?: 'MU' | 'VU' | 'POS' | 'APFC' | 'OCPP'
  ) => {
    if (!deviceType) return;
    setSelectedDeviceType(deviceType);
  };

  return (
    <>
      <ToggleButtonGroup
        color='info'
        value={selectedDeviceType}
        exclusive
        onChange={handleChange}
        aria-label='Platform'
        sx={{
          mb: '2rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(5, 1fr)',
          gridTemplateRows: '1fr',
          '& .MuiToggleButton-root.Mui-selected': {
            color: theme.palette.accent.primary[500],
          },
        }}
        defaultValue='MU'
      >
        <ToggleButton value='MU'>MU</ToggleButton>
        <ToggleButton value='VU'>VU</ToggleButton>
        <ToggleButton value='POS'>POS</ToggleButton>
        <ToggleButton value='APFC'>APFC</ToggleButton>
        <ToggleButton value='OCPP'>OCPP</ToggleButton>
      </ToggleButtonGroup>
      {selectedDeviceType === 'MU' ? (
        <CreateMuForm
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={onCancel}
          selectedProject={selectedProject}
        />
      ) : selectedDeviceType === 'VU' ? (
        <CreateVuForm
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={onCancel}
          selectedProject={selectedProject}
        />
      ) : selectedDeviceType === 'POS' ? (
        <CreatePosForm
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={onCancel}
          selectedProject={selectedProject}
        />
      ) : selectedDeviceType === 'APFC' ? (
        // APFC
        <CreateAPFCForm
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={onCancel}
          selectedProject={selectedProject}
        />
      ) : selectedDeviceType === 'OCPP' ? (
        <CreateOCPPForm
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={onCancel}
          selectedProject={selectedProject}
        />
      ) : null}
    </>
  );
};
