import React, { CSSProperties } from 'react';
import ElectreonSmallLogo from 'Utils/Images/GlobalIcons/Logo.svg';
import '../Logo/logo.scss';

type LogoProps = {
  logo: string;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  alt: string;
  onClickLogo?: () => void;
};

export const Logo: React.FC<LogoProps> = ({
  logo = ElectreonSmallLogo,
  width = '100%',
  height = '100%',
  alt = 'Electreon logo',
  onClickLogo = () => {},
}) => {
  return <img src={logo} height={height} width={width} alt={alt} />;
};
Logo.displayName = 'Logo';
