import { ListItemIcon, ListItemText, Stack } from '@mui/material';

type ImgListItemProps = {
  text: string;
  Logo: React.FC;
  onClick?: () => void;
  isNew?: boolean;
};

export const ImgListItem: React.FC<ImgListItemProps> = ({ isNew, text, Logo, onClick }) => {
  return (
    <Stack
      direction='row'
      onClick={onClick}
      sx={{
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <ListItemIcon
        sx={{
          '& svg': {
            width: '25px',
            height: '25px',
          },
        }}
      >
        <Logo />
      </ListItemIcon>
      <ListItemText
        sx={{
          '& span': { fontSize: '16px' },
        }}
      >
        <span>{text}</span>
        {isNew && <span className='text-green-main'> - New</span>}
      </ListItemText>
    </Stack>
  );
};
ImgListItem.displayName = 'ImgListItem';
