import {
  VehicleUnitModel,
  ManagementUnitModel,
} from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { api } from 'Services/api';
import { debounce } from 'lodash';
import { BasicDeviceFotaOptions, checkFotaVersion } from 'Components/Forms/DeviceFotaForm/DeviceFotaUtils';
import {
  ManagementUnitV2FpgaFotaType,
  SomFOTAType,
} from '@electreon/electreon-device-control-service-gen-ts-client';
import { ValuesOf } from '@electreon_ui/shared/types/globals';

export const debouncedCheckFotaVersion = debounce(
  ({
    version,
    deviceType,
    firmwareType,
    firmwareSubType,
    setFotaVersionExists,
  }: {
    version: string;
    deviceType: ManagementUnitModel['deviceType'] | VehicleUnitModel['deviceType'];
    firmwareType: BasicDeviceFotaOptions;
    firmwareSubType?: ManagementUnitV2FpgaFotaType | SomFOTAType;
    setFotaVersionExists: (value: boolean) => void;
  }) => {
    checkFotaVersion(version, deviceType, firmwareType, firmwareSubType).then((res) => {
      setFotaVersionExists(res.data);
      // console.table({
      //   version,
      //   deviceType,
      //   firmwareType,
      //   firmwareSubType,
      //   setFotaVersionExists: res.data,
      // });
    });
  },
  800
);

export const FirmwareOptionName = {
  COMM_BOARD: 'Comm Board',
  DC_SAMPLER: 'DC Sampler',
  FANS_BOARD: 'Fans Board',
  FPGA_SD_CARD_IMAGE: 'FPGA SD Card Image',
} as const;

export const FirmwareOptionToApiMap: Record<
  ValuesOf<typeof FirmwareOptionName>,
  {
    fnName: keyof typeof api.deviceControl.muFotaControl;
    fotaType: BasicDeviceFotaOptions;
    fotaSubType?: ManagementUnitV2FpgaFotaType | SomFOTAType;
    indirectDownload?: boolean;
    fileName?: string;
  }
> = {
  [FirmwareOptionName.COMM_BOARD]: {
    fnName: 'downloadCommBoardFirmware',
    fotaType: BasicDeviceFotaOptions.COMM_BOARD_FOTA,
    fileName: 'Comm_Board_App_with_bootloader_%s.bin',
  },
  [FirmwareOptionName.DC_SAMPLER]: {
    fnName: 'downloadDcSamplerFirmware',
    fotaType: BasicDeviceFotaOptions.DCSAMPLERS_FOTA,
    fileName: 'DC_Sampler_App_with_bootloader_%s.bin',
  },
  [FirmwareOptionName.FANS_BOARD]: {
    fnName: 'downloadFansBoardFirmware',
    fotaType: BasicDeviceFotaOptions.FANS_BOARD_FOTA,
    fileName: 'Fans_Board_App_with_bootloader_%s.bin',
  },
  [FirmwareOptionName.FPGA_SD_CARD_IMAGE]: {
    fnName: 'downloadFpgaSdCardImage',
    fotaType: BasicDeviceFotaOptions.FPGA_SD_CARD_IMAGE,
    fotaSubType: ManagementUnitV2FpgaFotaType.UserSpaceLinuxApp,
    indirectDownload: true,
    fileName: 'sdimage_fpga_%s.img',
  },
};
