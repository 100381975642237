import { GlobalConfig } from '@electreon_ui/shared/config/globalConfig';
import { BaseUrl, EnvType } from '@electreon_ui/shared/types/globals';

// aws-blue (new deployment) or aws-green (stable deployment)
export const DASHBOARD_CURRENT_DEPLOYMENT = 'aws-blue';

// local socket port
const localPort = 8868;
// set http request to local server
const USE_LOCAL_REQUESTS = false;
// listen to all socket messages (socket.onAny)
export const LISTEN_TO_ALL_SOCKET_MESSAGES = false;

export const USE_LOCAL_SOCKET = import.meta.env.VITE_USE_LOCAL_SOCKET === 'true';

export const { env } = {
  get env(): EnvType {
    if (import.meta.env.VITE_ENV_NAME === EnvType.Dev) return EnvType.Dev;
    if (import.meta.env.VITE_ENV_NAME === EnvType.Stage) return EnvType.Stage;
    if (import.meta.env.VITE_ENV_NAME === EnvType.Prod) return EnvType.Prod;
    throw new Error('Invalid env value');
  },
};

export const { baseUrl } = {
  get baseUrl(): BaseUrl {
    if (env === EnvType.Dev) return 'https://backend.dev.electreon.com';
    if (env === EnvType.Stage) return 'https://backend.stage.electreon.com';
    if (env === EnvType.Prod) return 'https://backend.app.electreon.com';
    throw new Error('Invalid baseUrl value');
  },
};

export const isLocalDevEnv = Boolean(import.meta.env.VITE_LOCAL_DEV);

const localDomain =
  import.meta.env.VITE_RUN_LOCAL === 'true' && USE_LOCAL_REQUESTS ? `http://localhost:${localPort}` : '';

export const apiCallDomain = localDomain || baseUrl;

export const globalConfig = new GlobalConfig({
  env,
  isLocalDevEnv,
  cognitoClientId: import.meta.env.VITE_APP_CLIENT_ID,
  cognitoClientSecret: import.meta.env.VITE_CLIENT_SECRET,
  cognitoUserPool: import.meta.env.VITE_USER_POOL_ID,
  region: import.meta.env.VITE_REGION,
});
