import { Button, Pagination } from '@mui/material';
import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPaginationModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { useAppStore } from 'MobxStores/context';
import { observer } from 'mobx-react-lite';

interface CustomFooterProps {
  projectId?: number;
  selectedTimeRange?: number;
  rowsNumber: number;
}

export const CustomFooter: React.FC<CustomFooterProps> = observer(
  ({ projectId, selectedTimeRange, rowsNumber }) => {
    const { alertStore } = useAppStore();
    const apiRef = useGridApiContext();
    const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <GridFooterContainer sx={{ justifyContent: 'center', position: 'relative' }}>
        <Button onClick={() => alertStore.getMoreAlerts(projectId, selectedTimeRange)}>Load More</Button>
        <span> Total Rows: {rowsNumber}</span>
        <Pagination
          count={pageCount}
          page={paginationModel.page + 1}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </GridFooterContainer>
    );
  }
);
