import { ElectreonApiServices } from '@electreon_ui/shared/services/ElectreonApiServices';
import axios from 'axios';
import { apiCallDomain } from 'config';
import { createContext, useContext } from 'react';
import { RootStore } from './RootStore';

export const api = new ElectreonApiServices(apiCallDomain, axios);

export const rootStore = new RootStore(api);

export const AppContext = createContext(rootStore);

export const useAppStore = () => useContext(AppContext);
