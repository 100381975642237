import { TextField } from '@mui/material';
import { FieldErrors } from 'react-hook-form';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { CreateParkingSpotsInput } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotSchema';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';

export function SelectProject(props: {
  selectedProject?: Project | null;
  errors?: FieldErrors<CreateParkingSpotsInput>;
  valueOverride?: string;
}) {
  const { selectedProject, errors, valueOverride } = props;

  return (
    <TextField
      label='Project'
      fullWidth
      sx={{ ...disabledTextFieldStyle, mb: 2 }}
      disabled
      helperText={errors?.['projectName'] ? errors?.['projectName'].message : ''}
      value={valueOverride || selectedProject?.name}
      InputLabelProps={{ shrink: true }}
    />
  );
}
