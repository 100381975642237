import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppStore } from 'MobxStores/context';
import { getProject } from 'Utils/APIUtils';

export const useHeaderData = () => {
  const { projectStore } = useAppStore();
  const { selectedProject } = projectStore;
  const projectId = useParams().projectId;

  useEffect(() => {
    if (projectId && !selectedProject) {
      getProject(projectId)
        .then((res) => {
          projectStore.setSelectedProject(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [projectId, projectStore, selectedProject]);
};
