import { Alert, Box, Container, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { createAPFCDevice } from 'Components/Forms/FormsAPIs/api';
import { useInputAvailability } from 'Components/Forms/CreateDevicePopup/utils/useInputAvailability';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { prefixStyles } from './utils/InputStyles';
import { getNextAPFCId } from './utils/CreateDeviceUtils';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';

const createAPFCSchema = object({
  deviceName: string()
    .min(1, 'Device name is required')
    .refine((value) => {
      return value.length >= 4 && value.length <= 20;
    }, 'Device name must be between 4 and 20 characters')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device name must not include spaces'),
  deviceId: string()
    .min(1, 'Device ID is required')
    .refine((value) => {
      return String(value).length === 7;
    }, 'Device ID must be 7 characters long')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Device ID must not include spaces'),
  projectName: string().optional(),
  prefix: string().min(1, 'Prefix is required'),
  longitude: string()
    .min(1, 'Longitude is required')
    .refine((value) => {
      const longitude = parseFloat(value);
      return longitude >= -180 && longitude <= 180;
    }, 'Longitude must be between -180 and 180')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Longitude must not include spaces'),
  latitude: string()
    .min(1, 'Latitude is required')
    .refine((value) => {
      const latitude = parseFloat(value);
      return latitude >= -90 && latitude <= 90;
    }, 'Latitude must be between -90 and 90')
    .refine((value) => {
      return !value.includes(' ');
    }, 'Latitude must not include spaces'),
});

const createAPFCDefaultValues = {
  deviceName: '',
  deviceId: '',
  projectName: '',
  prefix: 'AP',
  longitude: '',
  latitude: '',
};

type CreateAPFCInput = TypeOf<typeof createAPFCSchema>;

export const CreateAPFCForm: React.FC<FormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const {
      register,
      formState: { errors /* isSubmitSuccessful */ },
      watch,
      reset,
      setError,
      clearErrors,
      handleSubmit,
    } = useForm<CreateAPFCInput>({
      resolver: zodResolver(createAPFCSchema),
      defaultValues: createAPFCDefaultValues,
    });

    const { deviceNameAvaliable, deviceIDAvaliable } = useInputAvailability(
      watch,
      selectedProject?.id,
      setError,
      clearErrors,
      'APFC'
    );

    useEffect(() => {
      if (!selectedProject) return;
      getNextAPFCId()
        .then((nextID) => {
          reset({ ...watch(), deviceId: String(nextID || '') });
        })
        .catch((error) => {
          console.error(
            `Failed to get next APFC ID for project ${selectedProject?.id}`,
            JSON.stringify(error)
          );
        });
    }, [selectedProject, reset, watch]);

    const onSubmitHandler: SubmitHandler<CreateAPFCInput> = (values) => {
      console.log('submitting');
      setSubmitError(null);
      const { deviceName, deviceId, prefix, latitude, longitude } = values;
      setLoading(true);
      createAPFCDevice({
        name: deviceName,
        projectId: selectedProject?.id as number,
        deviceType: 'APFC',
        deviceSubType: 'SIMATIC_S7_1200',
        id: `${prefix}${deviceId}`,
        latitude: Number(latitude),
        longitude: Number(longitude),
        timezone: selectedProject?.timezoneStr as string,
      })
        .then((response) => {
          if (response.status !== 201) {
            toast.error('APFC device creation failed');
            setSubmitError('APFC device creation failed');
            throw new Error('Failed to create APFC device');
          }
          toast.success('APFC device created');
          if (response.status === 201) {
            reset(createAPFCDefaultValues);
            onSuccessfulSubmit?.();
          }
        })
        .catch((error) => {
          toast.error('APFC device creation failed');
          console.error('Failed to create APFC device', JSON.stringify(error));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Container disableGutters>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Create APFC Unit
        </Typography>

        <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
          <SelectProject selectedProject={selectedProject} errors={errors} />
          <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
            <TextField disabled sx={prefixStyles} {...register('prefix')} />
            <TextField
              sx={{ flex: 8 }}
              InputLabelProps={{ shrink: !!watch('deviceId') }}
              label='Device ID'
              fullWidth
              error={!!errors['deviceId']}
              helperText={errors['deviceId'] ? errors['deviceId'].message : ''}
              type='number'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <InputAdornmentIcon isValid={deviceIDAvaliable} />
                  </InputAdornment>
                ),
              }}
              {...register('deviceId')}
            />
          </Stack>
          <TextField
            sx={{ mb: 2 }}
            label='Device Name'
            fullWidth
            type='deviceName'
            error={!!errors['deviceName']}
            helperText={errors['deviceName'] ? errors['deviceName'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={deviceNameAvaliable} />
                </InputAdornment>
              ),
            }}
            {...register('deviceName')}
          />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...register('longitude')}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...register('latitude')}
            />
          </Stack>
          {/* <AssociatedMuIdsSelection register={register} disabled={isFormDisabled} /> */}
          <TextField
            label='Device Subtype'
            fullWidth
            sx={{ ...disabledTextFieldStyle, mb: 2 }}
            disabled
            value={'SIMATIC_S7_1200'}
            InputLabelProps={{ shrink: true }}
          />
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Create Device' />
        </Box>
      </Container>
    );
  }
);
