import Italy_project from 'Utils/Images/LoginBackground/Italy_project.png';
import Sweden_project from 'Utils/Images/LoginBackground/Sweden.png';
import Israel_project from 'Utils/Images/LoginBackground/Israel.png';
import usu1 from 'Utils/Images/LoginBackground/usu1.png';
import usu2 from 'Utils/Images/LoginBackground/usu2.png';
import afikim from 'Utils/Images/LoginBackground/afikim.jpg';

const randImage = [
  [Italy_project, 'Arena del Futuro, Italy'],
  [Sweden_project, 'Electrec Road System, Sweden'],
  [Israel_project, 'Electreon R&D, Israel'],
  [usu1, 'USU, Track'],
  [usu2, 'USU, Track'],
  [afikim, 'Rosh Haayin, Israel'],
];

export function getRandomImageData() {
  const chosenFile = randImage[Math.floor(Math.random() * randImage.length)];

  return chosenFile;
}
