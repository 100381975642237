import { IndexableManagementUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureMuShadowForm';
import { IndexableVehicleUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureVuShadowForm';
import _ from 'lodash';
import { FieldErrors, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { z } from 'zod';

export type FieldDefinition = {
  properties?: Record<string, FieldDefinition>;
  items?: FieldDefinition;
  type?: 'string' | 'number' | 'boolean' | 'array' | 'object' | 'integer';
  format?: string;
  minimum?: number;
  maximum?: number;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  enum?: string[];
  required?: string;
};

export const hasFullPathError = (errors: FieldErrors, fullPath: string) => {
  return _.get(errors, fullPath) !== undefined;
};

export const filterValues = (values: any, dirtyFields?: any) => {
  let filteredValues: Record<string, any> = {};

  for (const [key, value] of Object.entries(values)) {
    if (typeof value === 'object') {
      const filteredNestedValues = filterValues(value, dirtyFields?.[key]);
      if (Object.keys(filteredNestedValues).length > 0) {
        filteredValues[key] = filteredNestedValues;
      }
    } else if (dirtyFields?.[key]) {
      filteredValues[key] = value;
    }
  }

  return filteredValues;
};

export const getFieldDefinition = (swaggerSchema: Record<string, any>, propertyName: string) => {
  const propertyPath = _.findKey(swaggerSchema, (value, key) => {
    if (_.isObject(value) && _.has(value, 'properties')) {
      return _.has((value as any).properties, propertyName);
    }
  });
  if (!propertyPath) return null;
  const fieldDefinition: FieldDefinition = _.get(swaggerSchema, `${propertyPath}.properties.${propertyName}`);
  return fieldDefinition;
};

export const validateInput = (
  setError: UseFormSetError<any>,
  swaggerSchema: Record<string, any>,
  clearErrors: UseFormClearErrors<IndexableManagementUnitConfiguration | IndexableVehicleUnitConfiguration>
): ((event: any, fullPath?: boolean) => void) | undefined => {
  return (e: React.ChangeEvent<HTMLInputElement>, fullPath?: boolean) => {
    const leafName = e.target.name.split('.').pop();
    const propertyName = fullPath ? e.target.name : leafName;
    if (!propertyName || !leafName) return;
    clearErrors(propertyName);
    const fieldDefinition = getFieldDefinition(swaggerSchema, leafName);
    if (!fieldDefinition) {
      console.log('Field definition not found');
      return;
    }

    const isNumeric = fieldDefinition.type === 'number' || fieldDefinition.type === 'integer';
    const isMinLimit = fieldDefinition.minimum !== undefined;
    const isMaxLimit = fieldDefinition.maximum !== undefined;
    if (isNumeric) {
      const currentValueAsNumber = Number(e.target.value);

      const schema = z.object({
        [propertyName]: z.number(),
      });
      try {
        schema.parse({ [propertyName]: currentValueAsNumber });
      } catch (error) {
        return setError(propertyName, {
          type: 'manual',
          message: 'Field must be a number',
        });
      }

      if (isMinLimit || isMaxLimit) {
        const isRange = isMinLimit && isMaxLimit;
        if (isRange) {
          const schema = z.object({
            [propertyName]: z.number().min(fieldDefinition.minimum!).max(fieldDefinition.maximum!),
          });
          try {
            schema.parse({ [propertyName]: currentValueAsNumber });
          } catch (error) {
            return setError(propertyName, {
              type: 'manual',
              message: `Field must be between ${fieldDefinition.minimum} and ${fieldDefinition.maximum}`,
            });
          }
        } else if (isMinLimit) {
          const schema = z.object({
            [propertyName]: z.number().min(fieldDefinition.minimum!),
          });
          try {
            schema.parse({ [propertyName]: currentValueAsNumber });
          } catch (error) {
            return setError(propertyName, {
              type: 'manual',
              message: `Field must be greater than ${fieldDefinition.minimum}`,
            });
          }
        } else if (isMaxLimit) {
          const schema = z.object({
            [propertyName]: z.number().max(fieldDefinition.maximum!),
          });
          try {
            schema.parse({ [propertyName]: currentValueAsNumber });
          } catch (error) {
            return setError(propertyName, {
              type: 'manual',
              message: `Field must be less than ${fieldDefinition.maximum}`,
            });
          }
        }
      }
    }
  };
};
