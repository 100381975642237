import { UserRole } from '@electreon_ui/shared/constants/constants';
import { isoCountries } from 'Utils/DefaultValues/countries';

export const getUsernameAbbreviation = (givenName?: string, familyName?: string) => {
  const firstNameLetter = givenName?.charAt(0) || '';
  const familyNameLetter = familyName?.charAt(0) || '';
  return `${firstNameLetter}${familyNameLetter}`;
};

export const getUserFullname = (givenName?: string, familyName?: string) => {
  const firstName = givenName || '';
  const lastName = familyName || '';
  return `${firstName} ${lastName}`;
};

export const getCountryCode = (code?: string) => {
  switch (code) {
    case 'IST':
      return 'IL';
    case 'UT':
      return 'US';
    default:
      return code || '';
  }
};

export const getFormattedUserRole = (role?: UserRole) => {
  switch (role) {
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.PROJECT_USER:
      return 'Project User';
    case UserRole.SUPER_ADMIN:
      return 'Super Admin';
    case UserRole.INTEGRATOR:
      return 'Integrator';
    case UserRole.OPERATOR:
      return 'Operator';
    case UserRole.PROJECT_VIEWER:
      return 'Project Viewer';
    default:
      console.error('Unknown user role');
      return 'Unknown Role';
  }
};

export const getAlpha2Code = (countryCode: string) => {
  const isAlpha2Code = countryCode.length === 2;
  const isAlpha3Code = countryCode.length === 3;
  const isInValidCode = !isAlpha2Code && !isAlpha3Code;

  if (isInValidCode) return countryCode;
  if (isAlpha2Code) return countryCode;
  else if (isAlpha3Code) {
    const country = isoCountries.find((country) => country.alpha3 === countryCode);
    if (!country) return countryCode;
    return country.alpha2;
  }
};
