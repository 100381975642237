import { UiDeviceType } from '@electreon_ui/shared/types/globals';
import { checkDeviceName } from 'Components/Forms/FormsAPIs/api';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useDeviceNameAvailability = (
  currentDeviceName: string,
  deviceName: string,
  projectId: number | undefined,
  deviceType: UiDeviceType
) => {
  const [deviceNameAvaliable, setDeviceNameAvaliable] = useState<boolean>(true);
  const debouncedCheckDeviceName = debounce(
    (deviceName: string | undefined, selectedProjectId: number | undefined, deviceType: UiDeviceType) => {
      if (currentDeviceName === deviceName) {
        setDeviceNameAvaliable(true);
        return;
      }
      if (!deviceName) {
        setDeviceNameAvaliable(false);
        return;
      }
      checkDeviceName(deviceName, selectedProjectId || 0, deviceType).then((res) => {
        setDeviceNameAvaliable(!!res);
      });
    },
    500
  );
  useEffect(() => {
    debouncedCheckDeviceName(deviceName, projectId, deviceType);
  }, [deviceName, projectId, debouncedCheckDeviceName, deviceType]);

  return { deviceNameAvaliable };
};
