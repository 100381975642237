export const ErrorMessage = {
  noError: '',
  wrongCredentials: 'username or password is incorrect',
  loginAttemptFailed: 'something went wrong; please try again later',
  passwordConfirmError: 'The passwords you entered do not match',
  missingUppercaseLetter: 'The password must contain at least one uppercase letter (A-Z).',
  missingLowercaseLetter: 'The password must contain at least one lowercase letter (a-z).',
  missingNumber: 'The password must contain at least one number.',
  missingNonAlphanumeric: 'The password must contain at least one non-alphanumeric character.',
  tooShort: 'The password must be at least 10 characters.',
};
