import { makeAutoObservable } from 'mobx';

class HeaderStore {
  searchMenuOpen = false;
  isControlPanelMenuOpen = false;
  isUserMenuOpen = false;
  navigationMenuOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSearchMenuOpen = (isOpen: boolean) => (this.searchMenuOpen = isOpen);
  setControlPanelMenuOpen = (isOpen: boolean) => (this.isControlPanelMenuOpen = isOpen);
  setUserMenuOpen = (isOpen: boolean) => (this.isUserMenuOpen = isOpen);
  setNavigationMenuOpen = (isOpen: boolean) => (this.navigationMenuOpen = isOpen);

  closeAllMenus = () => {
    this.setControlPanelMenuOpen(false);
    this.setUserMenuOpen(false);
    this.setNavigationMenuOpen(false);
  };
}

export const headerStore = new HeaderStore();
