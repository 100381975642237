import { makeAutoObservable } from 'mobx';
import { DashoardSelectedVehicleIds, LayoutType } from 'MobxStores/Types';

export class DashboardConfigurationStore {
  selectedVehicleId: DashoardSelectedVehicleIds = {
    first: undefined,
    second: undefined,
  };
  layoutType: LayoutType = 'singleVehicle';
  activeVehicles: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedVehicleId = ({ first, second }: DashoardSelectedVehicleIds) => {
    this.selectedVehicleId.first = first;
    this.selectedVehicleId.second = second;
  };

  setLayoutType = (layoutType: LayoutType) => {
    this.layoutType = layoutType;
  };

  clearStore = () => {
    this.layoutType = 'singleVehicle';
    this.selectedVehicleId = { first: undefined, second: undefined };
    this.activeVehicles = [];
  };
}