import { ChangeAvailabilityRequestV16 } from '@cshil/ocpp-tools/types/v16';
import { OCPPMeterValuesMessage } from '../types/ocppTypes';
import {
  isValidChangeAvailabilityResponseV16,
  isValidMeterValuesRequestV16,
  isValidStopTransactionResponseV16,
} from '@cshil/ocpp-tools/validation/v16';
import { isValidMeterValuesRequestV201 } from '@cshil/ocpp-tools/validation/v201';
import { OcppCommandRequest } from '@electreon/electreon-ocpp-control-service-gen-ts-client';
import { ElectreonApiServices } from '../services/ElectreonApiServices';
import { OcppChargerModelRefined } from '../types/globals';
import * as OCPPV16 from '@cshil/ocpp-tools/types/v16';
import * as OCPPV201 from '@cshil/ocpp-tools/types/v201';

export const parseOCPPMeterMesage = (message: OCPPMeterValuesMessage) => {
  const ocppMessage = message.data;

  if (isValidMeterValuesRequestV16(ocppMessage)) {
    const meterValuesMap = new Map(
      ocppMessage.meterValue[0].sampledValue.map((sampledValues) => [
        sampledValues.measurand,
        {
          value: sampledValues.value,
          unit: sampledValues.unit,
          context: sampledValues.context,
          location: sampledValues.location,
        },
      ])
    );

    return {
      connectorId: ocppMessage.connectorId,
      updateTime: ocppMessage.meterValue[0].timestamp,
      chargerVoltage: meterValuesMap.get('Voltage'),
      chargerTemperature: meterValuesMap.get('Temperature'),
      evSoc: meterValuesMap.get('SoC'),
      currentToEv: meterValuesMap.get('Current.Import'),
      powerToEv: meterValuesMap.get('Power.Active.Import'),
    };
  }

  if (isValidMeterValuesRequestV201(ocppMessage)) {
    const meterValuesMap = new Map(
      ocppMessage.meterValue[0].sampledValue.map((sampledValues) => [
        sampledValues.measurand,
        {
          value: sampledValues.value,
          unit: sampledValues.unitOfMeasure?.unit,
          context: sampledValues.context,
          location: sampledValues.location,
        },
      ])
    );

    return {
      connectorId: ocppMessage.evseId,
      updateTime: ocppMessage.meterValue[0].timestamp,
      chargerVoltage: meterValuesMap.get('Voltage'),
      chargerTemperature: undefined,
      evSoc: meterValuesMap.get('SoC'),
      currentToEv: meterValuesMap.get('Current.Import'),
      powerToEv: meterValuesMap.get('Power.Active.Import'),
    };
  }

  console.error(`Invalid meter values message in ${message.properties.deviceId}`);
};

export type ParsedMeterValues = ReturnType<typeof parseOCPPMeterMesage>;

export const toggleOcppConnector = ({
  action,
  api,
  connectorId,
  device,
}: {
  action: 'disable' | 'enable' | null;
  device: OcppChargerModelRefined;
  api: ElectreonApiServices;
  connectorId?: number;
}) => {
  if (!connectorId) throw new Error('Connector ID not found');
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const changeAvailabilityPayload = {
    connectorId: connectorId,
    type: action === 'disable' ? 'Inoperative' : 'Operative',
  } satisfies ChangeAvailabilityRequestV16;

  const request = {
    messageType: 'ChangeAvailability',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: changeAvailabilityPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const triggerBootNotification = ({
  api,
  device,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const ocppProtocolVersion = device.ocppProtocolVersion;

  const getBootNotificationPayload = (device: OcppChargerModelRefined) => {
    if (ocppProtocolVersion === 'ocpp1.6') {
      return {
        requestedMessage: 'BootNotification',
      } satisfies OCPPV16.TriggerMessageRequestV16;
    } else if (ocppProtocolVersion === 'ocpp2.0.1') {
      return {
        requestedMessage: 'BootNotification',
      } satisfies OCPPV201.TriggerMessageRequestV201;
    } else throw new Error('Invalid OCPP protocol version');
  };

  const triggerBootNotificationPayload = getBootNotificationPayload(device);

  const request = {
    messageType: 'TriggerMessage',
    ocppProtocolVersion,
    payload: triggerBootNotificationPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const triggerHeartbeat = ({
  api,
  device,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const getHeartbeatPayload = (device: OcppChargerModelRefined) => {
    if (device.ocppProtocolVersion === 'ocpp1.6') {
      return {
        requestedMessage: 'Heartbeat',
      } satisfies OCPPV16.TriggerMessageRequestV16;
    } else if (device.ocppProtocolVersion === 'ocpp2.0.1') {
      return {
        requestedMessage: 'Heartbeat',
      } satisfies OCPPV201.TriggerMessageRequestV201;
    } else throw new Error('Invalid OCPP protocol version');
  };

  const triggerHeartbeatPayload = getHeartbeatPayload(device);

  const request = {
    messageType: 'TriggerMessage',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: triggerHeartbeatPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const triggerMeterValues = ({
  api,
  device,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const getMeterValuesPayload = (device: OcppChargerModelRefined) => {
    if (device.ocppProtocolVersion === 'ocpp1.6') {
      return {
        requestedMessage: 'MeterValues',
      } satisfies OCPPV16.TriggerMessageRequestV16;
    } else if (device.ocppProtocolVersion === 'ocpp2.0.1') {
      return {
        requestedMessage: 'MeterValues',
      } satisfies OCPPV201.TriggerMessageRequestV201;
    } else throw new Error('Invalid OCPP protocol version');
  };

  const triggerMeterValuesPayload = getMeterValuesPayload(device);

  const request = {
    messageType: 'TriggerMessage',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: triggerMeterValuesPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const triggerStatusNotification = ({
  api,
  device,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const getStatusNotificationPayload = (device: OcppChargerModelRefined) => {
    if (device.ocppProtocolVersion === 'ocpp1.6') {
      return {
        requestedMessage: 'StatusNotification',
      } satisfies OCPPV16.TriggerMessageRequestV16;
    } else if (device.ocppProtocolVersion === 'ocpp2.0.1') {
      return {
        requestedMessage: 'StatusNotification',
      } satisfies OCPPV201.TriggerMessageRequestV201;
    } else throw new Error('Invalid OCPP protocol version');
  };

  const triggerStatusNotificationPayload = getStatusNotificationPayload(device);

  const request = {
    messageType: 'TriggerMessage',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: triggerStatusNotificationPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const getConnectorStateAndPossibleAction = (
  connectorStatus?: OCPPV16.StatusNotificationRequestV16
): {
  status: OCPPV16.StatusNotificationRequestV16['status'];
  isDisabled: boolean;
  nextAction: 'enable' | 'disable' | null;
  error?: string;
} => {
  const { status, errorCode, vendorErrorCode } = connectorStatus || {};
  switch (status) {
    case 'Available':
    case 'Charging':
    case 'SuspendedEV':
    case 'SuspendedEVSE':
    case 'Finishing':
    case 'Reserved':
    case 'Preparing':
      return { isDisabled: false, nextAction: 'disable', status };
    case 'Unavailable':
      return { isDisabled: true, nextAction: 'enable', status };
    case 'Faulted':
      return { isDisabled: true, nextAction: null, error: `${errorCode} (${vendorErrorCode})`, status };
    default:
      return { isDisabled: true, nextAction: null, status: 'Preparing' };
  }
};

/** connectorId is the last 3 digits of the transactionId (custom logic for Electreon's OCPP implementation) */
export const extractConnectorIdFromStopTransaction = (
  stopTransactionMessage: OCPPV16.StopTransactionRequestV16
) => {
  const MAX_CONNECTOR = 1000;
  const connectorId = stopTransactionMessage.transactionId % MAX_CONNECTOR;
  const isValidConnectorId = connectorId >= 0 && connectorId < MAX_CONNECTOR;

  if (!isValidConnectorId) {
    console.error(`Invalid transactionId: ${stopTransactionMessage.transactionId}`);
    return null;
  } else {
    return connectorId;
  }
};

export const sendResetRequest = ({
  api,
  device,
  type,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
  type: 'Hard' | 'Soft';
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const getResetPayload = (device: OcppChargerModelRefined) => {
    if (device.ocppProtocolVersion === 'ocpp1.6') {
      return {
        type,
      } satisfies OCPPV16.ResetRequestV16;
    } else if (device.ocppProtocolVersion === 'ocpp2.0.1') {
      return {
        type: type === 'Hard' ? 'Immediate' : 'OnIdle',
      } satisfies OCPPV201.ResetRequestV201;
    } else throw new Error('Invalid OCPP protocol version');
  };

  const resetPayload = getResetPayload(device);

  const request = {
    messageType: 'Reset',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: resetPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const sendRemoteStopTransaction = ({
  api,
  device,
  transactionId,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
  transactionId: number;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const remoteStopTransactionPayload = {
    transactionId,
  } satisfies OCPPV16.RemoteStopTransactionRequestV16;

  const request = {
    messageType: 'RemoteStopTransaction',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: remoteStopTransactionPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

export const sendChangeConfigurationRequest = ({
  api,
  device,
  key,
  value,
}: {
  api: ElectreonApiServices;
  device: OcppChargerModelRefined;
  key: string;
  value: string;
}) => {
  if (!device.ocppProtocolVersion) throw new Error('OCPP protocol version not found');
  if (!device.id) throw new Error('Device ID not found');

  const changeConfigurationPayload = {
    key,
    value,
  } satisfies OCPPV16.ChangeConfigurationRequestV16;

  const request = {
    messageType: 'ChangeConfiguration',
    ocppProtocolVersion: device.ocppProtocolVersion,
    payload: changeConfigurationPayload,
  } satisfies OcppCommandRequest;

  return api.ocppControl.sendOcppCommand(device.id, request);
};

/**
 * Handles the change availability request for an OCPP charger connector.
 *
 * This function sends a request to change the availability status of a specified OCPP charger connector.
 * It processes the response to determine if the request was accepted, rejected, or scheduled. In case of
 * a scheduled response, it attempts to stop the transaction via RemoteStopTransaction request.
 *
 * @description
 * The function performs the following steps:
 * 1. Sends a toggle request to change the connector's availability.
 * 2. Checks the validity of the response payload.
 * 3. Depending on the response status:
 *    - If rejected, throws an error.
 *    - If accepted, resolves the promise.
 *    - If it is a disable request and the response is "scheduled", checks if a transaction is in progress:
 *      - If no transaction is found, throws an error.
 *      - If a transaction is found, sends a stop transaction request and validates the response.
 *    - If it is an enable request and the response is "scheduled", resolves the promise with a message.
 */
export const handleChangeAvailabilityRequest = async ({
  device,
  connectorId,
  action,
  api,
  isInTransaction,
  transactionId,
}: {
  device: OcppChargerModelRefined;
  connectorId: number;
  action: 'disable' | 'enable' | null;
  api: ElectreonApiServices;
  isInTransaction: boolean;
  transactionId?: number;
}): Promise<{ message?: string }> => {
  if (!device.id) throw new Error('Device ID not found for OCPP charger');
  const {
    data: { responsePayload, responseError },
  } = await toggleOcppConnector({ device, connectorId, action, api });

  if (!isValidChangeAvailabilityResponseV16(responsePayload)) throw new Error('Invalid response payload');

  switch (responsePayload.status) {
    case 'Accepted':
      return {};
    case 'Rejected':
      throw new Error('Request rejected', { cause: { responseError, responsePayload } });
    case 'Scheduled':
      if (action === 'disable') {
        if (!isInTransaction)
          throw new Error('Received a scheduled response but no transaction is in progress');
        if (!transactionId) throw new Error('Received a scheduled response but no transaction id found');

        const {
          data: { responsePayload, responseError },
        } = await sendRemoteStopTransaction({ device, transactionId, api });
        const isValid = isValidStopTransactionResponseV16(responsePayload);
        if (!isValid)
          throw new Error(
            'Invalid response payload for stop transaction request, expected StopTransactionResponseV16'
          );
        if (responseError)
          throw new Error('Error response received for stop transaction request', { cause: responseError });

        return {};
      } else if (action === 'enable') {
        return { message: `Enable request for connector ${connectorId} is scheduled` };
      } else {
        throw new Error('Unexpected response status');
      }
    default:
      throw new Error('Unexpected response status');
  }
};
