import * as OCMS from '@electreon/electreon-ocpp-device-metadata-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class OcppChargersMetadataService {
  public ocpp: OCMS.OcppChargersControllerApi;

  constructor(basePath: string, axios: AxiosInstance, config?: OCMS.Configuration) {
    this.ocpp = new OCMS.OcppChargersControllerApi(config, basePath, axios);
  }
}
