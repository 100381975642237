import { DecodedTokens } from '../stores/authStore/authStoreTypes';
import { AppNames } from '../types/globals';

export enum WindowEvents {
  SHOULD_UPDATE_DEPLOYMENT = 'SHOULD_UPDATE_DEPLOYMENT',
  STALE_MESSAGE = 'STALE_MESSAGE',
  LOG_EXCEPTION = 'LOG_EXCEPTION',
  ANNOUNCE_APP_VERSION = 'ANNOUNCE_APP_VERSION',
  SHOULD_UPDATE_FLEETS_DEPLOYMENT = 'SHOULD_UPDATE_FLEETS_DEPLOYMENT',
  REFRESH_SESSION_FAILED = 'REFRESH_SESSION_FAILED',
}

export type EventDetails = {
  [WindowEvents.SHOULD_UPDATE_DEPLOYMENT]: unknown;
  [WindowEvents.STALE_MESSAGE]: {
    messageType: string;
    deviceId?: string;
    versions: Record<string, string | number>;
  };
  [WindowEvents.LOG_EXCEPTION]: { message: string };
  [WindowEvents.ANNOUNCE_APP_VERSION]: { appName: AppNames; version: string };
  [WindowEvents.SHOULD_UPDATE_FLEETS_DEPLOYMENT]: unknown;
  [WindowEvents.REFRESH_SESSION_FAILED]: DecodedTokens | undefined;
};

function createEventListener<T>(listener: (event: CustomEvent<T>) => void): EventListener {
  return (event: Event) => {
    listener(event as CustomEvent<T>);
  };
}

export const WindowEventService = {
  emit: <T extends WindowEvents>(event: T, details: EventDetails[T]): void => {
    const customEvent = new CustomEvent(event, { detail: details });

    window.dispatchEvent(customEvent);
  },
  subscribe: <T extends WindowEvents>(
    event: T,
    listener: (event: CustomEvent<EventDetails[T]>) => void
  ): void => {
    window.addEventListener(event, createEventListener(listener));
  },
  unsubscribe: <T extends WindowEvents>(
    event: T,
    listener: (event: CustomEvent<EventDetails[T]>) => void
  ): void => {
    window.removeEventListener(event, createEventListener(listener));
  },
};
