import { observer } from 'mobx-react-lite';
import {
  Alert,
  Box,
  TextField,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  InputAdornment,
  Container,
  Button,
} from '@mui/material';
import { InputAdornmentIcon } from '../CreateDevicePopup/utils/InputAdormentIcon';
import { useState } from 'react';
import { debounce } from 'lodash';
import { BasicDeviceFotaOptions, checkFotaVersion } from 'Components/Forms/DeviceFotaForm/DeviceFotaUtils';
import { formButtonStyle } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtonsStyles';
import { useAppStore } from 'MobxStores/context';
import { getSDCardImage } from 'Utils/APIUtils';
import { AbstractIoTDeviceModelRefined, AbstractIoTDeviceType } from '@electreon_ui/shared/types/globals';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { toast } from 'sonner';

enum DownloadModes {
  'SPECIFIC' = 'SPECIFIC',
  'LATEST' = 'LATEST',
}

const debouncedCheckFotaVersion = debounce(
  ({
    version,
    deviceType,
    setFotaVersionExists,
  }: {
    version: string;
    deviceType: AbstractIoTDeviceType;
    setFotaVersionExists: (value: boolean) => void;
  }) => {
    checkFotaVersion(version, deviceType, BasicDeviceFotaOptions.SOM_FOTA, 'SOM_ROOTFS_FOTA_TYPE').then(
      (res) => {
        setFotaVersionExists(res.data);
      }
    );
  },
  800
);

export const SDCardDownloadForm: React.FC<FormProps & { device: AbstractIoTDeviceModelRefined }> = observer(
  ({ onSuccessfulSubmit, onCancel, device }) => {
    const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
    const [downloadMode, setDownloadMode] = useState<DownloadModes>(DownloadModes.LATEST);
    const [fotaVersionExists, setFotaVersionExists] = useState<boolean | null>(null);
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const { deviceStore } = useAppStore();

    const handleModeChange = (event: React.MouseEvent<HTMLElement>, mode?: DownloadModes) => {
      if (!mode) return;
      setDownloadMode(mode);
      setSelectedVersion(null);
      setFotaVersionExists(null);
    };

    const onSubmitHandler = async () => {
      setLoading(true);
      if (deviceStore.isSDCardDLInitiating || !device.id) return;
      deviceStore.setIsSDCardDLInitiating(true);
      getSDCardImage(device.id, selectedVersion, (err) => {
        deviceStore.setIsSDCardDLInitiating(false);
        console.error(err);
      })
        .then(() => {
          toast.success('SD Card downloaded');
          onSuccessfulSubmit?.();
        })
        .catch((err) => {
          toast.error('Download SD Card failed');
          console.error(err);
          setSubmitError('Download SD Card failed');
        })
        .finally(() => {
          deviceStore.setIsSDCardDLInitiating(false);
          setLoading(false);
        });
    };

    return (
      <>
        <Typography sx={{ fontSize: '24px !important' }}>Download SD Card Image</Typography>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ mb: 1, fontSize: '16px !important' }}>Version Selection</Typography>
          <ToggleButtonGroup
            color='info'
            value={downloadMode}
            exclusive
            onChange={handleModeChange}
            aria-label='Platform'
            sx={{
              width: 'fit-content',
              mb: 2,
              marginInline: 'auto',
              '& .MuiToggleButton-root.Mui-selected': {
                color: theme.palette.accent.primary[500],
              },
            }}
            defaultValue={DownloadModes.LATEST}
          >
            <ToggleButton value={DownloadModes.LATEST}>Latest</ToggleButton>
            <ToggleButton value={DownloadModes.SPECIFIC}>Specific</ToggleButton>
          </ToggleButtonGroup>
          {downloadMode === DownloadModes.SPECIFIC && (
            <>
              <TextField
                sx={{ mb: 2 }}
                label='Requiered Version'
                fullWidth
                type='version'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <InputAdornmentIcon isValid={fotaVersionExists} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  setSelectedVersion(e.target.value);
                  debouncedCheckFotaVersion({
                    version: e.target.value,
                    deviceType: device.deviceType,
                    setFotaVersionExists,
                  });
                }}
              />
            </>
          )}
        </Box>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        {!fotaVersionExists && downloadMode === DownloadModes.SPECIFIC && (
          <Alert severity='error' sx={{ mb: 2 }}>
            Valid version is required
          </Alert>
        )}
        <Container disableGutters sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
          <Button
            variant='contained'
            sx={formButtonStyle}
            disabled={downloadMode === DownloadModes.SPECIFIC && !fotaVersionExists}
            onClick={onSubmitHandler}
          >
            {downloadMode === DownloadModes.LATEST ? 'Download Latest' : 'Download'}
          </Button>
          <Button variant='outlined' sx={formButtonStyle} onClick={onCancel}>
            Cancel
          </Button>
        </Container>
      </>
    );
  }
);
