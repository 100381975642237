import { ValuesOf } from '../types/globals';

export const CONNECTION_STATUS = {
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
} as const;

export const ScopeTypes = {
  SINGLE: 'SINGLE',
  GLOBAL: 'GLOBAL',
  MULTI: 'MULTI',
  NONE: 'NONE',
} as const;

export enum UNITS {
  WH = 'Wh',
  KWH = 'kWh',
  MWH = 'MWh',
  W = 'W',
  KW = 'kW',
  MW = 'MW',
  V = 'V',
  A = 'A',
  KMH = 'km/h',
  MPH = 'mph',
  PERCENT = '%',
  MS = 'ms',
  SEC = 'sec',
  MIN = 'min',
  CELSIUS = '°C',
  FAHRENHEIT = '°F',
};

export enum UserRole {
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  INTEGRATOR = 'INTEGRATOR',
  OPERATOR = 'OPERATOR',
  PROJECT_USER = 'PROJECT_USER',
  PROJECT_VIEWER = 'PROJECT_VIEWER',
}

export interface AbstractUserData {
  name?: string;
  role: UserRole;
  email?: string;
  projectScope?: number[] | string | null;
  sub?: string;
  projectIds?: number[];
  scope?: number[] | null;
  scopeType?: string;
  familyName?: string;
  givenName?: string;
}

export const RECV_STATE = {
  comOff: 0,
  comOn1: 1,
  comOn2: 2,
  comOn3: 3,
  comOn4: 4,
  forceOff: 9,
  errorComm: 10,
  disconnectedCU: 11,
  disconnectedRecv: 12,
  temperatureError: 13,
  cuTemperatureError: 14,
} as const;

export const ROLE_HIERARCHY = {
  [UserRole.SUPER_ADMIN]: 6,
  [UserRole.ADMIN]: 5,
  [UserRole.INTEGRATOR]: 4,
  [UserRole.OPERATOR]: 3,
  [UserRole.PROJECT_USER]: 2,
  [UserRole.PROJECT_VIEWER]: 1,
} as const;

export enum AppMode {
  Unknown = 0,
  _Deprecated_Auto = 1,
  Static_Deprecated_Manual = 2,
  Dynamic = 3,
}

export enum AppOperationalMode {
  Manual = 0,
  Auto = 1,
}

export enum GEAR_MODES {
  GEAR_STATE_DRIVE = 'GEAR_STATE_DRIVE',
  GEAR_STATE_NEUTRAL = 'GEAR_STATE_NEUTRAL',
  GEAR_STATE_REVERSE = 'GEAR_STATE_REVERSE',
  GEAR_STATE_NA = 'GEAR_STATE_NA',
}

export type GearModesType = ValuesOf<typeof GEAR_MODES>;

export enum ECAS_MODES {
  ECAS_STATE_SECOND_HIGH_LEVEL_POSITION = 'ECAS_STATE_SECOND_HIGH_LEVEL_POSITION',
  ECAS_STATE_OTHER = 'ECAS_STATE_OTHER',
}

export type EcasModesType = ValuesOf<typeof ECAS_MODES>;

export enum CHARGING_ALLOWED_MODES {
  CHARGING_ALLOWED = 'CHARGING_ALLOWED',
  CHARGING_NOT_ALLOWED = 'CHARGING_NOT_ALLOWED',
  CHARGING_NOT_ALLOWED_STOP_WAKE_UP = 'CHARGING_NOT_ALLOWED_STOP_WAKE_UP',
}

export type ChargingAllowedModesType = ValuesOf<typeof CHARGING_ALLOWED_MODES>;

export const DMU_DRAWER_COUNT = 5
export const D40_DRAWER_COUNT = 2
