import { OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';

interface OCPPSelectionInputProps {
  selectedOcpp: any | null;
  setSelectedOcpp?: React.Dispatch<React.SetStateAction<OcppChargerModelRefined | null>>;
  disabled?: boolean;
}

export const OCPPSelectionInput: React.FC<OCPPSelectionInputProps> = ({ selectedOcpp, setSelectedOcpp, disabled }) => {
  const { projectStore } = useAppStore();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      freeSolo={disabled ? true : false}
      sx={{ mb: 2 }}
      options={projectStore?.ocppList ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option as { id: string }).id}
      defaultValue={selectedOcpp}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          {option.id}
        </MenuItem>
      )}
      onChange={(e: any) => {
        const selectedOcpp = projectStore?.ocppList?.find((ocpp) => ocpp.id === e.target.outerText);
        selectedOcpp && setSelectedOcpp && setSelectedOcpp(selectedOcpp);
      }}
      value={selectedOcpp}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={disabled ? { ...disabledTextFieldStyle, mb: 2 } : {}}
          label='Device ID'
          error={!!errors['ocppId']}
          helperText={errors['ocppId'] ? errors['ocppId'].message?.toString() : ''}
          {...register('ocppId')}
          value={String(selectedOcpp?.id || '')}
        />
      )}
    />
  );
};
