import { MenuItem } from '@mui/material';
import { ImgListItem } from 'Components/MenuItems/ImgListItem';
import { ProjectDepotNavigationMenu } from 'Components/MenuItems/ProjectDepotNavigationMenu';
import { CustomMenu } from 'Components/Menu/MenuTamplate';

import MaintenanceIcon from 'Utils/Images/HeaderIcons/menu-maintenance-icon.svg?react';
import DashboardIcon from 'Utils/Images/HeaderIcons/menu-dashboard-icon.svg?react';
import AnalyticsIcon from 'Utils/Images/HeaderIcons/menu-analytic-icon.svg?react';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { hasDashboard } from 'dashboardConfig';
import { Link } from 'react-router-dom';
import { Functionalities, getAccessLevel, permissionsDefinition } from '@electreon_ui/shared/permissions';
import { useAppStore } from 'MobxStores/context';
import { ROLE_TYPES } from 'Utils/constants';
import { isEmail, ValuesOf } from '@electreon_ui/shared/types/globals';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { AccessLevels } from '@electreon_ui/shared/permissions/types/permissionsTypes';
import { userSpecificOverrides } from '@electreon_ui/shared/permissions/utils/permissionsUtils';

enum Pages {
  ANALYTICS = 'Analytics',
  INTEGRATION = 'Integration',
  REPORTS = 'Reports',
}

type NavigationItem = {
  name: Pages;
  Logo: React.FC<any>;
  url: (id: number | string) => string;
};

const navigationItems: NavigationItem[] = [
  {
    name: Pages.ANALYTICS,
    Logo: AnalyticsIcon,
    url: (id: number | string) => `/dashboard/history/project/${id}`,
  },
  {
    name: Pages.INTEGRATION,
    Logo: MaintenanceIcon,
    url: (id: number | string) => `/dashboard/integration/${id}`,
  },
  {
    name: Pages.REPORTS,
    Logo: () => <SummarizeIcon sx={{ fill: 'none', stroke: 'black' }} />,
    url: (id: number | string) => `/project/${id}/reports`,
  },
];

type MenuProps = {
  projectId?: number;
  handleLogout: () => void;
  className?: string;
  navMenuAnchor: HTMLElement | null;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const ProjectScreensNavigationMenu = forwardRef<HTMLDivElement, Omit<MenuProps, 'handleLogout'>>(
  ({ projectId, className, navMenuAnchor, isOpen, setIsOpen }, ref) => {
    const { userStore } = useAppStore();
    const userRole = userStore.userData?.role;

    const handleClose = (e?: MouseEvent, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick' || reason === 'escapeKeyDown' || e?.currentTarget === e?.target) {
        setIsOpen(false);
      }
    };

    return (
      <CustomMenu
        ref={ref}
        anchorEl={navMenuAnchor}
        open={isOpen}
        onClose={handleClose}
        title={
          <NavigationMenuTitle
            projectId={projectId}
            role={userRole || ROLE_TYPES.PROJECT_VIEWER}
            handleClose={handleClose}
          />
        }
        body={
          <NavigationMenuBody
            projectId={projectId}
            navigationItems={navigationItems}
            onItemClick={handleClose}
          />
        }
        footer={null}
        origin={{ vertical: 0, horizontal: 'left' }}
        className={className}
      />
    );
  }
);
ProjectScreensNavigationMenu.displayName = 'ProjectScreensNavigationMenu';

const NavigationMenuTitle: React.FC<{
  projectId?: number;
  role: ValuesOf<typeof ROLE_TYPES>;
  handleClose: () => void;
}> = observer(({ projectId, role, handleClose }) => {
  return (
    <div>
      {/* depot */}
      {permissionsDefinition[Functionalities.DEPOT]?.[role] && (
        <ProjectDepotNavigationMenu isOpen={true} selectedProjectId={projectId} handleClose={handleClose} />
      )}
      {/* dashboard */}
      <MenuItem disabled={!hasDashboard(projectId)}>
        <Link to={`/dashboard/live/project/${projectId}`} onClick={() => handleClose()}>
          <ImgListItem text='Dashboard' Logo={DashboardIcon} />
        </Link>
      </MenuItem>
    </div>
  );
});
NavigationMenuTitle.displayName = 'NavigationMenuTitle';

const NavigationMenuBody: React.FC<{
  projectId?: number;
  navigationItems: typeof navigationItems;
  onItemClick: () => void;
}> = observer(({ projectId, navigationItems, onItemClick }) => {
  const { userStore } = useAppStore();
  const userRole = userStore.userData?.role;
  const byPermission = (item: (typeof navigationItems)[number]): boolean => {
    if (!userRole) return false;
    if (item.name === Pages.REPORTS) {
      if (userStore.isCompanyUser) return true;
      return Boolean(permissionsDefinition[Functionalities.REPORTS]?.[userRole]);
    }
    if (item.name === Pages.INTEGRATION) {
      if (
        isEmail(userStore.userData?.email) &&
        userSpecificOverrides[userStore.userData?.email]?.[Functionalities.INTEGRATION] ===
          AccessLevels.DENIED
      )
        return false;
      else return Boolean(permissionsDefinition[Functionalities.INTEGRATION]?.[userRole]);
    }

    if (item.name === Pages.ANALYTICS) {
      if (userStore.isCompanyUser) return true;
      return Boolean(permissionsDefinition[Functionalities.ANALYTICS]?.[userRole]);
    }
    return true;
  };

  if (!projectId) return null;

  return navigationItems.filter(byPermission).map((item) => (
    <MenuItem key={item.name}>
      <Link to={item.url(projectId)} onClick={() => onItemClick()}>
        <ImgListItem text={item.name} Logo={item.Logo} />
      </Link>
    </MenuItem>
  ));
});
NavigationMenuBody.displayName = 'NavigationMenuBody';
