import { Backdrop, CircularProgress } from '@mui/material';

type FormLoadingProps = {
  open: boolean;
};

export const FormLoading: React.FC<FormLoadingProps> = ({ open }) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};
