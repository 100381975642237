import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { EditDeviceFormProps } from 'Components/Forms/EditDeviceForm/EditDeviceForm';
import { api } from 'Services/api';
import { type VehicleUnitConfiguration } from '@electreon/electreon-device-control-service-gen-ts-client';
import { updateVuShadow } from 'Utils/SOMApi';
import { filterValues } from 'Components/Forms/ConfigureShadowForm/configureFormUtils';
import { ConfigureFormTemplate } from 'Components/Forms/ConfigureShadowForm/ConfigureFormTemplate';
import { AxiosResponse } from 'axios';
import { useLockState } from 'CustomHooks/useLockState';
import { observer } from 'mobx-react-lite';
import { LockButton } from 'Components/Buttons/LockButton/LockButton';
import { toast } from 'sonner';

export interface IndexableVehicleUnitConfiguration extends VehicleUnitConfiguration {
  [key: string]: any;
}

export const ConfigureVuShadowForm: React.FC<EditDeviceFormProps> = observer(
  ({ device, onSuccessfulSubmit, onCancel }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [swaggerSchema, setSwaggerSchema] = useState<Record<string, any>>({});
    const [loading, setLoading] = useState(false);
    const [initialShadow, setInitialShadow] = useState<IndexableVehicleUnitConfiguration | null>(null);
    const { isLockedByMe } = useLockState(device);
    const submitButtonText = isLockedByMe ? 'Set VU Configuration' : 'Device Lock Required';

    const {
      register,
      formState: { errors, dirtyFields },
      setError,
      handleSubmit,
      clearErrors,
    } = useForm<IndexableVehicleUnitConfiguration>({
      shouldUseNativeValidation: true,
    });

    useEffect(() => {
      if (!device.id) return;
      api.deviceControl.vuConfig.getConfiguration(device.id).then((res) => {
        if (!res.data) return;
        setInitialShadow(res.data);
      });

      api.deviceControl.vuConfig.getValidations().then((res: AxiosResponse) => {
        if (!res.data) return;
        setSwaggerSchema(res.data.components.schemas as Record<string, any>);
      });
    }, [device.id]);

    const onSubmitHandler: SubmitHandler<IndexableVehicleUnitConfiguration> = (values) => {
      if (initialShadow === null) return;
      setLoading(true);
      setSubmitError(null);

      const filteredValues = filterValues(values, dirtyFields);

      // if filtered values is empty, then there is nothing to submit
      if (Object.keys(filteredValues).length === 0) {
        toast.success('Form closing, no changes made');
        onSuccessfulSubmit();
        return;
      }

      updateVuShadow(device.id, { ...filteredValues })
        .then((res: any) => {
          toast.success('Vu shadow updated');
          onSuccessfulSubmit();
        })
        .catch((err) => {
          toast.error('Update Vu shadow failed');
          setSubmitError(err.message);
        })
        .finally(() => setLoading(false));
    };

    // const formTitle = isLockedByMe
    //   ? `Edit Vehicle Unit Configuration (Shadow) ${device.name}`
    //   : `View Vehicle Unit Configuration (Shadow) ${device.name}`;

    const formTitle = (
      <header className='flex w-full items-center justify-between flex-row-reverse relative'>
        <h2 className='m-0 !text-2xl absolute left-1/2 -translate-x-1/2 whitespace-nowrap'>
          {isLockedByMe ? 'Edit' : 'View'} Vehicle Unit Configuration (Shadow) {device.name}
        </h2>
        <LockButton device={device} className='' />
      </header>
    );

    return (
      <ConfigureFormTemplate
        formTitle={formTitle}
        submitButtonText={submitButtonText}
        errors={errors}
        handleSubmit={handleSubmit}
        initialShadow={initialShadow}
        loading={loading}
        onSubmitHandler={onSubmitHandler}
        register={register}
        submitError={submitError}
        clearErrors={clearErrors}
        setError={setError}
        swaggerSchema={swaggerSchema}
        onCancel={onCancel}
        isLockedByMe={isLockedByMe}
      />
    );
  }
);
