import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const InputAdornmentIcon = ({ isValid }: { isValid?: boolean | null }) => {
  if (isValid === null) {
    return null;
  }
  return isValid ? (
    <CheckCircleOutlineIcon sx={{ color: 'success.main', width: 22, height: 22 }} />
  ) : (
    <ErrorOutlineIcon sx={{ color: 'error.main', width: 22, height: 22 }} />
  );
};
