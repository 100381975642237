import { ParkingSpot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { GridRemoveIcon } from '@mui/x-data-grid';
import { ConfirmationPopup } from 'Components/Forms/FormUtils/ConfirmationPopup';
import { CreateParkingSpotsInput } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotSchema';
import React, { useState } from 'react';
import { Control, FieldErrors, useFormContext, useWatch } from 'react-hook-form';

const defaultLng = '34.862906';
const defaultLat = '32.371288';

export function SegmentsFields({
  spot,
  errors,
  parkingIndex,
  control,
}: {
  spot: ParkingSpot;
  errors: FieldErrors<CreateParkingSpotsInput>;
  parkingIndex: number;
  control: Control<CreateParkingSpotsInput>;
}) {
  const increaseByOne = (num: number | undefined) => {
    if (num === undefined) return null;
    return String(num + 1);
  };
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const form = useWatch({ control });
  const { reset, register, setValue } = useFormContext();
  const handleDeleteSegment = (segmentIndex: number) => {
    reset(
      {
        ...form,
        parkingSpots: [
          ...(form.parkingSpots?.map((prevSpot, prevSpotIndex) => {
            if (prevSpotIndex !== parkingIndex) return prevSpot;

            const newSegments = prevSpot.segments?.filter((_, index) => index !== segmentIndex);

            return { ...prevSpot, segments: newSegments };
          }) || []),
        ],
      },
      { keepDirtyValues: true }
    );
  };

  return (
    <Grid container spacing={2} mb={1}>
      {spot?.segments?.map((segment, segIndex) => (
        <React.Fragment key={segIndex}>
          <Grid item sm={3.5}>
            <Autocomplete
              fullWidth
              options={['', ...Array.from({ length: 12 }, (_, i) => String(i + 1))]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Seg. Num'
                  error={!!errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.segmentNum}
                  helperText={errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.segmentNum?.message}
                />
              )}
              value={increaseByOne(segment.segmentNum) || ''}
              onKeyDown={(e) => {
                if (e.key.match(/[0-9]/)) {
                  e.preventDefault();
                  setValue(`parkingSpots.${parkingIndex}.segments.${segIndex}.segmentNum`, Number(e.key) - 1);
                }
              }}
              onChange={(_, value) => {
                const actualValue = value ? Number(value) - 1 : 0;
                setValue(`parkingSpots.${parkingIndex}.segments.${segIndex}.segmentNum`, actualValue);
              }}
            />
          </Grid>
          <Grid item sm={3.5}>
            <TextField
              fullWidth
              label='Long'
              error={!!errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.longitude}
              helperText={errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.longitude?.message}
              {...register(`parkingSpots.${parkingIndex}.segments.${segIndex}.longitude`)}
              defaultValue={segment.longitude || defaultLng}
              placeholder={defaultLng}
            />
          </Grid>
          <Grid item sm={3.5}>
            <TextField
              fullWidth
              label='Lat'
              error={!!errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.latitude}
              helperText={errors.parkingSpots?.[parkingIndex]?.segments?.[segIndex]?.latitude?.message}
              {...register(`parkingSpots.${parkingIndex}.segments.${segIndex}.latitude`)}
              defaultValue={segment.latitude || defaultLat}
              placeholder={defaultLat}
            />
          </Grid>
          <Grid
            item
            sm={1.5}
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 1,
            }}
          >
            <IconButton aria-label='delete' onClick={() => setIsConfirmPopupOpen(true)}>
              <GridRemoveIcon color='error' />
            </IconButton>
            <ConfirmationPopup
              isOpen={isConfirmPopupOpen}
              setIsOpen={setIsConfirmPopupOpen}
              confirmTitle='Are you sure you want to delete Segment?'
              confirmMessage='Segment will be permanently deleted!'
              onSubmitHandler={() => handleDeleteSegment(segIndex)}
            />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
