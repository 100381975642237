import { Button, Container, SxProps, Theme } from '@mui/material';
import { formButtonStyle } from './FormSubmitionButtonsStyles';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { useState } from 'react';
import { ConfirmationPopup } from 'Components/Forms/FormUtils/ConfirmationPopup';
import { SubmitHandler } from 'react-hook-form';

type FormSubmitionButtonsProps = {
  loading: boolean;
  onCancel: (e?: React.MouseEvent<HTMLElement>) => void;
  onSubmitHandler?: SubmitHandler<any>;
  submitLabel?: string;
  cancelLabel?: string;
  disableSubmit?: boolean;
  sx?: SxProps<Theme>;
  confirmation?: boolean;
  confirmTitle?: string;
  confirmMessage?: string;
};

export const FormSubmitionButtons: React.FC<FormSubmitionButtonsProps> = ({
  loading,
  onCancel,
  onSubmitHandler,
  submitLabel = 'Save Changes',
  cancelLabel = 'Cancel',
  disableSubmit = false,
  sx,
  confirmation,
  confirmTitle,
  confirmMessage,
}) => {
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-evenly',
        ...sx,
      }}
    >
      <Button
        variant='contained'
        type='submit'
        sx={formButtonStyle}
        disabled={disableSubmit}
        onClick={(e) => {
          if (confirmation) {
            e.stopPropagation();
            e.preventDefault();
            setIsConfirmPopupOpen(true);
          }
        }}
      >
        {submitLabel}
      </Button>
      <Button variant='outlined' sx={formButtonStyle} onClick={onCancel}>
        {cancelLabel}
      </Button>
      {confirmation && (
        <ConfirmationPopup
          isOpen={isConfirmPopupOpen}
          setIsOpen={setIsConfirmPopupOpen}
          onSubmitHandler={onSubmitHandler}
          confirmMessage={confirmMessage}
          confirmTitle={confirmTitle}
        />
      )}
    </Container>
  );
};
