import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

interface DepotSelectionInputProps {
  depotList: Depot[];
  selectedDepot: Depot | null;
  setSelectedDepot: React.Dispatch<React.SetStateAction<Depot | null>>;
  disabled?: boolean;
}

export const DepotSelectionInput: React.FC<DepotSelectionInputProps> = ({ depotList, selectedDepot, setSelectedDepot, disabled }) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <Autocomplete
      disabled={disabled}
      sx={{ mb: 2 }}
      options={depotList ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => String(option.name)}
      defaultValue={selectedDepot}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          {option.name} (ID:{option.id})
        </MenuItem>
      )}
      onChange={(e: any) => {
        const selectedDepot = depotList.find((depot) => e.target.outerText.includes(`ID:${depot.id}`));
        selectedDepot && setSelectedDepot(selectedDepot);
        setValue('depotId', Number(selectedDepot?.id));
      }}
      value={selectedDepot}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Depot'
          fullWidth
          error={!!errors['depotId']}
          helperText={errors['depotId'] ? errors['depotId'].message?.toString() : ''}
        />
      )}
    />
  );
};
