import * as DMS from '@electreon/electreon-device-metadata-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class DeviceMetadataService {
  public mu: DMS.ManagementUnitsControllerApi;
  public vu: DMS.VehicleUnitsControllerApi;
  public apfc: DMS.ApfcControllerApi;
  public firmware: DMS.FirmwareControllerApi;
  public pos: DMS.PosControllerApi;

  constructor(basePath: string, axios: AxiosInstance, config?: DMS.Configuration) {
    this.mu = new DMS.ManagementUnitsControllerApi(config, basePath, axios);
    this.vu = new DMS.VehicleUnitsControllerApi(config, basePath, axios);
    this.apfc = new DMS.ApfcControllerApi(config, basePath, axios);
    this.firmware = new DMS.FirmwareControllerApi(config, basePath, axios);
    this.pos = new DMS.PosControllerApi(config, basePath, axios);
  }
}
