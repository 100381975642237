export const tableTemplateHeaders = [
  { label: 'Start', units: '', key: 'start' },
  { label: 'End', units: '', key: 'end' },
  { label: 'Span', units: '', key: 'time', border: true },
  { label: 'VU', units: '', key: 'deviceId', pt: true },
  { label: 'Receivers #', units: '', key: 'receivers' },
  { label: 'VU SOC', units: '%', key: 'Soc' },
  { label: 'VU Voltage', units: 'V', key: 'Voltage' },
  { label: 'VU Speed', units: 'km/h', key: 'speed' },
  { label: 'VU Average Power', units: 'kW', key: 'AvgPower' },
  { label: 'VU Energy', units: 'Wh', key: 'EnrgVu' },
  {
    label: 'VU Energy / km',
    units: 'Wh/km',
    key: 'EnrgDist',
    border: true,
  },
  {
    label: 'MUs Energy',
    units: 'Wh',
    key: 'EnrgMu',
    border: true,
    pt: true,
  },
  {
    label: 'Efficiency',
    units: '%',
    key: 'PwrEfficiency',
    pt: true,
  },
  {
    label: '',
    units: '',
    key: 'map'
  },
];

export const ACTIVITY_GAP_THRESHOLD = 40 as const;
