import { ManagementUnitModelRefined } from '@electreon_ui/shared/types/globals';
import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';

interface MuSelectionInputProps {
  selectedMu: ManagementUnitModelRefined | null;
  setSelectedMu?: React.Dispatch<React.SetStateAction<ManagementUnitModelRefined | null>>;
  disabled?: boolean;
}

export const MuSelectionInput: React.FC<MuSelectionInputProps> = ({ selectedMu, setSelectedMu, disabled }) => {
  const { projectStore } = useAppStore();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      freeSolo={disabled ? true : false}
      sx={{ mb: 2 }}
      options={projectStore?.muList ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option as { id: string }).id}
      defaultValue={selectedMu}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          {option.id}
        </MenuItem>
      )}
      onChange={(e: any) => {
        const selectedMu = projectStore?.muList?.find((mu) => mu.id === e.target.outerText);
        selectedMu && setSelectedMu && setSelectedMu(selectedMu);
      }}
      value={selectedMu}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={disabled ? { ...disabledTextFieldStyle, mb: 2 } : {}}
          label='Device ID'
          error={!!errors['muId']}
          helperText={errors['muId'] ? errors['muId'].message?.toString() : ''}
          {...register('muId')}
          value={String(selectedMu?.id || '')}
        />
      )}
    />
  );
};
