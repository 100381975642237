import { Alert, Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useAppStore } from 'MobxStores/context';
import { isValidNumber, AsYouType, parsePhoneNumber, getCountries, CountryCode } from 'libphonenumber-js';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';
import { CognitoAttribute } from '@electreon/electreon-user-management-service-gen-ts-client';

const countries = getCountries();

const editUserSchema = object({
  name: string().nonempty('Name is required').min(2, 'Too Short!').max(50, 'Too Long!'),
  given_name: string().max(50, 'Too Long!').optional(),
  family_name: string().nonempty('Last name is required').min(2, 'Too Short!').max(50, 'Too Long!'),
  country_code: string().min(2, 'Too Short!').max(5, 'Too Long!').optional(),
  phone_number: string().optional(),
  username: string(),
});

const editUserDefaultValues = {
  name: '',
  given_name: '',
  family_name: '',
  country_code: '',
  phone_number: '',
  username: '',
};

type UserAttributesInput = TypeOf<typeof editUserSchema>;

export const EditUserForm: React.FC<FormProps> = ({ onSuccessfulSubmit, onCancel }) => {
  const { authStore } = useAppStore();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm<UserAttributesInput>({
    resolver: zodResolver(editUserSchema),
    defaultValues: editUserDefaultValues,
  });

  const onSubmitHandler: SubmitHandler<UserAttributesInput> = (values) => {
    setLoading(true);
    setSubmitError(null);
    const { country_code, username, ...submitValues } = values;
    const userAttributes: CognitoAttribute[] = Object.entries(submitValues).map(([key, value]) => {
      return { Name: key, Value: value };
    });
    authStore
      .updateCurrentUserData(username, userAttributes)
      .then(() => {
        toast.success('User attributes updated');
        onSuccessfulSubmit();
      })
      .catch((err) => {
        toast.error('Update user attributes failed');
        console.error('Error setting user attributes', err);
        setSubmitError(err?.response?.data?.message || 'Error setting user attributes');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    authStore.getCurrentUserData().then((userData) => {
      if (!userData) return;
      const parsedAttributes = userData.UserAttributes.reduce<Record<string, string>>((acc, curr) => {
        const { Name, Value } = curr;
        acc[Name] = Value;
        return acc;
      }, {});

      reset({
        name: parsedAttributes.name || '',
        given_name: parsedAttributes.given_name || '',
        family_name: parsedAttributes.family_name || '',
        phone_number: parsedAttributes.phone_number || '',
        username: userData.Username,
      });
    });
  }, [authStore, reset]);

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Edit User Details
      </Typography>

      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmitHandler, (errors) => {
          console.log(errors);
        })}
      >
        <TextField
          sx={{ mb: 1 }}
          fullWidth
          label='Name'
          InputLabelProps={{ shrink: !!watch('name') }}
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message || ' '}
        />
        <TextField
          sx={{ mb: 1 }}
          fullWidth
          label='Given Name'
          InputLabelProps={{ shrink: !!watch('given_name') }}
          {...register('given_name')}
          error={!!errors.given_name}
          helperText={errors.given_name?.message || ' '}
        />
        <TextField
          sx={{ mb: 1 }}
          fullWidth
          label='Family Name'
          InputLabelProps={{ shrink: !!watch('family_name') }}
          {...register('family_name')}
          error={!!errors.family_name}
          helperText={errors.family_name?.message || ' '}
        />
        <Stack spacing={2} direction='row' alignItems='center'>
          <Autocomplete
            fullWidth
            options={countries}
            sx={{ flex: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Country Code'
                error={!!errors['country_code']}
                helperText={errors.country_code?.message || ' '}
              />
            )}
            value={watch('country_code') || ''}
            onChange={(e, val) => {
              setValue('country_code', val || '');
            }}
          />

          <TextField
            sx={{ flex: 3 }}
            fullWidth
            disabled={!watch('country_code')}
            label='Phone Number'
            InputLabelProps={{ shrink: !!watch('phone_number') }}
            {...register('phone_number', {
              validate: (value) => {
                const countryCode = watch('country_code') as CountryCode;
                const phoneNumber =
                  value && isValidNumber(value) ? parsePhoneNumber(value, countryCode) : null;
                return !!phoneNumber;
              },
              onChange(event) {
                try {
                  const countryCode = watch('country_code') as CountryCode;
                  const number = new AsYouType(countryCode).input(event.target.value);
                  const formattedPhoneNumber = parsePhoneNumber(number, countryCode)?.number || '';
                  event.target.value = formattedPhoneNumber;
                } catch (err) {
                  console.error('Error formatting phone number', JSON.stringify(err));
                }
              },
            })}
            error={!!errors.phone_number}
            helperText={errors.phone_number?.message || ' '}
          />
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons loading={loading} onCancel={onCancel} />
      </Box>
    </>
  );
};
EditUserForm.displayName = 'EditUserForm';
