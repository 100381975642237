import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid, Typography, ThemeProvider, IconButton } from '@mui/material';
import { ErrorMessage } from 'Screens/Login/LoginConstants';
import {
  leftGridContainer,
  loginButton,
  loginErrorStyles,
  loginFormStyles,
  loginTheme,
} from 'Screens/Login/styles/LoginStyles';
import { RandomLoginImage } from 'Screens/LoginRandomImages/LoginRandomImages';
import { useAppStore } from 'MobxStores/context';
import { setUserInfo } from 'aws/authUtils';
import axios, { AxiosError } from 'axios';
import { Logo } from 'Components/Logo/Logo';
import ElectreonSmallLogo from 'Utils/Images/GlobalIcons/electreon-dark-logo.svg';
import { observer } from 'mobx-react-lite';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const validatePasswordRequierments = (password: string) => {
  if (password.length < 10) {
    return ErrorMessage.tooShort;
  }
  if (!/[A-Z]/.test(password)) {
    return ErrorMessage.missingUppercaseLetter;
  }
  if (!/[a-z]/.test(password)) {
    return ErrorMessage.missingLowercaseLetter;
  }
  if (!/[0-9]/.test(password)) {
    return ErrorMessage.missingNumber;
  }
  if (!/[^a-zA-Z0-9]/.test(password)) {
    return ErrorMessage.missingNonAlphanumeric;
  }
  return ErrorMessage.noError;
};

export const ForceChangePassword = observer(() => {
  const { userStore, authStore } = useAppStore();
  const navigate = useNavigate();
  const [loginStatusMessage, setLoginStatusMessage] = useState<string>(ErrorMessage.noError);
  const username = JSON.parse(
    authStore?.cognitoChallenge?.ChallengeParameters?.userAttributes || '{"email":""}'
  )?.email;
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const newPassword = data.get('new-password')?.toString();
    const newPassword2 = data.get('new-password2')?.toString();
    if (!username || !newPassword) {
      setLoginStatusMessage(ErrorMessage.wrongCredentials);
      console.log(`no username or password provided`);
      return;
    }
    if (newPassword2 !== newPassword) {
      setLoginStatusMessage(ErrorMessage.passwordConfirmError);
      console.log(
        `The passwords you entered do not match. newPassword is: ${newPassword}, the confirm one is: ${newPassword2}`
      );
      return;
    }
    const passwordRequiermentsValidation = validatePasswordRequierments(newPassword);
    if (passwordRequiermentsValidation !== ErrorMessage.noError) {
      setLoginStatusMessage(passwordRequiermentsValidation);
      return;
    }
    setLoginStatusMessage(ErrorMessage.noError);
    return authStore
      .forceChangePassword(username, newPassword)
      .then((session: any | null) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + authStore.IdToken;
        setUserInfo(session, userStore);
        navigate(userStore.getPathToUserMainPage());
      })
      .catch((err: AxiosError) => {
        if (axios.isAxiosError(err)) {
          setLoginStatusMessage(ErrorMessage.loginAttemptFailed);
          console.error('Login attempt failed', JSON.stringify(err));
        }
      });
  };

  return (
    <ThemeProvider theme={loginTheme}>
      <Grid item xs={12} height='100vh' container spacing={0}>
        <Grid item container xs={12} sm={6} md={6} sx={leftGridContainer}>
          <Box component='div'>
            <Logo
              logo={ElectreonSmallLogo}
              width='100%'
              height='100%'
              alt='Electreon logo'
              onClickLogo={() => ''}
            />
          </Box>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={loginFormStyles}>
            <Typography component='h1' variant='h3' marginBottom={2}>
              Change Password
            </Typography>
            <TextField
              disabled
              margin='normal'
              required
              fullWidth
              id='username'
              label='User Name'
              sx={{ '& .MuiInputBase-input': { height: '45px' } }}
              value={username}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='new-password'
              label='New Password'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                    }}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                  </IconButton>
                ),
              }}
              id='new-password'
              sx={{ '& .MuiInputBase-input': { height: '45px' } }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='new-password2'
              label='Confirm New Password'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                    }}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff fontSize='small' /> : <Visibility fontSize='small' />}
                  </IconButton>
                ),
              }}
              id='new-password2'
              sx={{ '& .MuiInputBase-input': { height: '45px' } }}
            />
            <Typography sx={loginErrorStyles}>{loginStatusMessage}</Typography>
            <Button type='submit' fullWidth sx={loginButton}>
              Change Password
            </Button>
          </Box>
        </Grid>
        <RandomLoginImage />
      </Grid>
    </ThemeProvider>
  );
});
