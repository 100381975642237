import {
  Fleet,
  ManagementUnitModel,
  OcppChargerModel,
  ParkingSpot,
  Project,
  ProjectApfcDeployment,
  ProjectDeployment,
  ProjectDynamicDeployment,
  ProjectStaticDeployment,
  RoadSection,
} from '@electreon/electreon-metadata-service-gen-ts-client';
import { computed, makeAutoObservable, reaction } from 'mobx';
import { IoTDeviceType } from '@electreon/electreon-device-metadata-service-gen-ts-client';
import {
  ApfcModelRefined,
  Logo,
  ManagementUnitModelRefined,
  OcppChargerModelRefined,
  OcppChargerWithParkingSpotsRefined,
  PosModelRefined,
  UiDevice,
  VehicleUnitModelRefined,
} from '../../types/globals';
import { ElectreonApiServices } from '../../services/ElectreonApiServices';
import { AbstractRootStore } from '../storeModels';
import { ProjectFleets } from '@electreon/electreon-metadata-service-gen-ts-client';
import { getProjectMetadata } from './projectStoreUtils';

class ProjectStore {
  api: ElectreonApiServices;
  selectedProject: Project | null = null;
  projectsDeployment: Record<string, ProjectDeployment> | null = null;
  muList: ManagementUnitModelRefined[] = [];
  vuList: VehicleUnitModelRefined[] = [];
  posList: PosModelRefined[] = [];
  apfcList: ApfcModelRefined[] = [];
  ocppList: OcppChargerModelRefined[] = [];
  rootStore: AbstractRootStore;
  cachedLogos: Record<string, Logo> = {};
  projectFleetsDeployment: Record<string, ProjectFleets> | null = null;

  constructor(rootStore: AbstractRootStore, api: ElectreonApiServices) {
    this.api = api;
    this.rootStore = rootStore;
    makeAutoObservable(this);

    // Setting MU, VU, POS, APFC lists and sets initial lock states in deviceLockStore
    reaction(
      () => this.selectedProject?.id,
      (projectId) => {
        if (!projectId) {
          // Clear device lists
          this.setMuList([]);
          this.setVuList([]);
          this.setPosList([]);
          this.setApfcList([]);
          this.setOcppList([]);
          return;
        }
        getProjectMetadata(projectId, this);
      }
    );
  }

  private sortDeploymentObjectByName = (deployment: ProjectDeployment) => {
    const sortedStaticDeployment = deployment?.staticDeployment?.depots?.map((depot) => ({
      ...depot,
      managementUnits: depot?.managementUnits?.toSorted(
        (a, b) => a.managementUnit?.name?.localeCompare(b.managementUnit?.name ?? '') ?? 0
      ),
    }));

    const sortedDynamicDeployment = deployment?.dynamicDeployment?.roads?.map((road) => ({
      ...road,
      roadSections: road?.roadSections?.map((roadSection) => ({
        ...roadSection,
        managementUnits: roadSection?.managementUnits?.toSorted(
          (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
        ),
      })),
    }));

    const sortedDeployment = {
      apfcDeployment: (deployment.apfcDeployment || null) as ProjectApfcDeployment,
      unassignedOcppChargers: (deployment.unassignedOcppChargers?.toSorted(
        (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
      ) || null) as OcppChargerModel[],
      unassignedUnits: (deployment.unassignedUnits?.toSorted(
        (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
      ) || null) as ManagementUnitModel[],
      staticDeployment: { depots: sortedStaticDeployment || null } as ProjectStaticDeployment,
      dynamicDeployment: { roads: sortedDynamicDeployment || null } as ProjectDynamicDeployment,
    };
    return sortedDeployment;
  };

  //seems like not needed in new implementation
  // private updateSelectedDevices = () => {
  //   const selectedMuId = this.rootStore.deviceStore?.selectedDevices?.mu?.id || '';
  //   const selectedVuId = this.rootStore.deviceStore?.selectedDevices?.vu?.id || '';
  //   const selectedOcppId = this.rootStore.deviceStore?.selectedDevices?.ocpp?.id || '';
  //   selectedMuId &&
  //     this.api.deviceMetadata.mu
  //       .getManagementUnit(selectedMuId)
  //       // .then((res) => this.rootStore.deviceStore?.setSelectedDevices(res.data));
  //   selectedVuId &&
  //     this.api.deviceMetadata.vu
  //       .getVehicleUnit(selectedVuId)
  //       // .then((res) => this.rootStore.deviceStore?.setSelectedDevices(res.data));
  //   selectedOcppId &&
  //     this.api.ocppChargersMetadata.ocpp
  //       .getOcppCharger(selectedOcppId)
  //       // .then((res) => this.rootStore.deviceStore?.setSelectedDevices(res.data as OcppChargerModelRefined));
  // };

  setProjectDeployment(projectId?: string | number, deployment?: ProjectDeployment) {
    if (!projectId || !deployment) return;
    if (!this.projectsDeployment) this.projectsDeployment = {};
    // sort devices by name
    this.projectsDeployment[projectId] = this.sortDeploymentObjectByName(deployment);
    // update selected device ( so that they have updated properties )
    // this.updateSelectedDevices();
  }

  setFleetsDeployment(projectId?: string | number, deployment?: ProjectFleets) {
    if (!projectId || !deployment) return;
    if (!this.projectFleetsDeployment) this.projectFleetsDeployment = {};
    this.projectFleetsDeployment[projectId] = deployment;
  }

  setMuList(muList: ManagementUnitModelRefined[]) {
    this.muList = muList?.toSorted((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
  }

  setVuList(vuList: VehicleUnitModelRefined[]) {
    this.vuList = vuList?.toSorted((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0);
  }

  setPosList(posList: PosModelRefined[]) {
    this.posList = posList;
  }

  setApfcList(apfcList: ApfcModelRefined[]) {
    this.apfcList = apfcList;
  }

  setOcppList(ocppList: OcppChargerModelRefined[]) {
    this.ocppList = ocppList as OcppChargerModelRefined[];
  }

  setSelectedProject = (project: Project | null) => {
    this.selectedProject = project;
  };

  @computed
  get projectIdList() {
    return (
      this.rootStore.queryClient.getQueryData<Project[]>(['projects'])?.map((project) => project.id) ?? []
    );
  }

  @computed
  get selectedProjectDeployment() {
    if (!this.selectedProject?.id) return undefined;
    return this.projectsDeployment?.[this.selectedProject.id];
  }

  @computed
  get selectedProjectFleetDeployment() {
    if (!this.selectedProject?.id) return undefined;
    return this.projectFleetsDeployment?.[this.selectedProject.id];
  }

  @computed
  get muMap() {
    const muMap = new Map<string, ManagementUnitModelRefined>();
    this.muList.forEach((mu) => {
      if (!mu.id) return;
      muMap.set(mu.id, mu);
    });
    return muMap;
  }

  @computed
  get vuMap() {
    const vuMap = new Map<string, VehicleUnitModelRefined>();
    this.vuList?.forEach((vu) => {
      if (!vu.id) return;
      vuMap.set(vu.id, vu);
    });
    return vuMap;
  }

  @computed
  get posMap() {
    const posMap = new Map<string, PosModelRefined>();
    this.posList.forEach((pos) => {
      if (!pos.id) return;
      posMap.set(pos.id, pos);
    });
    return posMap;
  }

  @computed
  get apfcMap() {
    const apfcMap = new Map<string, ApfcModelRefined>();
    this.apfcList.forEach((apfc) => {
      if (!apfc.id) return;
      apfcMap.set(apfc.id, apfc);
    });
    return apfcMap;
  }

  @computed
  get ocppMap() {
    const ocppMap = new Map<string, OcppChargerModelRefined>();
    this.ocppList.forEach((ocpp) => {
      if (!ocpp.id) return;
      ocppMap.set(ocpp.id, ocpp);
    });
    return ocppMap;
  }

  @computed
  get ocppToParkingSpotMap() {
    const ocppToParkingSpotMap = new Map<string, ParkingSpot[]>();
    this.selectedProjectDeployment?.staticDeployment?.depots?.forEach((depot) => {
      depot?.ocppChargers?.forEach((ocppWithParkingSpots) => {
        if (!ocppWithParkingSpots.ocppCharger?.id || !ocppWithParkingSpots.parkingSpots) return;
        ocppToParkingSpotMap.set(ocppWithParkingSpots.ocppCharger.id, ocppWithParkingSpots.parkingSpots);
      });
    });
    return ocppToParkingSpotMap;
  }

  @computed
  get parkingSpotIdToParkingSpotMap() {
    const parkingSpotIdToParkingSpotMap = new Map<number, ParkingSpot>();
    this.selectedProjectDeployment?.staticDeployment?.depots?.forEach((depot) => {
      depot?.ocppChargers?.forEach((ocppWithParkingSpots) => {
        ocppWithParkingSpots.parkingSpots?.forEach((parkingSpot) => {
          if (!parkingSpot.id) return;
          parkingSpotIdToParkingSpotMap.set(parkingSpot.id, parkingSpot);
        });
      });

      depot?.managementUnits?.forEach((mu) => {
        mu?.parkingSpots?.forEach((parkingSpot) => {
          if (!parkingSpot.id) return;
          parkingSpotIdToParkingSpotMap.set(parkingSpot.id, parkingSpot);
        });
      });
    });
    return parkingSpotIdToParkingSpotMap;
  }

  @computed
  get muIdToParkingSpotsArrayMap() {
    const muIdToParkingSpotsArrayMap = new Map<string, ParkingSpot[]>();
    this.selectedProjectDeployment?.staticDeployment?.depots?.forEach((depot) => {
      depot?.managementUnits?.forEach((mu) => {
        if (!mu.managementUnit?.id || !mu.parkingSpots) return;
        muIdToParkingSpotsArrayMap.set(mu.managementUnit.id, mu.parkingSpots);
      });
    });
    return muIdToParkingSpotsArrayMap;
  }

  @computed
  get deviceIdToIOTDeviceModelMap() {
    const deviceIdToIOTModelMap = new Map<string, UiDevice>();
    this.muList.forEach((mu) => {
      if (!mu.id) throw new Error(`Management Unit ${mu.name} has no id`);
      deviceIdToIOTModelMap.set(mu.id, { ...mu, deviceType: IoTDeviceType.Mu });
    });
    this.vuList?.forEach((vu) => {
      if (!vu.id) throw new Error(`Vehicle Unit ${vu.name} has no id`);
      deviceIdToIOTModelMap.set(vu.id, vu);
    });
    this.posList.forEach((pos) => {
      if (!pos.id) throw new Error(`POS ${pos.name} has no id`);
      deviceIdToIOTModelMap.set(pos.id, pos);
    });
    this.apfcList.forEach((apfc) => {
      if (!apfc.id) throw new Error(`APFC ${apfc.name} has no id`);
      deviceIdToIOTModelMap.set(apfc.id, apfc);
    });
    this.ocppList.forEach((ocpp) => {
      if (!ocpp.id) throw new Error(`OCPP ${ocpp.name} has no id`);
      deviceIdToIOTModelMap.set(ocpp.id, ocpp);
    });
    return deviceIdToIOTModelMap;
  }

  setCachedLogos(projectId: string | number, logo: { main: string; small: string }) {
    if (!this.cachedLogos) this.cachedLogos = {};
    this.cachedLogos = { ...this.cachedLogos, [String(projectId)]: { main: logo?.main, small: logo?.small } };
  }

  @computed
  get roadSectionToManagementUnitIdsMap() {
    const roadSectionToManagementUnitIdMap = new Map<string, string[]>();
    this.selectedProjectDeployment?.dynamicDeployment?.roads?.forEach((road) => {
      road.roadSections?.forEach((roadSection) => {
        if (!roadSection.id || !roadSection.managementUnits) return;
        roadSectionToManagementUnitIdMap.set(
          String(roadSection.id),
          roadSection.managementUnits.map((mu) => mu.id ?? '')
        );
      });
    });
    return roadSectionToManagementUnitIdMap;
  }

  @computed
  get roadSectionMap() {
    const roadSectionMap = new Map<string, RoadSection>();
    this.selectedProjectDeployment?.dynamicDeployment?.roads?.forEach((road) => {
      road.roadSections?.forEach((roadSection) => {
        if (!roadSection.id) return;
        roadSectionMap.set(String(roadSection.id), roadSection);
      });
    });
    return roadSectionMap;
  }

  @computed
  get depotIdToOcppChargersMap() {
    const depotIdToOcppChargersMap = new Map<string, OcppChargerWithParkingSpotsRefined[]>();
    this.selectedProjectDeployment?.staticDeployment?.depots?.forEach((depot) => {
      if (!depot.id || !depot.ocppChargers) return;
      depotIdToOcppChargersMap.set(
        String(depot.id),
        depot.ocppChargers as OcppChargerWithParkingSpotsRefined[]
      );
    });
    return depotIdToOcppChargersMap;
  }

  @computed
  get parkingSpotIdToOcppChargerMap() {
    const parkingSpotIdToOcppChargerMap = new Map<number, OcppChargerModelRefined | undefined>();
    this.selectedProjectDeployment?.staticDeployment?.depots?.forEach((depot) => {
      depot?.ocppChargers?.forEach((ocppWithParkingSpots) => {
        if (!ocppWithParkingSpots.ocppCharger?.id || !ocppWithParkingSpots.parkingSpots) return;
        ocppWithParkingSpots.parkingSpots?.forEach((parkingSpot) => {
          if (!parkingSpot.id) return;
          parkingSpotIdToOcppChargerMap.set(
            parkingSpot.id,
            ocppWithParkingSpots.ocppCharger as OcppChargerModelRefined
          );
        });
      });
    });
    return parkingSpotIdToOcppChargerMap;
  }

  @computed
  get fleetIdToFleetMap() {
    const fleetIdToFleetMap = new Map<number, Fleet>();
    this.selectedProjectFleetDeployment?.fleets?.forEach((fleet) => {
      if (!fleet.id) return;
      fleetIdToFleetMap.set(fleet.id, fleet);
    });
    return fleetIdToFleetMap;
  }

  @computed
  get projectIdToProjectMap() {
    const projectIdToProjectMap = new Map<number, Project>();
    this.rootStore.queryClient.getQueryData<Project[]>(['projects'])?.forEach((project) => {
      if (!project.id) return;
      projectIdToProjectMap.set(project.id, project);
    });
    return projectIdToProjectMap;
  }

  resetStore() {
    this.selectedProject = null;
  }
}

export default ProjectStore;
