import { Alert, Box, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { disabledTextFieldStyle } from 'Components/Forms/PopupWrapper';
import { useDeviceFotaFormLogic } from 'CustomHooks/useDeviceFotaFormLogic';
import {
  DeviceFotaOptionsFormProps,
  DeviceFotaInput,
  deviceFotaSchema,
  BasicDeviceFotaOptions,
  SomFotaOptionsDisplay,
  FpgaFotaOptionsDisplay,
  fotaOptionByPermission,
} from './DeviceFotaUtils';
import { useInputAvailability } from '../CreateDevicePopup/utils/useInputAvailability';
import { InputAdornmentIcon } from '../CreateDevicePopup/utils/InputAdormentIcon';
import { useMemo } from 'react';
import { useAppStore } from 'MobxStores/context';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { useLockState } from 'CustomHooks/useLockState';

export const DeviceFotaOptionsForm: React.FC<DeviceFotaOptionsFormProps> = ({
  device,
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
}) => {
  const { userStore } = useAppStore();
  const { isLockedByMe } = useLockState(device);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    watch,
    reset,
    handleSubmit,
    setError,
    clearErrors,
  } = useForm<DeviceFotaInput>({
    resolver: zodResolver(deviceFotaSchema),
    defaultValues: {
      version: '',
      fotaType: '',
      fotaSubType: '',
    },
  });
  const { fotaVersionExists } = useInputAvailability(watch, null, setError, clearErrors, device?.deviceType);
  const { onSubmitHandler, submitError, loading, deviceTypeName, DeviceFotaTypes } = useDeviceFotaFormLogic(
    device,
    reset,
    onSuccessfulSubmit
  );
  const fotaTypeSelected = watch('fotaType');
  const DeviceFotaSubTypes = useMemo(() => {
    return fotaTypeSelected === BasicDeviceFotaOptions.SOM_FOTA
      ? SomFotaOptionsDisplay.filter((o) => fotaOptionByPermission(o.key, userStore.userData?.role))
      : fotaTypeSelected === BasicDeviceFotaOptions.FPGA_FOTA
        ? FpgaFotaOptionsDisplay
        : [];
  }, [fotaTypeSelected, userStore.userData?.role]);

  return (
    <>
      <Typography variant='h4' component='h1'>
        FOTA For {deviceTypeName}
      </Typography>
      {!isLockedByMe && (
        <Alert severity='warning' sx={{ mt: 2 }}>
          This device is not locked by you.
        </Alert>
      )}
      <Box
        component='form'
        noValidate
        autoComplete='off'
        sx={{ mt: 4 }}
        onSubmit={handleSubmit(onSubmitHandler, (err) => {
          console.log(err);
          console.log(watch());
        })}
      >
        <Stack direction='row' spacing={0} sx={{ mb: 2 }}>
          <TextField
            InputLabelProps={{ shrink: true }}
            label='Project'
            disabled
            sx={{ ...disabledTextFieldStyle }}
            value={selectedProject?.name}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            disabled
            sx={{ ...disabledTextFieldStyle }}
            label='Device Id'
            fullWidth
            value={device?.id}
          />
        </Stack>
        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <TextField
            select
            label='Select FOTA Type'
            sx={{ flex: 0.5 }}
            error={!!errors['fotaType']}
            helperText={errors['fotaType'] ? errors['fotaType'].message : ''}
            value={watch('fotaType')}
            {...register('fotaType')}
          >
            {DeviceFotaTypes.map((fotaType) => (
              <MenuItem key={fotaType.key} value={fotaType.key}>
                {fotaType.text}
              </MenuItem>
            ))}
          </TextField>
          {DeviceFotaSubTypes.length !== 0 && (
            <TextField
              select
              sx={{ flex: 0.8 }}
              label='Select FOTA Sub Type'
              fullWidth
              error={!!errors['fotaSubType']}
              helperText={errors['fotaSubType'] ? errors['fotaSubType'].message : ''}
              {...register('fotaSubType')}
              defaultValue={''}
            >
              {DeviceFotaSubTypes.map((fotaType) => (
                <MenuItem key={fotaType.key} value={fotaType.key}>
                  {fotaType.text}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Stack>

        <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
          <TextField
            sx={{ mb: 2 }}
            label='Requiered Version'
            fullWidth
            type='version'
            error={!!errors['version']}
            helperText={errors['version'] ? errors['version'].message : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <InputAdornmentIcon isValid={fotaVersionExists} />
                </InputAdornment>
              ),
            }}
            {...register('version')}
          />
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons
          loading={loading}
          onCancel={onCancel}
          submitLabel='Perform FOTA'
          disableSubmit={!isLockedByMe}
        />
      </Box>
    </>
  );
};
DeviceFotaOptionsForm.displayName = 'DeviceFotaOptionsForm';
