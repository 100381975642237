import { useLocation } from 'react-router-dom';

export enum HeaderMode {
  DEFAULT = 'default',
  TRANSPARENT = 'transparent',
  HIDDEN = 'hidden',
  DARK = 'dark',
  LIGHT = 'light',
}

export const useHeaderMode = (): {
  headerMode: HeaderMode;
  themeColor: 'dark' | 'light';
  isDashboard: boolean;
} => {
  const location = useLocation().pathname;
  const isHome = Boolean(location.match(/^\/$/));
  const isDashboard = Boolean(location.match(/\/dashboard\/live\/project\/\d+$/));
  const isForceChangePassword = Boolean(location.match(/\/force_change_password/));//location.pathname.includes('/force_change_password')
  const isDepot = location.includes('depot');
  let headerMode;
  let themeColor: 'dark' | 'light';
  switch (true) {
    case isDashboard:
      headerMode = HeaderMode.TRANSPARENT;
      break;
    case isHome:
      headerMode = HeaderMode.HIDDEN;
      break;
    case isDepot:
      headerMode = HeaderMode.LIGHT;
      break;
    case isForceChangePassword:
      headerMode = HeaderMode.HIDDEN;
      break;

    default:
      headerMode = HeaderMode.DEFAULT;
      break;
  }

  themeColor =
    headerMode === HeaderMode.DARK ||
      headerMode === HeaderMode.TRANSPARENT ||
      headerMode === HeaderMode.DEFAULT
      ? 'dark'
      : 'light';

  return { headerMode, themeColor, isDashboard };
};
