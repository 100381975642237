import { useEffect } from 'react';
import {
  EventDetails,
  WindowEventService,
  WindowEvents,
} from '@electreon_ui/shared/services/WindowEventService';
import { RootStore } from 'MobxStores/RootStore';
import { useAppStore } from 'MobxStores/context';
import LogRocket from 'logrocket';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { isLocalDevEnv } from 'config';

type EventCallbacks = {
  [K in WindowEvents]?: (
    event: CustomEvent<EventDetails[K]>,
    rootStore: RootStore,
    navigate: NavigateFunction
  ) => void;
};

const eventsAndCallbacks: EventCallbacks = {
  [WindowEvents.STALE_MESSAGE]: (evt, rootStore) => {
    const { messageType, deviceId, versions } = evt.detail;
    LogRocket.captureException(
      new Error(
        `Stale message received for device ${deviceId} of type ${messageType}. Versions: ${JSON.stringify(versions)}`
      ),
      { tags: { staleMessage: messageType } }
    );
  },
  [WindowEvents.LOG_EXCEPTION]: (evt, rootStore) => {
    const { message } = evt.detail;
    LogRocket.captureException(new Error(message));
  },
  [WindowEvents.ANNOUNCE_APP_VERSION]: (evt, rootStore) => {
    const { appName, version } = evt.detail;
    rootStore.appVersions[appName] = version;
  },
  [WindowEvents.REFRESH_SESSION_FAILED]: (evt, rootStore, navigate) => {
    LogRocket.captureException(
      new Error(`Session refresh failed for refresh token ${evt.detail?.RefreshToken}`),
      { tags: { refreshSessionFailed: true } }
    );
    navigate('/');
  },
};

export const useWindowEvents = () => {
  const rootStore = useAppStore();
  const navigate = useNavigate();
  if (isLocalDevEnv) window.eventService = WindowEventService;

  useEffect(() => {
    const subscriptions: Array<() => void> = [];

    Object.entries(eventsAndCallbacks).forEach(([event, callback]) => {
      if (callback) {
        WindowEventService.subscribe(event as WindowEvents, (evt) =>
          callback(evt as CustomEvent<any>, rootStore, navigate)
        );
        subscriptions.push(() =>
          WindowEventService.unsubscribe(event as WindowEvents, (evt) =>
            callback(evt as CustomEvent<any>, rootStore, navigate)
          )
        );
      }
    });

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [rootStore]);
};
