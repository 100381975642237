import { CSSProperties } from 'react';

interface SpacerProps {
  height?: CSSProperties['height'];
  width?: CSSProperties['width'];
  margin?: CSSProperties['margin'];
  backgroundColor?: CSSProperties['backgroundColor'];
}

export const Spacer: React.FC<SpacerProps> = ({
  height = '100%',
  width = '1px',
  margin = '0',
  backgroundColor = 'transparent',
}) => {
  return (
    <div
      style={{
        height,
        width,
        margin,
        backgroundColor,
      }}
    />
  );
};
