export enum AccessLevels {
  ALLOWED = 'allowed',
  LIMITED = 'limited',
  DENIED = 'denied',
}

export enum Functionalities {
  HEADER = "header",
  HEADER_LOGO = "header-logo",
  HEADER_SEARCH = "header-search",
  HEADER_BELL = "header-bell",
  HEADER_CONTROL_PANEL = "header-control-panel",
  HEADER_ACCOUNT = "header-account",
  HEADER_TIME = "header-time",
  HEADER_FLAG = "header-flag",
  PROJECTS = "projects",
  TOGGLE_EMPTY_PROJECTS = "toggle-empty-projects",
  PROJECTS_CARDS = "projects-cards",
  PROJECTS_CARD_MENU = "projects-card-menu",
  DEPOT = "depot",
  DEPOT_SPOT_TOGGLE = "depot-spot-toggle",
  DEPOT_VEHICLE_TAB = "depot-vehicle-tab",
  DEPOT_SPOT_TAB = "depot-spot-tab",
  DEPOT_SORT_AND_FILTER = "depot-sort-and-filter",
  DEPOT_POPUP_DEVICE_ID = "depot-popup-device-id",
  DEPOT_VEHICLE_CHARGING_SESSIONS = "depot-vehicle-charging-sessions",
  DEPOT_VEHICLE_SESSIONS_SELECT_DATE = "depot-vehicle-sessions-select-date",
  DEPOT_VEHICLE_CHARGING_SESSIONS_AVERAGE_POWER = "depot-vehicle-charging-sessions-average-power",
  DEPOT_SPOT_CHARGING_SESSIONS = "depot-spot-charging-sessions",
  DEPOT_SPOT_SESSIONS_SELECT_DATE = "depot-spot-sessions-select-date",
  DEPOT_SPOT_CHARGING_SESSIONS_AVERAGE_POWER = "depot-spot-charging-sessions-average-power",
  DEPOT_MAP = "depot-map",
  DEPOT_MAP_VEHICLE_ETF = "depot-map-vehicle-etf",
  DEPOT_MAP_VEHICLE_POWER = "depot-map-vehicle-power",
  DASHBOARD = "dashboard",
  DASHBOARD_TOGGLE_SINGLE_MULTI_VEHICLE = "dashboard-toggle-single-multi-vehicle",
  ALERTS_BELL_POPUP = "alerts-bell-popup",
  ALERTS_SORT = "alerts-sort",
  ALERTS_FILTER = "alerts-filter",
  ALERTS_NEXT_PREVIOUS_PAGE = "alerts-next-previous-page",
  ALERTS_READ_UNREAD = "alerts-read-unread",
  ALERTS_READ_ALL = "alerts-read-all",
  INTEGRATION = "integration",
  INTEGRATION_DEVICE_SIDEBAR = "integration-device-sidebar",
  INTEGRATION_GA_DEVICES = "integration-ga-devices",
  INTEGRATION_VU_DEVICES = "integration-vu-devices",
  INTEGRATION_DEVICE_OPTIONS_MENU = "integration-device-options-menu",
  DEVICE_ENERGY_METER_CHANGE = "device-energy-meter-change",
  INTEGRATION_MU = "integration-mu",
  MU_LOCK = "mu-lock",
  MU_MAN_AUTO = "mu-man-auto",
  MU_LV_POWER_SUPPLY = "mu-lv-power-supply",
  MU_HV_POWER_TOGGLE = "mu-hv-power-toggle",
  MU_VERSIONS_TOOLTIP = "mu-versions-tooltip",
  MU_RACK_IDLE = "mu-rack-idle",
  MU_RACK_OFF = "mu-rack-off",
  MU_ALL_FANS_FORCE_ON = "mu-all-fans-force-on",
  MU_COMM_GAIN_CONTROL = "mu-comm-gain-control",
  MU_RACK_CALIBRATE = "mu-rack-calibrate",
  MU_FPGA_CONFIG_VIEW = "mu-fpga-config-view",
  MU_FPGA_CONFIG_PARAMETERS = "mu-fpga-config-parameters",
  MU_FPGA_CONFIG_SAVE_DEFAULT_APPLY = "mu-fpga-config-save-default-apply",
  MU_FPGA_CONFIG_LOAD = "mu-fpga-config-load",
  MU_FPGA_VERSIONS = "mu-fpga-versions",
  MU_SEGMENT_OFF = "mu-segment-off",
  MU_SEGMENT_IDLE = "mu-segment-idle",
  MU_SEGMENT_FORCE_ON = "mu-segment-force-on",
  MU_SEGMENT_FORCE_COMM = "mu-segment-force-comm",
  MU_SEGMENT_FORCE_PWM = "mu-segment-force-pwm",
  MU_SEGMENT_BAD_COMM = "mu-segment-bad-comm",
  MU_VOLTAGE = "mu-voltage",
  MU_I_RMS = "mu-i-rms",
  MU_POWER = "mu-power",
  MU_I_ZERO = "mu-i-zero",
  MU_I_A = "mu-i-a",
  MU_I_B = "mu-i-b",
  MU_FREQUENCY_INDICATED = "mu-frequency-indicated",
  MU_DUTY_CYCLE_SLIDER = "mu-duty-cycle-slider",
  MU_RF_COMM = "mu-rf-comm",
  MU_SEGMENT_TEMPERATURES = "mu-segment-temperatures",
  MU_FILTER_TEMPERATURES = "mu-filter-temperatures",
  MU_RESONANCE_FREQUENCY = "mu-resonance-frequency",
  MU_PHASE = "mu-phase",
  MU_WORKING_FREQUENCY = "mu-working-frequency",
  MU_DUTY_CYCLE_NUMERIC = "mu-duty-cycle-numeric",
  SMU_HYBRID_STATIC_DYNAMIC = "smu-hybrid-static-dynamic",
  SMU_HYBRID_RACK_IDLE = "smu-hybrid-rack-idle",
  SMU_HYBRID_PWM_ON_TIME = "smu-hybrid-pwm-on-time",
  SMU_HYBRID_PWM_OFF_TIME = "smu-hybrid-pwm-off-time",
  SMU_HYBRID_TARGET_POWER = "smu-hybrid-target-power",
  INTEGRATION_VU = "integration-vu",
  VU_LOCK = "vu-lock",
  VU_FORCE_WAKEUP = "vu-force-wakeup",
  VU_VERSIONS = "vu-versions",
  VU_COMM_LEVEL = "vu-comm-level",
  VU_CHARGING_POSSIBLE_CLOUD = "vu-charging-possible-cloud",
  VU_REPARK = "vu-repark",
  VU_OUT_OF_SERVICE = "vu-out-of-service",
  VU_GPS = "vu-GPS",
  VU_CAN = "vu-CAN",
  VU_RECEIVER_OFF_EV = "vu-receiver-off-ev",
  VU_RECEIVER_FAN_FORCE_ON = "vu-receiver-fan-force-on",
  INTEGRATION_GRAPHS = "integration-graphs",
  INTEGRATION_GRAPHS_TIMESPAN_SELECT = "integration-graphs-timespan-select",
  INTEGRATION_EDIT_Y_DOMAIN = "integration-edit-y-domain",
  INTEGRATION_OCPP = "integration-ocpp",
  OCPP_CONNECTOR_TOGGLE = "ocpp-connector-toggle",
  OCPP_TRIGGER_COMMANDS = "ocpp-trigger-commands",
  ANALYTICS = "analytics",
  ANALYTICS_ADD_PARAMS = "analytics-add-params",
  ANALYTICS_PARAMS_POWER_SELECT_BUTTON = "analytics-params-power-select-button",
  ANALYTICS_PARAMS_VU_EXPAND = "analytics-params-vu-expand",
  ANALYTICS_PARAMS_VU_DC_SAMPLER = "analytics-params-vu-dc-sampler",
  ANALYTICS_PARAMS_VU_GENERAL = "analytics-params-vu-general",
  ANALYTICS_PARAMS_VU_CONTROL_UNIT = "analytics-params-vu-control-unit",
  ANALYTICS_PARAMS_VU_CAN_BUS = "analytics-params-vu-can-bus",
  ANALYTICS_PARAMS_VU_GPS = "analytics-params-vu-gps",
  ANALYTICS_PARAMS_VU_RECEIVERS = "analytics-params-vu-receivers",
  ANALYTICS_PARAMS_VU_GPIOS = "analytics-params-vu-gpios",
  ANALYTICS_PARAMS_VU_POSITIONING_SYSTEM = "analytics-params-vu-positioning-system",
  ANALYTICS_PARAMS_MU_EXPAND = "analytics-params-mu-expand",
  ANALYTICS_PARAMS_MU_DC_SAMPLER = "analytics-params-mu-dc-sampler",
  ANALYTICS_PARAMS_MU_GENERAL = "analytics-params-mu-general",
  ANALYTICS_PARAMS_MU_FPGA = "analytics-params-mu-fpga",
  ANALYTICS_PARAMS_MU_GPIOS = "analytics-params-mu-gpios",
  ANALYTICS_PARAMS_MU_SENSORS = "analytics-params-mu-sensors",
  ANALYTICS_PARAMS_MU_FANS_BOARDS = "analytics-params-mu-fans-boards",
  ANALYTICS_PARAMS_OK_BUTTON = "analytics-params-ok-button",
  ANALYTICS_ACTIVITY_BY_SELECT = "analytics-activity-by-select",
  ANALYTICS_DATE_SELECT = "analytics-date-select",
  ANALYTICS_EXPORT = "analytics-export",
  ANALYTICS_EXPORT_CHOOSE_RESOLUTION = "analytics-export-choose-resolution",
  ANALYTICS_ACTIVITY_BAR_BUTTON = "analytics-activity-bar-button",
  ANALYTICS_SPAN_SELECT = "analytics-span-select",
  ANALYTICS_EVENTS = "analytics-events",
  ANALYTICS_MAP = "analytics-map",
  ANALYTICS_EXTRA_PAYLOAD = "analytics-extra-payload",
  REPORTS = "reports",
}
interface FunctionalityRoles {
  SUPER_ADMIN: boolean | 'limited';
  ADMIN: boolean | 'limited';
  INTEGRATOR: boolean | 'limited';
  OPERATOR: boolean | 'limited';
  EMPLOYEE: boolean | 'limited';
  PROJECT_USER: boolean | 'limited';
  PROJECT_VIEWER: boolean | 'limited';
}
export type FunctionalitiesMap = { [key in Functionalities]: FunctionalityRoles };
