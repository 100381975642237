import { InputAdornment, TextField } from '@mui/material';
import { InputAdornmentIcon } from 'Components/Forms/CreateDevicePopup/utils/InputAdormentIcon';
import { useFormContext } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useAppStore } from 'MobxStores/context';
import { useRoadNameAvailability } from '../Hooks/useRoadNameAvailability';

interface RoadNameInputProps {
  currentRoadName: string;
}

export const RoadNameInput: React.FC<RoadNameInputProps> = ({ currentRoadName }) => {
  const [roadName, setRoadName] = useState<string>(currentRoadName);
  const { projectStore } = useAppStore();
  const { roadNameAvaliable } = useRoadNameAvailability(
    currentRoadName,
    roadName,
    projectStore?.selectedProject?.id
  );
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setRoadName(currentRoadName);
    setValue('roadName', currentRoadName);
  }, [currentRoadName, setValue]);

  return (
    <TextField
      sx={{ mb: 2 }}
      label='Rode Name'
      fullWidth
      type='roadName'
      error={!!errors['roadName']}
      helperText={errors['roadName'] ? errors['roadName'].message?.toString() : ''}
      value={roadName}
      {...register('roadName')}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <InputAdornmentIcon isValid={roadNameAvaliable} />
          </InputAdornment>
        ),
      }}
      onChange={(e: any) => {
        setRoadName(e.target.value);
      }}
    />
  );
};
