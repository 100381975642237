import { EnvType } from '@electreon_ui/shared/types/globals';
import { isLocalDevEnv, env } from 'config';

const redirectUrl = isLocalDevEnv
  ? 'http://localhost:3000/oauth2/callback'
  : env === EnvType.Dev
    ? 'https://dev.electreon.com/oauth2/callback'
    : env === EnvType.Stage
      ? 'https://stage.electreon.com/oauth2/callback'
      : env === EnvType.Prod
        ? 'https://app.electreon.com/oauth2/callback'
        : 'https://dev.electreon.com/oauth2/callback';

const electreonCognitoClientID = import.meta.env.VITE_APP_CLIENT_ID || 'lbu3mqk89oqjg4ob0m9vef8vn';
const clientSecret =
  import.meta.env.VITE_CLIENT_SECRET || '1cgqlgtd3v22rtpu43j9s5skb7152hpafcf6238ampev684fqgjv';
const userPoolId = import.meta.env.VITE_USER_POOL_ID || 'eu-west-1_d8ggWXCyf';
const region = import.meta.env.VITE_REGION || 'eu-west-1';
const staticAssetsUrl = import.meta.env.VITE_STATIC_ASSETS || 'd2861i1yki41mw.cloudfront.net';

export const AWS_CONFIG = {
  COGNITO: {
    REGION: region,
    USER_POOL_ID: userPoolId,
    APP_CLIENT_ID: electreonCognitoClientID,
    CLIENT_SECRET: clientSecret,
    FEDERATED_SIGN_IN_URL: `https://${env}-electreon.auth.${region}.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${electreonCognitoClientID}`,
    TOKEN_ENDPOINT: `https://${env}-electreon.auth.${region}.amazoncognito.com/oauth2/token`,
  },
  S3: {
    REGION: region,
    BUCKET: 'dev-electreon-frontend',
  },
  STATIC_ASSETS: `https://${staticAssetsUrl}`,
} as const;
