import { Alert, Box, Button, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { api } from 'Services/api';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import DeleteIcon from '@mui/icons-material/Delete';
import { PopupWrapper } from 'Components/Forms/PopupWrapper';
import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { MultiPolygonCoordinates } from 'Components/Map/MapLoader';
import { Map } from 'Components/Map/Map';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';

const createDepotSchema = object({
  depotName: string().min(1, 'Depot name is required'),
  projectName: string().optional(),
});

const createDepotDefaultValues = {
  depotName: '',
  projectName: '',
};

type CreateDepotInput = TypeOf<typeof createDepotSchema>;

export const CreateDepotForm: React.FC<FormProps> = ({ onSuccessfulSubmit, onCancel, selectedProject }) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [geoFenceModalOpen, setGeoFenceModalOpen] = useState(false);
  const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    handleSubmit,
  } = useForm<CreateDepotInput>({
    resolver: zodResolver(createDepotSchema),
    defaultValues: createDepotDefaultValues,
  });

  const onSubmitHandler: SubmitHandler<CreateDepotInput> = (values) => {
    setLoading(true);
    setSubmitError(null);

    const coordinates: MultiPolygonCoordinates = polygons.map((polygon) => {
      const path = polygon.getPath();
      const points = path.getArray().map((point) => [[point.lat(), point.lng()]]);
      return points;
    });

    const geoJsonMultipolygon = {
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'MultiPolygon',
            coordinates,
          },
        },
      ],
    };

    const depot: Depot = {
      projectId: selectedProject?.id,
      name: values.depotName,
      geoFence: JSON.stringify(geoJsonMultipolygon),
    };

    api.metadata.depots
      .createDepot(depot)
      .then((res) => {
        toast.success('Depot created');
        reset(createDepotDefaultValues);
        onSuccessfulSubmit?.();
      })
      .catch((err) => {
        toast.error('Create depot failed');
        console.error('Create depot failed:', JSON.stringify(err));
        setSubmitError('Create depot failed');
        throw new Error('Create depot failed');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setModalState = ({ isOpen }: { isOpen: boolean }) => {
    setGeoFenceModalOpen(isOpen);
  };

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Create Depot
      </Typography>

      <Stack component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <SelectProject selectedProject={selectedProject} errors={errors} />
        <TextField
          sx={{ mb: 2 }}
          label='Depot Name'
          fullWidth
          error={!!errors['depotName']}
          helperText={errors['depotName'] ? errors['depotName'].message : ''}
          {...register('depotName')}
        />
        <Button
          variant='outlined'
          sx={{ minWidth: '200px', alignSelf: 'center', mt: 3 }}
          onClick={() => setModalState({ isOpen: true })}
        >
          {polygons.length > 0 ? 'Edit Geofence' : 'Add Geofence'}
        </Button>
        <Stack direction='row' sx={{ placeContent: 'center' }}>
          <FormHelperText sx={{ mb: 2, alignSelf: 'center' }}>
            <Typography
              component='span'
              sx={{
                display: 'block',
                px: 2,
                pt: 1,
                color: (theme) =>
                  polygons.length > 0 ? theme.palette.accent.primary.main : theme.palette.accent.red.main,
              }}
            >
              {polygons.length > 0
                ? `${polygons.length} geo-fence${polygons.length > 1 ? 's' : ''} selected`
                : 'No geo-fence selected'}
            </Typography>
          </FormHelperText>
          {polygons.length > 0 && (
            <Box
              sx={{
                alignSelf: 'center',
                cursor: 'pointer',
                '&:hover': { color: (theme) => theme.palette.accent.red.light },
              }}
              onClick={() => {
                setPolygons([]);
              }}
            >
              <DeleteIcon />
            </Box>
          )}
        </Stack>
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Create Depot' />
      </Stack>

      {/* Map popup */}
      <PopupWrapper
        open={geoFenceModalOpen}
        onClose={() => setModalState({ isOpen: false })}
        wrapperStyles={{
          width: '70vw',
          height: '65vh',
          padding: 0,
        }}
      >
        <Map
          polygons={polygons}
          setPolygons={setPolygons}
          onSubmitted={() => setModalState({ isOpen: false })}
        />
      </PopupWrapper>
    </>
  );
};
CreateDepotForm.displayName = 'CreateDepotForm';
