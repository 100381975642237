import { convertedSummaryTimeSpan } from 'Utils/AnalyticsUtils';
import { ACTIVITY_GAP_THRESHOLD } from 'Utils/DefaultValues/AnalyticsDefaultValues';
import {
  format,
  subDays,
  set,
  addMinutes,
  isSameDay,
  isSameMinute,
  getSeconds,
  subSeconds,
  addSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const getFormattedTableData = (tableData, selectedVuId, spanValue, selectedVuName) => {
  const calcSpan = convertedSummaryTimeSpan(
    tableData[selectedVuId].startTime,
    tableData[selectedVuId].endTime
  );

  const calcRoundedDistance = (speed, span) => {
    return Math.round((((speed * 1000) / 60) * (span / 60)) / 5) * 5;
  };

  const resObject = {
    deviceId: selectedVuName || selectedVuId,
    start: format(new Date(tableData[selectedVuId].startTime), 'dd/MM/yy HH:mm:ss.SSS'),
    end: format(new Date(tableData[selectedVuId].endTime), 'dd/MM/yy HH:mm:ss.SSS'),
    time: calcSpan,
    SocStart:
      tableData[selectedVuId].stateOfCharge?.stateOfChargeStart === 0
        ? 0
        : tableData[selectedVuId].stateOfCharge?.stateOfChargeStart || null,
    SocEnd:
      tableData[selectedVuId].stateOfCharge?.stateOfChargeEnd === 0
        ? 0
        : tableData[selectedVuId].stateOfCharge?.stateOfChargeEnd || null,
    VuStart:
      tableData[selectedVuId].stateOfCharge?.voltageStart === 0
        ? 0
        : Math.round(tableData[selectedVuId].stateOfCharge?.voltageStart) || null,
    VuEnd:
      tableData[selectedVuId].stateOfCharge?.voltageEnd === 0
        ? 0
        : Math.round(tableData[selectedVuId].stateOfCharge?.voltageEnd) || null,
    speed: Math.round(tableData[selectedVuId].averageGpsSpeed) || null,
    distance: calcRoundedDistance(tableData[selectedVuId].averageGpsSpeed, spanValue) || null,
    receivers: tableData[selectedVuId].averageReceiversNumber,
    EnrgMu: tableData[selectedVuId].muEnergySummary?.totalEnergy?.toFixed(0) || null,
    EnrgVu: tableData[selectedVuId].vuEnergySummary?.totalEnergy?.toFixed(0) || null,
    AvgPower: (tableData[selectedVuId].averagePower / 1000 || null)?.toFixed(1),
    PwrEfficiency: tableData[selectedVuId].efficiency || null,
    EnrgDist: Math.round(tableData[selectedVuId].vuTotalEnergyReceivedPerDistance) || null,
  };

  return resObject;
};

export const getFormattedTableDataToDisplay = (data) => {
  const Soc =
    data.SocStart && data.SocEnd
      ? `${data.SocStart}-${data.SocEnd}`
      : data.SocStart && !data.SocEnd
        ? `${data.SocStart}`
        : !data.SocStart && data.SocEnd
          ? `${data.SocEnd}`
          : null;
  const Voltage =
    data.VuStart && data.VuEnd
      ? `${data.VuStart}-${data.VuEnd}`
      : data.VuStart && !data.VuEnd
        ? `${data.VuStart}`
        : !data.voltageStart && data.VuEnd
          ? `${data.VuEnd}`
          : null;
  return {
    Soc: Soc,
    Voltage: Voltage,
    ...data,
  };
};

export function createTimeSeries(timezoneStr, data, activityDeviceId, startTime, currentDate) {
  let initialStartTime;
  let finalStartTime;
  let firstActivityTime;

  function createTimestamps(startTime) {
    const timestamps = Array.from({ length: 2161 }, (_, i) => addMinutes(startTime, i).valueOf());
    return timestamps;
  }

  const timestamps = createTimestamps(startTime);
  const dataMap = new Map();

  if (data && data.length > 0) {
    for (const record of data) {
      // offset the timestamp to match graph being displayed in local time
      dataMap.set(utcToZonedTime(record.timestamp, timezoneStr).valueOf(), record);
    }
  }

  const filledTimeSeries = [];

  for (const timestamp of timestamps) {
    const matchingRecord = dataMap.get(timestamp);
    if (matchingRecord) {
      filledTimeSeries.push({
        ...matchingRecord,
        timestamp: timestamp,
      });

      const isActivityDevice = matchingRecord.deviceId === activityDeviceId;
      const isRecordCharging = Boolean(matchingRecord.chargeActive);
      const isRecordInCurrentDate = isSameDay(timestamp, currentDate);

      if (isActivityDevice && isRecordCharging && isRecordInCurrentDate) {
        finalStartTime = filledTimeSeries[filledTimeSeries.length - 1].timestamp;

        const initialIndexCandidate = filledTimeSeries.length - ACTIVITY_GAP_THRESHOLD - 1; // 30 minutes earlier, assuming one record per minute

        if (initialIndexCandidate >= 0) {
          initialStartTime = filledTimeSeries[initialIndexCandidate].timestamp;
        }
        if (!firstActivityTime) {
          firstActivityTime = filledTimeSeries[filledTimeSeries.length - 1].timestamp;
        }
      }
    } else {
      filledTimeSeries.push({
        timestamp: timestamp,
        value: null,
      });
    }
  }

  return {
    filledTimeSeries,
    initialStartTime,
    finalStartTime,
    firstActivityTime,
  };
}

export const getStartAndEndTime = (selectedDate) => {
  const startTime = set(subDays(selectedDate, 1), {
    hours: 18,
    minutes: 0,
    seconds: 0,
  });
  return { startTime };
};

/**
 * @param { Date | number | string } start
 * @param { Date | number | string } end
 * @returns
 */
export const padTimeTo = (start, end) => {
  const startUnit = getSeconds(start);
  const endUnit = getSeconds(end);
  const startDiff = startUnit === 0 ? 0 : 1;
  const endDiff = endUnit === 0 ? 0 : 1;
  const newStart = subSeconds(start, startDiff);
  const newEnd = addSeconds(end, endDiff);
  return { newStart, newEnd };
};

export const roundUpToNearest10 = (value) => Math.ceil(value / 10) * 10;

export const roundUpToNearest10k = (value) => Math.ceil(value / 10000) * 10000;

export const roundUpToNearest100k = (value) => Math.ceil(value / 100000) * 100000;

export const roundUpToNearest1k = (value) => Math.ceil(value / 1000) * 1000;
