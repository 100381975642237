import React, { ComponentProps, useEffect } from 'react';
import { useAppStore } from 'MobxStores/context';
import { useNavigate } from 'react-router-dom';
import { Functionalities, getAccessLevel } from '@electreon_ui/shared/permissions';
import { AccessLevels } from '@electreon_ui/shared/permissions/types/permissionsTypes';
import { observer } from 'mobx-react-lite';

type AuthWrapperProps<T = ComponentProps<any>> = {
  ComponentToProtect: React.ComponentType<T>;
  restrictBy?: Functionalities;
  override?: boolean | (() => boolean);
  props?: T;
};

export const WithAuth = observer(
  <T,>({ ComponentToProtect, restrictBy, override, props }: AuthWrapperProps<T>) => {
    const { authStore, userStore } = useAppStore();
    const { IdToken, AccessToken, RefreshToken } = authStore;
    const tokens = { IdToken, AccessToken, RefreshToken };
    const [authChecked, setAuthChecked] = React.useState(false);
    const navigate = useNavigate();

    const shouldOverride = typeof override === 'function' ? override() : override;

    const permissionGranted =
      shouldOverride || !restrictBy || getAccessLevel(restrictBy, userStore.userData) !== AccessLevels.DENIED;

    useEffect(() => {
      if (!authStore.IdToken) {
        authStore.logout();
        navigate('/');
        setAuthChecked(true);
      } else if (authStore.shouldAcquireToken) {
        console.info('IdToken is expired, attempting to refresh session...');
        authStore
          .refreshSession()
          .then(() => setAuthChecked(true))
          .catch((err) => {
            console.info('Error refreshing session', err);
            authStore.logout();
            navigate('/');
            setAuthChecked(true);
          });
      } else {
        setAuthChecked(true);
      }
    }, [authStore, navigate]);

    if (!authChecked) return null;
    if (!permissionGranted) return null;
    return <ComponentToProtect {...tokens} {...(props as T)} />;
  }
);
