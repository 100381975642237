import { Alert, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import { ConfirmationPopup } from 'Components/Forms/FormUtils/ConfirmationPopup';
import { SegmentsFields } from 'Components/Forms/ParkingSpotForm/ParkingSpotsFields/SegmentsFields';
import { CreateParkingSpotsInput } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotSchema';
import { getFirstAvailableSegmentNum } from 'Components/Forms/ParkingSpotForm/utils/createParkingSpotUtils';
import { isFalseyButNotZero } from 'Utils/utils';
import { useState } from 'react';
import { Control, FieldErrors, useFormContext, useWatch } from 'react-hook-form';

const defaultLng = '34.862906';
const defaultLat = '32.371288';

export function ParkingSpotsWithSegmentsFields({
  errors,
  projectId,
  maxParkingSpots,
  control,
}: {
  errors: FieldErrors<CreateParkingSpotsInput>;
  projectId?: number;
  maxParkingSpots: number | undefined;
  control: Control<CreateParkingSpotsInput>;
}) {
  const form = useWatch({ control });
  const muId = form.muId;
  const { reset, register } = useFormContext();
  const parkingSpots = form.parkingSpots;
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const isMaxParkingSpotsReached = () => {
    if (!maxParkingSpots) return true;
    const parkingSpotCount = parkingSpots?.filter((spot) => spot.muId === muId).length || 0;
    return parkingSpotCount >= maxParkingSpots;
  };
  const handleDeleteParkingSpot = (parkingSpots: any, parkingIndex: number) => {
    const filteredParkingSpots = [...parkingSpots].filter((spot) => spot.muId === muId);
    filteredParkingSpots.splice(parkingIndex, 1);
    reset(
      {
        ...form,
        parkingSpots: filteredParkingSpots,
      },
      { keepDirtyValues: true }
    );
  };

  const handleAddSegment = (parkingIndex: number) => {
    reset(
      {
        ...form,
        parkingSpots: [
          ...(parkingSpots?.map((prevSpot, prevSpotIndex) => {
            if (!(prevSpotIndex === parkingIndex)) return prevSpot;

            if (!prevSpot.segments) {
              prevSpot.segments = [];
            }

            prevSpot.segments.push({
              segmentNum: getFirstAvailableSegmentNum(form.parkingSpots || []),
              muId: prevSpot.muId,
              projectId: projectId,
              longitude: Number(defaultLng),
              latitude: Number(defaultLat),
            });

            return prevSpot;
          }) || []),
        ],
      },
      { keepDirtyValues: true }
    );
  };

  return (
    <Stack alignItems='center'>
      <Stack spacing={2}>
        {parkingSpots &&
          parkingSpots
            .filter((spot) => spot.muId === muId)
            .map((spot, parkingIndex) => {
              return (
                <Grid
                  item
                  sm={12}
                  key={`parkingSpot-${spot.id || parkingIndex}`}
                  marginY={2}
                  p={2}
                  sx={{
                    position: 'relative',
                    border: (theme) => `1px solid ${theme.palette.accent.secondary.main}`,
                  }}
                >
                  <IconButton
                    aria-label='delete'
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                    }}
                    onClick={() => setIsConfirmPopupOpen(true)}
                  >
                    <GridDeleteIcon />
                  </IconButton>
                  <ConfirmationPopup
                    isOpen={isConfirmPopupOpen}
                    setIsOpen={setIsConfirmPopupOpen}
                    confirmTitle='Are you sure you want to delete Parking Spot?'
                    confirmMessage='Parking Spot will be permanently deleted!'
                    onSubmitHandler={() => handleDeleteParkingSpot(parkingSpots, parkingIndex)}
                  />
                  <Typography variant='subtitle1' component='h3' sx={{ mb: '1rem' }}>
                    Parking Spot {parkingIndex + 1}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label='Name'
                        error={!!errors.parkingSpots?.[parkingIndex]?.name}
                        helperText={errors.parkingSpots?.[parkingIndex]?.name?.message}
                        {...register(`parkingSpots.${parkingIndex}.name`)}
                        defaultValue={spot.name || ''}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField
                        fullWidth
                        label='Long'
                        error={!!errors.parkingSpots?.[parkingIndex]?.longitude}
                        helperText={errors.parkingSpots?.[parkingIndex]?.longitude?.message}
                        {...register(`parkingSpots.${parkingIndex}.longitude`)}
                        defaultValue={spot.longitude || defaultLng}
                        placeholder={defaultLng}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField
                        fullWidth
                        label='Lat'
                        error={!!errors.parkingSpots?.[parkingIndex]?.latitude}
                        helperText={errors.parkingSpots?.[parkingIndex]?.latitude?.message}
                        {...register(`parkingSpots.${parkingIndex}.latitude`)}
                        defaultValue={spot.latitude || defaultLat}
                        placeholder={defaultLat}
                      />
                    </Grid>
                  </Grid>
                  <Grid item sm={12} mt={2}>
                    <SegmentsFields
                      spot={spot}
                      errors={errors}
                      parkingIndex={parkingIndex}
                      control={control}
                    />
                    <Stack alignItems='center' mt={2}>
                      <Button
                        variant='outlined'
                        color='primary'
                        startIcon={<div>+</div>}
                        onClick={() => handleAddSegment(parkingIndex)}
                      >
                        Add Segment
                      </Button>
                    </Stack>
                    {/* if no segments exist display alert */}
                    {spot.segments?.length === 0 && (
                      <Stack sx={{ placeItems: 'center', mt: 2 }}>
                        <Alert severity='warning'>At least one segment is required</Alert>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              );
            })}
      </Stack>
      <Button
        disabled={isMaxParkingSpotsReached()}
        fullWidth
        variant='outlined'
        color='primary'
        startIcon={!isMaxParkingSpotsReached ? <div>+</div> : null}
        sx={{ mt: '1rem' }}
        onClick={() => {
          reset(
            {
              ...form,
              parkingSpots: [
                ...(parkingSpots || []),
                {
                  id: undefined,
                  muId: muId,
                  name: `P-${(parkingSpots?.length ?? 0) + 1} ${muId}`,
                  segments: [],
                  longitude: Number(defaultLng),
                  latitude: Number(defaultLat),
                },
              ].filter((spot) => spot.muId === form.muId),
            },
            { keepDirtyValues: true }
          );
        }}
      >
        {isFalseyButNotZero(maxParkingSpots)
          ? "Num of contactors not configured. Can't add parking spots."
          : !isMaxParkingSpotsReached()
            ? 'Add Parking Spot'
            : `Maximum Spots Reached - ${maxParkingSpots}`}
      </Button>
    </Stack>
  );
}
