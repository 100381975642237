import {
  ManagementUnitV2FpgaFotaType,
  SomFOTAType,
} from '@electreon/electreon-device-control-service-gen-ts-client';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import {
  muCommBoardFota,
  muCommBoardVersionExists,
  muDcSamplerFota,
  muDcSamplerVersionExists,
  muFansBoardFota,
  muFansBoardVersionExists,
  muFpgaFota,
  muFpgaSdCardImageVersionExists,
  muFpgaVersionExists,
  muSomFota,
  muSomVersionExists,
  vuDcSamplerFota,
  vuDcSamplerVersionExists,
  vuSomFota,
  vuSomVersionExists,
} from 'Utils/APIUtils/DeviceApiUtils';
import { ROLE_TYPES } from 'Utils/constants';
import { AxiosResponse } from 'axios';
import { object, string, TypeOf } from 'zod';
import { AbstractIoTDeviceModelRefined, AbstractIoTDeviceType } from '@electreon_ui/shared/types/globals';
import { ValuesOf } from '@electreon_ui/shared/types/globals';

export const deviceFotaSchema = object({
  version: string()
    .min(1, 'Version is required')
    .regex(/^\d+\.\d+\.\d+$/, 'Invalid version format. Expected x.y.z'),
  fotaType: string().min(1, 'Fota type is Rrequired'),
  fotaSubType: string().optional(),
});

export type DeviceFotaInput = TypeOf<typeof deviceFotaSchema>;

export interface DeviceFotaOptionsFormProps extends FormProps {
  device: AbstractIoTDeviceModelRefined;
  selectedProject?: Project | null;
}

export enum BasicDeviceFotaOptions {
  SOM_FOTA = 'SOM_FOTA',
  DCSAMPLERS_FOTA = 'DCSAMPLERS_FOTA',
  FPGA_FOTA = 'FPGA_FOTA',
  FANS_BOARD_FOTA = 'FANS_BOARD_FOTA',
  COMM_BOARD_FOTA = 'COMM_BOARD_FOTA',
  FPGA_SD_CARD_IMAGE = 'FPGA_SD_CARD_IMAGE',
}

const DeviceFotaOptionsDisplay = [
  { key: BasicDeviceFotaOptions.SOM_FOTA, text: 'SOM FOTA' },
  { key: BasicDeviceFotaOptions.DCSAMPLERS_FOTA, text: 'DC Samplers FOTA' },
  { key: BasicDeviceFotaOptions.FPGA_FOTA, text: 'FPGA FOTA' },
  { key: BasicDeviceFotaOptions.FANS_BOARD_FOTA, text: 'Fans Board FOTA' },
  { key: BasicDeviceFotaOptions.COMM_BOARD_FOTA, text: 'Comm Board Fota' },
];
export const SomFotaOptionsDisplay = [
  { key: SomFOTAType.AppFotaType, text: 'SOM Application FOTA' },
  { key: SomFOTAType.BootFotaType, text: 'SOM Boot Loader FOTA' },
  { key: SomFOTAType.RootfsFotaType, text: 'SOM Root FS FOTA' },
];
export const FpgaFotaOptionsDisplay = [
  { key: ManagementUnitV2FpgaFotaType.FpgaFirmware, text: 'FPGA Bitstream FOTA' },
  { key: ManagementUnitV2FpgaFotaType.UserSpaceLinuxApp, text: 'FPGA Application FOTA' },
];

export const MuFotaTypes = [...DeviceFotaOptionsDisplay];

export const VuFotaTypes = DeviceFotaOptionsDisplay.filter(
  ({ key, text }) =>
    key !== BasicDeviceFotaOptions.FPGA_FOTA &&
    key !== BasicDeviceFotaOptions.COMM_BOARD_FOTA &&
    key !== BasicDeviceFotaOptions.FANS_BOARD_FOTA
);

export const DeviceFotaApi = (
  deviceId: string,
  deviceType: AbstractIoTDeviceType,
  version: string,
  fotaType: BasicDeviceFotaOptions,
  fotaSubType: string
) => {
  switch (deviceType) {
    case 'MU':
      switch (fotaType) {
        case BasicDeviceFotaOptions.SOM_FOTA:
          return muSomFota(deviceId, version, fotaSubType as SomFOTAType);
        case BasicDeviceFotaOptions.DCSAMPLERS_FOTA:
          return muDcSamplerFota(deviceId, version);
        case BasicDeviceFotaOptions.FANS_BOARD_FOTA:
          return muFansBoardFota(deviceId, version);
        case BasicDeviceFotaOptions.FPGA_FOTA:
          return muFpgaFota(deviceId, version, fotaSubType as ManagementUnitV2FpgaFotaType);
        case BasicDeviceFotaOptions.COMM_BOARD_FOTA:
          return muCommBoardFota(deviceId, version);
        default:
          throw new Error('FOTA Type for MU is not recognized');
      }
    case 'VU':
      switch (fotaType) {
        case BasicDeviceFotaOptions.SOM_FOTA:
          return vuSomFota(deviceId, version, fotaSubType as SomFOTAType);
        case BasicDeviceFotaOptions.DCSAMPLERS_FOTA:
          return vuDcSamplerFota(deviceId, version);
        default:
          throw new Error('FOTA Type for VU is not recognized');
      }
    default:
      throw new Error('Device Type is not MU nor VU');
  }
};

export const checkFotaVersion = (
  version: string,
  deviceType: AbstractIoTDeviceType,
  fotaType: BasicDeviceFotaOptions,
  fotaSubType?: SomFOTAType | ManagementUnitV2FpgaFotaType
) => {
  switch (deviceType) {
    case 'MU':
      switch (fotaType) {
        case BasicDeviceFotaOptions.SOM_FOTA:
          return muSomVersionExists(version, fotaSubType as SomFOTAType);
        case BasicDeviceFotaOptions.DCSAMPLERS_FOTA:
          return muDcSamplerVersionExists(version);
        case BasicDeviceFotaOptions.FANS_BOARD_FOTA:
          return muFansBoardVersionExists(version);
        case BasicDeviceFotaOptions.FPGA_FOTA:
          return muFpgaVersionExists(version, fotaSubType as ManagementUnitV2FpgaFotaType);
        case BasicDeviceFotaOptions.COMM_BOARD_FOTA:
          return muCommBoardVersionExists(version);
        case BasicDeviceFotaOptions.FPGA_SD_CARD_IMAGE:
          return muFpgaSdCardImageVersionExists(version);
        default:
          console.error('FOTA Type for MU is not recognized');
          return Promise.resolve({ data: false } as AxiosResponse<boolean, any>);
      }
    case 'VU':
      switch (fotaType) {
        case BasicDeviceFotaOptions.SOM_FOTA:
          return vuSomVersionExists(version, fotaSubType as SomFOTAType);
        case BasicDeviceFotaOptions.DCSAMPLERS_FOTA:
          return vuDcSamplerVersionExists(version);
        default:
          console.error('FOTA Type for VU is not recognized');
          return Promise.resolve({ data: false } as AxiosResponse<boolean, any>);
      }
    default:
      console.error('Device Type is not MU nor VU');
      return Promise.resolve({ data: false } as AxiosResponse<boolean, any>);
  }
};

export const fotaOptionByPermission = (option: SomFOTAType, role?: ValuesOf<typeof ROLE_TYPES>) => {
  switch (option) {
    case SomFOTAType.AppFotaType:
      return Boolean(role === ROLE_TYPES.SUPER_ADMIN);
    case SomFOTAType.BootFotaType:
      return Boolean(role === ROLE_TYPES.SUPER_ADMIN || role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.INTEGRATOR);
    default:
      return true;
  }
};
