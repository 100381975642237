import type { RuleSeverityEnum } from '@electreon/electreon-metadata-service-gen-ts-client';

export enum AlertReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export type AlertMessage = {
  id: string;
  severity: RuleSeverityEnum;
  alertTime: number;
  projectName: string;
  projectId: number;
  origin: string;
  alertDescription: string;
  isActive: boolean;
  deviceName: string;
};
