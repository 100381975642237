import { Box, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { hasFullPathError, validateInput } from 'Components/Forms/ConfigureShadowForm/configureFormUtils';
import { IndexableManagementUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureMuShadowForm';
import { IndexableVehicleUnitConfiguration } from 'Components/Forms/ConfigureShadowForm/ConfigureVuShadowForm';
import { Spacer } from 'Components/UtilityComponents/Spacer';
import { FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetError } from 'react-hook-form';
import { snakeToTitleCase } from 'Utils/utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const ConfigureDeviceFormFields: React.FC<{
  shadowEntry?: IndexableManagementUnitConfiguration | IndexableVehicleUnitConfiguration | null;
  errors: FieldErrors;
  swaggerSchema: Record<string, any>;
  register: UseFormRegister<any>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<IndexableManagementUnitConfiguration | IndexableVehicleUnitConfiguration>;
  parentKey?: string;
  isExpanded?: boolean;
}> = ({ shadowEntry, errors, swaggerSchema, register, setError, parentKey, clearErrors }) => {
  if (!shadowEntry || Object.keys(shadowEntry).length === 0)
    return (
      <>
        <Skeleton variant='rounded' height={150} />
        <Spacer height={20} />
        <Skeleton variant='rounded' height={150} />
        <Spacer height={20} />
        <Skeleton variant='rounded' height={150} />
        <Spacer height={20} />
        <Skeleton variant='rounded' height={150} />
      </>
    );

  return (
    <>
      {Object.entries(shadowEntry).map(([key, value], index) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        const accordionId = `panel-${fullKey}`;

        if (typeof value === 'object' && value !== null) {
          return (
            <Accordion key={fullKey}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${accordionId}-content`}
                id={`${accordionId}-header`}
              >
                <Typography>{snakeToTitleCase(key)}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <ConfigureDeviceFormFields
                  shadowEntry={value ?? {}}
                  errors={errors}
                  swaggerSchema={swaggerSchema}
                  register={register}
                  setError={setError}
                  parentKey={fullKey}
                  clearErrors={clearErrors}
                />
              </AccordionDetails>
            </Accordion>
          );
        }

        const errorMessage = (errors[fullKey]?.message as string) || (errors[key]?.message as string);
        const fieldType = typeof value === 'number' ? 'string' : typeof value;

        return (
          <Box key={fullKey} sx={{ width: 'fit-content' }}>
            <TextField
              inputProps={{ 'data-fullkey': fullKey }} // will be available as e.target.dataset.fullkey
              label={snakeToTitleCase(key)}
              title={snakeToTitleCase(key)}
              error={hasFullPathError(errors, fullKey) || errors[key]?.type !== undefined}
              defaultValue={value}
              type={fieldType}
              variant='outlined'
              {...register(fullKey, {
                onChange: validateInput(setError, swaggerSchema, clearErrors),
              })}
              helperText={errorMessage || ' '}
            />
          </Box>
        );
      })}
    </>
  );
};
