import React from 'react';
import { Box, Divider, MenuItem } from '@mui/material';
import { ImgListItem } from 'Components/MenuItems/ImgListItem';
import { TextListItem } from 'Components/MenuItems/TextListItem';
import { CustomMenu } from 'Components/Menu/MenuTamplate';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { useAppStore } from 'MobxStores/context';
import { observer } from 'mobx-react-lite';

type MenuProps = {
  anchorEl: null | HTMLElement;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export const ControlPanelMenu: React.FC<MenuProps> = ({ anchorEl, isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <CustomMenu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={handleClose}
      title={<CPHeader />}
      body={<CPBody handleItemClick={handleClose} />}
      footer={null}
      origin={{ vertical: 'top', horizontal: 'center' }}
    />
  );
};
ControlPanelMenu.displayName = 'ControlPanelMenu';

const CPHeader: React.FC = observer(() => {
  return (
    <MenuItem key='User' sx={{ pointerEvents: 'none' }}>
      <TextListItem title='Control Menu' paragraph='' extra='' />
    </MenuItem>
  );
});

const CPBody: React.FC<{ handleItemClick: () => void }> = observer(({ handleItemClick }) => {
  const { userStore, popupStore, projectStore } = useAppStore();
  const { canCreateDevice, canEditDevice } = userStore.userPermissions;
  const selectedProject = projectStore.selectedProject;
  return (
    <Box key='menu-body'>
      <MenuItem
        onClick={() => {
          handleItemClick();
          popupStore.openCreateProjectPopup();
        }}
      >
        <ImgListItem text='Create Project' Logo={CreateNewFolderIcon} />
      </MenuItem>
      {selectedProject && (
        <MenuItem
          onClick={() => {
            handleItemClick();
            popupStore.openEditProjectPopup();
          }}
        >
          <ImgListItem text='Edit Project' Logo={BuildCircleIcon} />
        </MenuItem>
      )}

      {selectedProject && canCreateDevice && (
        <>
          <Divider />
          <MenuItem
            onClick={() => {
              handleItemClick();
              popupStore.openCreateDevicePopup();
            }}
          >
            <ImgListItem text='Create Device' Logo={AddCircleIcon} />
          </MenuItem>
        </>
      )}
      {selectedProject && (
        <>
          <Divider />
          <MenuItem
            onClick={() => {
              handleItemClick();
              popupStore.openCreateDepotPopup();
            }}
          >
            <ImgListItem text='Create Depot' Logo={CreateNewFolderIcon} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleItemClick();
              popupStore.openCreateRoadPopup();
            }}
          >
            <ImgListItem text='Create Road' Logo={CreateNewFolderIcon} />
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              handleItemClick();
              popupStore.openCreateFleetPopup();
            }}
          >
            <ImgListItem text='Create Fleet' Logo={CreateNewFolderIcon} />
          </MenuItem>
          {selectedProject && canEditDevice && (
            <>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleItemClick();
                  popupStore.openEditPOSPopup();
                }}
              >
                <ImgListItem text='Edit POS' Logo={BuildCircleIcon} />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleItemClick();
                  popupStore.openEditAPFCPopup();
                }}
              >
                <ImgListItem text='Edit APFC' Logo={BuildCircleIcon} />
              </MenuItem>
            </>
          )}
          <Divider />
          <MenuItem
            onClick={() => {
              handleItemClick();
              popupStore.openChargingSchedulePopup();
            }}
          >
            <ImgListItem text='Charging Schedule' Logo={HistoryToggleOffIcon} />
          </MenuItem>
        </>
      )}
      <MenuItem
        onClick={() => {
          popupStore.openFirmwareDownloadPopup();
        }}
      >
        <ImgListItem text='Download Firmware Files' Logo={FileDownloadIcon} />
      </MenuItem>
    </Box>
  );
});
CPBody.displayName = 'CPBody';
