import { Autocomplete, MenuItem, TextField } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { useFormContext } from 'react-hook-form';
import { ApfcModelRefined } from '@electreon_ui/shared/types/globals';

interface APFCSelectionInputProps {
  selectedApfc: ApfcModelRefined | null;
  setSelectedApfc: React.Dispatch<React.SetStateAction<ApfcModelRefined | null>>;
}

export const APFCSelectionInput: React.FC<APFCSelectionInputProps> = ({ selectedApfc, setSelectedApfc }) => {
  const { projectStore } = useAppStore();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <Autocomplete
      sx={{ mb: 2 }}
      options={projectStore?.apfcList ?? []}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option as { id: string }).id}
      defaultValue={selectedApfc}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id} defaultValue={option.id}>
          {option.id}
        </MenuItem>
      )}
      onChange={(e: any) => {
        const selectedApfc = projectStore?.apfcList?.find((apfc) => apfc.id === e.target.outerText);
        selectedApfc && setSelectedApfc(selectedApfc);
      }}
      value={selectedApfc}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Device ID'
          fullWidth
          error={!!errors['deviceId']}
          helperText={errors['deviceId'] ? errors['deviceId'].message?.toString() : ''}
          {...register('deviceId')}
          value={String(selectedApfc?.id || '')}
        />
      )}
    />
  );
};
