import { Box, Modal, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';

const style: SxProps = {
  position: 'fixed',
  inset: 0,
  margin: 'auto',
  width: 450,
  height: 'fit-content',
  maxHeight: '80vh',
  overflowY: 'auto',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '7px',
  'input, label, textarea, button, select, option, p, h1, h2, h3, h4, h5, h6, ul, li, span': {
    fontSize: '16px',
    fontFamily: 'Urbanist, sans-serif',
  },

  '& .MuiInputBase-root': {
    height: '56px',
  },
  '& .MuiFormHelperText-root, Mui-FormHelperText-root': {
    fontSize: '12px',
    fontFamily: 'Urbanist, sans-serif',
  },
  '& .MuiAutocomplete-endAdornment': {
    right: '0.15rem',
    top: '7px',

    '& .MuiSvgIcon-root': {
      width: '25px',
      height: '25px',
    },
  },
};

export const disabledTextFieldStyle: SxProps = {
  '& .MuiOutlinedInput-root *': {
    border: 'none',
  },
  '& .MuiOutlinedInput-root': {
    borderBottom: '1px dashed #ccc',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
};

export const PopupWrapper: React.FC<PopupWrapperProps> = observer(
  ({ open, onClose, children, wrapperStyles, zIndex }) => {
    return (
      <Modal open={open} onClose={onClose} sx={{ zIndex }}>
        <Box
          sx={{
            ...style,
            bgcolor: (theme) => theme.palette.secondary.main,
            '& svg': { fontSize: '16px' },
            ...wrapperStyles,
          }}
        >
          {children}
        </Box>
      </Modal>
    );
  }
);
