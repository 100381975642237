import { Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormReset,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { AssignOCPPInput } from './Utils/FormsSchemas';
import { ConfirmationPopup } from '../FormUtils/ConfirmationPopup';
import { useState } from 'react';

const defaultLng = '34.862906';
const defaultLat = '32.371288';

export function OcppParkingSpots({
  reset,
  errors,
  register,
  control,
  ocppName,
}: {
  reset: UseFormReset<AssignOCPPInput>;
  errors: FieldErrors<AssignOCPPInput>;
  register: UseFormRegister<AssignOCPPInput>;
  control: Control<AssignOCPPInput>;
  ocppName: string;
}) {
  const form = useWatch({ control });
  const { getValues, setValue } = useFormContext();
  const ocppId = form.ocppId;
  const depotId = form.depotId;
  const parkingSpots = form.parkingSpots;
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const handleDeleteParkingSpot = (parkingSpots: any, parkingIndex: number) => {
    const filteredParkingSpots = [...parkingSpots].filter((spot) => spot.muId === ocppId);
    filteredParkingSpots.splice(parkingIndex, 1);
    reset(
      {
        ...form,
        parkingSpots: filteredParkingSpots,
      },
      { keepDirtyValues: true }
    );
  };

  return (
    <Stack alignItems='center'>
      <Stack spacing={2}>
        {parkingSpots &&
          parkingSpots
            .filter((spot) => spot.muId === ocppId)
            .map((spot, parkingIndex) => {
              return (
                <Grid
                  item
                  sm={12}
                  key={`parkingSpot-${spot.id || parkingIndex}`}
                  marginY={2}
                  p={2}
                  sx={{
                    position: 'relative',
                    border: (theme) => `1px solid ${theme.palette.accent.secondary.main}`,
                  }}
                >
                  <IconButton
                    aria-label='delete'
                    sx={{
                      position: 'absolute',
                      top: '0',
                      right: '0',
                    }}
                    onClick={() => setIsConfirmPopupOpen(true)}
                  >
                    <GridDeleteIcon />
                  </IconButton>
                  <ConfirmationPopup
                    isOpen={isConfirmPopupOpen}
                    setIsOpen={setIsConfirmPopupOpen}
                    confirmTitle='Are you sure you want to delete Parking Spot?'
                    confirmMessage='Parking Spot will be permanently deleted!'
                    onSubmitHandler={() => handleDeleteParkingSpot(parkingSpots, parkingIndex)}
                  />
                  <Typography variant='subtitle1' component='h3' sx={{ mb: '1rem' }}>
                    Parking Spot {parkingIndex + 1}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        label='Name'
                        error={!!errors.parkingSpots?.[parkingIndex]?.name}
                        helperText={errors.parkingSpots?.[parkingIndex]?.name?.message}
                        {...register(`parkingSpots.${parkingIndex}.name`)}
                        onChange={() => {
                          setValue(
                            `parkingSpots.${parkingIndex}.version`,
                            getValues().parkingSpots[parkingIndex].version + 1
                          );
                          setValue(`parkingSpots.${parkingIndex}.id`, undefined);
                        }}
                        defaultValue={spot.name || ''}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField
                        fullWidth
                        label='Long'
                        error={!!errors.parkingSpots?.[parkingIndex]?.longitude}
                        helperText={errors.parkingSpots?.[parkingIndex]?.longitude?.message}
                        {...register(`parkingSpots.${parkingIndex}.longitude`)}
                        defaultValue={spot.longitude || defaultLng}
                        placeholder={defaultLng}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <TextField
                        fullWidth
                        label='Lat'
                        error={!!errors.parkingSpots?.[parkingIndex]?.latitude}
                        helperText={errors.parkingSpots?.[parkingIndex]?.latitude?.message}
                        {...register(`parkingSpots.${parkingIndex}.latitude`)}
                        defaultValue={spot.latitude || defaultLat}
                        placeholder={defaultLat}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
      </Stack>
      <Button
        fullWidth
        variant='outlined'
        color='primary'
        startIcon={<div>+</div>}
        sx={{ mt: '1rem', mb: '1rem' }}
        onClick={() => {
          reset(
            {
              ...form,
              parkingSpots: [
                ...(parkingSpots || []),
                {
                  id: undefined,
                  muId: ocppId,
                  depotId: depotId,
                  name: `P-${(parkingSpots?.length ?? 0) + 1} ${ocppName}`,
                  longitude: Number(defaultLng),
                  latitude: Number(defaultLat),
                },
              ].filter((spot) => spot.muId === form.ocppId),
            },
            { keepDirtyValues: true }
          );
        }}
      >
        Add Parking Spot
      </Button>
    </Stack>
  );
}
