import { LocalizationProvider, TimePicker, TimePickerProps, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from 'react';

export const CustomTimePicker: React.FC<{
  label: string;
  value: moment.Moment;
  onChange: TimePickerProps<moment.Moment>['onChange'];
}> = ({ label, value, onChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        label={label}
        value={value}
        onChange={onChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnSelect
        views={['hours', 'minutes']}
        ampm={false}
        ampmInClock={false}
        viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock }}
        slotProps={{
          openPickerButton: {
            sx: {
              display: 'none',
            },
          },
          textField: {
            onClick: () => setOpen(true),
            variant: 'standard',
            sx: {
              minWidth: '65px',
              '& .MuiInput-underline:before': {
                border: 'none',
              },
              '& .MuiInput-underline:after': {
                border: 'none',
              },
              '& .MuiInput-underline:hover:before': {
                border: 'none',
              },
              '& .MuiInputLabel-root': {
                display: 'none',
              },
              '& .MuiInput-root': {
                marginTop: 0,
              },
              '& .MuiInputBase-input': {
                fontSize: 25,
                cursor: 'pointer',
                textAlign: 'center',
              },
              '& .MuiInputBase-root': {
                height: 40,
              },
              '& .MuiInputAdornment-root': {
                display: 'none',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
