import { object, string, number, array, TypeOf } from 'zod';

export const createParkingSpotsSchema = object({
  depotId: number().min(1, 'Depot ID is required'),
  projectName: string().optional(),
  projectId: number().optional(),
  muId: string().min(1, 'Mu ID is required'),
  parkingSpots: array(
    object({
      id: number().optional(),
      name: string().refine((value) => {
        return value && value.length > 0;
      }, 'Parking spot name is required'),
      depotId: number().optional(),
      muId: string().optional(),
      longitude: string()
        .or(number())
        // .optional()
        .refine((value) => {
          if (value === '' || value === undefined) return true;
          return !isNaN(+value);
        }, 'Number req.')
        .transform(Number),
      latitude: string()
        .or(number())
        // .optional()
        .refine((value) => {
          if (value === '' || value === undefined) return true;
          return !isNaN(+value);
        }, 'Number req.')
        .transform(Number),
      segments: array(
        object({
          muId: string().optional(),
          projectId: number().optional(),
          drawerNum: number().optional(),
          segmentNum: string()
            .or(number())
            .refine((value) => {
              return !isNaN(+value) && +value >= 0;
            }, 'Positive number or 0 req.')
            .transform(Number),
          longitude: string()
            .or(number())
            // .optional()
            .refine((value) => {
              if (value === '' || value === undefined) return true;
              return !isNaN(+value);
            }, 'Number req.')
            .transform(Number),
          latitude: string()
            .or(number())
            // .optional()
            .refine((value) => {
              if (value === '' || value === undefined) return true;
              return !isNaN(+value);
            })
            .transform(Number),
        })
      ).min(1, 'At least one segment is required'),
    })
  ).min(1, 'At least one parking spot is required'),
});

export const createParkingSpotsDefaultValues = {
  depotId: 0,
  projectName: '',
  muId: '',
  parkingSpots: [],
};

export type CreateParkingSpotsInput = TypeOf<typeof createParkingSpotsSchema>;
