import { Avatar, Box, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { controlPanelIconStyles, headerButtonWrapperStyles } from 'Components/Header/Styles/HeaderStyles';
import { forwardRef, HTMLAttributes } from 'react';

export const ControlPanelIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ onClick }, ref) => {
    return (
      <Box sx={headerButtonWrapperStyles} onClick={onClick} ref={ref}>
        <IconButton sx={{ height: 'min-content', padding: 0 }}>
          <Avatar sx={controlPanelIconStyles}>
            <AddIcon />
          </Avatar>
        </IconButton>
      </Box>
    );
  }
);
ControlPanelIcon.displayName = 'ControlPanelIcon';
