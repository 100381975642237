import { FC, Suspense, lazy } from 'react';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
  redirect,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import { HeaderMode, useHeaderMode } from 'Components/Header/Hooks/useHeaderMode';
import { useAxiosMiddleware } from 'CustomHooks/useAxiosMiddleware';
import { useWindowEvents } from 'CustomHooks/useWindowEvents';
import { Header } from 'Components/Header/Header';
import { PopupsWrapper } from 'Components/PopupsWrapper/PopupsWrapper';
import { useTheme } from '@mui/material';
import Login from 'Screens/Login/Login';
import { ForceChangePassword } from 'Screens/Login/ForceChangePassword';
import { ManageUsers } from 'Screens/ManageUsers/ManageUsers';
import { Functionalities } from '@electreon_ui/shared/permissions';
import { useAutoNavigate } from 'CustomHooks/useAutoNavigate';
import { ElectreonLoader } from '@electreon_ui/shared';
import { rootStore } from 'MobxStores/context';
import { observer } from 'mobx-react-lite';
import { WithAuth } from 'CustomHooks/AuthRouteWrapper';
import { ErrorPage } from 'Screens/ErrorPage/ErrorPage';
import { Toaster } from '@repo/ui';
import { RootStore } from 'MobxStores/RootStore';
import { hasDashboard } from 'dashboardConfig';
import { isFilteredByUser } from '@electreon_ui/shared/utils/privateProjectUtils';
import { env } from 'config';

const ProjectsCardsDashboard = lazy(() => import('Screens/ProjectsCardsDashboard/ProjectsCardsDashboard'));
const AlertsDashboard = lazy(() => import('Screens/AlertDashboard/AlertsDashboard'));
const DepotDashboard = lazy(() => import('Screens/DepotDashboard/DepotDashboard'));
const MainDashboardWrapper = lazy(() => import('Screens/ProjectDashboard/ContextWrapper'));
const ProjectAnalyticsTool = lazy(() => import('Screens/AnalyticsTool/ProjectAnalyticsTool'));
const IntegrationPage = lazy<FC<Partial<RootStore>>>(() => import('integrationView/IntegrationView'));
const Reports = lazy(() => import('Screens/Reports/Reports'));

const Layout = observer(() => {
  const { headerMode } = useHeaderMode();
  const navigation = useNavigation();
  const theme = useTheme();
  const location = useLocation();
  const isLoginPage = location.pathname === '/';
  const isForceChangePasswordPage = location.pathname === '/force_change_password';
  useAxiosMiddleware();
  useWindowEvents();
  useAutoNavigate();

  const isNavigating = navigation.state === 'loading';

  return (
    <>
      <div style={{ paddingTop: headerMode !== HeaderMode.HIDDEN ? theme.header?.height : 0 }}>
        {headerMode !== HeaderMode.HIDDEN && !isLoginPage && !isForceChangePasswordPage && <Header />}
      </div>
      {isNavigating ? (
        <ElectreonLoader />
      ) : (
        <Suspense fallback={<ElectreonLoader />}>
          <Outlet />
          <PopupsWrapper />
          <Toaster />
        </Suspense>
      )}
    </>
  );
});

const router = createBrowserRouter([
  {
    element: <Layout />,
    ErrorBoundary: ErrorPage,
    children: [
      { path: '/oauth2/callback', element: <Login /> },
      { path: '/', element: <WithAuth ComponentToProtect={Login} /> },
      { path: '/force_change_password', element: <ForceChangePassword /> },
      { path: '/manage/users', element: <WithAuth ComponentToProtect={ManageUsers} /> },
      {
        path: '/dashboard/live/project/:projectId/:variant?',
        loader: ({ params }) => {
          if (hasDashboard(params.projectId)) return null;
          return redirect(`/?error=Project ${params.projectId} has no dashboard`);
        },
        element: (
          <WithAuth ComponentToProtect={MainDashboardWrapper} restrictBy={Functionalities.DASHBOARD} />
        ),
      },
      {
        path: '/dashboard/history/project/:projectId',
        element: (
          <WithAuth
            ComponentToProtect={ProjectAnalyticsTool}
            restrictBy={Functionalities.ANALYTICS}
            override={rootStore.userStore.isCompanyUser}
          />
        ),
      },
      {
        path: '/dashboard',
        element: (
          <WithAuth ComponentToProtect={ProjectsCardsDashboard} restrictBy={Functionalities.DASHBOARD} />
        ),
      },
      {
        path: '/dashboard/live/depot/:projectId/:depotId',
        element: <WithAuth ComponentToProtect={DepotDashboard} restrictBy={Functionalities.DEPOT} />,
      },
      {
        path: '/dashboard/alerts',
        element: <WithAuth ComponentToProtect={AlertsDashboard} />,
      },
      {
        path: '/dashboard/alerts/project/:projectId',
        element: <WithAuth ComponentToProtect={AlertsDashboard} />,
      },
      {
        path: '/dashboard/integration/:projectId',
        element: (
          <WithAuth
            ComponentToProtect={IntegrationPage}
            props={rootStore}
            restrictBy={Functionalities.INTEGRATION}
          />
        ),
      },
      {
        path: '/project/:projectId/reports',
        element: (
          <WithAuth
            ComponentToProtect={Reports}
            restrictBy={Functionalities.REPORTS}
            override={rootStore.userStore.isCompanyUser}
          />
        ),
      },
      {
        path: '*',
        element: <Navigate to='/dashboard' />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default observer(AppRouter);
