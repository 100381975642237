import { Box, SxProps } from '@mui/material';
import React from 'react';
import { Theme } from '@mui/material/styles';

type AlertsTableCustomFilterProps = {
  children?: React.ReactNode;
  index?: number;
  value?: number;
  sx?: SxProps<Theme>;
  onClose?: () => void;
};

// todo: this should be adjusted to support other filters in the future.

export const AlertsTableCustomFilter: React.FC<AlertsTableCustomFilterProps> = ({
  children,
  value = 0,
  index = 0,
  onClose,
}) => {
  return (
    <Box hidden={value !== index} id={`alerts-table-${index}`}>
      {value === index && children}
    </Box>
  );
};
