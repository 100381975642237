import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { alpha, Divider, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

export const HeaderDivider: React.FC<CSSProperties> = ({ ...styles }) => {
  return (
    <Divider
      orientation='vertical'
      variant='middle'
      sx={{
        borderColor: alpha(theme.palette.primary.main, 0.3),
        height: '24px',
      }}
      style={styles}
    />
  );
};
