import { ProjectAnalyticsStore } from 'MobxStores/ProjectAnalyticsStore';
import { DepotStore } from 'MobxStores/DepotStore';

import { DashboardConfigurationStore } from 'MobxStores/DashboardConfigurationStore';
import { AlertStore } from 'MobxStores/AlertStore';
import UserStore from '@electreon_ui/shared/stores/userStore/UserStore';
import DeviceLockStore from '@electreon_ui/shared/stores/deviceLockStore/DeviceLockStore';
import PopupStore from '@electreon_ui/shared/stores/popupStore/PopupStore';
import AuthStore from '@electreon_ui/shared/stores/authStore/AuthStore';
import { toJS } from 'mobx';
import { ElectreonApiServices } from '@electreon_ui/shared/services/ElectreonApiServices';
import { env, globalConfig, isLocalDevEnv } from 'config';
import DeviceStore from '@electreon_ui/shared/stores/deviceStore/DeviceStore';
import { AbstractRootStore } from '@electreon_ui/shared/stores/storeModels';
import ProjectStore from '@electreon_ui/shared/stores/projectStore/ProjectStore';
import OCPPStore from '@electreon_ui/shared/stores/ocppStore/OCPPStore';
import { AppNames, EnvType } from '@electreon_ui/shared/types/globals';
import { QueryClient } from '@tanstack/react-query';

export class RootStore implements AbstractRootStore {
  appVersions: Record<AppNames, string | null> = {
    [AppNames.SmartRoad]: import.meta.env.VITE_BUILD_VERSION,
    [AppNames.Integration]: null,
  };
  api: ElectreonApiServices;
  userStore: UserStore;
  projectAnalyticsStore: ProjectAnalyticsStore;
  depotStore: DepotStore;
  dashboardConfigurationStore: DashboardConfigurationStore;
  deviceStore: DeviceStore;
  deviceLockStore: DeviceLockStore;
  projectStore: ProjectStore;
  popupStore: PopupStore;
  authStore: AuthStore;
  ocppStore: OCPPStore;
  alertStore: AlertStore;
  queryClient: QueryClient;

  constructor(api: ElectreonApiServices) {
    if (isLocalDevEnv || env !== EnvType.Prod) window.rootStore = this;
    this.api = api;
    this.userStore = new UserStore();
    this.projectAnalyticsStore = new ProjectAnalyticsStore(this);
    this.depotStore = new DepotStore(this);
    this.dashboardConfigurationStore = new DashboardConfigurationStore();
    this.deviceStore = new DeviceStore(this);
    this.deviceLockStore = new DeviceLockStore();
    this.projectStore = new ProjectStore(this, api);
    this.popupStore = new PopupStore();
    this.authStore = new AuthStore(this, {
      clientSecret: globalConfig.cognitoClientSecret,
      devElectreonCognitoClientID: globalConfig.cognitoClientId,
      federatedSignInUrl: globalConfig.federatedSignInUrl,
      redirectUrl: globalConfig.redirectUrl,
      tokenEndpoint: globalConfig.tokenEndpoint,
      userPoolId: globalConfig.cognitoUserPool,
    });
    this.alertStore = new AlertStore(this);
    this.ocppStore = new OCPPStore(this);
    this.queryClient = new QueryClient();
  }

  debug = () => console.log(toJS(this));
  debugDepot = () => console.log(toJS(this.depotStore));
  debugProjectAnalytics = () => console.log(toJS(this.projectAnalyticsStore));
  debugUser = () => console.log(toJS(this.userStore));
  debugDevice = () => console.log(toJS(this.deviceStore));
  debugDeviceLock = () => console.log(toJS(this.deviceLockStore));
  debugProject = () => console.log(toJS(this.projectStore));
  debugPopup = () => console.log(toJS(this.popupStore));
  debugAuth = () => console.log(toJS(this.authStore));
  debugDashboardConfig = () => console.log(toJS(this.dashboardConfigurationStore));
  debugAlert = () => console.log(toJS(this.alertStore));
  debugOCPP = () => console.log(toJS(this.ocppStore));
}
