import { useState } from 'react';
import { Button, Box, Grid, Typography, Tooltip } from '@mui/material';

import { getRandomImageData } from 'Screens/Login/RandomLoginPicture';

import ShufileImage from 'Utils/Images/GlobalIcons/shufileImage.png';
import depotLocation from 'Utils/Images/GlobalIcons/location_Elecreon.svg';

import {
  LocationIcon,
  loginRandomImageFooter,
  ProjectNameStyle,
  projectNameWrapper,
  randomImageFooterWrapper,
  refreshButtonWrapper,
} from 'Screens/Login/styles/LoginStyles';

export function RandomLoginImage() {
  const [[selectedImage, selectedImageText], setBgImage] = useState<string[]>(getRandomImageData());

  const getNewImageData = () => {
    const newImageData = getRandomImageData();
    const [newImage] = newImageData;
    if (newImage === selectedImage) {
      getNewImageData();
    } else {
      setBgImage(newImageData);
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      md={6}
      display={{ xs: 'none', sm: 'block' }}
      sx={{
        backgroundImage: `Url(${selectedImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Box sx={randomImageFooterWrapper}>
        <Box sx={loginRandomImageFooter}>
          <Box sx={projectNameWrapper}>
            <Typography sx={LocationIcon}>
              <img className='project-location' src={depotLocation} alt='Project Location' />
            </Typography>
            <Typography sx={ProjectNameStyle}>{selectedImageText}</Typography>
          </Box>
          <Box sx={refreshButtonWrapper}>
            <Typography component='h1' variant='h5'>
              <Tooltip title='Change Site' arrow placement='top'>
                <Button onClick={getNewImageData}>
                  <img className='log-in-button' src={ShufileImage} alt='Shufile' />
                </Button>
              </Tooltip>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
