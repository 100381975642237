import { Badge, IconButton, badgeClasses, useTheme } from '@mui/material';
import NotificationBell from 'Utils/Images/HeaderIcons/notifications-icon.svg?react';
import BlackNotificationBell from 'Utils/Images/HeaderIcons/black-notification-bell-icon.svg?react';
import { notificationsButtonStyles } from 'Components/Header/Styles/HeaderStyles';
import { HeaderMode } from 'Components/Header/Hooks/useHeaderMode';

type HeaderNotificationIconProps = {
  notificationCount?: number;
  onClick: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  disableBellCount: boolean;
  hide: boolean;
};

export const HeaderNotificationIcon: React.FC<HeaderNotificationIconProps> = ({
  notificationCount,
  onClick,
  disableBellCount,
  hide,
}) => {
  const theme = useTheme();
  const badgeContent = disableBellCount && notificationCount ? ' ' : notificationCount || 0;
  if (hide) return null;
  return (
    <IconButton
      sx={notificationsButtonStyles}
      onClick={(e: React.MouseEvent<HTMLElement>) => onClick(e.currentTarget)}
      id='test-notifications-button'
    >
      <Badge
        variant='dot'
        sx={{
          [`& .${badgeClasses.dot}`]: {
            width: 12,
            height: 12,
            borderRadius: '50%',
            position: 'absolute',
            top: '4px',
            right: '6px',
          },
        }}
        badgeContent={badgeContent}
        color='error'
        overlap='circular'
        id='test-notifications-badge'
      >
        {theme.palette.mode === HeaderMode.DARK ? <NotificationBell /> : <BlackNotificationBell />}
      </Badge>
    </IconButton>
  );
};
