import {
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  Box,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  inputLabelStyles,
  multiSelectChipContainerStyles,
  multiSelectChipStyles,
} from '../CreateDevicePopup/utils/InputStyles';
import { getChipContainerHeight, SelectMusMenuProps } from '../EditAPFC&POSForms/Utils/EditFormsUtils';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import { ProjectNameDisplay } from './ProjectNameDisplay';
import { useProjects } from 'Utils/APIUtils';
import { useAppStore } from 'MobxStores/context';

interface ProjectMultiSelectionFormInputProps {
  disabled?: boolean;
}

export const ProjectMultiSelectionFormInput: React.FC<ProjectMultiSelectionFormInputProps> = observer(
  ({ disabled = false }) => {
    const { data: projectsMap } = useProjects();
    const projects = [...(projectsMap || []).values()];
    const { projectStore } = useAppStore();
    const {
      register,
      formState: { errors },
      setValue,
      watch,
    } = useFormContext();
    const selectedProjects = watch('selectedProjects');

    return (
      <FormControl sx={{ mb: 2, '& .MuiSelect-icon': { fontSize: '25px' } }} fullWidth>
        <InputLabel sx={inputLabelStyles} id='selected-projects-label' disabled={disabled}>
          Select Projects
        </InputLabel>
        <Select
          MenuProps={SelectMusMenuProps}
          sx={{
            minHeight: getChipContainerHeight(selectedProjects?.length),
            maxHeight: '135px',
          }}
          labelId='selected-projects-label'
          id='selected-projects'
          multiple
          value={selectedProjects}
          {...register('selectedProjects')}
          onChange={(event) =>
            setValue(
              'selectedProjects',
              // On autofill we get a stringified value.
              typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
            )
          }
          input={<OutlinedInput id='selected-projects' label='Selected Projects' />}
          renderValue={(selected: string[]) => (
            <Box sx={multiSelectChipContainerStyles}>
              {selected.map((value) => {
                const projectOutOfUserScope = !projectStore.projectIdToProjectMap.get(Number(value))?.name;
                return (
                  <Chip
                    key={`project-multi-select-chip-${value}`}
                    clickable={!disabled && !projectOutOfUserScope}
                    deleteIcon={<HighlightOffIcon onMouseDown={(event) => event.stopPropagation()} />}
                    onDelete={
                      !disabled && !projectOutOfUserScope
                        ? (event) => setValue('selectedProjects', _.without(selectedProjects, value))
                        : undefined
                    }
                    sx={multiSelectChipStyles}
                    label={projectStore.projectIdToProjectMap.get(Number(value))?.name || value}
                  />
                );
              })}
            </Box>
          )}
          disabled={disabled}
          error={!!errors['selectedProjects']}
        >
          {projects?.map((project) => (
            <MenuItem key={`project-multi-selection-input-${project.id}`} value={project.id}>
              <ProjectNameDisplay project={project} />
            </MenuItem>
          ))}
        </Select>
        {errors['selectedProjects'] && typeof errors['selectedProjects'].message === 'string' && (
          <FormHelperText error={!!errors['selectedProjects']?.message}>
            {errors['selectedProjects'].message}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);
