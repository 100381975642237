import {
  ManagementUnitV2FpgaFotaType,
  SomFOTAType,
} from '@electreon/electreon-device-control-service-gen-ts-client';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { checkDeviceId, checkDeviceName } from 'Components/Forms/FormsAPIs/api';
import { BasicDeviceFotaOptions, checkFotaVersion } from 'Components/Forms/DeviceFotaForm/DeviceFotaUtils';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { UseFormClearErrors, UseFormSetError, UseFormWatch } from 'react-hook-form';
import { AbstractIoTDeviceType, UiDevice, UiDeviceType } from '@electreon_ui/shared/types/globals';

export const useInputAvailability = (
  watch: UseFormWatch<any>,
  selectedProjectId: Project['id'] | null,
  setError: UseFormSetError<any>,
  clearErrors: UseFormClearErrors<any>,
  deviceType: UiDeviceType,
  currentDevice?: UiDevice
) => {
  const [deviceNameAvaliable, setDeviceNameAvaliable] = useState<boolean | null>(null);
  const [deviceIDAvaliable, setDeviceIDAvaliable] = useState<boolean | null>(null);
  const [fotaVersionExists, setFotaVersionExists] = useState<boolean | null>(null);

  useEffect(() => {
    if (setError) {
      if (deviceNameAvaliable === false) {
        setError('deviceName', {
          type: 'manual',
          message: 'Device name already exists',
        });
      } else {
        clearErrors('deviceName');
      }
      if (deviceIDAvaliable === false) {
        setError('deviceId', {
          type: 'manual',
          message: 'Device ID already exists',
        });
      } else {
        clearErrors('deviceId');
      }
      if (fotaVersionExists === false) {
        setError('fotaVersion', {
          type: 'manual',
          message: 'FOTA Version does not Exist',
        });
      } else {
        clearErrors('fotaVersion');
      }
    }
  }, [fotaVersionExists, deviceNameAvaliable, deviceIDAvaliable, setError, clearErrors]);

  const debouncedCheckDeviceName = useMemo(
    () =>
      debounce(
        (
          deviceName: string,
          selectedProjectId: Project['id'] | null,
          deviceType: AbstractIoTDeviceType
        ) => {
          checkDeviceName(deviceName, selectedProjectId || 0, deviceType).then((res) => {
            setDeviceNameAvaliable(res);
          });
        },
        800
      ),
    []
  );

  const debouncedCheckDeviceId = useMemo(
    () =>
      debounce((deviceId: string, deviceType: AbstractIoTDeviceType) => {
        checkDeviceId(deviceId, deviceType).then((res) => {
          setDeviceIDAvaliable(res);
        });
      }, 800),
    []
  );

  const debouncedCheckFotaVersion = useMemo(
    () =>
      debounce(
        (
          version: string,
          deviceType: AbstractIoTDeviceType,
          fotaType: BasicDeviceFotaOptions, // ??
          fotaSubType?: SomFOTAType | ManagementUnitV2FpgaFotaType
        ) => {
          checkFotaVersion(version, deviceType, fotaType, fotaSubType).then((res) => {
            setFotaVersionExists(res.data);
          });
        },
        800
      ),
    []
  );

  useEffect(() => {
    const subscription = watch((value) => {
      const { deviceName, prefix } = value;
      const deviceId = String(value?.deviceId || '');
      const deviceIdString = `${prefix}${deviceId}`;
      if (currentDevice?.id === deviceIdString) {
        setDeviceIDAvaliable(true);
      } else if (deviceId && deviceId.length === 7 && prefix) {
        debouncedCheckDeviceId(deviceIdString, deviceType);
      }

      if (currentDevice?.name === deviceName) {
        setDeviceNameAvaliable(true);
      } else if (deviceName && deviceName.length >= 4 && deviceName.length <= 20 && selectedProjectId) {
        debouncedCheckDeviceName(deviceName, selectedProjectId, deviceType);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, selectedProjectId, debouncedCheckDeviceName, deviceType, debouncedCheckDeviceId, currentDevice]);

  useEffect(() => {
    const subscription = watch((value) => {
      const { version, fotaType, fotaSubType } = value;
      if (version && /^\d+\.\d+\.\d+$/.test(version)) {
        debouncedCheckFotaVersion(version, deviceType, fotaType, fotaSubType);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, deviceType, debouncedCheckFotaVersion]);

  return { deviceNameAvaliable, deviceIDAvaliable, fotaVersionExists };
};
