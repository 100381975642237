import { Alert, Autocomplete, Box, FormHelperText, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState, useEffect } from 'react';
import { api } from 'Services/api';
import { Depot } from '@electreon/electreon-metadata-service-gen-ts-client';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { toast } from 'sonner';

const deleteDepotSchema = object({
  depotId: string().min(1, 'Depot ID is required'),
  depotName: string().min(1, 'Depot name is required'),
  projectName: string().optional(),
  projectId: string().min(1, 'Project ID is required'),
  confirmDelete: string().min(1, 'Confirm delete is required'),
})
  .partial()
  .refine(
    (data) => {
      console.debug(`delete depot ${data.depotName}`);
      console.debug(`delete depot ${data.confirmDelete}`);
      return `delete depot ${data.depotName}` === data.confirmDelete;
    },
    {
      message: 'Confirmation does not match.',
      path: ['confirmDelete'],
    }
  );

const deleteDepotDefaultValues = {
  depotId: '',
  projectName: '',
  depotName: '',
  confirmDelete: '',
};

type DeleteDepotInput = TypeOf<typeof deleteDepotSchema>;

interface DeleteDepotForm extends FormProps {
  depotId?: number;
}
export const DeleteDepotForm: React.FC<DeleteDepotForm> = ({
  onSuccessfulSubmit,
  onCancel,
  selectedProject,
  depotId,
}) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
  const [depotList, setDepotList] = useState<Depot[]>([]);
  const {
    register,
    formState: { errors /* isSubmitSuccessful */ },
    reset,
    watch,
    handleSubmit,
    setValue,
  } = useForm<DeleteDepotInput>({
    resolver: zodResolver(deleteDepotSchema),
    defaultValues: deleteDepotDefaultValues,
  });

  const onSubmitHandler: SubmitHandler<DeleteDepotInput> = async (values) => {
    if (!values?.depotId) {
      setSubmitError('Depot ID is required');
      return;
    }

    setLoading(true);
    setSubmitError(null);
    try {
      const deleteRes = await api.metadata.depots.deleteDepot(+values?.depotId);
      if (!deleteRes) {
        toast.error('Error deleting depot');
        console.error('Error deleting depot', JSON.stringify(deleteRes));
        setSubmitError('Error deleting depot');
        setLoading(false);
        return;
      }
      toast.success('Depot deleted');
      onSuccessfulSubmit?.();
    } catch (e) {
      toast.error('Error deleting depot');
      console.error(JSON.stringify(e));
      setSubmitError('Error deleting depot');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getDepots = async () => {
      if (selectedProject && selectedProject.id) {
        reset(
          {
            ...watch(),
            projectName: selectedProject.name,
            projectId: String(selectedProject.id),
          },
          { keepDirtyValues: true }
        );

        try {
          const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
          if (depotRes) setDepotList(depotRes.data);
        } catch (e) {
          setDepotList([]);
          console.error(JSON.stringify(e));
        }
      }
    };

    getDepots();
  }, [selectedProject, reset, watch]);

  useEffect(() => {
    if (depotId) {
      const depot = depotList.find((depot) => depot.id === depotId);
      if (depot) {
        setSelectedDepot(depot);
        setValue('depotId', String(depot.id));
        setValue('depotName', String(depot.name));
      }
    }
  }, [depotList, depotId, setValue]);

  if (!selectedProject) return null;

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Delete Depot
      </Typography>

      <Box component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmitHandler)}>
        <SelectProject selectedProject={selectedProject} errors={errors} />
        <TextField
          disabled
          sx={{ mb: 2 }}
          label='Project ID'
          InputLabelProps={{ shrink: !!watch('projectId') }}
          fullWidth
          error={!!errors['projectId']}
          helperText={errors['projectId'] ? errors['projectId'].message : ''}
          {...register('projectId')}
        />
        <Autocomplete
          fullWidth
          options={depotList || []}
          getOptionLabel={(option) => option.name || 'Unnamed Depot'}
          onChange={(_, value) => {
            if (value) {
              reset(
                {
                  ...watch(),
                  depotName: value.name,
                  depotId: String(value.id),
                },
                { keepDirtyValues: true }
              );
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Depot'
              error={!!errors['depotName']}
              helperText={errors['depotName'] ? errors['depotName'].message : ''}
              {...register('depotName')}
            />
          )}
          value={depotId ? selectedDepot : null}
          disabled={!!depotId}
        />
        <TextField
          disabled
          sx={{ mt: 2 }}
          label='Depot ID'
          InputLabelProps={{ shrink: !!watch('depotId') }}
          fullWidth
          error={!!errors['depotId']}
          helperText={errors['depotId'] ? errors['depotId'].message : ''}
          {...register('depotId')}
        />
        <FormHelperText sx={{ mb: 2, color: 'red' }} error>
          {`To confirm deletion, type 'delete depot <depot name>'`}
        </FormHelperText>
        <TextField
          sx={{ mb: 2 }}
          label='Confirm Delete'
          fullWidth
          error={!!errors['confirmDelete']}
          helperText={errors['confirmDelete'] ? errors['confirmDelete'].message : ''}
          {...register('confirmDelete')}
        />
        {submitError && (
          <Alert severity='error' sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Delete Depot' />
      </Box>
    </>
  );
};
DeleteDepotForm.displayName = 'DeleteDepotForm';
