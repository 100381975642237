import {
  BasicDeviceFotaOptions,
  DeviceFotaApi,
  DeviceFotaInput,
  MuFotaTypes,
  VuFotaTypes,
} from 'Components/Forms/DeviceFotaForm/DeviceFotaUtils';
import { useState } from 'react';
import { SubmitHandler, UseFormReset } from 'react-hook-form';
import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';
import { toast } from 'sonner';

export const useDeviceFotaFormLogic = (
  device: AbstractIoTDeviceModelRefined,
  reset: UseFormReset<DeviceFotaInput>,
  onSuccessfulSubmit: () => void
) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const deviceType = device?.deviceType;
  const deviceTypeName =
    deviceType === 'MU' ? 'Management Unit' : deviceType === 'VU' ? 'Vehicle Unit' : 'Device';
  const DeviceFotaTypes =
    deviceType === 'MU'
      ? [...MuFotaTypes]
      : deviceType === 'VU'
        ? [...VuFotaTypes]
        : [...MuFotaTypes, ...VuFotaTypes];
  const onSubmitHandler: SubmitHandler<DeviceFotaInput> = (values) => {
    setSubmitError(null);
    const { version, fotaType, fotaSubType } = values;
    setLoading(true);
    DeviceFotaApi(
      device?.id || '',
      deviceType,
      version,
      fotaType as BasicDeviceFotaOptions,
      fotaSubType || ''
    )
      .then((res) => {
        if (res.status === 200) {
          toast.success('Device FOTA started');
          reset();
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('Device FOTA failed');
          setSubmitError('Device FOTA failed');
          throw new Error('Device FOTA failed');
        } else if (res.status === 422) {
          toast.error('Could Not perform FOTA on Device, Lock Device and Try Again');
          setSubmitError('Could Not perform FOTA on Device, Lock Device and Try Again');
          throw new Error('Device FOTA failed');
        } else {
          //todo: what are possible errors??
          toast.error('Device FOTA failed');
          setSubmitError('Device FOTA failed');
          throw new Error('Device FOTA failed');
        }
      })
      .catch((err) => {
        toast.error('Device FOTA failed');
        console.error('Device FOTA error', JSON.stringify(err));
        if (err.message.includes('422')) {
          setSubmitError('Could Not perform FOTA on Device, Lock Device and Try Again');
          throw new Error('Device FOTA failed');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    onSubmitHandler: onSubmitHandler,
    submitError: submitError,
    loading: loading,
    deviceTypeName: deviceTypeName,
    DeviceFotaTypes: DeviceFotaTypes,
  };
};
