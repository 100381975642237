import { GetSdCardImageStatusResponse } from 'Utils/UtilsTypes';
import { api } from 'Services/api';
import {
  ManagementUnitConfiguration,
  VehicleUnitConfiguration,
} from '@electreon/electreon-device-control-service-gen-ts-client';

export const getSessionId = (triggieredFrom?: string) => {
  const userStore = localStorage.getItem('UserStore');
  const parsedSessionStore = userStore ? JSON.parse(userStore) : {};
  const sub = parsedSessionStore?.userData?.sub;
  if (!sub) {
    console.log(
      `Smart Road Error: no SUB found!!! ${triggieredFrom ? `triggiered from ${triggieredFrom}` : ''}`
    );
  }
  return sub;
};

export async function generateSDCardImage(deviceId: string, version?: string) {
  try {
    const response = await api.deviceMetadata.firmware.startSDCardImageCreation(deviceId, version);
    return response.data;
  } catch (error) {
    console.error(`Error in generateSDCardImage request: ${error}`);
    throw error;
  }
}

export async function getSDCardImageStatus(commandId: string): Promise<GetSdCardImageStatusResponse> {
  try {
    const response = await api.deviceMetadata.firmware.getSDCardImageCreationStatus(commandId);
    return response.data as GetSdCardImageStatusResponse;
  } catch (error) {
    console.error(`Error in getSDCardImageStatus request: ${error}`);
    throw error;
  }
}

export async function updateMuShadow(muId: string, shadow: ManagementUnitConfiguration) {
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.muConfig.updateConfiguration(muId, sessionId, shadow);
    return response;
  } catch (error) {
    console.error(`Error in updateMuShadow request: ${error}`);
    throw error;
  }
}

export async function updateVuShadow(vuId?: string, shadow?: VehicleUnitConfiguration) {
  if (!vuId || !shadow) throw new Error('Missing vuId or shadow');
  try {
    const sessionId = getSessionId();
    if (!sessionId) throw new Error('No session id found');
    const response = await api.deviceControl.vuConfig.updateConfiguration(vuId, sessionId, shadow);
    return response.data;
  } catch (error) {
    console.error(`Error in updateVuShadow request: ${error}`);
    throw error;
  }
}
