import openSocket from 'socket.io-client';
import { AlertStore } from 'MobxStores/AlertStore';
import { LISTEN_TO_ALL_SOCKET_MESSAGES, USE_LOCAL_SOCKET, baseUrl } from 'config';
import { v4 } from 'uuid';
import { AlertsSocketMessage } from '@electreon_ui/shared/types/socketMessageTypes';

export const getProjectIdFromURL = () => {
  const projectNumberRegex = /\/project\/(\d+)\//;
  const match = window.location.pathname.match(projectNumberRegex);
  return match ? parseInt(match[1], 10) : undefined;
};
export const initAlertsSocket = (store: AlertStore, idToken: string) => {
  // Close alerts socket
  if (store.liveAlertsSocket) {
    store.liveAlertsSocket.emit('closeSocket');
  }

  if (!idToken) {
    console.error('No ID token found, cannot connect to alerts socket');
    return;
  }

  const connectionuuid = v4();
  const url = USE_LOCAL_SOCKET ? 'http://localhost:8868' : baseUrl;

  const alertsSocket = openSocket(url, {
    extraHeaders: {
      Authorization: `${idToken}`,
      connectionuuid,
    },
    protocols: [connectionuuid],
  });

  store.liveAlertsSocket = alertsSocket || null;

  if (alertsSocket) {
    alertsSocket.emit('joinAlerts', {
      idToken,
      connectionuuid,
    });
    LISTEN_TO_ALL_SOCKET_MESSAGES && alertsSocket?.onAny((event, ...args) => console.log(event, args));

    alertsSocket.on('alertsMessage', (message: AlertsSocketMessage) => {
      store.handleAlertSocketMessage(message);
    });

    alertsSocket.on('disconnect', () => {
      console.info(`Disconnected: Alerts`);
    });

    alertsSocket.on('connect', () => {
      console.info(`Connected: Alerts`);
    });

    alertsSocket.on('connect_error', (err: any) => {
      console.error(`Error connecting to alerts socket: ${err.message}`);
    });
  }
};
