import { useState, FC } from 'react';
import { DataGrid, GridColDef, GridRowParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box, Stack, Typography, alpha, useTheme } from '@mui/material';
import { useAppStore } from 'MobxStores/context';
import { AlertsMainTableStyles } from 'Screens/AlertDashboard/AlertsDashboardStyles';
import { observer } from 'mobx-react-lite';
import { ElectreonLoader } from '@electreon_ui/shared';
import { CustomFooter } from '../CustomFooter';
import { AlertMessage, AlertReadStatus } from 'MobxStores/utils/alertStoreTypes';

type AlertsTableProps = {
  rows: AlertMessage[];
  columns: GridColDef<AlertMessage>[];
  selectedRowIds: GridRowSelectionModel;
  setSelectedRowIds: (ids: GridRowSelectionModel) => void;
  selectedTimeRange?: number;
  projectId?: number;
};

export const AlertsTable: FC<AlertsTableProps> = observer(
  ({ rows, columns, selectedRowIds, setSelectedRowIds, selectedTimeRange, projectId }) => {
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
    const [hideLoadMoreButton, setHideLoadMoreButton] = useState(false);

    const { alertStore } = useAppStore();
    const { isLoading } = alertStore;
    const theme = useTheme();

    const handleMouseEnter = (e: any) => {
      const id = e.currentTarget.dataset.id;
      setHoveredRowId(id);
    };

    const handleMouseLeave = (e: any) => {
      const id = e.currentTarget.dataset.id;
    };
    const isNotificationPopup = !location.pathname.includes('/dashboard/alerts');

    return (
      <Box
        sx={{
          height: isNotificationPopup
            ? `calc(70vh - ${theme.header?.height})`
            : `calc(80vh - ${theme.header?.height})`, //todo: need to set the height to fit all screens
          width: '100%',
          fontFamily: 'Urbanist, Arial, sans-serif',
          '&& .read-alert-row *': {
            'span,p': {
              color: alpha(theme.palette.grey[500], 1),
            },
            fontWeight: '400 !important',
          },
          '&& .read-alert-row': {
            bgcolor: alpha(theme.palette.grey[100], 1),
          },
          '&& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: `${alpha(theme.palette.grey[100], 1)} !important`,
          },
        }}
      >
        <DataGrid
          pagination={true}
          loading={Boolean(isLoading)}
          getRowHeight={() => 'auto'}
          onRowClick={(e) => {
            alertStore.setAlertsStatus(AlertReadStatus.READ, e.id);
          }}
          onRowSelectionModelChange={(ids) => {
            // Uncomment to get access to the selected rows data
            // const selectedIds = new Set(ids);
            // const selectedRowData = rows.filter((row) => selectedIds.has(row.id.toString()));
            setSelectedRowIds(ids);
          }}
          rowSelectionModel={selectedRowIds}
          getRowClassName={(params: GridRowParams<AlertMessage>) => {
            // This applies the "read styles".
            if (alertStore.isAlertRead(params.row.id)) {
              return 'read-alert-row';
            }
            return '';
          }}
          rows={rows.map((row) => ({
            ...row,
            // This change causes the dataGird table to rerender on change of the isRead status.
            cellClassName: alertStore.isAlertRead(row.id) ? 'read-alert-row' : '',
          }))}
          getRowId={(row) => row.id}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: 'alertTime', sort: 'desc' }],
            },
          }}
          slotProps={{
            row: {
              onMouseEnter: handleMouseEnter,
              onMouseLeave: handleMouseLeave,
            },
            baseCheckbox: {
              sx: {
                '& svg': {
                  fontSize: '20px',
                },
              },
            },
          }}
          sx={AlertsMainTableStyles}
          hideFooter={isNotificationPopup || hideLoadMoreButton}
          slots={{
            noRowsOverlay: () => (
              <Stack direction='row' justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                <Typography>There are no Alerts to display at this time</Typography>
              </Stack>
            ),
            loadingOverlay: (props) => (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: (theme) => theme.palette.secondary.main,
                }}
              >
                <ElectreonLoader width='100%' height='100%' />
              </Box>
            ),
            footer: () => (
              <CustomFooter
                projectId={projectId}
                selectedTimeRange={selectedTimeRange}
                rowsNumber={rows?.length || 0}
              />
            ),
          }}
        />
      </Box>
    );
  }
);
