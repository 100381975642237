import { Button, Typography, useTheme } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCurrentPageName } from 'CustomHooks/useCurrentPageName';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { currentPageDisplayStyles, getNavButtonDynamicStyles } from 'Components/Header/Styles/HeaderStyles';
import { observer } from 'mobx-react-lite';
import { useMedia } from 'react-use';
import { ComponentProps, forwardRef } from 'react';

interface CurrentPageDisplayProps extends ComponentProps<typeof Button> {
  navigationMenuOpen: boolean;
}

const Component = forwardRef<HTMLButtonElement, CurrentPageDisplayProps>(
  ({ navigationMenuOpen, ...props }, ref) => {
    const theme = useTheme();
    const currentPage = useCurrentPageName();
    const isMobile = useMedia(`(max-width: ${theme.breakpoints.values.sm}px)`);

    return (
      <Button
        ref={ref}
        {...props}
        endIcon={
          navigationMenuOpen ? (
            <KeyboardArrowUpIcon className='arrow-icon' />
          ) : (
            <KeyboardArrowDownIcon className='arrow-icon' />
          )
        }
        className='header-project-screens-navigation'
        variant='projectScreensNavMenu'
        sx={{
          padding: isMobile ? 0 : 2,
          '& .MuiButton-endIcon': {
            marginLeft: isMobile ? '-6px' : '4px',
          },
          ...getNavButtonDynamicStyles(navigationMenuOpen),
        }}
      >
        <Typography
          sx={currentPageDisplayStyles}
          color={theme.palette.primary.main}
          textTransform='capitalize'
        >
          {startCase(toLower(currentPage))}
        </Typography>
      </Button>
    );
  }
);
Component.displayName = 'CurrentPageDisplay';

export const CurrentPageDisplay = observer(Component);
