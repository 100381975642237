import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { Container, Stack } from '@mui/material';
import { CurrentPageDisplay } from 'Components/Header/Components/CurrentPageDisplay';
import { HeaderDivider } from 'Components/Header/Components/HeaderDivider';
import { ProjectNameAndLogo } from 'Components/Header/Components/ProjectNameAndLogo';
import { headerStore } from 'Components/Header/headerStore';
import { headerLeftSideStyles } from 'Components/Header/Styles/HeaderStyles';
import { ProjectScreensNavigationMenu } from 'Components/Menu';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'MobxStores/context';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

export const HeaderNavigationSection = observer(() => {
  const { userStore, projectStore } = useAppStore();
  const { selectedProject } = projectStore;
  const { navigationMenuOpen, setNavigationMenuOpen } = headerStore;
  const location = useLocation();
  const isMobile = useMedia(`(max-width: ${theme.breakpoints.values.sm}px)`);
  const navMenuRef = useRef<HTMLButtonElement>(null);

  const shouldRenderNavigationSection =
    Boolean(selectedProject) && !location.pathname.includes('/dashboard/alerts');

  if (!shouldRenderNavigationSection) return null;

  return (
    <Stack direction='row' sx={headerLeftSideStyles} className='header-left-section-project-info'>
      <HeaderDivider margin={'0 1rem 0 1px'} />
      {!isMobile && (
        <>
          <ProjectNameAndLogo project={selectedProject} />
          <HeaderDivider margin={'0 1px 0 1rem'} />
        </>
      )}
      {!userStore?.isSingleProjectViewer && (
        <Container sx={headerLeftSideStyles} disableGutters className='project-screens-nav'>
          <CurrentPageDisplay
            ref={navMenuRef}
            navigationMenuOpen={navigationMenuOpen}
            onClick={() => {
              headerStore.closeAllMenus();
              setNavigationMenuOpen(!navigationMenuOpen);
            }}
          />
          <ProjectScreensNavigationMenu
            navMenuAnchor={navMenuRef.current}
            projectId={selectedProject?.id}
            isOpen={navigationMenuOpen}
            setIsOpen={setNavigationMenuOpen}
          />
        </Container>
      )}
    </Stack>
  );
});
