import { makeAutoObservable, computed } from 'mobx';
import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { getUserPermissions, getUserScopeType } from './userStoreUtils';
import { AbstractUserData, ScopeTypes, UserRole } from '../../constants/constants';
import { AbstractRootStore } from '../storeModels';

class UserStore {
  userData?: UserData;
  scopeType?: string;
  sub?: string;

  constructor() {
    this.userData = { role: UserRole.PROJECT_VIEWER };
    makeAutoObservable(this, {
      userPermissions: computed,
      isCompanyUser: computed,
    });

    makePersistable(this, {
      name: 'UserStore',
      properties: ['userData'],
      storage: window.localStorage,
    });
  }

  get userPermissions() {
    return getUserPermissions(this.userData?.role);
  }

  setUserData = (user: UserData) => {
    this.userData = new UserData(user);
    this.sub = user.sub;
    this.updateRole(user.role);
  };

  updateRole(role: UserRole) {
    if (!this.userData) return;
    console.info('user store updating role to: ', JSON.stringify(role));
    this.userData.role = role;
  }

  resetStore() {
    this.userData = { role: UserRole.PROJECT_VIEWER };
    this.sub = '';
    stopPersisting(this);
  }

  @computed
  get isSingleProjectViewer() {
    return this.userData?.role === UserRole.PROJECT_VIEWER && this.userData.scopeType === ScopeTypes.SINGLE;
  }

  @computed
  get isSingleScoped() {
    return this.scopeType === ScopeTypes.SINGLE;
  }

  @computed
  get isProjectViewer() {
    return this.userData?.role === UserRole.PROJECT_VIEWER;
  }

  getPathToUserMainPage = () => {
    const isSingleProjectViewer = this.isSingleProjectViewer;
    const isSingleScoped = this.isSingleScoped;
    const firstProjectInScope = this.userData?.projectScope?.[0];
    if (isSingleProjectViewer) return `/dashboard/live/project/${firstProjectInScope}`;
    else if (isSingleScoped) return `/dashboard/live/project/${firstProjectInScope}/inline`;
    else return '/dashboard';
  };

  get isCompanyUser() {
    return Boolean(this.userData?.name?.startsWith('google_'));
  }
}

export class UserData implements AbstractUserData {
  name?: string;
  role: UserRole;
  email?: string;
  projectScope?: number[] | string | null;
  sub?: string;
  projectIds?: number[];
  scope?: number[] | null;
  scopeType?: string;
  familyName?: string;
  givenName?: string;

  constructor(user: UserData) {
    this.name = user.name;
    this.role = user.role || UserRole.PROJECT_VIEWER;
    this.email = user.email;
    this.projectScope = user.scope;
    this.scope = user.scope;
    this.sub = user.sub;
    this.projectIds = [...(this.projectScope || [])];
    this.scopeType = getUserScopeType(user);
    this.familyName = user.familyName || '';
    this.givenName = user.givenName || '';
  }
}

export default UserStore;
