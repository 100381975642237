import { UserRole } from '../../constants/constants';
import jwtDecode from 'jwt-decode';
// import LogRocket from 'logrocket';
import { DecodedAccessToken, DecodedIdToken, DecodedTokens, LoginResponse } from './authStoreTypes';
import UserStore from '../userStore/UserStore';

export const accountTypes = { google: 'Google', local: 'Local' };

const getRoleFromIdToken = (decodedIdToken: DecodedIdToken): UserRole | undefined => {
  if (!Array.isArray(decodedIdToken['cognito:groups'])) return decodedIdToken['cognito:groups'];
  const groups = decodedIdToken['cognito:groups'];
  const role = groups.find((group) => group.match(/^[A-Z]+[_A-Z]+[A-Z]+$/));
  if (!role) return undefined;
  return role as UserRole;
};

export const setUserInfo = (userData: DecodedTokens, store?: UserStore) => {
  const { IdToken /* , AccessToken, RefreshToken */ } = userData;
  const user = {
    name: IdToken['cognito:username'],
    username: IdToken.email,
    role: getRoleFromIdToken(IdToken) || UserRole.PROJECT_VIEWER,
    email: IdToken['email'],
    scope: JSON.parse(IdToken['custom:authorization']).projectScope,
    sub: IdToken.sub,
    givenName: IdToken.given_name || '',
    familyName: IdToken.family_name || '',
  };

  store?.setUserData?.(user);

  // LogRocket.identify?.(IdToken.sub, {
  //   name: `${user.givenName || 'UnknownFirst'} ${user.familyName || 'UnknownLast'}`,
  //   email: user.email,
  //   userRole: user.role || 'No role',
  //   userScope: user.scope,
  //   accountType: IdToken['cognito:username'].startsWith('google_') ? accountTypes.google : accountTypes.local,
  // });
};

export const decodeTokensFromSession = (session: LoginResponse) => {
  const idToken = session.IdToken;
  const accessToken = session.AccessToken;
  const refreshToken = session.RefreshToken;
  const decodedIdToken: DecodedIdToken = jwtDecode(idToken || '');
  const decodedAccessToken: DecodedAccessToken = jwtDecode(accessToken || '');

  return { refreshToken, decodedIdToken, decodedAccessToken };
};
