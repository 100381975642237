import { Stack, Typography } from '@mui/material';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { Spacer } from 'Components/UtilityComponents/Spacer';
import { headerIconHeight, ProjectNameStyles } from 'Components/Header/Styles/HeaderStyles';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useProjectLogo } from 'Utils/ProjectUtils';

interface ProjectNameAndLogoProps {
  project: Project | null;
}

const projectNamesToUnformat = ['THK', 'Electreon R&D'];
const shouldRemoveFormat = (name: string) => projectNamesToUnformat.includes(name);

export const ProjectNameAndLogo: React.FC<ProjectNameAndLogoProps> = ({ project }) => {
  const { name = 'Electreon', id } = project || {};
  const { data: logo } = useProjectLogo(String(id), 'small');
  const shouldHideLogo = Boolean(name === 'ElectReon' || name === 'Electreon R&D');
  const nameToDisplay = shouldRemoveFormat(name) ? name : startCase(toLower(name));
  return (
    <Stack direction='row' gap={1} sx={{ alignItems: 'center' }}>
      {logo && !shouldHideLogo && (
        <Stack sx={headerIconHeight} justifyContent='center'>
          <img src={logo} alt='project-logo' style={{ maxHeight: '30px' }} />
        </Stack>
      )}
      <Spacer width={4} />
      <Typography sx={ProjectNameStyles}>{nameToDisplay}</Typography>
    </Stack>
  );
};
