import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { ProjectSelectionFormInput } from '../ProjectSelectionInputs/ProjectSelectionFormInput';
import { DeviceNameInput } from '../EditAPFC&POSForms/FormInputs/DeviceNameInput';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { getProjectMetadata } from '@electreon_ui/shared/stores/projectStore/projectStoreUtils';
import { FormLoading } from '../FormUtils/FormLoading';
import { EditOCPPInput, EditOCPPSchema } from '../EditAPFC&POSForms/Utils/FormsSchemas';
import { updateOCPPDevice } from '../FormsAPIs/api';
import { OCPPSelectionInput } from '../EditAPFC&POSForms/FormInputs/OCPPSelectionInput';
import { OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { observer } from 'mobx-react-lite';
import { getProjectDeployment } from 'Utils/APIUtils';
import { WindowEventService, WindowEvents } from '@electreon_ui/shared/services/WindowEventService';
import { toast } from 'sonner';

interface EditOCPPFormProps extends FormProps {
  selectedProject?: Project | null;
  selectedDevice?: OcppChargerModelRefined;
}

export const EditOCPPForm: React.FC<EditOCPPFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject: _selectedProject, selectedDevice }) => {
    const { projectStore, deviceStore } = useAppStore();
    const [selectedProject, setSelectedProject] = useState<Project | null>(_selectedProject ?? null);
    const [selectedOcpp, setSelectedOcpp] = useState<OcppChargerModelRefined | null>(
      selectedDevice ? selectedDevice : (projectStore?.ocppList[0] ?? null)
    );
    const [deviceLongitude, setDeviceLongitude] = useState<string>(String(selectedOcpp?.longitude) || '');
    const [deviceLatitude, setDeviceLatitude] = useState<string>(String(selectedOcpp?.latitude) || '');
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const deviceChangeVersion = selectedOcpp?.id
      ? deviceStore.versions?.[selectedOcpp?.id]?.deviceChangesVersion
      : selectedOcpp?.version;

    const methods = useForm<EditOCPPInput>({
      resolver: zodResolver(EditOCPPSchema),
      defaultValues: {
        deviceId: selectedOcpp?.id || '',
        deviceName: selectedOcpp?.name || '',
        projectName: selectedProject?.name,
        longitude: String(selectedOcpp?.longitude),
        latitude: String(selectedOcpp?.latitude),
      },
    });

    const errors = methods?.formState?.errors;

    const onSubmitHandler: SubmitHandler<EditOCPPInput> = (values) => {
      setLoading(true);
      setSubmitError(null);
      const { deviceName, deviceId, longitude, latitude } = values;
      updateOCPPDevice({
        id: deviceId,
        name: deviceName,
        projectId: selectedProject?.id as number,
        version: deviceChangeVersion || selectedOcpp?.version || 0,
        longitude: Number(longitude) || selectedOcpp?.longitude || 0,
        latitude: Number(latitude) || selectedOcpp?.latitude || 0,
        timezone: selectedProject?.timezoneStr || 'Asia/Jerusalem',
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success('OCPP device updated');
            methods.reset();
            onSuccessfulSubmit?.();
            getProjectDeployment(selectedProject?.id || '').then((deployment) => {
              projectStore.setProjectDeployment(selectedProject?.id, deployment.data);
            });
            WindowEventService.emit(WindowEvents.SHOULD_UPDATE_DEPLOYMENT, {});
          } else if (res.status === 400) {
            toast.error('OCPP device update failed');
            setSubmitError('OCPP Device update failed');
            throw new Error('Update OCPP device failed');
          } else if (res.status === 409) {
            toast.error('OCPP creation conflict');
            setSubmitError('OCPP Creation conflict');
            throw new Error('Update OCPP device failed');
          } else {
            toast.error('OCPP device update failed');
            setSubmitError('OCPP Device update failed');
            throw new Error('Update OCPP device failed');
          }
        })
        .catch((err) => {
          toast.error('OCPP device update failed');
          console.error('Update OCPP device error', JSON.stringify(err));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // update devices lists
    useEffect(() => {
      if (!projectStore?.selectedProject?.id) return;
      setFormLoading(true);
      getProjectMetadata(projectStore.selectedProject.id, projectStore).then((res) => {
        setSelectedOcpp(
          projectStore?.ocppList.find((device) => device.id === selectedDevice?.id) ||
            projectStore?.ocppList[0]
        );
        setFormLoading(false);
      });
    }, [projectStore, selectedDevice?.id]);

    useEffect(() => {
      setDeviceLongitude(String(selectedOcpp?.longitude) || '');
      methods.setValue('longitude', String(selectedOcpp?.longitude) || '');

      setDeviceLatitude(String(selectedOcpp?.latitude) || '');
      methods.setValue('latitude', String(selectedOcpp?.latitude) || '');
    }, [selectedOcpp, methods]);

    return (
      <FormProvider {...methods}>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          Edit OCPP
        </Typography>

        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
            console.error(e);
          })}
        >
          <ProjectSelectionFormInput
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            disabled
          />
          <OCPPSelectionInput
            selectedOcpp={selectedOcpp}
            setSelectedOcpp={setSelectedOcpp}
            disabled={!!selectedDevice}
          />
          <DeviceNameInput currentDeviceName={selectedOcpp?.name || ''} deviceType={'OCPP'} />
          <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
            <TextField // long
              sx={{ flex: 1 }}
              label='Longitude'
              value={deviceLongitude}
              fullWidth
              error={!!errors['longitude']}
              helperText={errors['longitude'] ? errors['longitude'].message : ''}
              {...methods.register('longitude')}
              onChange={(e: any) => {
                setDeviceLongitude(e.target.value);
              }}
            />
            <TextField // lat
              sx={{ flex: 1 }}
              label='Latitude'
              value={deviceLatitude}
              fullWidth
              error={!!errors['latitude']}
              helperText={errors['latitude'] ? errors['latitude'].message : ''}
              {...methods.register('latitude')}
              onChange={(e: any) => {
                setDeviceLatitude(e.target.value);
              }}
            />
          </Stack>
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormLoading open={formLoading} />
          <FormSubmitionButtons loading={loading} onCancel={onCancel} />
        </Box>
      </FormProvider>
    );
  }
);
