import {
  DataGraphModel,
  DataGraphParameterType,
  DataGraphParametersModel,
  DataGraphParametersModelChartTypeEnum,
  DataGraphRequest,
  IoTDeviceType,
  TimeSeriesTelemetryModelDeviceEventLog,
} from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { colors } from '@mui/material';
import { SelectedDevices, TimeSeries } from 'MobxStores/Types';
import {
  AnalyticsDataGraphRequest,
  AnalyticsEventSummary,
  AnalyticsParamsResponse,
  DataGraphParametersCategoryModelRefined,
  EVENT_CATEGORIES,
  EventCategory,
  FormattedEventGraphPoint,
  FormattedTimeSeriesTelemetryModelDeviceEventLog,
  SelectedEventCategoriesAndTypes,
  isEventCategory,
} from 'MobxStores/Types/AnalyticsTypes';
import { isFalseyButNotZero, snakeToTitleCase } from 'Utils/utils';
import {
  roundUpToNearest10,
  roundUpToNearest10k,
  roundUpToNearest100k,
  roundUpToNearest1k,
} from 'Utils/AnalyticUtils';
import { Coordinate } from 'ol/coordinate';
import { rootStore } from 'MobxStores/context';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { isSameMinute } from 'date-fns';
import { TimeMeasurement } from 'Screens/AnalyticsTool/Hooks/useTimeMeasure';
import { getPalette } from '@electreon_ui/shared/Themes/colorPalette';
import { getISOFormat } from 'Utils/utils';
import moment from 'moment';

const theme = getPalette();

/**
 * this will create a gaps in the chart when minGapMs is passed between two cells which hold truthy values (not null or undefined) assuming connectNulls is false
 *
 * if time passed between previous and current cells which hold truthy values (not null or undefined) is greater than minGapMs:
 *
 * add { timestamp: previous.timestamp + 1, value: null } to the array
 *
 * and add { timestamp: current.timestamp - 1, value: null } to the array
 *
 */
export const insertGaps = (d: TimeSeries, minGapMs?: number | null) => {
  if (!minGapMs) return d;
  const gapped = d.reduce((acc: TimeSeries, curr, i) => {
    if (i === 0) return acc;
    const p = d[i - 1];
    const c = d[i];
    if (isFalseyButNotZero(c.timestamp) || isFalseyButNotZero(p.timestamp)) return acc;
    const timePassed = c.timestamp - p.timestamp;
    if (timePassed > minGapMs) {
      acc.push({
        timestamp: p.timestamp + 1,
        value: null,
        previousNonNullishValue: p.value ?? p.previousNonNullishValue ?? undefined,
      });
      acc.push({
        timestamp: c.timestamp - 1,
        value: null,
        previousNonNullishValue: c.value ?? p.previousNonNullishValue ?? undefined,
      });
    }
    acc.push(curr);
    return acc;
  }, []);
  return gapped;
};

export const debugPrintGapTable = (
  scaleFactor: number,
  shift: number,
  power: number,
  minNullGapInMs: number = 200,
  samples = 1000
) => {
  const minTimeInMs = 1 * 1000; // 1 second
  const maxTimeInMs = 36 * 60 * 60 * 1000; // 36 hours

  const timePoints = Array.from(
    { length: samples },
    (_, i) => minTimeInMs + (i * (maxTimeInMs - minTimeInMs)) / samples - 1
  );

  const dataPoints = timePoints.map((displayedTimeRangeInMs) => {
    const minGapInMs = Math.max(
      minNullGapInMs,
      scaleFactor / Math.pow(displayedTimeRangeInMs / 1000 + shift, -power)
    );
    const minGapInSeconds = minGapInMs / 1000;
    const displayedTimeRangeInMins = displayedTimeRangeInMs / 1000 / 60;
    return {
      displayedTimeRangeInMins: +displayedTimeRangeInMins.toFixed(2),
      minGapInSeconds: +minGapInSeconds.toFixed(2),
      currentHour: Math.floor(displayedTimeRangeInMins / 60),
    };
  });

  console.table(dataPoints);
};

export class GraphProperties {
  public graphType: GraphType;

  constructor(
    private graphId: DataGraphParameterType,
    private minValue: number,
    private maxValue: number,
    private chartType?: DataGraphParametersModelChartTypeEnum,
    private measurementUnit?: string,
    private lastValue?: number | null
  ) {
    this.graphId = graphId;
    this.minValue = minValue;
    this.maxValue = maxValue;
    this.chartType = chartType;
    this.measurementUnit = measurementUnit;
    this.lastValue = lastValue;
    this.graphType = new GraphType(graphId, chartType);
  }

  private _calcMinDomain = (minValue: number, factor: number) => {
    // if its negative divide by factor else multiply
    return minValue < 0 ? minValue / factor : minValue * factor;
  };

  get shouldConnectNulls() {
    const graphType = this.graphType;

    // Dc sampler and fpga graphs should not connect nulls
    if (graphType.isDcSamplerGraph || graphType.isFpgaGraph) return false;

    return (
      graphType.isDiscreteChart ||
      graphType.isTemperatureGraph ||
      graphType.isGpsHeadingGraph ||
      graphType.isSpeedGraph ||
      graphType.isMaxChargingLevelGraph ||
      graphType.isSocGraph ||
      graphType.isCanBusChargingPowerGraph ||
      graphType.isCanActiveReceiversGraph ||
      graphType.isHeatSinkGraph ||
      graphType.isRfBoostGraph ||
      graphType.isBatteryTemperatureGraph ||
      graphType.isEvCurrentGraph ||
      graphType.isFullyChargedGraph ||
      graphType.isFpgaCurrentGraph
    );
  }

  get graphUnits() {
    switch (true) {
      case this.graphType.isCanActiveReceiversGraph:
        return ' ';

      default:
        return this.measurementUnit || ' ';
    }
  }

  public getLineGraphYDomain = ([dataMin, dataMax]: [number, number]): [number, number] => {
    switch (true) {
      case !Number.isFinite(this.minValue) || !Number.isFinite(this.maxValue):
        return [-1, 1];
      case this.graphType.isChargingPossibleGraph:
        return [-1, 2];
      case this.graphType.isDiscreteChart:
        return [0, this.maxValue + 1 + 0.02];
      case this.graphType.isGpsHeadingGraph:
        return [0, 360];
      case this.graphType.isSocGraph || this.graphType.isFpgaSegmentCommDuration:
        return [0, 100];
      case this.graphType.isFullyChargedGraph:
        return [0, 16];
      case this.graphType.isCanActiveReceiversGraph:
        return [0, this.maxValue + 1];
      case this.graphType.isAccumulatedGraph:
        return [0, roundUpToNearest10(dataMax * 1.15)];
      case this.graphType.isSpeedGraph:
        return [0, roundUpToNearest10(dataMax)];
      case this.graphType.isMuPowerGraph ||
        this.graphType.isVuPowerGraph ||
        this.graphType.isVuAvgPowerGraph ||
        this.graphType.isVuAvgCurrentGraph:
        return [0, roundUpToNearest10(dataMax)];
      case this.graphType.isFpgaCurrentGraph:
        return [this.minValue, this.maxValue * 1.15];
      case this.minValue > 0:
        return [
          this._calcMinDomain(this.minValue, 0.9),
          +roundUpToNearest10(this.maxValue * 1.15).toFixed(2),
        ];
      default:
        return [
          (this.graphType.isVoltageGraph && dataMin) || 0,
          +roundUpToNearest10(dataMax * 1.15).toFixed(2),
        ];
    }
  };

  get lineGraphTicks() {
    switch (true) {
      case !Number.isFinite(this.minValue) || !Number.isFinite(this.maxValue):
        return [-1, 0, 1];
      case this.graphType.isDiscreteChart:
        return [0, this.maxValue + 1];
      case this.graphType.isGpsHeadingGraph:
        return [0, 180, 360];
      case this.graphType.isSocGraph || this.graphType.isFpgaSegmentCommDuration:
        return [0, 50, 100];
      case this.graphType.isFullyChargedGraph:
        return [0, 8, 16];
      case this.graphType.isCanActiveReceiversGraph:
        return [0, Math.round((this.maxValue + 1) / 2), this.maxValue + 1];
      case this.graphType.isAccumulatedGraph:
        if (!this.lastValue) return [];
        const roundTo100k = roundUpToNearest100k(this.lastValue);
        const roundTo10k = roundUpToNearest10k(this.lastValue);
        const roundTo1k = roundUpToNearest1k(this.lastValue);
        const isValueLessThan100k = this.lastValue < 100000;
        const isValueLessThan10k = this.lastValue < 10000;
        const roundedTick = isValueLessThan10k ? roundTo1k : isValueLessThan100k ? roundTo10k : roundTo100k;
        return [0, Math.round(roundedTick / 2), roundedTick];
      case this.graphType.isSpeedGraph:
        return [
          0,
          Math.round(roundUpToNearest10(this.maxValue * 1.15) / 2),
          roundUpToNearest10(this.maxValue * 1.15),
        ];
      case this.graphType.isMuPowerGraph ||
        this.graphType.isVuPowerGraph ||
        this.graphType.isVuAvgPowerGraph ||
        this.graphType.isVuAvgCurrentGraph:
        const isValueLessThanThousand = this.maxValue > 1000;
        const roundedValue = isValueLessThanThousand
          ? roundUpToNearest10k(this.maxValue * 1.15)
          : roundUpToNearest10(this.maxValue * 1.15);
        return [0, roundedValue / 2, roundedValue];
      case this.graphType.isFpgaCurrentGraph:
        return [this.minValue, this.maxValue * 1.15];
      default:
        return [
          this._calcMinDomain(this.minValue, 0.9),
          this.maxValue / 1000 > 1
            ? roundUpToNearest10k(this.minValue * 0.9 + this.maxValue * 1.15) / 2
            : roundUpToNearest10(this.minValue * 0.9 + this.maxValue * 1.15) / 2,
          this.maxValue / 1000 > 1
            ? roundUpToNearest10k(this.maxValue * 1.15).toFixed(2)
            : roundUpToNearest10(this.maxValue * 1.15).toFixed(2),
        ];
    }
  }
}

export class GraphType {
  constructor(
    private graphId: DataGraphParameterType,
    private chartType?: DataGraphParametersModelChartTypeEnum
  ) {
    this.graphId = graphId;
    this.chartType = chartType;
  }

  private idMatches = (graphId: DataGraphParameterType) => this.graphId === graphId;

  get isDiscreteChart() {
    return this.chartType === DataGraphParametersModelChartTypeEnum.Discrete;
  }

  get isEvCurrentGraph() {
    return this.idMatches(DataGraphParameterType.VuCanTxEvCurrent);
  }

  get isBatteryTemperatureGraph() {
    return this.idMatches(DataGraphParameterType.VuCanTxBatteryTemp);
  }

  get isHeatSinkGraph() {
    return (
      this.idMatches(DataGraphParameterType.CuUnitHeatSinkTemperature0) ||
      this.idMatches(DataGraphParameterType.CuUnitHeatSinkTemperature1) ||
      this.idMatches(DataGraphParameterType.VuReceiverCapHeatSink0) ||
      this.idMatches(DataGraphParameterType.VuReceiverCapHeatSink1)
    );
  }

  get isVuPowerGraph() {
    return this.idMatches(DataGraphParameterType.VuDcMeterPower);
  }

  get isVuAvgPowerGraph() {
    return this.idMatches(DataGraphParameterType.VuDcMeterPowerAvg);
  }

  get isMuPowerGraph() {
    return (
      this.idMatches(DataGraphParameterType.MuDcMeterPower) ||
      this.idMatches(DataGraphParameterType.MuDcMeterTotalPower) ||
      this.idMatches(DataGraphParameterType.MuDcMeterTotalPowerAvg) ||
      this.idMatches(DataGraphParameterType.MuDcMeterPowerAvg)
    );
  }

  get isAccumulatedGraph() {
    return (
      this.idMatches(DataGraphParameterType.MuDcMeterAccumulatedPower) ||
      this.idMatches(DataGraphParameterType.VuDcMeterAccumulatedPower)
    );
  }

  get isTemperatureGraph() {
    return String(this.graphId).match(/temp/gi) !== null || String(this.graphId).match(/heat_/gi) !== null;
  }

  get isRfBoostGraph() {
    return this.idMatches(DataGraphParameterType.CuUnitRfBoostLevel);
  }

  get isGpsHeadingGraph() {
    return this.idMatches(DataGraphParameterType.VuGpsHeading);
  }

  get isVuAvgCurrentGraph() {
    return this.idMatches(DataGraphParameterType.VuDcMeterCurrentAvg);
  }

  get isCurrentGraph() {
    return (
      this.idMatches(DataGraphParameterType.FpgaOverCurrentThreshold) ||
      this.idMatches(DataGraphParameterType.VuCanTxEvCurrent) ||
      this.idMatches(DataGraphParameterType.MuDcMeterCurrent) ||
      this.idMatches(DataGraphParameterType.VuDcMeterCurrent) ||
      this.idMatches(DataGraphParameterType.FpgaSegmentIzeroCurrent) ||
      this.idMatches(DataGraphParameterType.FpgaSegmentPhaseACurrent)
    );
  }

  get isSpeedGraph() {
    return this.idMatches(DataGraphParameterType.VuGpsSpeed);
  }

  get isCanActiveReceiversGraph() {
    return this.idMatches(DataGraphParameterType.VuCanRxNumReceiversActive);
  }

  get isCanBusChargingPowerGraph() {
    return this.idMatches(DataGraphParameterType.VuCanRxChargingPower);
  }

  get isCanBusGraph() {
    return this.graphId.includes('VU_CAN');
  }

  get isSocGraph() {
    return this.idMatches(DataGraphParameterType.VuCanTxSoc);
  }

  get isFullyChargedGraph() {
    return this.idMatches(DataGraphParameterType.VuCanRxTimeToFullyCharge);
  }

  get isVoltageGraph() {
    return this.idMatches(DataGraphParameterType.VuDcMeterVoltage || DataGraphParameterType.MuDcMeterVoltage);
  }

  get isMaxChargingLevelGraph() {
    return this.idMatches(DataGraphParameterType.VuCanTxMaxChargingLevel);
  }

  get isFpgaCurrentOffParameterGraph() {
    return this.idMatches(DataGraphParameterType.FpgaCurrentOff);
  }

  get isFpgaCurrentGraph() {
    return (
      this.idMatches(DataGraphParameterType.FpgaSegmentPhaseACurrent) ||
      this.idMatches(DataGraphParameterType.FpgaSegmentIzeroCurrent)
    );
  }

  get isChargingPossibleGraph() {
    return this.idMatches(DataGraphParameterType.VuCanRxChargingPossible);
  }

  get isFpgaGraph() {
    return this.graphId.includes('FPGA');
  }

  get isDcSamplerGraph() {
    return this.graphId.includes('DC_METER');
  }

  get isFpgaSegmentCommDuration() {
    return this.idMatches(DataGraphParameterType.FpgaSegmentCommDuration);
  }
}

export const DEVAULT_CATEGORY_SELECTION: SelectedEventCategoriesAndTypes['string'] = {
  EVENTS_TYPE_INFO: { isEventCategorySelected: false },
  EVENTS_TYPE_WARNING: { isEventCategorySelected: false },
  EVENTS_TYPE_ERROR: { isEventCategorySelected: true },
} as const;

export const getSelectedEventsFromSummary = (
  summary: AnalyticsEventSummary,
  devicesCategorySelection: SelectedEventCategoriesAndTypes
): string[] => {
  const matchedSeverities = Object.entries(summary).reduce<Array<string>>(
    (acc, [deviceId, categoryToCountMap]) => {
      return Object.entries(categoryToCountMap).reduce<Array<string>>(
        (acc, [category, eventTypesToCountMap]) => {
          if (!isEventCategory(category)) return acc;
          const selectedEventTypes = Object.keys(eventTypesToCountMap).reduce<Array<string>>(
            (acc, eventType) => {
              if (devicesCategorySelection[deviceId]?.[category]?.isEventCategorySelected)
                // entire category is selected, so return all event types under it
                return [...acc, eventType];
              else if (devicesCategorySelection[deviceId]?.[category]?.[eventType])
                // specific event type is selected
                return [...acc, eventType];
              else return acc;
            },
            []
          );
          return [...acc, ...selectedEventTypes];
        },
        acc
      );
    },
    []
  );

  return matchedSeverities;
};

export const normalizeTimeSeriesLengths = (
  graphData: DataGraphModel[],
  eventsData?: { [deviceId: string]: TimeSeriesTelemetryModelDeviceEventLog },
  eventsSummary?: AnalyticsEventSummary
) => {
  // const allUniqueTimestamps: Set<number> = new Set();
  const allUniqueTimeStamps: {
    [timestamp: number]: {
      event?: FormattedEventGraphPoint;
    };
  } = {};

  const isAccumulatedGraph = (paramId?: string) => paramId?.includes('ACCUMULATED_POWER');

  // Collect all unique timestamps and find the maximum power value
  for (const data of graphData) {
    const timeSeriesArray = (data.timeSeriesModel?.timeSeries || []) as TimeSeries;
    for (const timeSeries of timeSeriesArray) {
      if (!timeSeries.timestamp) continue;
      allUniqueTimeStamps[timeSeries.timestamp] = {};
    }
  }

  // Add timestamps from events data
  Object.values(eventsData || {}).forEach((deviceEventLog) => {
    deviceEventLog.timeSeries?.forEach((event) => {
      if (!deviceEventLog.deviceId) throw new Error(`Device id is missing for device event log`);
      if (event.timestamp) {
        allUniqueTimeStamps[event.timestamp] = { event: { ...event, deviceId: deviceEventLog.deviceId } };
      }
    });
  });

  const sortedUniqueTimestamps: Array<{ timestamp: number }> = [];
  const sortedTimestampsAndEventsMap: {
    timestamp: number;
    event?: FormattedEventGraphPoint;
  }[] = Object.entries(allUniqueTimeStamps)
    // Sort the timestamps and events by timestamp (key) and map to an array of objects
    .sort(([a], [b]) => +a - +b)
    .map(([timestamp, { event }]) => {
      sortedUniqueTimestamps.push({ timestamp: +timestamp });
      return { timestamp: +timestamp, event };
    });

  // Normalize graph data
  const normalizedGraphData = graphData.map((data, i) => {
    // Calculate the minimum accumulated value for each graph individually
    const timeSeries = (data.timeSeriesModel?.timeSeries || []) as TimeSeries;
    let minAccumulatedValue = isAccumulatedGraph(data.param?.id) ? timeSeries[0]?.value || 0 : 0;

    let maxValue = -Infinity;
    let minValue = Infinity;

    const timeSeriesMap = new Map(
      timeSeries.map((timeSeriesElement) => {
        // If it's an accumulated graph and the current value is less than the minimum, update the minimum
        if (
          isAccumulatedGraph(data.param?.id) &&
          timeSeriesElement.value &&
          timeSeriesElement.value < minAccumulatedValue
        ) {
          minAccumulatedValue = timeSeriesElement.value;
        }
        maxValue = Math.max(maxValue, timeSeriesElement.value || -Infinity);
        minValue = Math.min(minValue, timeSeriesElement.value || Infinity);
        return [timeSeriesElement.timestamp, timeSeriesElement.value];
      })
    );

    let previousValue: number | undefined = undefined;
    let lastDefinedIndex: number | undefined = undefined; // To track the last index with a defined value

    const timeSeriesModel = {
      deviceId: data.timeSeriesModel?.deviceId,
      deviceType: data.timeSeriesModel?.deviceType,
      intervalTimeMs: data.timeSeriesModel?.intervalTimeMs,
      timeSeries: sortedTimestampsAndEventsMap.map(({ timestamp, event }, index) => {
        // If it's an accumulated graph and the timestamp exists in the map, subtract the minimum accumulated value
        // if there is no value for the current timestamp, set the value as undefined
        const valueToSet =
          isAccumulatedGraph(data.param?.id) && timeSeriesMap.has(timestamp)
            ? timeSeriesMap.get(timestamp)! - minAccumulatedValue
            : (timeSeriesMap.get(timestamp) ?? undefined);

        // Capture the current previous value before potentially updating it
        let currentPreviousValue = previousValue;
        if (valueToSet !== undefined) {
          previousValue = valueToSet;
          lastDefinedIndex = index; // Update the last defined index when value is not undefined
        }

        const seriesEntry = {
          timestamp,
          value: valueToSet ?? (event ? previousValue : undefined),
          previousNonNullishValue: currentPreviousValue, // Add the previously tracked non-nullish value to each cell
          event,
        };
        return seriesEntry;
      }),
    };

    return {
      ...data,
      minValue,
      maxValue,
      lastDefinedIndex,
      filters: data.filters,
      timeSeriesModel: timeSeriesModel,
    };
  });

  const eventTypeToCategory = new Map<string, EventCategory>();
  Object.entries(eventsSummary || {}).forEach(([deviceId, deviceEvents]) => {
    Object.entries(deviceEvents).forEach(([eventCategory, eventTypes]) => {
      if (!isEventCategory(eventCategory)) throw new Error('Invalid event category');
      Object.keys(eventTypes).forEach((eventType) => {
        eventTypeToCategory.set(eventType, eventCategory);
      });
    });
  });

  // Normalize events data and format the cells
  const normalizedEventsData = Object.entries(eventsData || {}).reduce<{
    [deviceId: string]: FormattedTimeSeriesTelemetryModelDeviceEventLog;
  }>((acc, [deviceId, eventLog]) => {
    const normalizedTimeSeries = sortedTimestampsAndEventsMap.map(({ timestamp, event }) => {
      return event && event.deviceId === deviceId ? formatEventData(event, eventTypeToCategory) : null;
    });

    acc[deviceId] = { ...eventLog, timeSeries: normalizedTimeSeries };
    return acc;
  }, {});

  return {
    normalizedData: { eventsData: normalizedEventsData, advancedParamsData: normalizedGraphData },
    timestampsArray: sortedUniqueTimestamps,
  };
};

const formatEventData = (
  event: FormattedEventGraphPoint,
  eventTypeToCategory: Map<string, EventCategory>
): FormattedEventGraphPoint => {
  const categoryToYPosition = new Map([
    [EVENT_CATEGORIES.INFO, 1],
    [EVENT_CATEGORIES.WARNING, 2],
    [EVENT_CATEGORIES.ERROR, 3],
    [EVENT_CATEGORIES.FPGA, 4],
    [undefined, 4],
  ]);

  const getColor = (eventCategory?: EventCategory) => {
    switch (eventCategory) {
      case EVENT_CATEGORIES.INFO:
        return colors.green[300];
      case EVENT_CATEGORIES.WARNING:
        return colors.yellow[700];
      case EVENT_CATEGORIES.ERROR:
        return colors.red[300];
      case EVENT_CATEGORIES.FPGA:
        return colors.blue[300];
      default:
        return 'yellow';
    }
  };

  const formattedData = {
    deviceId: event.deviceId,
    x: event.timestamp,
    y: categoryToYPosition.get(eventTypeToCategory.get(event.eventName || '')),
    name: event.eventName,
    fill: getColor(eventTypeToCategory.get(event.eventName || '')),
    payload: event.payload,
  } satisfies FormattedEventGraphPoint;

  return {
    ...event,
    ...formattedData,
  };
};

export const formatEventTypeLabel = (eventType: string) => {
  const formatted = snakeToTitleCase(eventType.replace('EVENT_', ''), { onlyFirstWordCapital: true });
  return formatted;
};

export const prepareDataGraphParams = ({
  zoomStart,
  zoomEnd,
  selectedDevices,
}: {
  zoomStart?: Date | number;
  zoomEnd?: Date | number;
  selectedDevices: SelectedDevices;
}): {
  startTime: string;
  endTime: string;
  advancedParamsRequestData: DataGraphRequest[];
} => {
  const startTime = getISOFormat(zoomStart!);
  const endTime = getISOFormat(zoomEnd!);

  const advancedParamsRequestData: DataGraphRequest[] = Object.entries(
    selectedDevices
  ).reduce<AnalyticsDataGraphRequest>((requestBody, [deviceId, deviceObj]) => {
    const { selectedParameters } = deviceObj;

    selectedParameters.forEach((param) => {
      const parsedParam = JSON.parse(param);
      if (!parsedParam.parameter) throw new Error('No parameter provided');

      requestBody.push({
        deviceId,
        param: parsedParam.parameter,
        filters: parsedParam.filters,
      });
    });
    return requestBody;
  }, []);

  return {
    startTime,
    endTime,
    advancedParamsRequestData,
  };
};

export const addCommasToLargerThanThousand = (value?: string | number) => {
  const valueToParse = typeof value === 'string' ? value : value?.toString();
  if (!valueToParse) return value;
  if (valueToParse.length > 3 && !isNaN(Number(valueToParse))) {
    return valueToParse.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

type CoordinateTimeSeries = Array<{ timestamp?: number | null; value?: number | null }>;

export const combineLatLongTimeSeriesArrays = ({
  latArray,
  longArray,
}: {
  latArray?: CoordinateTimeSeries;
  longArray?: CoordinateTimeSeries;
}): Array<{ timestamp: number; coordinate: Coordinate }> => {
  if (!latArray || !longArray) return [];
  if (latArray.length !== longArray.length) {
    console.warn('Lat and long arrays are not equal length aborting array combination process.');
    return [];
  }
  // Combines the lat and long arrays into a single array of coordinates
  // if a timestamp exists in one array but not the other, logs a warning and skips the timestamp
  const combinedArray: Array<{ timestamp: number; coordinate: Coordinate }> = [];
  for (let i = 0; i < latArray.length; i++) {
    if (latArray[i].timestamp !== longArray[i].timestamp) {
      console.warn(`Lat and long arrays have different timestamps at index ${i}`);
      continue;
    }
    const latValue = latArray[i].value;
    const longValue = longArray[i].value;
    if (latValue && longValue && latArray[i].timestamp) {
      combinedArray.push({ timestamp: latArray[i].timestamp!, coordinate: [longValue, latValue] });
    }
  }
  return combinedArray;
};

export const getTooltipLabel = ({
  units,
  currentValue,
  graphType,
  maxValue,
  isTimeGraph,
  graphParamType,
}: {
  currentValue?: number;
  units?: string;
  graphType?: GraphType;
  maxValue?: number;
  graphParamType?: DataGraphParameterType;
  isTimeGraph?: boolean;
}) => {
  const formattedLabel: { value: string; units: string } = { value: '', units: '' };
  const isDiscreteChart = graphType?.isDiscreteChart;
  const isAccumulatedPowerGraph = graphType?.isAccumulatedGraph;
  if (!currentValue && currentValue !== 0) return formattedLabel;
  if (!isTimeGraph) {
    if (
      (graphType?.isMuPowerGraph || graphType?.isVuPowerGraph || graphType?.isVuAvgPowerGraph) &&
      maxValue &&
      maxValue > 1000
    ) {
      formattedLabel.units = 'kW';
      formattedLabel.value = Number(currentValue / 1000).toFixed(2);
    } else {
      const isWatts = units === 'W';
      const isKw = isWatts && currentValue > 1000;
      const isWh = units === 'wH' || units === 'Wh';
      const isKwh = isWh && currentValue > 1000;

      const formattedDiscreteValue = snakeToTitleCase(
        rootStore.projectAnalyticsStore.paramToEnumMap.get(graphParamType || '')?.[currentValue] ||
        'Unknown value',
        { onlyFirstWordCapital: true }
      );

      formattedLabel.units = isKw ? 'kW' : isKwh && !isAccumulatedPowerGraph ? 'kWh' : units || '';

      formattedLabel.value = isNaN(currentValue)
        ? '--'
        : isKwh && !isAccumulatedPowerGraph
          ? Math.round(Number(currentValue / 1000)).toString()
          : isKw
            ? Number(currentValue / 1000).toFixed(2)
            : isDiscreteChart
              ? `${formattedDiscreteValue} (${currentValue})`
              : Number(currentValue).toFixed(2);
    }
  }

  return formattedLabel;
};

// function that takes start and end time, and returns new start and end times which are calculated by:
// timespan = end - start, newStart = start - timespan * 0.05, newEnd = end + timespan * 0.05
// returns unix timestamps
export const getPaddedTime = (
  startTime?: number,
  endTime?: number,
  padPrecent: number = 5,
  timezoneStr: string = 'Asia/Jerusalem'
): { paddedStart?: number; paddedEnd?: number } => {
  if (!startTime || !endTime || !timezoneStr) return {};
  const timespan = endTime - startTime;
  let padding = timespan * padPrecent * 0.01;

  // if padding is less than 1 second, set it to 1 second
  if (padding < 1000) padding = 1000;

  const paddedStart = utcToZonedTime(startTime - padding, timezoneStr).getTime();
  const paddedEnd = utcToZonedTime(endTime + padding, timezoneStr).getTime();

  return { paddedStart, paddedEnd };
};

type DeviceSubType = string;
type ParameterId = string;
type ParamHash = string;
type NestedCategoryName = string | undefined;
type PossibleFilters = Array<{ name: string; options: Set<number> }>;
type SubTypeToParameterWithFilter = Map<DeviceSubType, Map<ParameterId, PossibleFilters>>;
type ParamHashToNestedCategoryName = Map<ParamHash, NestedCategoryName>;

/**
 * Transforms an AnalyticsParamsResponse into an object containing a map from deviceSubType to parameterId to possible filters,
 * and a map from parameterId to display name.
 * @param analyticsParams - The analytics parameters response data.
 * @returns An object with the mapped device sub types to parameter IDs and their possible filters, and a map from parameter ID to display name.
 */
export function transformAnalyticsParams(analyticsParams: AnalyticsParamsResponse): {
  subTypeToParameterWithFilterMap: SubTypeToParameterWithFilter;
  paramIdToParamObjectMap: Map<ParameterId, DataGraphParametersModel>;
  paramHashToNestedCategoryName: ParamHashToNestedCategoryName;
} {
  const subTypeToParameterWithFilterMap: SubTypeToParameterWithFilter = new Map<
    DeviceSubType,
    Map<ParameterId, PossibleFilters>
  >();
  const paramIdToParamObjectMap: Map<ParameterId, DataGraphParametersModel> = new Map();
  const paramHashToNestedCategoryName: ParamHashToNestedCategoryName = new Map<
    ParamHash,
    NestedCategoryName
  >();

  const processCategory = (
    subType: DeviceSubType,
    category: DataGraphParametersCategoryModelRefined,
    subTypeMap: Map<ParameterId, PossibleFilters>,
    nestedCategoryNames?: string
  ) => {
    // Process each parameterModel to ensure they are all accounted for and collect all parameter IDs
    category.parameterModels?.forEach((parameterModel) => {
      if (parameterModel.id) {
        const paramHash = `${parameterModel.id}_${JSON.stringify(category.filters || null)}`;
        paramHashToNestedCategoryName.set(paramHash, nestedCategoryNames);
        paramIdToParamObjectMap.set(parameterModel.id, parameterModel);
        if (!subTypeMap.has(parameterModel.id)) {
          subTypeMap.set(parameterModel.id, []);
        }
      }
    });

    // Handle the filters in the category
    if (category.filters && category.parameterModels) {
      Object.entries(category.filters).forEach(([filterName, value]) => {
        const options = new Set<number>();
        options.add(value);
        const newFilter = { name: filterName, options };

        // Apply the filter to all parameterModels within this category
        category.parameterModels?.forEach((parameterModel) => {
          if (parameterModel.id) {
            const paramId = parameterModel.id;
            const existingFilters = subTypeMap.get(paramId) || [];
            const existingFilter = existingFilters.find((f) => f.name === filterName);
            if (existingFilter) {
              existingFilter?.options?.add(value);
            } else {
              existingFilters.push(newFilter);
            }
            subTypeMap.set(paramId, existingFilters);
          }
        });
      });
    }

    // Recursively handle sub-categories
    if (category.subCategories) {
      category.subCategories.forEach((subCategory) => {
        let modifiedCategoryNames = subCategory.name;

        modifiedCategoryNames = nestedCategoryNames
          ? nestedCategoryNames + ` / ${subCategory.name}`
          : subCategory.name;

        return processCategory(subType, subCategory, subTypeMap, modifiedCategoryNames);
      });
    }
  };

  for (const deviceType in analyticsParams) {
    for (const deviceSubType in analyticsParams[deviceType]) {
      const category = analyticsParams[deviceType][deviceSubType];
      const subTypeMap: Map<ParameterId, PossibleFilters> = new Map();
      processCategory(deviceSubType, category, subTypeMap);
      subTypeToParameterWithFilterMap.set(deviceSubType, subTypeMap); // Ensure updates are saved back to the result map
    }
  }

  return {
    subTypeToParameterWithFilterMap,
    paramIdToParamObjectMap,
    paramHashToNestedCategoryName,
  };
}

export const convertTsToProjectTime = (ts: number | undefined, timezoneStr: string) =>
  !ts ? undefined : utcToZonedTime(ts, timezoneStr).valueOf();

export const getMeasurementTime = (timeMeasurement: TimeMeasurement, timezoneStr: string) => {
  const measurementStart = timeMeasurement.start?.timestamp
    ? convertTsToProjectTime(timeMeasurement.start.timestamp, timezoneStr)
    : undefined;
  const measurementEnd = timeMeasurement.end?.timestamp
    ? convertTsToProjectTime(timeMeasurement.end.timestamp, timezoneStr)
    : undefined;
  const measurementHover = timeMeasurement.hover?.timestamp
    ? convertTsToProjectTime(timeMeasurement.hover.timestamp, timezoneStr)
    : undefined;

  return {
    measurementStart,
    measurementEnd,
    measurementHover,
  };
};

export const getPowerGraphColors = (isVuGraph: boolean, isFillColor?: boolean) => {
  if (isVuGraph) {
    return isFillColor ? theme.palette.accent.primary[300] : theme.palette.accent.primary.main;
  } else {
    return isFillColor ? theme.palette.accent.secondary[300] : theme.palette.accent.secondary.main;
  }
};

export const convertFormattedTimeToMiliseconds = (formattedTime: string) => {
  const time = formattedTime.split(':');

  if (time.length > 2) {
    return Number(time[0]) * 60 * 60 * 1000 + Number(time[1]) * 60 * 1000 + Number(time[2]) * 1000;
  } else {
    return Number(time[0]) * 60 * 1000 + Number(time[1]) * 1000;
  }
};

export const convertedSummaryTimeSpan = (startTime: number, endTime: number) => {
  const diff = moment
    .utc(moment.duration(moment(endTime).diff(moment(startTime))).as('milliseconds'))
    .format('HH:mm:ss.SSS');

  const daysDiff = moment.duration(moment(endTime).diff(moment(startTime))).as('days');

  const convertedDayPlusTime = diff
    .split(':')
    .map((el, i) => (i === 0 ? Number(el) + 24 : el))
    .join(':');

  return daysDiff > 1 ? convertedDayPlusTime : diff;
};
