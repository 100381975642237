import { Alert, Box, Stack, Typography } from '@mui/material';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Depot, Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { useAppStore } from 'MobxStores/context';
import { ProjectSelectionFormInput } from '../ProjectSelectionInputs/ProjectSelectionFormInput';
import { DeviceNameInput } from '../EditAPFC&POSForms/FormInputs/DeviceNameInput';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import { getProjectMetadata } from '@electreon_ui/shared/stores/projectStore/projectStoreUtils';
import { FormLoading } from '../FormUtils/FormLoading';
import { assignOCPPDeviceToDepot } from '../FormsAPIs/api';
import { OCPPSelectionInput } from '../EditAPFC&POSForms/FormInputs/OCPPSelectionInput';
import { OcppChargerModelRefined } from '@electreon_ui/shared/types/globals';
import { DepotSelectionInput } from '../EditAPFC&POSForms/FormInputs/DepotSelectionInput';
import { AssignOCPPInput, AssignOCPPSchema } from './Utils/FormsSchemas';
import { api } from 'Services/api';
import { OcppParkingSpots } from './OcppParkingSpots';
import { getAndRefreshOcppParkingSpots } from '../ParkingSpotForm/utils/createParkingSpotUtils';
import { getProjectDeployment } from 'Utils/APIUtils';
import { observer } from 'mobx-react-lite';
import { WindowEventService, WindowEvents } from '@electreon_ui/shared/services/WindowEventService';
import { toast } from 'sonner';

interface AssignOcppToDepotFormProps extends FormProps {
  selectedProject?: Project | null;
  selectedDevice?: OcppChargerModelRefined;
}

export const AssignOcppToDepotForm: React.FC<AssignOcppToDepotFormProps> = observer(
  ({ onSuccessfulSubmit, onCancel, selectedProject: _selectedProject, selectedDevice }) => {
    const depotId = selectedDevice?.depotId;
    const { projectStore } = useAppStore();
    const [selectedProject, setSelectedProject] = useState<Project | null>(_selectedProject ?? null);
    const [selectedOcpp, setSelectedOcpp] = useState<OcppChargerModelRefined | null>(
      selectedDevice ? selectedDevice : (projectStore?.ocppList[0] ?? null)
    );
    const [depotList, setDepotList] = useState<Depot[]>([]);
    const [selectedDepot, setSelectedDepot] = useState<Depot | null>(null);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const methods = useForm<AssignOCPPInput>({
      resolver: zodResolver(AssignOCPPSchema),
      defaultValues: {
        ocppId: selectedOcpp?.id || '',
        depotId: selectedDepot?.id,
        parkingSpots: [],
      },
    });
    const showEmptyParkingSpotsAlert = methods.getValues('parkingSpots').length === 0;

    const onSubmitHandler: SubmitHandler<AssignOCPPInput> = (values) => {
      setLoading(true);
      setSubmitError(null);
      const { depotId, ocppId, parkingSpots } = values;
      assignOCPPDeviceToDepot(depotId, ocppId, parkingSpots)
        .then((res) => {
          if (res.status === 200) {
            toast.success('OCPP device assigned');
            methods.reset();
            onSuccessfulSubmit?.();
            getProjectDeployment(selectedProject?.id || '').then((deployment) => {
              projectStore.setProjectDeployment(selectedProject?.id, deployment.data);
            });
            WindowEventService.emit(WindowEvents.SHOULD_UPDATE_DEPLOYMENT, {});
          } else if (res.status === 400) {
            toast.error('OCPP device assignment failed');
            setSubmitError('OCPP Device assignment failed');
            throw new Error('Assigning OCPP device failed');
          } else if (res.status === 409) {
            toast.error('OCPP assignment conflict');
            setSubmitError('OCPP assignment conflict');
            throw new Error('Assigning OCPP device failed');
          } else {
            toast.error('OCPP device assignment failed');
            setSubmitError('OCPP Device assignment failed');
            throw new Error('Assigning OCPP device failed');
          }
        })
        .catch((err) => {
          toast.error('OCPP device assignment failed');
          console.error('Assigning OCPP device error', JSON.stringify(err));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    // update devices lists
    useEffect(() => {
      if (!projectStore?.selectedProject?.id) return;
      setFormLoading(true);
      getProjectMetadata(projectStore.selectedProject.id, projectStore).then((res) => {
        setSelectedOcpp(
          projectStore?.ocppList.find((device) => device.id === selectedDevice?.id) ||
            projectStore?.ocppList[0]
        );
        setFormLoading(false);
      });
    }, [projectStore, selectedDevice?.id]);
    // update depots list
    useEffect(() => {
      const getDepots = async () => {
        if (!selectedProject?.id) return;
        try {
          const depotRes = await api.metadata.depots.getProjectDepots(selectedProject.id);
          if (depotRes) {
            setDepotList(depotRes.data);
            const selectedDepot = depotRes.data.find((depot) => depot.id === selectedDevice?.depotId);
            if (selectedDepot) {
              setSelectedDepot(selectedDepot);
              methods.setValue('depotId', Number(selectedDepot?.id));
            }
          }
        } catch (e) {
          setDepotList([]);
          console.error(JSON.stringify(e));
        }
      };
      getDepots();
    }, [selectedProject, methods, selectedDevice?.depotId]);
    // update parking spots list
    useEffect(() => {
      const updateParkingSpots = async () => {
        if (selectedDepot?.id && selectedOcpp?.id) {
          try {
            const parkingSpots = await getAndRefreshOcppParkingSpots(selectedDepot?.id, selectedOcpp?.id);
            if (selectedDepot?.id === selectedOcpp.depotId) {
              methods.reset(
                { ...methods.getValues(), ocppId: selectedOcpp?.id, parkingSpots },
                { keepDirtyValues: true }
              );
            } else {
              const parkingSpots = methods
                .getValues('parkingSpots')
                .map((parkingSpot) => ({ ...parkingSpot, depotId: selectedDepot.id, id: undefined }));
              methods.reset(
                { ...methods.getValues(), ocppId: selectedOcpp?.id, parkingSpots },
                { keepDirtyValues: true }
              );
            }
          } catch (e) {
            methods.reset(
              { ...methods.getValues(), ocppId: '', parkingSpots: [] },
              { keepDirtyValues: true }
            );
            console.error(JSON.stringify(e));
          }
        }
      };
      updateParkingSpots();
    }, [selectedOcpp?.id, selectedDepot?.id, methods, selectedOcpp?.depotId]);

    return (
      <FormProvider {...methods}>
        <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
          {depotId ? 'Manage Parking Spots' : 'Assign OCPP to Depot'}
        </Typography>
        {showEmptyParkingSpotsAlert && (
          <Alert severity='warning'>Assigned OCPP device should have at least one Parking Spot.</Alert>
        )}
        <Box
          component='form'
          noValidate
          autoComplete='off'
          sx={showEmptyParkingSpotsAlert ? { mt: 4 } : {}}
          onSubmit={methods.handleSubmit(onSubmitHandler, (e) => {
            console.error(e);
          })}
        >
          <ProjectSelectionFormInput
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            disabled
          />
          <Stack direction='row'>
            <DeviceNameInput
              currentDeviceName={selectedOcpp?.name || ''}
              deviceType={'OCPP'}
              disabled={!!selectedDevice}
            />
            <OCPPSelectionInput
              selectedOcpp={selectedOcpp}
              setSelectedOcpp={setSelectedOcpp}
              disabled={!!selectedDevice}
            />
          </Stack>
          <DepotSelectionInput
            depotList={depotList}
            selectedDepot={selectedDepot}
            setSelectedDepot={setSelectedDepot}
            disabled={!!depotId}
          />
          <OcppParkingSpots
            reset={methods?.reset}
            errors={methods?.formState?.errors}
            register={methods?.register}
            control={methods.control}
            ocppName={selectedOcpp?.name || ''}
          />
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormLoading open={formLoading} />
          <FormSubmitionButtons loading={loading} onCancel={onCancel} />
        </Box>
      </FormProvider>
    );
  }
);
