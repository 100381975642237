import { Button } from '@repo/ui';
import { isLocalDevEnv } from 'config';
import LogRocket from 'logrocket';
import { useNavigate, useRouteError } from 'react-router-dom';
import HomeIcon from './homeIcon.svg?react';
import BackIcon from './backIcon.svg?react';

export const ErrorPage: React.FC<{ routeName?: string }> = ({ routeName }) => {
  const navigate = useNavigate();
  const error = useRouteError();
  if (!isLocalDevEnv) {
    LogRocket.captureException(new Error(JSON.stringify(error, null, 2)), {
      tags: { error: 'Application crashed' },
    });
  }

  console.error(error);

  return (
    <div
      role='alert'
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1 className='text-3xl mb-2'>Something went wrong!</h1>
      {routeName && <p className='text-sm'>Route: {routeName}</p>}
      <p className='text-sm'>Notification has been sent to the development team.</p>
      <div className='flex flex-row gap-2 mt-4'>
        <Button variant='outline' className='flex gap-2 h-8 px-2' onClick={() => navigate(-1)}>
          <BackIcon className='h-4 w-4' /> Back
        </Button>
        <Button variant='outline' className='flex gap-2 h-8 px-2' onClick={() => navigate('/dashboard')}>
          <HomeIcon className='h-4 w-4' /> Home
        </Button>
      </div>
    </div>
  );
};
