
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';

import { object, string, TypeOf } from 'zod';

import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';

export const deviceEnergyMeterValueSchema = object({
    energyMeterValue: string().min(1, 'Energy meter value is required'),
    dcSamplerIndex: string().min(1, 'dcSampler index is required'),
});

export type DeviceEnergyMeterValueInput = TypeOf<typeof deviceEnergyMeterValueSchema>;

export interface DeviceEnergyMeterValueFormProps extends FormProps {
    device: AbstractIoTDeviceModelRefined;
    selectedProject?: Project | null;
}

