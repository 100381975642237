import {
  MuResetTypes,
  ResetDeviceApi,
  ResetDeviceInput,
  VuResetTypes,
} from 'Components/Forms/DeviceResetOptionsForm/DeviceResetUtils';
import { useState } from 'react';
import { SubmitHandler, UseFormReset } from 'react-hook-form';
import { AbstractIoTDeviceModelRefined } from '@electreon_ui/shared/types/globals';
import { toast } from 'sonner';

export const useDeviceResetFormLogic = (
  device: AbstractIoTDeviceModelRefined,
  reset: UseFormReset<ResetDeviceInput>,
  onSuccessfulSubmit: () => void
) => {
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const deviceType = device?.deviceType;
  const deviceTypeName =
    deviceType === 'MU' ? 'Management Unit' : deviceType === 'VU' ? 'Vehicle Unit' : 'Device';
  const DeviceResetTypes =
    deviceType === 'MU'
      ? [...MuResetTypes]
      : deviceType === 'VU'
        ? [...VuResetTypes]
        : [...MuResetTypes, ...VuResetTypes];

  const onSubmitHandler: SubmitHandler<ResetDeviceInput> = (values) => {
    setSubmitError(null);
    const delay = Number(values.delay);
    const resetType = values.resetType;
    setLoading(true);
    ResetDeviceApi(device?.id || '', resetType, deviceType, delay)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Device reseted');
          reset();
          onSuccessfulSubmit?.();
        } else if (res.status === 400) {
          toast.error('Device reset failed');
          setSubmitError('Device reset failed');
          throw new Error('Reset device failed');
        } else if (res.status === 422) {
          toast.error('Could Not Reset Device, Lock Device and Try Again');
          setSubmitError('Could Not Reset Device, Lock Device and Try Again');
          throw new Error('Reset device failed');
        } else {
          //todo: what are possible errors??
          toast.error('Device reset failed');
          setSubmitError('Device reset failed');
          throw new Error('Reset device failed');
        }
      })
      .catch((err) => {
        toast.error('Device reset failed');
        console.error('Reset device error', JSON.stringify(err));
        if (err.message.includes('422')) {
          setSubmitError('Could Not Reset Device, Lock Device and Try Again');
          throw new Error('Reset device failed');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    onSubmitHandler: onSubmitHandler,
    submitError: submitError,
    loading: loading,
    deviceTypeName: deviceTypeName,
    DeviceResetTypes: DeviceResetTypes,
  };
};
