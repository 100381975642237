export const defaultMapOptions: google.maps.MapOptions = {
  // disableDefaultUI: false,
  fullscreenControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  keyboardShortcuts: false,
  styles: [
    // hide poi labels
    { featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'off' }] },
    // disable transit stations
    { featureType: 'transit.station', elementType: 'labels', stylers: [{ visibility: 'off' }] },
  ],
};

export const defaultDrawingManagerOptions: google.maps.drawing.DrawingManagerOptions = {
  drawingMode: google.maps.drawing.OverlayType.POLYGON,
  drawingControl: true,
  drawingControlOptions: {
    position: google.maps.ControlPosition.TOP_CENTER,
    drawingModes: [google.maps.drawing.OverlayType.POLYGON],
  },
  polygonOptions: {
    clickable: true,
    draggable: true,
    editable: true,
  },
};
