import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { MenuItem, Typography, FormControl, InputLabel, Select, Button } from '@mui/material';
import { ActiveVehicleSelection } from 'Components/Header/Components';
import { SelectChangeEvent } from '@mui/material/Select';
import { useAppStore } from 'MobxStores/context';
import { LayoutType, DashoardSelectedVehicleIds } from 'MobxStores/Types';
import { toast } from 'sonner';

const layoutTypes: LayoutType[] = ['singleVehicle', 'multipleVehicles'];

export const DashboardConfigurationForm: React.FC = observer(() => {
  const { dashboardConfigurationStore, popupStore } = useAppStore();

  const [layoutType, setLayoutType] = useState<LayoutType>(dashboardConfigurationStore.layoutType);
  const [selectedVehicleId, setSelectedVehicleId] = useState<DashoardSelectedVehicleIds>({
    first: dashboardConfigurationStore.selectedVehicleId.first,
    second: dashboardConfigurationStore.selectedVehicleId.second,
  });

  const handleLayoutChange = (e: SelectChangeEvent) => {
    setSelectedVehicleId({ first: undefined, second: undefined });
    setLayoutType(e.target.value as LayoutType);
  };

  const handleSubmit = () => {
    toast.success('Dashboard updated');
    dashboardConfigurationStore.setLayoutType(layoutType);
    dashboardConfigurationStore.setSelectedVehicleId(selectedVehicleId);

    popupStore.closeDashboardConfigurationPopup();
  };

  return (
    <>
      <Typography variant='h4' component='h1' sx={{ mb: '2rem' }}>
        Dashboard Configuration
      </Typography>
      <FormControl sx={{ mb: 2 }} size='small'>
        <InputLabel id='layout-label'>Layout Type</InputLabel>
        <Select labelId='layout-label' value={layoutType} label='Layout Type' onChange={handleLayoutChange}>
          {layoutTypes.map((layoutType: LayoutType, i: number) => (
            <MenuItem key={i} value={layoutType}>
              {layoutType === 'multipleVehicles' ? 'Multiple Vehicles' : 'Single Vehicle'}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ActiveVehicleSelection
        layoutType={layoutType}
        setSelectedVehicleId={setSelectedVehicleId}
        selectedVehicleId={selectedVehicleId}
      />
      <Button
        variant='contained'
        fullWidth
        onClick={handleSubmit}
        sx={{ py: '0.8rem', mt: '1rem', height: '45px' }}
      >
        Edit Dashboard Configuration
      </Button>
    </>
  );
});
DashboardConfigurationForm.displayName = 'DashboardConfigurationForm';
