import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { FormControl, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { inputLabelStyles, multiSelectChipContainerStyles, multiSelectChipStyles } from './InputStyles';
import { getApfcMusList } from '../../FormsAPIs/api';
import { useAppStore } from 'MobxStores/context';
import { MuDeviceSubTypeOptions } from '../CreateMuForm';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  SelectMusMenuProps,
  getChipContainerHeight,
} from 'Components/Forms/EditAPFC&POSForms/Utils/EditFormsUtils';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { ManagementUnitModel } from '@electreon/electreon-metadata-service-gen-ts-client';

type AssignedMusSelectionProps = {
  disabled: boolean;
  apfcId: string;
};

export const AssignedMusSelection: React.FC<AssignedMusSelectionProps> = ({ disabled, apfcId }) => {
  const { projectStore } = useAppStore();
  const { register, setValue } = useFormContext();
  const [assignedMus, setAssignedMus] = useState<string[]>([]);
  const selectableMus = projectStore?.muList?.filter(
    (mu) =>
      mu.deviceSubType === MuDeviceSubTypeOptions.DMU_APFC ||
      mu.deviceSubType === MuDeviceSubTypeOptions.DMU_AC ||
      mu.deviceSubType === MuDeviceSubTypeOptions.SMU_AC || //&& !mu.apfcId
      mu.deviceSubType === MuDeviceSubTypeOptions.DMU_APFC_HYBRID ||
      mu.deviceSubType === MuDeviceSubTypeOptions.SMU_DC // this is temporary so that SM0000022 (project Balingen 126 in prod) can be assigned to APFC without changing its subtype
  );

  useEffect(() => {
    getApfcMusList(apfcId)
      .then((res) => {
        const assignedMuIds = res.map((mu) => mu.id!);
        setAssignedMus(assignedMuIds);
        setValue('muIds', assignedMuIds);
      })
      .catch((error) => {
        console.error(`error getting the assigned MUs: ${error}`);
      });
  }, [apfcId, setValue]);

  const handleChange = (event: SelectChangeEvent<typeof assignedMus>) => {
    const {
      target: { value },
    } = event;
    setAssignedMus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormControl sx={{ mb: 2 }} fullWidth>
      <InputLabel sx={inputLabelStyles} id='assigned-mus-label'>
        Assigned MUs
      </InputLabel>
      <Select
        MenuProps={SelectMusMenuProps}
        sx={{ minHeight: getChipContainerHeight(assignedMus.length), maxHeight: '135px' }}
        labelId='assigned-mus-label'
        id='assigned-mus'
        multiple
        value={assignedMus}
        {...register('muIds')}
        onChange={handleChange}
        input={<OutlinedInput id='assigned-mus' label='Assigned MUs' />}
        renderValue={(selected: string[]) => (
          <Box sx={multiSelectChipContainerStyles}>
            {selected.map((value) => (
              <Chip
                clickable
                deleteIcon={<HighlightOffIcon onMouseDown={(event) => event.stopPropagation()} />}
                onDelete={(event) => {
                  setAssignedMus(_.without(assignedMus, value));
                  setValue('muIds', _.without(assignedMus, value));
                }}
                sx={multiSelectChipStyles}
                key={value}
                label={value}
              />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {selectableMus.map((mu) => (
          <MenuItem key={mu.id} value={mu.id}>
            <Typography>{mu.id}</Typography>
            <Typography sx={{ color: theme.palette.accent.primary.main, ml: 1 }} fontSize={12}>
              ({mu.name})
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
