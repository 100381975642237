import { Alert, Box, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SelectProject } from 'Components/Forms/ParkingSpotForm/SelectFields/SelectProject';
import { FormSubmitionButtons } from 'Components/Buttons/FormSubmitAndCancelButtons/FormSubmitionButtons';
import AddIcon from '@mui/icons-material/Add';
import { api } from 'Services/api';
import { ScheduleItem } from 'Components/Forms/ChargingScheduleForm/Components/ScheduleItem';
import { FleetChargingSchedule } from '@electreon/electreon-metadata-service-gen-ts-client';
import { toast } from 'sonner';

const defaultScheduleItem: FleetChargingSchedule = {
  startTime: '12:00',
  endTime: '13:00',
  enabled: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: true,
};

export const ChargingScheduleForm: React.FC<FormProps & { previousSchedule: FleetChargingSchedule[] }> =
  observer(({ onSuccessfulSubmit, onCancel, selectedProject, previousSchedule }) => {
    const [submitError, setSubmitError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState<FleetChargingSchedule[]>(previousSchedule || []);

    const onSubmitHandler = (values: FleetChargingSchedule[]) => {
      if (!selectedProject?.id) {
        setSubmitError('Project is not selected');
        return;
      }
      setSubmitError(null);
      setLoading(true);

      const schedule = values.map((scheduleItem) => {
        const { startTime, endTime, enabled, id, version, projectId, ...weekdays } = scheduleItem;
        const [startHours, startMinutes] = startTime?.split(':').map((item) => parseInt(item)) || [];
        const [endHours, endMinutes] = endTime?.split(':').map((item) => parseInt(item)) || [];
        const paddedStartMinutes = startMinutes < 10 ? `0${startMinutes}` : startMinutes;
        const paddedEndMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;
        const paddedStartHours = startHours < 10 ? `0${startHours}` : startHours;
        const paddedEndHours = endHours < 10 ? `0${endHours}` : endHours;
        return {
          id,
          projectId: selectedProject.id,
          version,
          startTime: `${paddedStartHours}:${paddedStartMinutes}`,
          endTime: `${paddedEndHours}:${paddedEndMinutes}`,
          enabled,
          ...weekdays,
        } satisfies FleetChargingSchedule;
      });

      api.metadata.projects
        .updateProjectChargingSchedules(selectedProject.id, schedule)
        .then((response) => {
          toast.success('Charging schedule updated');
          onSuccessfulSubmit?.();
        })
        .catch((error) => {
          toast.error('Charging schedule update failed');
          setSubmitError(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Box>
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitHandler(schedule);
          }}
        >
          <SelectProject
            selectedProject={selectedProject}
            valueOverride={`${selectedProject?.name} Charging Schedule`}
          />
          <Stack sx={{ gap: 2, mb: 3 }}>
            {schedule.length
              ? schedule.map((scheduleItem, index) => (
                  <React.Fragment key={index}>
                    <ScheduleItem
                      scheduleItem={scheduleItem}
                      schedule={schedule}
                      index={index}
                      updateSchedule={setSchedule}
                    />
                  </React.Fragment>
                ))
              : null}
          </Stack>
          <Stack direction='row' justifyContent='center' alignItems='center' sx={{ mb: 3 }}>
            <Button
              variant='outlined'
              startIcon={<AddIcon />}
              onClick={() => {
                const newForm = [...schedule];
                newForm.push(defaultScheduleItem);
                setSchedule(newForm);
              }}
            >
              Add Schedule Item
            </Button>
          </Stack>
          {submitError && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {submitError}
            </Alert>
          )}
          <FormSubmitionButtons loading={loading} onCancel={onCancel} submitLabel='Set Schedule' />
        </Box>
      </Box>
    );
  });
