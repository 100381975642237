import { Avatar, Skeleton, Typography } from '@mui/material';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';
import { Project } from '@electreon/electreon-metadata-service-gen-ts-client';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react/jsx-runtime';
import { useProjectLogo } from 'Utils/ProjectUtils';

export const ProjectNameDisplay = observer(({ project }: { project: Project }) => {
  const { data: projectLogo } = useProjectLogo(String(project?.id), 'small');
  return (
    <Fragment>
      {projectLogo ? (
        <Avatar
          alt='project logo'
          src={projectLogo}
          className='project-logo-avatar'
          sx={{
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              backgroundColor: 'transparent',
            },
          }}
        />
      ) : (
        <Skeleton variant='circular' sx={{ aspectRatio: '1/1' }} />
      )}
      <Typography sx={{ ml: 2 }}>{project.name}</Typography>
      <Typography sx={{ color: theme.palette.accent.primary.main, ml: 1 }} fontSize={12}>
        (ID: {project.id})
      </Typography>
    </Fragment>
  );
});
