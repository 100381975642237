import { CSSProperties } from 'react';

export const ElectreonLoader: React.FC<{
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  iconHeight?: string;
  iconWidth?: string;
  margin?: string;
  style?: React.CSSProperties;
}> = ({
  iconHeight = '200px',
  iconWidth = '200px',
  margin = 'auto',
  width = '100%',
  height = 'calc(100vh - 64px)',
  style,
}) => {
  return (
    <div
      style={{
        width,
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        style={{
          margin: margin,
          background: 'transparent',
          display: 'block',
          shapeRendering: 'auto',
        }}
        width={iconWidth}
        height={iconHeight}
        viewBox='0 0 100 100'
        preserveAspectRatio='xMidYMid'
        data-darkreader-inline-bgimage=''
        data-darkreader-inline-bgcolor=''
      >
        <path
          fill='none'
          stroke='#01d2fd'
          strokeWidth='9'
          strokeDasharray='228.36414611816406 28.224782104492192'
          d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z'
          strokeLinecap='round'
          style={{ transform: 'scale(0.8)', transformOrigin: '50px 50px' }}
          data-darkreader-inline-stroke=''
        >
          <animate
            attributeName='stroke-dashoffset'
            repeatCount='indefinite'
            dur='2.564102564102564s'
            keyTimes='0;1'
            values='0;256.58892822265625'
          />
        </path>
      </svg>
    </div>
  );
};
