import { Avatar, Box, IconButton } from '@mui/material';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import { controlPanelIconStyles } from 'Components/Header/Styles/HeaderStyles';
import { useAppStore } from 'MobxStores/context';

export const SelectActiveVehicleIcon: React.FC = () => {
  const { popupStore } = useAppStore();

  return (
    <Box onClick={popupStore.openDashboardConfigurationPopup}>
      <IconButton sx={{ height: 'min-content', padding: 0 }}>
        <Avatar sx={controlPanelIconStyles}>
          <AirportShuttleIcon />
        </Avatar>
      </IconButton>
    </Box>
  );
};
