import 'Screens/DepotDashboard/DepotOverviewTable/DepotOverviewTable.scss';
import { Box, Typography, Avatar, Skeleton, Stack, Button } from '@mui/material';
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from '@mui/x-data-grid';
import { formatDateString, alertDataLargeStyle, openInNewTab } from 'Screens/AlertDashboard/alertUtils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTimezoneStr } from 'CustomHooks/useTimezoneStr';
import { RuleSeverityEnum } from '@electreon/electreon-metadata-service-gen-ts-client';
import { AlertMessage } from 'MobxStores/utils/alertStoreTypes';
import { observer } from 'mobx-react-lite';
import { useProjectLogo } from 'Utils/ProjectUtils';
import { isVu } from '@electreon_ui/shared/utils/globalUtils';
import { rootStore } from 'MobxStores/context';

const columnFlexRatios = [0.3, 0.3, 0.8, 1.1, 3.5, 1.3, 1.2, 0.5];

type ColumnHeaderProps = GridColumnHeaderParams<any, any, any> & {
  isEmphasized?: boolean;
};

const ColumnHeader: React.FC<ColumnHeaderProps> = ({ colDef, isEmphasized }) => {
  return (
    <Typography overflow='hidden' noWrap textOverflow='ellipsis'>
      {colDef.headerName}
    </Typography>
  );
};

export const alertsTableColumns: GridColDef<AlertMessage>[] = [
  {
    field: 'projectID',
    headerName: ' ',
    flex: columnFlexRatios[0],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params) => {
      return <div style={{ backgroundColor: 'transparent', width: '100%', height: '100%' }}> </div>;
    },
  },
  {
    field: 'notificationType',
    headerName: '',
    flex: columnFlexRatios[1],
    renderHeader: (params) => <ColumnHeader {...params} />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => {
      const isActive = params.row.isActive; // isActive=false means that alert is recovered and should display "check" icon
      const highSeverity = params.row.severity === RuleSeverityEnum.High;
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '6vh',
            }}
          >
            {isActive === true && highSeverity ? (
              <ErrorIcon style={{ fontSize: 24, color: '#D32F2F' }} />
            ) : isActive === false ? (
              <CheckCircleIcon style={{ fontSize: 24, color: '#138520' }} />
            ) : (
              ''
            )}
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'alertTime',
    headerName: 'Time',
    flex: columnFlexRatios[5],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => <AlertTimeCell {...params} />,
  },
  {
    field: 'projectName',
    headerName: 'Project',
    flex: columnFlexRatios[6],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => <ProjectNameCell {...params} />,
  },
  {
    field: 'origin',
    headerName: 'Device ID',
    flex: columnFlexRatios[2],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => {
      return (
        <Typography
          variant='dynamicValue'
          overflow='hidden'
          noWrap
          textOverflow='ellipsis'
          sx={alertDataLargeStyle}
        >
          {params.row.origin || ''}
        </Typography>
      );
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: columnFlexRatios[3],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => {
      return (
        <Typography overflow='hidden' noWrap textOverflow='ellipsis' sx={alertDataLargeStyle}>
          {params.row.deviceName || ''}
        </Typography>
      );
    },
  },
  {
    field: 'alertDescription',
    headerName: 'Description',
    flex: columnFlexRatios[4],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => {
      return (
        <Typography sx={{ ...alertDataLargeStyle, whiteSpace: 'break-spaces' }}>
          {params.row.alertDescription}
        </Typography>
      );
    },
  },
  {
    field: 'inlarge',
    headerName: '',
    flex: columnFlexRatios[7],
    renderHeader: (params) => <ColumnHeader {...params} isEmphasized />,
    renderCell: (params: GridRenderCellParams<AlertMessage>) => {
      if (!params.row.origin) {
        return '';
      }
      const projectId = params.row.projectId;
      return (
        <Button
          sx={{
            '&.MuiButtonBase-root:active': {
              backgroundColor: 'transparent !important',
              borderStyle: 'outset',
              outline: 'none',
              boxShadow: 'none',
              border: 'none',
            },
          }}
          onClick={(e) => {
            if (params.row.origin) {
              const device = rootStore.projectStore?.deviceIdToIOTDeviceModelMap.get(params.row.origin);
              const paramKey = isVu(device) ? 'vu' : 'charger';
              openInNewTab(`/dashboard/integration/${projectId}?${paramKey}=${params.row.origin}`);
            }
          }}
        >
          <OpenInNewIcon sx={{ fontSize: '14px', fontStyle: 'bold', transform: 'translateY(1px)' }} />
        </Button>
      );
    },
  },
];

const AlertTimeCell: React.FC<GridRenderCellParams<AlertMessage>> = observer((params) => {
  const timezoneStr = useTimezoneStr();
  const formattedDateString = formatDateString(params.row.alertTime, timezoneStr);
  let date = formattedDateString.split(' ');

  return (
    <Typography
      variant='dynamicValue'
      overflow='hidden'
      noWrap
      textOverflow='ellipsis'
      sx={alertDataLargeStyle}
    >
      {date[0]}
      {', '}
      {date[1]}
    </Typography>
  );
});

const ProjectNameCell: React.FC<GridRenderCellParams<AlertMessage>> = observer((params) => {
  const projectId = String(params.row?.projectId);
  const { data: projectLogo } = useProjectLogo(projectId, 'small');
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {projectLogo ? (
        <Avatar
          alt='project logo'
          src={projectLogo}
          className='project-logo-avatar'
          sx={{
            '& img': {
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              backgroundColor: 'transparent',
            },
          }}
        />
      ) : (
        <Skeleton variant='circular' sx={{ aspectRatio: '1/1', width: '40px', height: '40px' }} />
      )}
      <Typography overflow='hidden' noWrap textOverflow='ellipsis' sx={alertDataLargeStyle}>
        {params.row.projectName}
      </Typography>
    </Stack>
  );
});
