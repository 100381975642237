import { Box, Button, Stack, Switch, Typography, useTheme } from '@mui/material';
import { CustomTimePicker } from 'Components/Forms/ChargingScheduleForm/Components/CustomTimePicker';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from 'moment';
import { FleetChargingSchedule } from '@electreon/electreon-metadata-service-gen-ts-client';
import { theme } from '@electreon_ui/shared/Themes/globalTheme';

type Weekday = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const isWeekdayEntry = (entry: [string, boolean]): entry is [Weekday, boolean] => {
  // if its sunday, monday, tuesday, wednesday, thursday, friday, saturday return true
  return (
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].includes(entry[0]) &&
    typeof entry[1] === 'boolean'
  );
};

export const ScheduleItem: React.FC<{
  scheduleItem: FleetChargingSchedule;
  index: number;
  updateSchedule: (newSchedule: FleetChargingSchedule[]) => void;
  schedule: FleetChargingSchedule[];
}> = ({ scheduleItem, index, updateSchedule, schedule }) => {
  const deleteScheduleItem = () => {
    const newForm = [...schedule];
    newForm.splice(index, 1);
    updateSchedule(newForm);
  };

  const updateScheduleItem = (newScheduleItem: FleetChargingSchedule) => {
    const newForm = [...schedule];
    newForm[index] = newScheduleItem;
    updateSchedule(newForm);
  };

  return (
    <Stack
      spacing={1.5}
      key={index}
      sx={{
        border: `1px solid ${theme.palette.accent.gray[100]}`,
        p: 2,
        borderRadius: 2,
      }}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%'>
        <Stack
          direction='row'
          alignItems='center'
          width='min-content'
          sx={{
            pointerEvents: !scheduleItem.enabled ? 'none' : 'all',
            opacity: !scheduleItem.enabled ? 0.5 : 1,
          }}
        >
          <CustomTimePicker
            label='Start Time'
            value={moment({
              hours: scheduleItem.startTime?.split(':').map((item) => parseInt(item))[0],
              minutes: scheduleItem.startTime?.split(':').map((item) => parseInt(item))[1],
            })}
            onChange={(value) => {
              if (!value) return;
              updateScheduleItem({
                ...scheduleItem,
                startTime: moment(value).format('HH:mm'),
              });
            }}
          />
          <Typography sx={{ mx: 0.5 }}>&mdash;</Typography>
          <CustomTimePicker
            label='End Time'
            value={moment({
              hours: scheduleItem.endTime?.split(':').map((item) => parseInt(item))[0],
              minutes: scheduleItem.endTime?.split(':').map((item) => parseInt(item))[1],
            })}
            onChange={(value) => {
              if (!value) return;
              updateScheduleItem({
                ...scheduleItem,
                endTime: moment(value).format('HH:mm'),
              });
            }}
          />
        </Stack>
        <Switch
          checked={scheduleItem.enabled}
          onChange={(_, checked) => {
            updateScheduleItem({
              ...scheduleItem,
              enabled: checked,
            });
          }}
        />
      </Stack>
      <Stack direction='row' justifyContent='space-between' width='100%'>
        <Stack
          direction='row'
          gap={1}
          sx={{
            pointerEvents: !scheduleItem.enabled ? 'none' : 'all',
            opacity: !scheduleItem.enabled ? 0.5 : 1,
          }}
        >
          {Object.entries(scheduleItem)
            .filter(isWeekdayEntry)
            .map(([key, selected], i) => {
              // const day = key as keyof typeof scheduleItem.weekdays;
              return (
                <Box
                  key={`${key}-${i}`}
                  sx={{
                    width: 28,
                    height: 28,
                    borderRadius: '50%',
                    backgroundColor: selected ? theme.palette.accent.primary.main : 'transparent',
                    border: `1px solid ${theme.palette.accent.gray[300]}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '&&:hover': {
                      opacity: selected ? 0.8 : 1,
                      backgroundColor: selected
                        ? theme.palette.accent.primary.main
                        : theme.palette.accent.primary[100],
                    },
                    transition: 'all 0.2s ease-in-out',
                  }}
                  onClick={() => {
                    updateScheduleItem({
                      ...scheduleItem,
                      [key]: !scheduleItem[key],
                    });
                  }}
                >
                  <Typography>{key[0].toUpperCase()}</Typography>
                </Box>
              );
            })}
        </Stack>
        <Button style={{ border: 'none', background: 'none' }} onClick={deleteScheduleItem}>
          <DeleteOutlineIcon
            titleAccess='Delete Item'
            sx={{
              height: '25px',
              width: '25px',
              color: theme.palette.accent.gray[500],
            }}
          />
        </Button>
      </Stack>
    </Stack>
  );
};
