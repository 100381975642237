import * as DTS from '@electreon/electreon-device-telemetry-service-gen-ts-client';
import { AxiosInstance } from 'axios';

export class DeviceTelemetryService {
  public AlertsApi: DTS.AlertsControllerApi;
  public AnalyticsApi: DTS.AnalyticsTelemetryControllerApi;
  public MUTelemetryApi: DTS.ManagementUnitTelemetryControllerApi;
  public TelemetryExportApi: DTS.TelemetryExportControllerApi;
  public VUTelemetryApi: DTS.VehicleUnitTelemetryControllerApi;
  public DepotDashboardApi: DTS.DepotDashboardControllerApi;
  public ReportsApi: DTS.ReportsControllerApi;

  constructor(basePath: string, axios: AxiosInstance, config?: DTS.Configuration) {
    this.AlertsApi = new DTS.AlertsControllerApi(config, basePath, axios);
    this.AnalyticsApi = new DTS.AnalyticsTelemetryControllerApi(config, basePath, axios);
    this.MUTelemetryApi = new DTS.ManagementUnitTelemetryControllerApi(config, basePath, axios);
    this.TelemetryExportApi = new DTS.TelemetryExportControllerApi(config, basePath, axios);
    this.VUTelemetryApi = new DTS.VehicleUnitTelemetryControllerApi(config, basePath, axios);
    this.DepotDashboardApi = new DTS.DepotDashboardControllerApi(config, basePath, axios);
    this.ReportsApi = new DTS.ReportsControllerApi(config, basePath, axios);
  }
}
